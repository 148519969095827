/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateChecklistMessage
 */
export interface UpdateChecklistMessage {
  /**
   *
   * @type {string}
   * @memberof UpdateChecklistMessage
   */
  instance?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateChecklistMessage
   */
  content?: string;
}

/**
 * Check if a given object implements the UpdateChecklistMessage interface.
 */
export function instanceOfUpdateChecklistMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateChecklistMessageFromJSON(json: any): UpdateChecklistMessage {
  return UpdateChecklistMessageFromJSONTyped(json, false);
}

export function UpdateChecklistMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateChecklistMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    instance: !exists(json, 'instance') ? undefined : json['instance'],
    content: !exists(json, 'content') ? undefined : json['content'],
  };
}

export function UpdateChecklistMessageToJSON(value?: UpdateChecklistMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    instance: value.instance === undefined ? undefined : value.instance,
    content: value.content,
  };
}
