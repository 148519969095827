/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContractChecklistGroup } from './ContractChecklistGroup';
import {
  ContractChecklistGroupFromJSON,
  ContractChecklistGroupFromJSONTyped,
  ContractChecklistGroupToJSON,
} from './ContractChecklistGroup';

/**
 *
 * @export
 * @interface ContractChecklist
 */
export interface ContractChecklist {
  /**
   *
   * @type {string}
   * @memberof ContractChecklist
   */
  description?: string;
  /**
   *
   * @type {Array<ContractChecklistGroup>}
   * @memberof ContractChecklist
   */
  groups?: Array<ContractChecklistGroup>;
}

/**
 * Check if a given object implements the ContractChecklist interface.
 */
export function instanceOfContractChecklist(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContractChecklistFromJSON(json: any): ContractChecklist {
  return ContractChecklistFromJSONTyped(json, false);
}

export function ContractChecklistFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractChecklist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    groups: !exists(json, 'groups') ? undefined : (json['groups'] as Array<any>).map(ContractChecklistGroupFromJSON),
  };
}

export function ContractChecklistToJSON(value?: ContractChecklist | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    groups: value.groups === undefined ? undefined : (value.groups as Array<any>).map(ContractChecklistGroupToJSON),
  };
}
