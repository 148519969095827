/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountUpdateMessage
 */
export interface AccountUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof AccountUpdateMessage
   */
  password?: string;
  /**
   *
   * @type {number}
   * @memberof AccountUpdateMessage
   */
  organizationIdForSummaries?: number;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateMessage
   */
  summaries?: AccountUpdateMessageSummariesEnum;
  /**
   * Set to NONE to turn off all notifications using SMS.
   * @type {string}
   * @memberof AccountUpdateMessage
   */
  receiveReminders?: AccountUpdateMessageReceiveRemindersEnum;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateMessage
   */
  email?: string;
}

/**
 * @export
 */
export const AccountUpdateMessageSummariesEnum = {
  None: 'NONE',
  All: 'ALL',
  Scheduled: 'SCHEDULED',
} as const;
export type AccountUpdateMessageSummariesEnum =
  (typeof AccountUpdateMessageSummariesEnum)[keyof typeof AccountUpdateMessageSummariesEnum];

/**
 * @export
 */
export const AccountUpdateMessageReceiveRemindersEnum = {
  None: 'NONE',
  Sms: 'SMS',
} as const;
export type AccountUpdateMessageReceiveRemindersEnum =
  (typeof AccountUpdateMessageReceiveRemindersEnum)[keyof typeof AccountUpdateMessageReceiveRemindersEnum];

/**
 * Check if a given object implements the AccountUpdateMessage interface.
 */
export function instanceOfAccountUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AccountUpdateMessageFromJSON(json: any): AccountUpdateMessage {
  return AccountUpdateMessageFromJSONTyped(json, false);
}

export function AccountUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: !exists(json, 'password') ? undefined : json['password'],
    organizationIdForSummaries: !exists(json, 'organizationIdForSummaries')
      ? undefined
      : json['organizationIdForSummaries'],
    summaries: !exists(json, 'summaries') ? undefined : json['summaries'],
    receiveReminders: !exists(json, 'receiveReminders') ? undefined : json['receiveReminders'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function AccountUpdateMessageToJSON(value?: AccountUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    organizationIdForSummaries: value.organizationIdForSummaries,
    summaries: value.summaries,
    receiveReminders: value.receiveReminders,
    email: value.email,
  };
}
