/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RefreshTokenMessage
 */
export interface RefreshTokenMessage {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenMessage
   */
  refreshToken: string;
}

/**
 * Check if a given object implements the RefreshTokenMessage interface.
 */
export function instanceOfRefreshTokenMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'refreshToken' in value;

  return isInstance;
}

export function RefreshTokenMessageFromJSON(json: any): RefreshTokenMessage {
  return RefreshTokenMessageFromJSONTyped(json, false);
}

export function RefreshTokenMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    refreshToken: json['refreshToken'],
  };
}

export function RefreshTokenMessageToJSON(value?: RefreshTokenMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    refreshToken: value.refreshToken,
  };
}
