/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimekeepingCorrection
 */
export interface TimekeepingCorrection {
  /**
   *
   * @type {string}
   * @memberof TimekeepingCorrection
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof TimekeepingCorrection
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof TimekeepingCorrection
   */
  hours: number;
  /**
   *
   * @type {string}
   * @memberof TimekeepingCorrection
   */
  notes: string;
  /**
   *
   * @type {string}
   * @memberof TimekeepingCorrection
   */
  correctionType: TimekeepingCorrectionCorrectionTypeEnum;
}

/**
 * @export
 */
export const TimekeepingCorrectionCorrectionTypeEnum = {
  Plus: 'PLUS',
  Minus: 'MINUS',
} as const;
export type TimekeepingCorrectionCorrectionTypeEnum =
  (typeof TimekeepingCorrectionCorrectionTypeEnum)[keyof typeof TimekeepingCorrectionCorrectionTypeEnum];

/**
 * Check if a given object implements the TimekeepingCorrection interface.
 */
export function instanceOfTimekeepingCorrection(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'hours' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'correctionType' in value;

  return isInstance;
}

export function TimekeepingCorrectionFromJSON(json: any): TimekeepingCorrection {
  return TimekeepingCorrectionFromJSONTyped(json, false);
}

export function TimekeepingCorrectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimekeepingCorrection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    date: json['date'],
    hours: json['hours'],
    notes: json['notes'],
    correctionType: json['correctionType'],
  };
}

export function TimekeepingCorrectionToJSON(value?: TimekeepingCorrection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    date: value.date,
    hours: value.hours,
    notes: value.notes,
    correctionType: value.correctionType,
  };
}
