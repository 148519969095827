import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../../library/components/d-popup.js';
import '../../library/editors/elements/d-checkbox';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/editors/elements/d-select-dropdown';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import './d-new-issue';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { IssueUpdateMessage } from 'src/store/api';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { DNewIssue, NewIssueEditItem } from 'src/content/issues/d-new-issue';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface NewIssueInput {
  organizationId: number;
  currentEmployeeUuid: string;
  recipientOptions: SelectDropdownOption[];
  createIssueAction: (id: string, message: IssueUpdateMessage) => void;
}
export interface NewIssueResult {
  action: 'done';
}

/**
 *
 * USAGE:
 *    d-application
 *
 */
@customElement('new-issue-dialog')
export class NewIssueDialog extends BaseDialog<NewIssueInput, NewIssueResult> {
  @property({ type: Array })
  recipientOptions: SelectDropdownOption[] = [];
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  currentEmployeeUuid: string = '';
  @property({ type: Object })
  newIssueEditItem: NewIssueEditItem = {
    description: '',
    recipient: '',
    classified: false,
    anonymous: false,
    eventDate: '',
    immediateMeasures: '',
    furtherMeasures: '',
    processing: 'DEFAULT',
  };
  @property({ type: Boolean })
  issueRegistered = false;
  @property({ type: Boolean })
  registrationComplete = false;
  width = 600;
  @query('d-new-issue')
  private newIssueElm?: DNewIssue;

  protected get calculatedTitle(): string {
    if (this.registrationComplete) {
      return 'Takk!';
    }
    return 'Ny avviksmelding';
  }

  protected get calculatedFooterActions(): ActionInput[] {
    if (this.registrationComplete) {
      return [{ name: 'Lukk', action: 'close' }];
    }
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.sendDisabled },
    ];
  }

  private get sendDisabled() {
    return isEmptyOrInvalidString(this.newIssueEditItem.description) && !this.issueRegistered;
  }

  onNewIssueEditItemChanged(e) {
    e.stopPropagation();
    this.newIssueEditItem = e.detail.value;
  }

  onIssueRegistered() {
    this.issueRegistered = true;
  }

  onRegistrationComplete() {
    this.registrationComplete = true;
  }

  renderBody() {
    if (this.newIssueEditItem) {
      return html`
        <d-new-issue
          .editItem=${this.newIssueEditItem}
          .recipientOptions=${this.recipientOptions}
          .organizationId=${this.organizationId}
          .currentEmployeeUuid=${this.currentEmployeeUuid}
          .issueRegistered=${this.issueRegistered}
          .registrationComplete=${this.registrationComplete}
          @new-issue-edit-item-changed=${(e) => this.onNewIssueEditItemChanged(e)}
          @issue-registered=${() => this.onIssueRegistered()}
          @registration-complete=${() => this.onRegistrationComplete()}
          @close=${(e) => this.onClose(e)}
        ></d-new-issue>
      `;
    }
    return html``;
  }

  protected async onDispatchAction(disabled: boolean, action: string) {
    if (this.newIssueElm) {
      if (this.registrationComplete) {
        this.newIssueElm.close();
      } else if (this.issueRegistered) {
        if (action === 'cancel') {
          this.newIssueElm.close();
        }
        if (action === 'send') {
          this.newIssueElm.saveAdditionalInformation();
        }
      } else if (action === 'send' && !disabled) {
        this.newIssueElm.newIssue();
      } else if (action === 'cancel') {
        this.newIssueElm.close();
      }
    }
  }

  protected fetchResult(_detail: string | undefined): NewIssueResult {
    return {
      action: 'done',
    };
  }

  protected initializeDialog(input: NewIssueInput) {
    this.organizationId = input.organizationId;
    this.currentEmployeeUuid = input.currentEmployeeUuid;
    this.recipientOptions = input.recipientOptions;
    this.newIssueEditItem = {
      description: '',
      recipient: input.recipientOptions[0].value,
      classified: false,
      anonymous: false,
      eventDate: '',
      immediateMeasures: '',
      furtherMeasures: '',
      processing: 'DEFAULT',
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-issue-dialog': NewIssueDialog;
  }
}
