/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserCreateMessage
 */
export interface UserCreateMessage {
  /**
   *
   * @type {string}
   * @memberof UserCreateMessage
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserCreateMessage
   */
  password?: string;
}

/**
 * Check if a given object implements the UserCreateMessage interface.
 */
export function instanceOfUserCreateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserCreateMessageFromJSON(json: any): UserCreateMessage {
  return UserCreateMessageFromJSONTyped(json, false);
}

export function UserCreateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    password: !exists(json, 'password') ? undefined : json['password'],
  };
}

export function UserCreateMessageToJSON(value?: UserCreateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
  };
}
