import { createSelector } from '@reduxjs/toolkit';
import { userPropertiesForCurrentOrganization } from 'src/store';

export interface UiSettings {
  isSimplifiedUi: boolean;
}

export const uiSettings = createSelector([userPropertiesForCurrentOrganization], function (userProperties): UiSettings {
  return (
    userProperties.uiSettings || {
      isSimplifiedUi: false,
    }
  );
});
