/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EventOccurrenceRelatedAssetUpdateMessage
 */
export interface EventOccurrenceRelatedAssetUpdateMessage {
  /**
   * The status of the asset relating to this event.
   * @type {string}
   * @memberof EventOccurrenceRelatedAssetUpdateMessage
   */
  assetStatus: EventOccurrenceRelatedAssetUpdateMessageAssetStatusEnum;
  /**
   * Comment on the status update
   * @type {string}
   * @memberof EventOccurrenceRelatedAssetUpdateMessage
   */
  comment: string;
  /**
   * The related asset uuid.
   * @type {string}
   * @memberof EventOccurrenceRelatedAssetUpdateMessage
   */
  assetUuid: string;
  /**
   * The event occurrence uuid.
   * @type {string}
   * @memberof EventOccurrenceRelatedAssetUpdateMessage
   */
  uuid: string;
}

/**
 * @export
 */
export const EventOccurrenceRelatedAssetUpdateMessageAssetStatusEnum = {
  None: 'NONE',
  Ok: 'OK',
  NotOk: 'NOT_OK',
} as const;
export type EventOccurrenceRelatedAssetUpdateMessageAssetStatusEnum =
  (typeof EventOccurrenceRelatedAssetUpdateMessageAssetStatusEnum)[keyof typeof EventOccurrenceRelatedAssetUpdateMessageAssetStatusEnum];

/**
 * Check if a given object implements the EventOccurrenceRelatedAssetUpdateMessage interface.
 */
export function instanceOfEventOccurrenceRelatedAssetUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'assetStatus' in value;
  isInstance = isInstance && 'comment' in value;
  isInstance = isInstance && 'assetUuid' in value;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function EventOccurrenceRelatedAssetUpdateMessageFromJSON(json: any): EventOccurrenceRelatedAssetUpdateMessage {
  return EventOccurrenceRelatedAssetUpdateMessageFromJSONTyped(json, false);
}

export function EventOccurrenceRelatedAssetUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EventOccurrenceRelatedAssetUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assetStatus: json['assetStatus'],
    comment: json['comment'],
    assetUuid: json['assetUuid'],
    uuid: json['uuid'],
  };
}

export function EventOccurrenceRelatedAssetUpdateMessageToJSON(
  value?: EventOccurrenceRelatedAssetUpdateMessage | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assetStatus: value.assetStatus,
    comment: value.comment,
    assetUuid: value.assetUuid,
    uuid: value.uuid,
  };
}
