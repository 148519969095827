import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';
import { openCriiptoAuth, openHelseIdAuth } from 'src/store/oauth-browser';
import { displayAlert } from 'src/utilities/display-alert';

/**
 *
 * STATUS OK
 */
@customElement('d-account-secured')
export class DAccountSecured extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .outskirts {
      margin: 0 auto;
      height: 100vh;
      overflow: auto;
    }

    .outskirts d-application-header {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader {
      margin: 0 auto;
      padding: 0 20px;
      width: 768px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader > div {
      display: flex;
      justify-content: space-between;
      position: relative;
      color: white;
      height: var(--applicationHeaderHeight);
      box-sizing: border-box;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    }

    .outskirtsHeader > div > div {
      display: flex;
    }

    .outskirtsHeader .logo {
      flex: none;
      width: 137px;
      height: 45px;
      margin-bottom: -5px;
      background: url(/images/trinnvis-logo.svg) -2px 10px no-repeat;
      background-size: 97px 25px;
      text-indent: -10000px;
    }

    .outskirtsHeader a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
      text-decoration: none;
      cursor: hand;
    }

    .outskirtsHeader a:last-child {
      margin-right: -20px;
    }

    .outskirts a {
      color: white;
    }

    .outskirts input,
    .outskirts .selectWrapper,
    .outskirts iron-autogrow-textarea {
      border-radius: 4px;
    }

    .outskirts input,
    .outskirts select,
    .outskirts iron-autogrow-textarea {
      font-family: var(--mainSans);
    }

    .outskirts h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 120%;
      color: white;
      margin-bottom: 20px;
    }

    .outskirts h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts p,
    .outskirts ul {
      font-size: 16px;
      font-weight: 300;
      line-height: 140%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts ul {
      margin-left: 18px;
    }

    .outskirts #label.d-label {
      font-weight: 500;
    }

    .outskirts d-section #flexWrapper {
      margin: 0 -5px;
      border: none;
    }

    .outskirts #flexWrapper > d-section-element,
    .outskirts #flexWrapper > d-edit-text,
    .outskirts #flexWrapper > d-select,
    .outskirts #flexWrapper > d-select-access-level,
    .outskirts #flexWrapper > d-universal-selector,
    .outskirts #flexWrapper > d-edit-textarea,
    .outskirts #flexWrapper > d-prepare-select {
      border: none;
      padding: 8px 0 6px 0;
    }

    .outskirts #flexWrapper > * > .sectionElementInner {
      border: none;
      padding: 0 6px;
    }

    .outskirts d-edit-text[disabled] {
      opacity: 1;
    }

    .outskirts d-edit-text[disabled] input {
      opacity: 0.5;
    }

    .outskirts d-application-header a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
    }

    .outskirts .tooltipTrigger {
      color: var(--themeColorDarkerTwo);
    }

    .outskirtsContent {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .outskirtsContent {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .secureLogin {
      margin-top: 10px;
      border: 1px solid hsla(0, 0%, 100%, 0.4);
      border-radius: 12px;
      padding: 106px 30px 30px 30px;
      background: transparent url(/images/personal-data-items-gray.svg) 50% 20px no-repeat;
      background-size: 70px 70px;
      text-align: center;
    }

    #loginForm {
      max-width: 300px;
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .secureLogin h2 {
      font-size: 20px;
      line-height: 120%;
      color: white;
    }

    #loginForm input[type='button'] {
      margin-top: 10px;
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
    }

    #loginForm .secureLogin input {
      margin: 10px;
    }

    .outskirts input,
    .outskirts textarea,
    .outskirts .selectWrapper,
    .outskirts .textarea,
    .outskirts iron-autogrow-textarea,
    .outskirts d-edit-checkbox #box {
      border: none;
    }

    .outskirts input,
    .outskirts textarea,
    .outskirts .selectWrapper,
    .outskirts .textarea,
    .outskirts iron-autogrow-textarea,
    .outskirts d-edit-checkbox #box,
    .outskirts d-universal-selector .icon,
    .themePage input,
    .themePage textarea,
    .themePage .selectWrapper,
    .themePage .textarea,
    .themePage d-edit-checkbox #box,
    .themePage #textEdit {
      background-color: white;
      box-shadow: none;
    }

    .outskirts input,
    .outskirts select,
    .outskirts iron-autogrow-textarea {
      font-family: var(--mainSans);
    }

    .action {
      text-transform: uppercase;
      cursor: pointer;
    }
  `;
  @property({ type: String })
  organizationName = '';
  @property({ type: Object })
  user?: {
    authDescription?: string;
    criiptoAuthDescription?: string;
  };

  render() {
    return html`
      <div class="outskirts">
        <div class="outskirtsHeader">
          <div>
            <div class="logo">TrinnVis</div>
            <div>
              <a class="header-btn" href="/account">Organisasjoner</a>
              <a href="/" @click=${() => this.logout()}>Logg ut</a>
            </div>
          </div>
        </div>
        <div class="outskirtsContent">
          <form id="loginForm">
            <div class="secureLogin">
              <h2>Krav til sikrere innlogging</h2>
              <p>Alle brukere av virksomhetskontoen ${this.organizationName} må logge inn med BankID eller HelseID.</p>
              <input class="action" @click=${async () => this.loginAuthCriipto()} type="button" value="BankID" />
              <input class="action" @click=${async () => this.loginAuthHelseId()} type="button" value="HelseID" />
            </div>
          </form>
        </div>
      </div>
    `;
  }

  private async loginAccount(provider: string) {
    if (provider === 'oidc') {
      await openHelseIdAuth();
    } else if (provider === 'criipto') {
      await openCriiptoAuth();
    } else {
      throw new Error('Illegal state (E302), provider value ' + provider);
    }
  }

  private linkAccount() {
    displayAlert('Logg ut. Deretter login først med BankID eller HelseID for å koble den til din konto etterpå.');
  }

  private async loginAuth(provider) {
    if (!this.hasProvider(provider)) {
      this.linkAccount();
    } else {
      await this.loginAccount(provider);
    }
  }

  private async loginAuthCriipto() {
    await this.loginAuth('criipto');
  }

  private logout() {
    this.dispatchEvent(new CustomEvent('request-logout'));
  }

  private async loginAuthHelseId() {
    await this.loginAuth('oidc');
  }

  private hasProvider(provider: string) {
    if (provider === 'oidc') {
      return this.hasAuthDescription(this.user);
    } else if (provider === 'criipto') {
      return this.hasCriiptoAuthDescription(this.user);
    }
    throw new Error('Illegal state (E301), provider value ' + provider);
  }

  private hasAuthDescription(u) {
    return u && u.authDescription !== null && u.authDescription !== undefined && u.authDescription !== '';
  }

  private hasCriiptoAuthDescription(u) {
    return (
      u &&
      u.criiptoAuthDescription !== null &&
      u.criiptoAuthDescription !== undefined &&
      u.criiptoAuthDescription !== ''
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-account-secured': DAccountSecured;
  }
}
