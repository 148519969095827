/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SendChangedEventRecipient } from './SendChangedEventRecipient';
import {
  SendChangedEventRecipientFromJSON,
  SendChangedEventRecipientFromJSONTyped,
  SendChangedEventRecipientToJSON,
} from './SendChangedEventRecipient';

/**
 *
 * @export
 * @interface SendChangedEventCommand
 */
export interface SendChangedEventCommand {
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  time: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  schedule: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  assignedTo: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  notes: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedEventCommand
   */
  procedure: string;
  /**
   *
   * @type {Array<SendChangedEventRecipient>}
   * @memberof SendChangedEventCommand
   */
  recipients: Array<SendChangedEventRecipient>;
}

/**
 * Check if a given object implements the SendChangedEventCommand interface.
 */
export function instanceOfSendChangedEventCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'message' in value;
  isInstance = isInstance && 'time' in value;
  isInstance = isInstance && 'schedule' in value;
  isInstance = isInstance && 'assignedTo' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'procedure' in value;
  isInstance = isInstance && 'recipients' in value;

  return isInstance;
}

export function SendChangedEventCommandFromJSON(json: any): SendChangedEventCommand {
  return SendChangedEventCommandFromJSONTyped(json, false);
}

export function SendChangedEventCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendChangedEventCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    message: json['message'],
    time: json['time'],
    schedule: json['schedule'],
    assignedTo: json['assignedTo'],
    notes: json['notes'],
    procedure: json['procedure'],
    recipients: (json['recipients'] as Array<any>).map(SendChangedEventRecipientFromJSON),
  };
}

export function SendChangedEventCommandToJSON(value?: SendChangedEventCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    message: value.message,
    time: value.time,
    schedule: value.schedule,
    assignedTo: value.assignedTo,
    notes: value.notes,
    procedure: value.procedure,
    recipients: (value.recipients as Array<any>).map(SendChangedEventRecipientToJSON),
  };
}
