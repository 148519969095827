/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RequestSignaturesSignatory
 */
export interface RequestSignaturesSignatory {
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesSignatory
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesSignatory
   */
  role: RequestSignaturesSignatoryRoleEnum;
}

/**
 * @export
 */
export const RequestSignaturesSignatoryRoleEnum = {
  Employee: 'EMPLOYEE',
  Organization: 'ORGANIZATION',
  Partner: 'PARTNER',
} as const;
export type RequestSignaturesSignatoryRoleEnum =
  (typeof RequestSignaturesSignatoryRoleEnum)[keyof typeof RequestSignaturesSignatoryRoleEnum];

/**
 * Check if a given object implements the RequestSignaturesSignatory interface.
 */
export function instanceOfRequestSignaturesSignatory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'role' in value;

  return isInstance;
}

export function RequestSignaturesSignatoryFromJSON(json: any): RequestSignaturesSignatory {
  return RequestSignaturesSignatoryFromJSONTyped(json, false);
}

export function RequestSignaturesSignatoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RequestSignaturesSignatory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    role: json['role'],
  };
}

export function RequestSignaturesSignatoryToJSON(value?: RequestSignaturesSignatory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    role: value.role,
  };
}
