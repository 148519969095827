import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../../abstracts/labeled-element';
import '../../elements/d-section';
import '../elements/d-checkbox';
import '../elements/d-edit-text';
import '../../elements/d-action';
import './d-signee-details';
import '../../elements/d-smooth-resize';
import { PersonForSigning } from 'src/layout/parts/request-signatures-dialog';

/**
 *
 * USAGE:
 *    request-signatures-dialog
 *
 * STATUS OK
 */
@customElement('d-edit-signees')
export class DEditSignees extends LabeledElement {
  static styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
      }
      d-signee-details {
        margin-left: 29px;
      }
    `,
  ];
  @property({ type: Array })
  signees: string[] = [];
  @property({ type: Array })
  availablePeople: PersonForSigning[] = [];
  @property({ type: Object })
  editPerson: PersonForSigning | undefined = undefined;
  @property({ type: Boolean })
  editingSomewhere = false;

  dispatchValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: {
            availablePeople: this.availablePeople,
            signees: this.signees,
          },
        },
      }),
    );
  }

  detailsChanged(e) {
    e.stopPropagation();
    this.availablePeople = this.availablePeople.map((s) => {
      if (e.detail.value.uuid === s.uuid) {
        return e.detail.value;
      }
      return s;
    });
    this.dispatchValueChanged();
    this.dispatchEvent(
      new CustomEvent('update-signature-person', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: e.detail.value.uuid,
          email: e.detail.value.email,
          personalIdentifier: e.detail.value.personalIdentifier,
        },
      }),
    );
  }

  onEditModeChanged(e) {
    e.stopPropagation();
    this.editingSomewhere = e.detail.value;
    this.dispatchEvent(
      new CustomEvent('edit-mode-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: e.detail.value,
        },
      }),
    );
  }

  editingSomewhereElse(person) {
    return this.editingSomewhere && this.editPerson && this.editPerson.uuid !== person.uuid;
  }

  protected renderContent() {
    return html`
      ${this.availablePeople.map((person) => {
        return html`
          <d-section>
            <div>
              <d-checkbox
                wrap-label
                option-label="${person.firstName + ' ' + person.lastName}"
                ?disabled="${!person.email || this.editingSomewhere}"
                .checked=${this.signees.includes(person.uuid)}
                @checked-changed=${(e) => {
                  if (e.detail.checked && !this.signees.includes(person.uuid)) {
                    const signees = [...this.signees];
                    signees.push(person.uuid);
                    this.signees = signees;
                  } else {
                    this.signees = this.signees.filter((signee) => signee != person.uuid);
                  }
                  this.dispatchValueChanged();
                }}
              ></d-checkbox>
              <d-smooth-resize>
                <d-signee-details
                  .signee=${person}
                  .editingSomewhere=${this.editingSomewhere}
                  @value-changed=${(e) => this.detailsChanged(e)}
                  @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
                ></d-signee-details>
              </d-smooth-resize>
            </div>
          </d-section>
        `;
      })}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-signees': DEditSignees;
  }
}
