import type { SearchResult } from 'src/layout/parts/d-organization-search.js';
import { arrayFind, getStore } from 'src/store';
import type { SearchResultHit } from 'src/store/api';
import type { OrganizationState } from 'src/store/types.js';
import Bugsnag from '@bugsnag/js';

export function mapSearchResultHits(
  organizationId: number,
  hits: SearchResultHit[],
  singleUserVersion: boolean,
  o: OrganizationState,
  employeeNamesById: { [p: string]: string },
  partnerNamesById: { [p: string]: string },
): SearchResult[] {
  return hits
    .filter((hit) => !(hit.entity === 'Function' && singleUserVersion))
    .filter((hit) => hit.entity !== undefined && Object.keys(entities).includes(hit.entity))
    .map((h) => toSearchResult(organizationId, h, o, employeeNamesById, partnerNamesById));
}

export const entities = {
  Partner: 'partners',
  Employee: 'employees',
  Computer: 'computers',
  Asset: 'assets',
  Network: 'networks',
  ExternalConnection: 'externalConnections',
  Task: 'tasks',
  Issue: 'issues',
  RiskAssessment: 'riskAssessments',
  Contract: 'contracts',
  Document: 'documents',
  ConstitutionalDocument: 'constitutionalDocuments',
  Guideline: 'guidelines',
  PersonalDataItem: 'personalDataItems',
  Function: 'functions',
  Report: 'reports',
  Substance: 'substances',
  SearchablePage: 'searchablePages',
  SearchableReference: 'searchableReference',
  CalendarEvent: 'events',
  StartTask: 'startTasks',
  EventOccurrence: 'eventOccurrences',
  MeetingOccurrence: 'meetingOccurrences',
};

function toSearchResult(
  organizationId: number,
  hit: SearchResultHit,
  o: OrganizationState,
  employeeNamesById: { [p: string]: string },
  partnerNamesById: { [p: string]: string },
): SearchResult {
  return {
    party: toParty(hit, o, employeeNamesById, partnerNamesById),
    description: hit.description ?? '',
    id: hit.id ?? '',
    entity: toEntityType(hit, o),
    target: hit.entity === 'SearchableReference' ? '_blank' : '_self',
    href: _computeHref(organizationId, hit, o) ?? '',
  };
}

function toEntityType(hit: SearchResultHit, o: OrganizationState): string {
  if (hit.entity === 'Task') {
    const found = o.tasksById[hit.id ?? ''];
    if (found?.locked) return 'StartTask';
  }

  return hit.entity ?? '';
}

function resultUrl(entity: string, uuid: string, o: OrganizationState) {
  if (entity === 'Partner') {
    return '104/partners/' + uuid;
  }
  if (entity === 'CalendarEvent') {
    return '318434'; // TODO '318434/events/' + uuid + ':' + calendarEventInstance(uuid, o);
  }
  if (entity === 'SearchablePage') {
    return uuid.split('-')[1];
  }
  if (entity === 'Employee') {
    return '104/employees/' + uuid;
  }
  if (entity === 'Computer') {
    return '20';
  }
  if (entity === 'Network') {
    return '20';
  }
  if (entity === 'PersonalDataItem') {
    return '20';
  }
  if (entity === 'MeetingOccurrence') {
    return '9772/meetingOccurrences/' + uuid;
  }
  if (entity === 'EventOccurrence') {
    return '318434/eventOccurrences/' + uuid;
  }
  if (entity === 'Asset') {
    return '110/assets/' + uuid;
  }
  if (entity === 'Issue') {
    return '882901/issues/' + uuid;
  }
  if (entity === 'RiskAssessment') {
    return '712363/riskAssessments/' + uuid;
  }
  if (entity === 'Substance') {
    return '12368/substances/' + uuid;
  }
  if (entity === 'ExternalConnection') {
    return '20';
  }
  if (entity === 'Task') {
    const task = o.tasksById[uuid];
    if (task !== undefined) {
      const parent = o.functionsById[task.functionUuid];
      if (parent === undefined) {
        Bugsnag.notify(new Error('Undefined function for task ' + task.uuid));
        console.error('Undefined function for task ' + task.uuid);
        return '357' + '/tasks/' + uuid;
      }
      const responsible = o.functionsById[task.responsibleFunctionUuid ?? ''];
      if (!o.singleUser) {
        return parent.pages[0] + '/functions/' + parent.uuid + '/tasks/' + uuid;
      } else if (task.pages && task.pages.length > 0) {
        return task.pages[0] + '/tasks/' + uuid;
      } else if (parent.type !== 'COMMON') {
        return parent.pages[0] + '/tasks/' + uuid;
      } else if (responsible) {
        return responsible.pages[0] + '/tasks/' + uuid;
      } else {
        return '357' + '/tasks/' + uuid;
      }
    }
    return '357' + '/tasks/' + uuid;
  }
  const map = {
    Contract: o.contractsById,
    Document: o.documentsById,
    Guideline: o.guidelinesById,
    Function: o.functionsById,
    Report: o.reportsById,
    ConstitutionalDocument: o.constitutionalDocumentsById,
  };
  const items = map[entity];
  if (!items) {
    console.error(entity);
  }
  const i = items[uuid];
  if (i && i.pages && i.pages.length) {
    return '' + i.pages[0] + '/' + entities[entity] + '/' + uuid;
  } else {
    return '695944';
  }
}

function _findReferenceHref(pageId) {
  const r = getStore().getState().references;
  if (r.length === 3) {
    const v = r[0].pages.concat(r[1].pages, r[2].pages);

    const isPage = function (e) {
      return e.pageId === +pageId;
    };
    const n = arrayFind(v, isPage);
    return n?.linkHref ?? '';
  }
}
function _computeHref(id: number, result: SearchResultHit, o: OrganizationState) {
  if (result.entity === 'SearchableReference' && result.id !== undefined) {
    const pageId = result.id.split('-')[1];
    return _findReferenceHref(pageId);
  } else if (result.entity === 'CalendarEvent') {
    return '/account/' + id + '/' + resultUrl(result.entity, result.id ?? '', o);
  } else {
    return '/account/' + id + '/' + resultUrl(result.entity ?? '', result.id ?? '', o);
  }
}

function toParty(
  result: SearchResultHit,
  o: OrganizationState,
  employeeNamesById: { [p: string]: string },
  partnerNamesById: { [p: string]: string },
): string {
  if (result.entity === 'Contract') {
    const found = o.contractsById[result.id ?? ''];
    if (found && found.pages && found.pages.length > 0) {
      let p = '';
      if (found.employees && found.employees.length > 0) {
        const employeeNames = found.employees.reduce<string[]>(function (en, id) {
          en.push(employeeNamesById[id]);
          return en;
        }, []);
        p = '(' + employeeNames.join(', ') + ')';
      }
      if (found.partners && found.partners.length > 0) {
        const partnerNames = found.partners.reduce<string[]>(function (en, id) {
          en.push(partnerNamesById[id]);
          return en;
        }, []);
        p = p + ' (' + partnerNames.join(', ') + ')';
      }
      return p;
    }
  }

  return '';
}
