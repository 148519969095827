/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ComputerUpdateMessage
 */
export interface ComputerUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  journalType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  journalSupplier?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  journalSize?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  messagingType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  messagingSupplier?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  localPrinter?: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  printerPositionSecure?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  antivirus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  laptop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  storesPersonalInformation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  storesJournal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  journalRegistered?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  journalHasElectronicMessaging?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  messageMonitoring?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  autoLookupPatients?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  autoLookupAddress?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  autoLookupAddressNhn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  autoUpdateAddressNhn?: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  networkUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  unitType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  serviceProvider?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  connectionType?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerUpdateMessage
   */
  contracts?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  systemUpdateOperatorType?: ComputerUpdateMessageSystemUpdateOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  systemUpdateOperator?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  antiVirusOperatorType?: ComputerUpdateMessageAntiVirusOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  antiVirusOperator?: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  elevated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  locked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  restrictedPhysicalAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerUpdateMessage
   */
  displayPositionSecure?: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputerUpdateMessage
   */
  serviceProviderContract?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerUpdateMessage
   */
  antiVirusRequirements?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerUpdateMessage
   */
  mobileMedicalDataRequirements?: Array<string>;
}

/**
 * @export
 */
export const ComputerUpdateMessageSystemUpdateOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type ComputerUpdateMessageSystemUpdateOperatorTypeEnum =
  (typeof ComputerUpdateMessageSystemUpdateOperatorTypeEnum)[keyof typeof ComputerUpdateMessageSystemUpdateOperatorTypeEnum];

/**
 * @export
 */
export const ComputerUpdateMessageAntiVirusOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type ComputerUpdateMessageAntiVirusOperatorTypeEnum =
  (typeof ComputerUpdateMessageAntiVirusOperatorTypeEnum)[keyof typeof ComputerUpdateMessageAntiVirusOperatorTypeEnum];

/**
 * Check if a given object implements the ComputerUpdateMessage interface.
 */
export function instanceOfComputerUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function ComputerUpdateMessageFromJSON(json: any): ComputerUpdateMessage {
  return ComputerUpdateMessageFromJSONTyped(json, false);
}

export function ComputerUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputerUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    name: !exists(json, 'name') ? undefined : json['name'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    location: !exists(json, 'location') ? undefined : json['location'],
    type: !exists(json, 'type') ? undefined : json['type'],
    journalType: !exists(json, 'journalType') ? undefined : json['journalType'],
    journalSupplier: !exists(json, 'journalSupplier') ? undefined : json['journalSupplier'],
    journalSize: !exists(json, 'journalSize') ? undefined : json['journalSize'],
    messagingType: !exists(json, 'messagingType') ? undefined : json['messagingType'],
    messagingSupplier: !exists(json, 'messagingSupplier') ? undefined : json['messagingSupplier'],
    localPrinter: !exists(json, 'localPrinter') ? undefined : json['localPrinter'],
    printerPositionSecure: !exists(json, 'printerPositionSecure') ? undefined : json['printerPositionSecure'],
    antivirus: !exists(json, 'antivirus') ? undefined : json['antivirus'],
    laptop: !exists(json, 'laptop') ? undefined : json['laptop'],
    storesPersonalInformation: !exists(json, 'storesPersonalInformation')
      ? undefined
      : json['storesPersonalInformation'],
    storesJournal: !exists(json, 'storesJournal') ? undefined : json['storesJournal'],
    journalRegistered: !exists(json, 'journalRegistered') ? undefined : json['journalRegistered'],
    journalHasElectronicMessaging: !exists(json, 'journalHasElectronicMessaging')
      ? undefined
      : json['journalHasElectronicMessaging'],
    messageMonitoring: !exists(json, 'messageMonitoring') ? undefined : json['messageMonitoring'],
    autoLookupPatients: !exists(json, 'autoLookupPatients') ? undefined : json['autoLookupPatients'],
    autoLookupAddress: !exists(json, 'autoLookupAddress') ? undefined : json['autoLookupAddress'],
    autoLookupAddressNhn: !exists(json, 'autoLookupAddressNhn') ? undefined : json['autoLookupAddressNhn'],
    autoUpdateAddressNhn: !exists(json, 'autoUpdateAddressNhn') ? undefined : json['autoUpdateAddressNhn'],
    networkUuid: !exists(json, 'networkUuid') ? undefined : json['networkUuid'],
    unitType: !exists(json, 'unitType') ? undefined : json['unitType'],
    serviceProvider: !exists(json, 'serviceProvider') ? undefined : json['serviceProvider'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    contracts: !exists(json, 'contracts') ? undefined : json['contracts'],
    systemUpdateOperatorType: !exists(json, 'systemUpdateOperatorType') ? undefined : json['systemUpdateOperatorType'],
    systemUpdateOperator: !exists(json, 'systemUpdateOperator') ? undefined : json['systemUpdateOperator'],
    antiVirusOperatorType: !exists(json, 'antiVirusOperatorType') ? undefined : json['antiVirusOperatorType'],
    antiVirusOperator: !exists(json, 'antiVirusOperator') ? undefined : json['antiVirusOperator'],
    elevated: !exists(json, 'elevated') ? undefined : json['elevated'],
    locked: !exists(json, 'locked') ? undefined : json['locked'],
    restrictedPhysicalAccess: !exists(json, 'restrictedPhysicalAccess') ? undefined : json['restrictedPhysicalAccess'],
    displayPositionSecure: !exists(json, 'displayPositionSecure') ? undefined : json['displayPositionSecure'],
    serviceProviderContract: !exists(json, 'serviceProviderContract') ? undefined : json['serviceProviderContract'],
    antiVirusRequirements: !exists(json, 'antiVirusRequirements') ? undefined : json['antiVirusRequirements'],
    mobileMedicalDataRequirements: !exists(json, 'mobileMedicalDataRequirements')
      ? undefined
      : json['mobileMedicalDataRequirements'],
  };
}

export function ComputerUpdateMessageToJSON(value?: ComputerUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    notes: value.notes,
    location: value.location,
    type: value.type,
    journalType: value.journalType,
    journalSupplier: value.journalSupplier,
    journalSize: value.journalSize,
    messagingType: value.messagingType,
    messagingSupplier: value.messagingSupplier,
    localPrinter: value.localPrinter,
    printerPositionSecure: value.printerPositionSecure,
    antivirus: value.antivirus,
    laptop: value.laptop,
    storesPersonalInformation: value.storesPersonalInformation,
    storesJournal: value.storesJournal,
    journalRegistered: value.journalRegistered,
    journalHasElectronicMessaging: value.journalHasElectronicMessaging,
    messageMonitoring: value.messageMonitoring,
    autoLookupPatients: value.autoLookupPatients,
    autoLookupAddress: value.autoLookupAddress,
    autoLookupAddressNhn: value.autoLookupAddressNhn,
    autoUpdateAddressNhn: value.autoUpdateAddressNhn,
    networkUuid: value.networkUuid,
    unitType: value.unitType,
    serviceProvider: value.serviceProvider,
    connectionType: value.connectionType,
    contracts: value.contracts,
    systemUpdateOperatorType: value.systemUpdateOperatorType,
    systemUpdateOperator: value.systemUpdateOperator,
    antiVirusOperatorType: value.antiVirusOperatorType,
    antiVirusOperator: value.antiVirusOperator,
    elevated: value.elevated,
    locked: value.locked,
    restrictedPhysicalAccess: value.restrictedPhysicalAccess,
    displayPositionSecure: value.displayPositionSecure,
    serviceProviderContract: value.serviceProviderContract,
    antiVirusRequirements: value.antiVirusRequirements,
    mobileMedicalDataRequirements: value.mobileMedicalDataRequirements,
  };
}
