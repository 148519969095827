import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-signup-plans';
import './d-signup-module';
import { UserForSignup } from 'src/outskirts/signup/d-signup-form';

/**
 *
 */
@customElement('d-signup')
export class DSignup extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      height: 100vh;
      overflow: auto;
      box-sizing: border-box;
    }
  `;
  @property({ type: String })
  module = '';
  @property({ type: Object })
  currentUser: UserForSignup | undefined = undefined;

  render() {
    if (!this.module) {
      return html`<d-signup-plans .currentUser=${this.currentUser}>
        <slot slot="usernameInput" name="usernameInput"></slot>
        <slot slot="passwordInput" name="passwordInput"></slot>
      </d-signup-plans>`;
    } else {
      return html`
        <d-signup-module .module=${this.module} .currentUser=${this.currentUser}>
          <slot slot="usernameInput" name="usernameInput"></slot>
          <slot slot="passwordInput" name="passwordInput"></slot>
        </d-signup-module>
      `;
    }
  }

  protected willUpdate() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);
    if (params.has('module')) {
      this.module = params.get('module') ?? '';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signup': DSignup;
  }
}
