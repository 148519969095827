/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FunctionAssignment } from './FunctionAssignment';
import {
  FunctionAssignmentFromJSON,
  FunctionAssignmentFromJSONTyped,
  FunctionAssignmentToJSON,
} from './FunctionAssignment';

/**
 *
 * @export
 * @interface EmployeeUpdateMessage
 */
export interface EmployeeUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  profession: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  notes: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  herNumber: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  hprNumber: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  personalIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  expertise: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  associationType?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  gender?: EmployeeUpdateMessageGenderEnum;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  accessLevel: EmployeeUpdateMessageAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  nextOfKin: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  secondaryPhone: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  speciality?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeUpdateMessage
   */
  status: EmployeeUpdateMessageStatusEnum;
  /**
   *
   * @type {Array<FunctionAssignment>}
   * @memberof EmployeeUpdateMessage
   */
  functionAssignments?: Array<FunctionAssignment>;
}

/**
 * @export
 */
export const EmployeeUpdateMessageGenderEnum = {
  Undefined: 'UNDEFINED',
  Male: 'MALE',
  Female: 'FEMALE',
} as const;
export type EmployeeUpdateMessageGenderEnum =
  (typeof EmployeeUpdateMessageGenderEnum)[keyof typeof EmployeeUpdateMessageGenderEnum];

/**
 * @export
 */
export const EmployeeUpdateMessageAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type EmployeeUpdateMessageAccessLevelEnum =
  (typeof EmployeeUpdateMessageAccessLevelEnum)[keyof typeof EmployeeUpdateMessageAccessLevelEnum];

/**
 * @export
 */
export const EmployeeUpdateMessageStatusEnum = {
  Active: 'ACTIVE',
  Leave: 'LEAVE',
  Terminated: 'TERMINATED',
} as const;
export type EmployeeUpdateMessageStatusEnum =
  (typeof EmployeeUpdateMessageStatusEnum)[keyof typeof EmployeeUpdateMessageStatusEnum];

/**
 * Check if a given object implements the EmployeeUpdateMessage interface.
 */
export function instanceOfEmployeeUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'address' in value;
  isInstance = isInstance && 'profession' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'herNumber' in value;
  isInstance = isInstance && 'hprNumber' in value;
  isInstance = isInstance && 'expertise' in value;
  isInstance = isInstance && 'accessLevel' in value;
  isInstance = isInstance && 'phone' in value;
  isInstance = isInstance && 'nextOfKin' in value;
  isInstance = isInstance && 'secondaryPhone' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function EmployeeUpdateMessageFromJSON(json: any): EmployeeUpdateMessage {
  return EmployeeUpdateMessageFromJSONTyped(json, false);
}

export function EmployeeUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    address: json['address'],
    profession: json['profession'],
    notes: json['notes'],
    email: json['email'],
    lastName: json['lastName'],
    firstName: json['firstName'],
    herNumber: json['herNumber'],
    hprNumber: json['hprNumber'],
    personalIdentifier: !exists(json, 'personalIdentifier') ? undefined : json['personalIdentifier'],
    expertise: json['expertise'],
    associationType: !exists(json, 'associationType') ? undefined : json['associationType'],
    gender: !exists(json, 'gender') ? undefined : json['gender'],
    accessLevel: json['accessLevel'],
    phone: json['phone'],
    nextOfKin: json['nextOfKin'],
    secondaryPhone: json['secondaryPhone'],
    speciality: !exists(json, 'speciality') ? undefined : json['speciality'],
    status: json['status'],
    functionAssignments: !exists(json, 'functionAssignments')
      ? undefined
      : (json['functionAssignments'] as Array<any>).map(FunctionAssignmentFromJSON),
  };
}

export function EmployeeUpdateMessageToJSON(value?: EmployeeUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    address: value.address,
    profession: value.profession,
    notes: value.notes,
    email: value.email,
    lastName: value.lastName,
    firstName: value.firstName,
    herNumber: value.herNumber,
    hprNumber: value.hprNumber,
    personalIdentifier: value.personalIdentifier,
    expertise: value.expertise,
    associationType: value.associationType,
    gender: value.gender,
    accessLevel: value.accessLevel,
    phone: value.phone,
    nextOfKin: value.nextOfKin,
    secondaryPhone: value.secondaryPhone,
    speciality: value.speciality,
    status: value.status,
    functionAssignments:
      value.functionAssignments === undefined
        ? undefined
        : (value.functionAssignments as Array<any>).map(FunctionAssignmentToJSON),
  };
}
