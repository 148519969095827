/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateEventOccurrenceCommand
 */
export interface UpdateEventOccurrenceCommand {
  /**
   * The event occurrence uuid.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  uuid: string;
  /**
   * Angir om etterfølgende møter også skal oppdateres
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  saveType: UpdateEventOccurrenceCommandSaveTypeEnum;
  /**
   * Om gjeldende og tidligere deltagere skal ha melding
   * @type {boolean}
   * @memberof UpdateEventOccurrenceCommand
   */
  alerts: boolean;
  /**
   * Valgfri melding dersom `alerts` er `true`.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  alertMessage?: string;
  /**
   * Inkluder detaljer i meldingene
   * @type {boolean}
   * @memberof UpdateEventOccurrenceCommand
   */
  alertIncludesDetails?: boolean;
  /**
   * Navnet på oppgaven.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  name: string;
  /**
   * Dato for oppgaven. Kan være null for å angi snarest-oppgave.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  date?: string;
  /**
   * Klokkeslett for oppgaven. Format "13:15". Må utelates dersom dette er en snarestoppgave eller det er en heldagsoppgave.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  time?: string;
  /**
   * Varighet for oppgaven. Kan være 0 for oppgaver uten varighet. Må være 0 dersom oppgaven ikke har dato og tidspunkt
   * @type {number}
   * @memberof UpdateEventOccurrenceCommand
   */
  durationMinutes: number;
  /**
   * Dersom oppgaven er tildelt et ansvarsområde.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  functionUuid?: string;
  /**
   * Den relaterte rutinen
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  taskUuid?: string;
  /**
   * Liste med ansatte denne oppgaven er tildelt.
   * @type {Array<string>}
   * @memberof UpdateEventOccurrenceCommand
   */
  employees: Array<string>;
  /**
   * List med kontaktpersoner tildelt denne oppgaven.
   * @type {Array<string>}
   * @memberof UpdateEventOccurrenceCommand
   */
  contacts: Array<string>;
  /**
   * Liste med utstyr relatert til denne oppgaven.
   * @type {Array<string>}
   * @memberof UpdateEventOccurrenceCommand
   */
  assets: Array<string>;
  /**
   * Om denne oppgaven følges opp etter dato er passert. For snarestoppgaver må denne være `false`
   * @type {boolean}
   * @memberof UpdateEventOccurrenceCommand
   */
  persistent: boolean;
  /**
   * Påminnelse om oppgaven. Må være `NONE` dersom oppgaven ikke har angitt klokkeslett.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  reminder: UpdateEventOccurrenceCommandReminderEnum;
  /**
   * Notater
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  notes: string;
  /**
   * Prosedyren eller annen beskrivelse av oppgaven.
   * @type {string}
   * @memberof UpdateEventOccurrenceCommand
   */
  description: string;
}

/**
 * @export
 */
export const UpdateEventOccurrenceCommandSaveTypeEnum = {
  This: 'THIS',
  Future: 'FUTURE',
} as const;
export type UpdateEventOccurrenceCommandSaveTypeEnum =
  (typeof UpdateEventOccurrenceCommandSaveTypeEnum)[keyof typeof UpdateEventOccurrenceCommandSaveTypeEnum];

/**
 * @export
 */
export const UpdateEventOccurrenceCommandReminderEnum = {
  None: 'NONE',
  AtStart: 'AT_START',
  OneHourBefore: 'ONE_HOUR_BEFORE',
  TwoHoursBefore: 'TWO_HOURS_BEFORE',
  ThreeHoursBefore: 'THREE_HOURS_BEFORE',
  FiveHoursBefore: 'FIVE_HOURS_BEFORE',
} as const;
export type UpdateEventOccurrenceCommandReminderEnum =
  (typeof UpdateEventOccurrenceCommandReminderEnum)[keyof typeof UpdateEventOccurrenceCommandReminderEnum];

/**
 * Check if a given object implements the UpdateEventOccurrenceCommand interface.
 */
export function instanceOfUpdateEventOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'saveType' in value;
  isInstance = isInstance && 'alerts' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'durationMinutes' in value;
  isInstance = isInstance && 'employees' in value;
  isInstance = isInstance && 'contacts' in value;
  isInstance = isInstance && 'assets' in value;
  isInstance = isInstance && 'persistent' in value;
  isInstance = isInstance && 'reminder' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'description' in value;

  return isInstance;
}

export function UpdateEventOccurrenceCommandFromJSON(json: any): UpdateEventOccurrenceCommand {
  return UpdateEventOccurrenceCommandFromJSONTyped(json, false);
}

export function UpdateEventOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateEventOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    saveType: json['saveType'],
    alerts: json['alerts'],
    alertMessage: !exists(json, 'alertMessage') ? undefined : json['alertMessage'],
    alertIncludesDetails: !exists(json, 'alertIncludesDetails') ? undefined : json['alertIncludesDetails'],
    name: json['name'],
    date: !exists(json, 'date') ? undefined : json['date'],
    time: !exists(json, 'time') ? undefined : json['time'],
    durationMinutes: json['durationMinutes'],
    functionUuid: !exists(json, 'functionUuid') ? undefined : json['functionUuid'],
    taskUuid: !exists(json, 'taskUuid') ? undefined : json['taskUuid'],
    employees: json['employees'],
    contacts: json['contacts'],
    assets: json['assets'],
    persistent: json['persistent'],
    reminder: json['reminder'],
    notes: json['notes'],
    description: json['description'],
  };
}

export function UpdateEventOccurrenceCommandToJSON(value?: UpdateEventOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    saveType: value.saveType,
    alerts: value.alerts,
    alertMessage: value.alertMessage,
    alertIncludesDetails: value.alertIncludesDetails,
    name: value.name,
    date: value.date === undefined ? undefined : value.date,
    time: value.time,
    durationMinutes: value.durationMinutes,
    functionUuid: value.functionUuid,
    taskUuid: value.taskUuid,
    employees: value.employees,
    contacts: value.contacts,
    assets: value.assets,
    persistent: value.persistent,
    reminder: value.reminder,
    notes: value.notes,
    description: value.description,
  };
}
