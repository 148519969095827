import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../elements/d-edit-text';
import '../../elements/d-action';
import { PersonForSigning } from 'src/layout/parts/request-signatures-dialog';
import * as dabihStore from 'src/store';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { validateNorwegianIdNumber } from 'norwegian-national-id-validator';
import { DEditText } from 'src/library/editors/elements/d-edit-text';

/**
 *
 * USAGE:
 *    d-edit-organizational-signees
 *    d-edit-signees
 *
 * STATUS OK
 */
@customElement('d-signee-details')
export class DSigneeDetails extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }

      .details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
      }

      .details.view {
        margin-left: 2px;
        margin-right: 2px;
        font-family: var(--mainSerif);
        line-height: 140%;
        line-break: anywhere;
      }

      .details.view[disabled] {
        opacity: 0.5;
      }

      .details.edit {
        display: block;
        margin-top: 0;
        margin-bottom: -4px;
        overflow: hidden;
      }

      .details > * {
        flex-grow: 1;
      }

      .details > *:first-child {
        flex-grow: 100;
        display: flex;
        flex-wrap: wrap;
      }

      .details > *:first-child {
        margin-left: -3px;
        margin-right: -3px;
      }

      .details.edit > *:first-child {
        margin-bottom: 6px;
      }

      .details > *:first-child > * {
        margin-left: 3px;
        margin-right: 3px;
      }

      .details.edit > *:first-child > * {
        margin-top: 6px;
      }

      .details.edit .name {
        flex-basis: 40%;
        flex-grow: 1;
        min-width: 220px;
      }

      .details.edit .email {
        flex-grow: 3;
        flex-basis: 200px;
        min-width: 270px;
      }

      .details.edit .person-number {
        flex-grow: 1;
        flex-basis: 130px;
        min-width: 130px;
      }

      .details > *:last-child {
        display: flex;
        justify-content: end;
      }

      .alert {
        background: url(/images/alert.svg) -5px 0 no-repeat;
        background-size: 28px 28px;
        margin: -4px 0;
        padding: 4px 0 4px 28px;
        font-family: var(--mainSans);
        font-size: 14px;
        font-weight: 500;
        color: var(--alertColor);
      }

      .note {
        font-family: var(--mainSans);
        font-size: 14px;
        font-style: italic;
      }

      d-action:last-child {
        margin-right: -8px;
      }
    `,
  ];
  @property({ type: Object })
  signee: PersonForSigning | undefined = undefined;
  @property({ type: Boolean, attribute: 'gray-background' })
  grayBackground = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  email = '';
  @property({ type: String })
  personalIdentifier = '';
  @property({ type: Boolean })
  editingSomewhere = false;

  private get invalidName() {
    return isEmptyOrInvalidString(this.firstName + this.lastName);
  }

  private get invalidEmail() {
    return this.email !== '' && !dabihStore.isEmailValid(this.email);
  }

  private get invalidNationalIdentityNumber() {
    return this.personalIdentifier !== '' && !validateNorwegianIdNumber(this.personalIdentifier);
  }

  private get invalid() {
    return this.invalidName || this.invalidEmail || this.invalidNationalIdentityNumber;
  }

  private get editingSomwhereElse() {
    return !this.editMode && this.editingSomewhere;
  }

  editModeChanged(value) {
    this.dispatchEvent(
      new CustomEvent('edit-mode-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: value,
        },
      }),
    );
  }

  edit(disabled) {
    if (!disabled) {
      this.firstName = this.signee?.firstName || '';
      this.lastName = this.signee?.lastName || '';
      this.email = this.signee?.email || '';
      this.personalIdentifier = this.signee?.personalIdentifier || '';
      this.editMode = true;
      this.editModeChanged(true);
    }
  }

  endEdit() {
    if (this.signee?.uuid === 'new') {
      this.dispatchEvent(
        new CustomEvent('delete-new', {
          bubbles: true,
          composed: true,
        }),
      );
    }
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.personalIdentifier = '';
    this.editMode = false;
    this.editModeChanged(false);
  }

  done(e) {
    if (!e.target.disabled) {
      this.signee = {
        uuid: this.signee?.uuid || '',
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        personalIdentifier: this.personalIdentifier,
      };
      if (this.signee.uuid === 'new') {
        this.dispatchEvent(
          new CustomEvent('new-person', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.signee,
            },
          }),
        );
      } else {
        this.dispatchEvent(
          new CustomEvent('value-changed', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.signee,
            },
          }),
        );
      }
      this.endEdit();
    }
  }

  valueChanged(e, property) {
    e.stopPropagation();
    this[property] = e.detail.value;
  }

  hasValue(n: string | undefined | null): boolean {
    return n !== undefined && n !== null && n.trim() !== '';
  }
  render() {
    if (this.editMode) {
      return html`
        <div class="details edit">
          <div>
            <d-edit-text
              light-label
              small-label
              ?theme-page=${this.grayBackground}
              .markIfEmpty=${this.invalidName}
              id="firstname"
              class="name"
              label="Fornavn"
              placeholder="Fornavn"
              .value=${this.firstName}
              ?disabled=${this.hasValue(this.signee?.firstName)}
              @value-changed=${(e) => {
                e.stopPropagation();
                this.valueChanged(e, 'firstName');
              }}
            ></d-edit-text>
            <d-edit-text
              light-label
              small-label
              ?theme-page=${this.grayBackground}
              .markIfEmpty=${this.invalidName}
              class="name"
              label="Etternavn"
              placeholder="Etternavn"
              .value=${this.lastName}
              ?disabled=${this.hasValue(this.signee?.lastName)}
              @value-changed=${(e) => {
                e.stopPropagation();
                this.valueChanged(e, 'lastName');
              }}
            ></d-edit-text>
            <d-edit-text
              light-label
              small-label
              ?theme-page=${this.grayBackground}
              ?invalid=${this.invalidEmail}
              class="email"
              label="Epost"
              placeholder="Epost"
              .value=${this.email}
              ?disabled=${this.hasValue(this.signee?.email)}
              @value-changed=${(e) => {
                e.stopPropagation();
                this.valueChanged(e, 'email');
              }}
            ></d-edit-text>
            <d-edit-text
              light-label
              small-label
              ?theme-page=${this.grayBackground}
              ?invalid=${this.invalidNationalIdentityNumber}
              class="person-number"
              field="employees_nationalIdentityNumber"
              hide-tooltip
              placeholder="Fødselssnr"
              ?disabled=${this.hasValue(this.signee?.personalIdentifier)}
              .value=${this.personalIdentifier}
              @value-changed=${(e) => {
                e.stopPropagation();
                this.valueChanged(e, 'personalIdentifier');
              }}
            ></d-edit-text>
          </div>
          <div>
            <d-action mini @click=${() => this.endEdit()}>Avbryt</d-action>
            <d-action mini @click=${(e) => this.done(e)} ?disabled=${this.invalid}>Ferdig</d-action>
          </div>
        </div>
      `;
    }
    if (this.signee) {
      return html`
        <div class="details view" ?disabled=${this.editingSomwhereElse}>
          <div>
            ${this.signee.email
              ? html`
                  <div>${this.signee.email}</div>
                  ${this.signee.personalIdentifier
                    ? html` <div>${this.signee.personalIdentifier}</div>`
                    : html` <div class="note">Mangler fødselsnummer</div>`}
                `
              : html` <div class="alert">Kan ikke signere: mangler epost</div>`}
          </div>
          ${this.signee.email && this.signee.personalIdentifier
            ? nothing
            : html` <d-action mini @click=${(e) => this.edit(e.target.disabled)} ?disabled=${this.editingSomewhere}
                >Rediger</d-action
              >`}
        </div>
      `;
    }
    return nothing;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('signee') && this.signee && this.signee.uuid === 'new') {
      this.edit(false);
      setTimeout(() => {
        const firstNameInput = this.shadowRoot?.getElementById('firstname') as DEditText;
        if (firstNameInput) {
          firstNameInput.setFocus();
        }
      }, 300);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signee-details': DSigneeDetails;
  }
}
