/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageContent } from './MessageContent';
import { MessageContentFromJSON, MessageContentFromJSONTyped, MessageContentToJSON } from './MessageContent';

/**
 *
 * @export
 * @interface AskCommand
 */
export interface AskCommand {
  /**
   *
   * @type {string}
   * @memberof AskCommand
   */
  model: string;
  /**
   *
   * @type {Array<MessageContent>}
   * @memberof AskCommand
   */
  messages: Array<MessageContent>;
}

/**
 * Check if a given object implements the AskCommand interface.
 */
export function instanceOfAskCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'model' in value;
  isInstance = isInstance && 'messages' in value;

  return isInstance;
}

export function AskCommandFromJSON(json: any): AskCommand {
  return AskCommandFromJSONTyped(json, false);
}

export function AskCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AskCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    model: json['model'],
    messages: (json['messages'] as Array<any>).map(MessageContentFromJSON),
  };
}

export function AskCommandToJSON(value?: AskCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    model: value.model,
    messages: (value.messages as Array<any>).map(MessageContentToJSON),
  };
}
