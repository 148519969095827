/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TutorialState } from './TutorialState';
import { TutorialStateFromJSON, TutorialStateFromJSONTyped, TutorialStateToJSON } from './TutorialState';

/**
 *
 * @export
 * @interface UpdateTutorialStateCommand
 */
export interface UpdateTutorialStateCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateTutorialStateCommand
   */
  id?: number;
  /**
   *
   * @type {TutorialState}
   * @memberof UpdateTutorialStateCommand
   */
  tutorialState?: TutorialState;
}

/**
 * Check if a given object implements the UpdateTutorialStateCommand interface.
 */
export function instanceOfUpdateTutorialStateCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateTutorialStateCommandFromJSON(json: any): UpdateTutorialStateCommand {
  return UpdateTutorialStateCommandFromJSONTyped(json, false);
}

export function UpdateTutorialStateCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateTutorialStateCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    tutorialState: !exists(json, 'tutorialState') ? undefined : TutorialStateFromJSON(json['tutorialState']),
  };
}

export function UpdateTutorialStateCommandToJSON(value?: UpdateTutorialStateCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    tutorialState: TutorialStateToJSON(value.tutorialState),
  };
}
