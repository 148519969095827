import type {
  EventOccurrenceView,
  EventRelatedAsset,
  EventOccurrenceViewEditItem,
} from 'src/content/event-occurrences/d-event-occurrence-view';
import { fetchDraft, toAttachmentItems } from 'src/models/factory-utilities';
import { BASE_PATH, EventOccurrenceViewModel, TaskViewModel } from 'src/store/api';
import {
  assetsNotDeleted,
  commonFunctionUuid,
  currentEmployeeShortName,
  currentEmployeeUuid,
  employeesNotDeleted,
  employeesShortNamesAndI,
  employeesShortNamesAndMe,
  eventOccurrencesNotDeleted,
  events,
  functionsNotDeleted,
  getAssetOptions,
  getContactsAsUsers,
  getModelEvents,
  getOrganization,
  key,
  singleUserVersion,
  tasksNotDeleted,
  writeAccess,
} from 'src/store/selectors';
import type { OrganizationState, State } from 'src/store/types';
import { LocalDate } from 'src/utilities/local-date';
import { isEmpty, sortBy } from 'lodash';
import {
  reminderText,
  dateTimeDescription,
  eventAssigneesText,
  functionNameWithAssignedNames,
} from 'src/store/utilities';
import { displayName } from 'src/utilities/text';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';

function _doneText(item: EventOccurrenceViewModel, organization: OrganizationState, singleUserVersion: boolean) {
  if (!item.doneDate) {
    return '';
  }
  let doneBy = '';
  if (!singleUserVersion && item.doneByEmployeeUuid) {
    doneBy = ' av ' + organization.employeesById[item.doneByEmployeeUuid].name;
  }
  return LocalDate.fromString(item.doneDate).toStringForDisplay() + doneBy;
}

export function getMessageRecipients(
  state: State,
  currentEmployeeUuid1: string | undefined,
  selectedEmployees: string[] = [],
) {
  return employeesNotDeleted(state)
    .filter((p) => p.status !== 'TERMINATED' || selectedEmployees.includes(p.uuid))
    .filter((p) => p.uuid !== currentEmployeeUuid1)
    .map((p) => ({ uuid: p.uuid, name: p.name, accessLevel: p.accessLevel?.toString() ?? 'NONE' }));
}

export function buildEventOccurrenceView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): EventOccurrenceView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const currentEmployeeUuid1 = currentEmployeeUuid(state);

  const eventOccurrence = organization.eventOccurrences.find((o) => o.uuid === uuid);

  assertIsDefined(eventOccurrence);

  const instance = eventOccurrence.date;

  const entity = eventOccurrence;

  const assets = assetsNotDeleted(state);
  const relatedAssetViewModels = eventOccurrence.relatedAssets ?? [];
  const relatedAssets: EventRelatedAsset[] = relatedAssetViewModels
    .map((a) => {
      const relatedAsset = assets.find((x) => x.uuid === a.assetUuid);
      return {
        asset: a,
        relatedAsset: relatedAsset,
      };
    })
    .filter((x) => x.relatedAsset !== undefined)
    .map((x) => {
      const relatedAsset = x.relatedAsset;
      assertIsDefined(relatedAsset);
      let name = relatedAsset.name + ' ' + relatedAsset.number || 'Uten navn';
      if (relatedAsset.discardedYear !== '') {
        name += ' (kassert ' + relatedAsset.discardedYear + ')';
      }
      const a = x.asset;
      return {
        name,
        location: relatedAsset.location ?? '',
        notes: '',
        assetCheck: true,
        assetCheckStatus: a.assetStatus ?? 'NONE',
        assetCheckDoneText: _checkedByTime(
          state,
          a.assetStatus ?? 'NONE',
          a.comments ?? '',
          a.statusLastUpdatedByEmployeeUuid ?? '',
          a.statusLastUpdatedAt ?? '',
        ),
        assetCheckComments: a.comments ?? '',
        href: '/account/' + organization.organizationId + '/110/assets/' + relatedAsset.uuid,
        uuid: a.assetUuid,
      };
    });

  const e = events(state).find((x) => x.eventOccurrences.find((y) => y.uuid === uuid) !== undefined);
  assertIsDefined(e);

  let task: TaskViewModel | undefined = undefined;
  if (entity.taskUuid !== undefined && entity.taskUuid !== null) {
    task = organization.tasksById[entity.taskUuid];
    assertIsDefined(task);
  }

  const localDate = LocalDate.fromString(entity.date);

  const canUpdateFutureOccurrences =
    entity.isConfirmedEntity &&
    !isEmpty(entity.date) &&
    e.eventOccurrences.filter((e2) => LocalDate.fromString(e2.date).isAfter(localDate)).length > 0;

  let relatedAssetForNew = '';
  if (!entity.isConfirmedEntity && relatedAssets.length) {
    relatedAssetForNew = relatedAssets[0].uuid;
  }

  const availableFunctions = functionsNotDeleted(state)
    .filter((f) => {
      return f.isConfirmedEntity;
    })
    .map((f) => {
      return {
        value: f.uuid,
        text: functionNameWithAssignedNames(f.uuid, functionsNotDeleted(state), employeesShortNamesAndI(state)),
        employees: f.employees,
      };
    });

  const taskOptions = tasksNotDeleted(state)
    .filter((t) => {
      return t.name !== undefined && t.name !== '';
    })
    .map((t) => {
      return { value: t.uuid, text: t.name || '' };
    });

  return {
    ...commonData,
    modelEvents: getModelEvents(
      events(state),
      eventOccurrencesNotDeleted(state),
      tasksNotDeleted(state).filter((t) => !t.locked),
      functionsNotDeleted(state),
      employeesShortNamesAndI(state),
      getContactsAsUsers(state),
      relatedAssetForNew,
    ),
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    isConfirmedEntity: entity.isConfirmedEntity,
    uuid: uuid,
    eventUuid: uuid,
    eventInstance: instance,
    helpContent: '', // TODO entity.helpContent ?? '',
    href: currentParent + '/eventOccurrences/' + uuid,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/eventOccurrences/' +
      uuid +
      '.pdf?key=' +
      key(state),
    parentHref: currentParent,
    deleted: false,
    reminderText: reminderText(entity.reminder ?? 'NONE'),
    assigneeDisplayText: eventAssigneesText(
      entity,
      functionsNotDeleted(state),
      employeesShortNamesAndMe(state),
      getContactsAsUsers(state),
    ),
    notes: entity.notes ?? '',
    scheduleDescription: '', // entity.scheduleDescription,
    timeDescription: dateTimeDescription(entity.date ?? '', entity.time ?? '', entity.durationMinutes),
    type: 'eventOccurrences',
    name: displayName(entity.name, ''),
    attachments: toAttachmentItems(api, 'eventOccurrences', entity.uuid, entity.attachments),
    doneDate: entity.doneDate ?? '',
    doneLabel: entity.doneDate ? 'Merket som utført' : 'Ikke utført',
    doneText: _doneText(entity, organization, organization.singleUser),
    hasAssets: relatedAssets.length > 0,
    thisEventInstance: '',
    defaultEmployee: '',
    defaultFunction: '',
    today: LocalDate.fromString(state.today),
    relatedAssets: relatedAssets,
    availableEmployees: sortBy(
      employeesShortNamesAndI(state)
        .filter((e) => e.name !== '')
        .filter((e) => e.status !== 'TERMINATED' || (entity.employees ?? []).includes(e.uuid))
        .map((p) => ({ value: p.uuid, text: p.name })),
      ['text'],
    ),
    availableContacts: sortBy(
      getContactsAsUsers(state)
        .filter((p) => p.name !== '')
        .map((p) => ({ value: p.uuid, text: p.name })),
      ['text'],
    ),
    availableFunctions: sortBy(availableFunctions, ['text']),
    commonFunctionUuid: commonFunctionUuid(state),
    currentEmployeeUuid: currentEmployeeUuid1,
    currentEmployeeShortName: currentEmployeeShortName(state),
    messageRecipients: getMessageRecipients(state, currentEmployeeUuid1, entity.employees ?? []),
    deletable: true,
    hasDraft: eventOccurrence.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'eventOccurrences', uuid, organization.organizationId);
      return command.draft as EventOccurrenceViewEditItem;
    },
    assetOptions: getAssetOptions(state, relatedAssets),
    newItem: !entity.isConfirmedEntity,
    procedures: entity.description,
    taskHref: task === undefined ? '' : currentParent + '/eventOccurrences/' + uuid + '/tasks/' + task.uuid,
    taskUuid: entity.taskUuid ?? '',
    taskName: task?.name ?? '',
    eventType: 'standard',
    date: entity.date ?? '',
    time: entity.time ?? 'NONE',
    durationMinutes: entity.durationMinutes,
    persistent: entity.persistent,
    assignedToEmployees: entity.employees ?? [],
    assignedToFunction: entity.functionUuid ?? '',
    assignedToContacts: entity.contacts ?? [],
    reminder: entity.reminder ?? 'NONE',
    occurrences: sortBy(
      e.eventOccurrences
        .filter((o) => {
          return o.isConfirmedEntity;
        })
        .map((o) => {
          let dateTime = o.date ?? '';
          if (o.time) {
            dateTime += ' ' + o.time;
          }
          let assignees = '';
          if (!singleUserVersion(state)) {
            assignees = eventAssigneesText(
              o,
              functionsNotDeleted(state),
              employeesShortNamesAndI(state),
              getContactsAsUsers(state),
            );
          }
          return {
            occurrenceUuid: o.uuid,
            dateTime,
            classified: false,
            restricted: false,
            displayDate: dateTimeDescription(o.date ?? '', o.time ?? '', o.durationMinutes),
            durationMinutes: o.durationMinutes,
            assignees,
            doneDate: o.doneDate,
            href: '/eventOccurrences/' + o.uuid,
          };
        }),
      (x) => x.dateTime,
      (x) => x.occurrenceUuid,
    ),
    canUpdateFutureOccurrences,
    taskOptions: sortBy(taskOptions, ['text']),
  };
}

function _checkedByTime(state: State, status, comments, checkedBy, checkedTime) {
  let result = '';
  let d = '';
  if (checkedTime) {
    d = LocalDate.fromString(checkedTime).toStringForDisplay();
  }
  if (status !== 'NONE' && checkedBy !== '') {
    let name = '';
    if (!singleUserVersion(state)) {
      const names = employeesShortNamesAndMe(state).filter((item) => item.uuid === checkedBy);
      if (names) {
        name = ' av ' + names[0].name;
      }
    }
    result = 'Utført ' + d + name;
  }
  return result;
}
