/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DataItemBackupUpdateMessage
 */
export interface DataItemBackupUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof DataItemBackupUpdateMessage
   */
  backupFrequency: string;
  /**
   *
   * @type {string}
   * @memberof DataItemBackupUpdateMessage
   */
  backupResponsible: string;
  /**
   *
   * @type {string}
   * @memberof DataItemBackupUpdateMessage
   */
  noBackupReason: string;
}

/**
 * Check if a given object implements the DataItemBackupUpdateMessage interface.
 */
export function instanceOfDataItemBackupUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'backupFrequency' in value;
  isInstance = isInstance && 'backupResponsible' in value;
  isInstance = isInstance && 'noBackupReason' in value;

  return isInstance;
}

export function DataItemBackupUpdateMessageFromJSON(json: any): DataItemBackupUpdateMessage {
  return DataItemBackupUpdateMessageFromJSONTyped(json, false);
}

export function DataItemBackupUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataItemBackupUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    backupFrequency: json['backupFrequency'],
    backupResponsible: json['backupResponsible'],
    noBackupReason: json['noBackupReason'],
  };
}

export function DataItemBackupUpdateMessageToJSON(value?: DataItemBackupUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    backupFrequency: value.backupFrequency,
    backupResponsible: value.backupResponsible,
    noBackupReason: value.noBackupReason,
  };
}
