/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TutorialParticipant } from './TutorialParticipant';
import {
  TutorialParticipantFromJSON,
  TutorialParticipantFromJSONTyped,
  TutorialParticipantToJSON,
} from './TutorialParticipant';

/**
 *
 * @export
 * @interface UpdateTutorialCommand
 */
export interface UpdateTutorialCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateTutorialCommand
   */
  id: number;
  /**
   *
   * @type {Array<TutorialParticipant>}
   * @memberof UpdateTutorialCommand
   */
  participants: Array<TutorialParticipant>;
  /**
   *
   * @type {string}
   * @memberof UpdateTutorialCommand
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTutorialCommand
   */
  comment: string;
}

/**
 * Check if a given object implements the UpdateTutorialCommand interface.
 */
export function instanceOfUpdateTutorialCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'comment' in value;

  return isInstance;
}

export function UpdateTutorialCommandFromJSON(json: any): UpdateTutorialCommand {
  return UpdateTutorialCommandFromJSONTyped(json, false);
}

export function UpdateTutorialCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTutorialCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    participants: (json['participants'] as Array<any>).map(TutorialParticipantFromJSON),
    dueDate: !exists(json, 'dueDate') ? undefined : json['dueDate'],
    comment: json['comment'],
  };
}

export function UpdateTutorialCommandToJSON(value?: UpdateTutorialCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    participants: (value.participants as Array<any>).map(TutorialParticipantToJSON),
    dueDate: value.dueDate,
    comment: value.comment,
  };
}
