/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TutorialParticipant } from './TutorialParticipant';
import {
  TutorialParticipantFromJSON,
  TutorialParticipantFromJSONTyped,
  TutorialParticipantToJSON,
} from './TutorialParticipant';

/**
 *
 * @export
 * @interface TutorialViewModel
 */
export interface TutorialViewModel {
  /**
   *
   * @type {number}
   * @memberof TutorialViewModel
   */
  id?: number;
  /**
   *
   * @type {Array<TutorialParticipant>}
   * @memberof TutorialViewModel
   */
  participants: Array<TutorialParticipant>;
  /**
   *
   * @type {string}
   * @memberof TutorialViewModel
   */
  dueDate?: string;
  /**
   *
   * @type {string}
   * @memberof TutorialViewModel
   */
  comment: string;
}

/**
 * Check if a given object implements the TutorialViewModel interface.
 */
export function instanceOfTutorialViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'comment' in value;

  return isInstance;
}

export function TutorialViewModelFromJSON(json: any): TutorialViewModel {
  return TutorialViewModelFromJSONTyped(json, false);
}

export function TutorialViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    participants: (json['participants'] as Array<any>).map(TutorialParticipantFromJSON),
    dueDate: !exists(json, 'dueDate') ? undefined : json['dueDate'],
    comment: json['comment'],
  };
}

export function TutorialViewModelToJSON(value?: TutorialViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    participants: (value.participants as Array<any>).map(TutorialParticipantToJSON),
    dueDate: value.dueDate,
    comment: value.comment,
  };
}
