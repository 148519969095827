/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RequestRoomCodeCommand
 */
export interface RequestRoomCodeCommand {
  /**
   *
   * @type {string}
   * @memberof RequestRoomCodeCommand
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof RequestRoomCodeCommand
   */
  personUuid: string;
}

/**
 * Check if a given object implements the RequestRoomCodeCommand interface.
 */
export function instanceOfRequestRoomCodeCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'personUuid' in value;

  return isInstance;
}

export function RequestRoomCodeCommandFromJSON(json: any): RequestRoomCodeCommand {
  return RequestRoomCodeCommandFromJSONTyped(json, false);
}

export function RequestRoomCodeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestRoomCodeCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    personUuid: json['personUuid'],
  };
}

export function RequestRoomCodeCommandToJSON(value?: RequestRoomCodeCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    personUuid: value.personUuid,
  };
}
