import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { DEditSelectText, Suggestion } from 'src/library/editors/components/d-edit-select-text';
import 'src/library/elements/d-smooth-resize';
import 'src/library/elements/d-section';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-edit-textarea';
import 'src/library/elements/d-label';

export interface OrganizationForSelector {
  name: string;
  number: string;
  address: string[];
  areaCode: string;
  area: string;
}
@customElement('d-edit-select-organization')
export class DEditSelectOrganization extends DEditSelectText {
  static readonly styles = DEditSelectText.styles.concat([
    css`
      #options {
        background: hsla(0, 0%, 100%, 0.5);
        color: var(--themeColor);
      }
      .option {
        border-top: 1px solid var(--borderColorTransparent);
      }
      .option:focus-visible,
      .option[selected] {
        outline: none;
        background: white;
        color: black;
      }
      :host([outskirts]) .option:focus-visible,
      :host([outskirts]) .option[selected] {
        background: white;
        color: black;
      }
      .prompt {
        padding: 10px 14px 0px 10px;
      }
      d-action {
        margin-left: -8px;
      }
      @media (hover: hover) {
        .option:hover,
        .option:focus-visible:hover,
        .option[selected]:hover {
          background: white;
          color: black;
        }
      }
    `,
  ]);
  @property({ type: String })
  searchString = '';
  @property({ type: String })
  name = '';
  @property({ type: String })
  number = '';
  @property({ type: Array })
  address: string[] = [];
  @property({ type: String })
  areaCode = '';
  @property({ type: String })
  area = '';
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean })
  outskirts = false;
  @property({ type: Boolean, attribute: 'system-content' })
  systemContent = false;
  @property({ type: Boolean })
  autofocus = false;
  @state()
  loginSelected = false;
  @state()
  addOrganization = false;
  @state()
  suggestedOrganizations: OrganizationForSelector[] = [];
  @state()
  private manualEntry = false;

  private get organizations(): Suggestion[] {
    if (this.number) {
      return [];
    }
    return this.suggestedOrganizations.map((o) => {
      return { name: o.name + ' ' + o.areaCode + ' ' + o.area, type: '', dataValue: o.number };
    });
  }

  private clear() {
    this.searchString = '';
    this.name = '';
    this.number = '';
    this.address = [];
    this.areaCode = '';
    this.area = '';
    this.dispatchValueChanged();
  }

  private setManualEntry() {
    this.manualEntry = true;
    this.clear();
  }

  private unsetManualEntry() {
    this.manualEntry = false;
    this.clear();
  }

  dispatchValueChanged() {
    this.dispatchEvent(
      new CustomEvent<OrganizationForSelector>('value-changed', {
        bubbles: true,
        composed: true,
        detail: {
          name: this.name,
          number: this.number,
          address: this.address,
          areaCode: this.areaCode,
          area: this.area,
        },
      }),
    );
  }

  onSearchStringChanged(e) {
    e.stopPropagation();
    this.searchString = e.detail.value;
    if (e.detail.value) {
      this.number = '';
      this.dispatchValueChanged();
    }
  }

  onNameChanged(e) {
    e.stopPropagation();
    this.name = e.detail.value;
    this.dispatchValueChanged();
  }

  onNumberChanged(e) {
    e.stopPropagation();
    this.address = e.detail.value;
    this.dispatchValueChanged();
  }

  onAddressChanged(e) {
    e.stopPropagation();
    this.address = e.detail.value.split('\n');
    this.dispatchValueChanged();
  }

  onAreaCodeChanged(e) {
    e.stopPropagation();
    this.areaCode = e.detail.value;
    this.setArea(this.areaCode);
  }

  selectOrganization(e) {
    e.stopPropagation();
    if (e.target.dataset.value) {
      this.number = e.target.dataset.value;
    }
    const organization = this.suggestedOrganizations.find((o) => {
      return o.number === this.number;
    });
    if (organization) {
      console.log('selectOrganization', organization);
      this.name = organization.name;
      this.address = organization.address;
      this.areaCode = organization.areaCode;
      this.area = organization.area;
      this.searchString = '';
    }
    this.dispatchValueChanged();
  }

  render() {
    return html`
      <d-smooth-resize>
        <d-section topless borderless style="padding-bottom: 0">
          ${!this.manualEntry && !this.number
            ? html` <d-edit-text
                  id="input"
                  type="search"
                  class="fullWidth"
                  autofocus
                  ?outskirts="${this.outskirts}"
                  ?theme-page="${this.themePage}"
                  ?system-content="${this.systemContent}"
                  select-on-focus
                  label="Virksomhet"
                  sublabel="Søk eller"
                  label-action="registrer manuelt"
                  label-action-plain
                  placeholder="Enhetsregisteret"
                  .value=${this.searchString}
                  @value-changed=${(e) => this.onSearchStringChanged(e)}
                  @label-action=${() => this.setManualEntry()}
                ></d-edit-text>
                ${this.searchString.length > 2
                  ? html` <div class="prompt fullWidth">
                      ${this.organizations.length ? 'Trykk for å velge' : 'Ingen treff'}
                    </div>`
                  : nothing}
                ${this.organizations.length
                  ? html`<div class="fullWidth">
                      <div id="options">
                        ${this.organizations.map(
                          (item) => html`
                            <a
                              class="option ${item.type}"
                              ?no-type="${!item.type}"
                              data-value="${item.dataValue ?? ''}"
                              tabindex="0"
                              @click=${(e) => this.selectOrganization(e)}
                              >${item.name}</a
                            >
                          `,
                        )}
                      </div>
                    </div> `
                  : nothing}`
            : nothing}
          ${this.number !== '' || this.manualEntry
            ? html`
                <d-edit-text
                  ?disabled="${!this.manualEntry}"
                  class="minWidth300"
                  label="Virksomhetsnavn"
                  .labelAction=${'Søk'}
                  label-action-plain
                  autofocus
                  ?system-content="${this.systemContent}"
                  ?theme-page="${this.themePage}"
                  ?outskirts="${this.outskirts}"
                  .value=${this.name}
                  @value-changed=${(e) => this.onNameChanged(e)}
                  @label-action=${() => this.unsetManualEntry()}
                ></d-edit-text>
                ${!this.manualEntry
                  ? html` <d-edit-text
                      ?disabled="${!this.manualEntry}"
                      label="Organisasjonsnummer"
                      class="minWidth200"
                      ?system-content="${this.systemContent}"
                      ?theme-page="${this.themePage}"
                      ?outskirts="${this.outskirts}"
                      .value=${this.number}
                      @value-changed=${(e) => this.onNumberChanged(e)}
                    ></d-edit-text>`
                  : nothing}
                <d-edit-textarea
                  ?disabled="${!this.manualEntry}"
                  label="Adresse"
                  class="fullWidth"
                  rows="1"
                  ?system-content="${this.systemContent}"
                  ?theme-page="${this.themePage}"
                  ?outskirts="${this.outskirts}"
                  .value=${this.address.join('\n')}
                  @value-changed=${(e) => this.onAddressChanged(e)}
                ></d-edit-textarea>
                <div>
                  <d-label label="Postnummer/-sted"></d-label>
                  <div style="display:flex;gap:12px">
                    <d-edit-text
                      ?disabled="${!this.manualEntry}"
                      style="width:70px;"
                      ?system-content="${this.systemContent}"
                      ?theme-page="${this.themePage}"
                      ?outskirts="${this.outskirts}"
                      .value=${this.areaCode}
                      @value-changed=${(e) => this.onAreaCodeChanged(e)}
                    ></d-edit-text>
                    <d-edit-text
                      disabled
                      style="flex: 1"
                      ?system-content="${this.systemContent}"
                      ?theme-page="${this.themePage}"
                      ?outskirts="${this.outskirts}"
                      .value=${this.area}
                    ></d-edit-text>
                  </div>
                </div>
              `
            : nothing}
        </d-section>
      </d-smooth-resize>
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('searchString')) {
      this.setOrganizationList(this.searchString);
    }
  }
  onKeyDown(event) {
    if (this.organizations.length) {
      const focusedElm: HTMLElement = this.shadowRoot?.activeElement as HTMLElement;
      if (focusedElm) {
        if (event.code === 'ArrowDown') {
          this.handleArrowDown(event, focusedElm);
        } else if (event.code === 'ArrowUp') {
          this.handleArrowUp(event, focusedElm);
        } else if (event.code === 'Enter') {
          focusedElm.click();
        }
      }
    }
  }

  private fixName(text: string): string {
    if (text) {
      const orgforms = ['AS', 'ANS', 'ASA', 'BA', 'DA', 'ENK'];
      const arr = text.split(' ');
      const capitalized = arr.map((item) => {
        if (!orgforms.includes(item)) {
          return item[0] + item.substring(1).toLowerCase();
        }
        if (item === '&') {
          return item;
        }
        return item.toLowerCase();
      });
      const arr2 = capitalized.join(' ').split('.');
      const result = arr2.map((item) => {
        if (item[0]) {
          return item[0].toUpperCase() + item.substring(1);
        }
        return item;
      });
      return result.join('.').replaceAll('&amp;', '&');
    }
    return '';
  }

  private setOrganizationList(name: string): void {
    const result: OrganizationForSelector[] = [];
    if (name.length > 1) {
      fetch('https://data.brreg.no/enhetsregisteret/api/enheter?size=200&navn=' + name)
        .then((response) => response.json())
        .then((data) => {
          if (data._embedded) {
            data._embedded.enheter.forEach((item) => {
              if (item.navn.toLowerCase().includes(name.toLowerCase())) {
                const name = this.fixName(item.navn);
                result.push({
                  name: name,
                  number: item.organisasjonsnummer,
                  address: item?.forretningsadresse?.adresse ?? [],
                  areaCode: item?.forretningsadresse?.postnummer ?? '',
                  area: item?.forretningsadresse?.poststed ?? '',
                });
              }
            });
            this.suggestedOrganizations = result;
          } else {
            this.suggestedOrganizations = [];
          }
        });
    } else {
      this.suggestedOrganizations = [];
    }
  }

  private setArea(code: string) {
    if (code.length === 4) {
      fetch('https://webapi.no/api/v1/zipcode/' + code)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.area = data.data.city;
            this.dispatchValueChanged();
          } else {
            this.area = '';
            this.dispatchValueChanged();
          }
        });
    }
    this.area = '';
    this.dispatchValueChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-select-organization': DEditSelectOrganization;
  }
}
