import { css } from 'lit';

export const levelHeaderStyles = css`
  :host {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: calc(-1px + var(--safe-area-inset-top));
    z-index: 3;
  }

  :host(#small-header),
  :host(#large-header) {
    position: absolute;
    width: 100%;
    visibility: hidden;
  }

  :host([covered]) {
    position: relative;
    top: 0;
  }

  .header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: calc(-1px + var(--safe-area-inset-top));
    z-index: 3;
  }

  .special-header {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    background-color: hsl(1, 0%, 96%);
    padding: 10px 20px 14px 20px;
    color: hsl(1, 0%, 40%);
  }

  .special-header d-label {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin: 0;
    padding: 6px 0 0 32px;
    background-position: -4px -2px;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    color: hsl(1, 0%, 40%);
  }

  .special-header .actions d-action {
    margin: -5px -8px -8px 0;
  }

  .draft-header d-label {
    background-image: url(/images/draft.svg);
  }

  .trash-header d-label {
    background-image: url(/images/trash.svg);
  }

  .header {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 10px;
    z-index: 3;
    transition: all var(--transition);
  }

  :host(:not([no-margin])) .header {
    margin: 0 20px;
  }

  :host(.width600:not([no-margin])) .header {
    margin: 0 30px;
  }

  :host([covered]) .header {
    padding-top: 2px;
  }

  .header .actions {
    flex: none;
    align-self: flex-start;
    text-align: right;
    max-height: 36px;
    margin: -8px -8px -10px 0;
    transition: all var(--transition);
  }

  d-more-menu {
    margin-bottom: -11px;
    margin-right: -8px;
    margin-left: -4px;
  }

  .actions ::slotted(d-action) {
    font-size: 10px;
  }

  .header-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  :host([covered]) .actions {
    max-height: 0;
    overflow: hidden;
  }

  .title {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    overflow: hidden;
    margin: 0;
    padding: 14px 0 18px 0;
    background-position: 0 6px;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
    color: var(--pageHeaderColor);
    font-family: var(--mainSans), sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 110%;
    word-break: break-word;
    transition:
      min-height var(--transition),
      font-size var(--transition),
      line-height var(--transition),
      padding var(--transition),
      margin var(--transition),
      background-position var(--transition),
      background-size var(--transition);
  }

  .title.icon {
    padding-left: 40px;
    background-size: 40px 40px;
    background-position: -4px 5px;
  }

  .title.icon.robot {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100"><style type="text/css"> .head { fill: %2315ade5; } .eye { fill: white; } .pupil { fill: %2315ade5; } .mouth.happy { opacity: 0.4; } .eye { animation: blink 5s infinite both; } @keyframes blink { 56.999% { opacity: 1; } 57% { opacity: 0; } 59.999% { opacity: 0; } 60% { opacity: 1; } 96.999% { opacity: 1; } 97% { opacity: 0; } 99.999% { opacity: 0; } } @-webkit-keyframes blink { 56.999% { opacity: 1; } 57% { opacity: 0; } 59.999% { opacity: 0; } 60% { opacity: 1; } 96.999% { opacity: 1; } 97% { opacity: 0; } 99.999% { opacity: 0; } } .pupil { animation: peek-right-down 5s infinite both; } @keyframes peek-right-down { 59.999% { transform: translate(0, 0); } 60% { transform: translate(5px, 5px); } 96.999% { transform: translate(5px, 5px); } 97% { transform: translate(0, 0); } } } </style><rect class="head" width="100" height="100" rx="20" /><circle class="eye" cx="28.75" cy="32.5" r="12.5" /><circle class="eye" cx="71.25" cy="32.5" r="12.5" /><circle class="pupil" cx="28.75" cy="32.5" r="7.5" /><circle class="pupil" cx="71.25" cy="32.5" r="7.5" /><path class="mouth happy" d="M27.65 62.5a22.49 22.49 0 0 0 44.7 0Z" /></svg>');
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: 0 9px;
  }

  :host([covered]) .title,
  :host([top]) .title {
    top: 6px;
    margin-top: 2px;
    padding: 8px 0 14px 0;
    color: hsl(1, 0%, 50%);
    font-size: 15px;
    cursor: pointer;
  }

  :host([covered]) .title.icon,
  :host([top]) .title.icon {
    background-size: 30px 30px;
    background-position: -2px 1px;
    padding-left: var(--listPaddingLeft);
  }

  :host([covered]) .title.icon.robot,
  :host([top]) .title.icon.robot {
    background-size: 24px 24px;
    background-position: 0 3px;
  }

  .name {
    margin-right: 0.2em;
    -webkit-user-select: text;
    -ms-user-select: text;
    -moz-user-select: text;
    user-select: text;
  }

  :host(.width600) .actions ::slotted(d-action) {
    font-size: 11px;
  }

  :host(:not([covered]):not([top]).width600) .header {
    padding-top: 16px;
    padding-bottom: 6px;
  }

  :host(:not([covered]):not([top]).width600) .title {
    font-size: 32px;
  }

  :host(:not([covered]):not([top]).width600) .title.icon {
    padding-left: 60px;
    background-size: 60px 60px;
    background-position: -6px 0;
  }

  :host(:not([covered]):not([top]).width600) .title.icon.robot {
    background-position: 0 6px;
    background-size: 48px auto;
  }
`;
