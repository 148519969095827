import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../library/elements/d-progress-bar.js';

export interface SlideNotificationContent {
  primaryText: string;
  secondaryText?: string;
  progressBar?: { total: number; partNumber: number };
}

/**
 *
 * Viser innholdet i slot primary og secondary
 *
 */
@customElement('d-slide-notification')
export class DSlideNotification extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      cursor: pointer;
      z-index: 2000;
    }

    :host([storybook]) {
      height: 500px;
      width: 800px;
    }

    #box {
      display: block;
      position: fixed;
      background: white;
      width: 340px;
      border-radius: 12px;
      box-shadow: 0 4px 12px 2px hsla(0, 0%, 0%, 0.3);
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      padding: 14px 16px 14px 16px;
      /* Space for status bar when application runs as native app */
      top: calc(var(--safe-area-inset-top) + 50px);
      margin: 0;
      right: -360px;
      color: var(--text-color);
      z-index: 100;
      transition: all 0.3s;
    }

    #box.in {
      right: 20px;
      transition: all 0.3s;
      transition-delay: 0.5s;
    }

    :host([storybook]) #box,
    :host([storybook]) #box.in {
      right: 50px;
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      transition-delay: 0s;
    }

    .primary {
      min-height: 36px;
      font-size: 15px;
      line-height: 140%;
    }

    .robot {
      width: 36px;
      height: 36px;
      float: left;
      margin-right: 8px;
      margin-top: 2px;
    }

    d-progress-bar {
      margin-top: 8px;
    }

    .secondary {
      clear: both;
      margin-top: 8px;
      font-size: 13px;
      font-weight: 200;
    }
  `;
  @property({ type: Boolean, reflect: true })
  show = false;
  @property({ type: Boolean })
  hold = false;
  @property({ type: Object })
  content: SlideNotificationContent | undefined = undefined;

  _show() {
    this.show = true;
    setTimeout(() => {
      this._hide();
    }, 5000);
  }

  _hide() {
    if (!this.hold) {
      this.show = false;
    }
  }

  _hold() {
    this.hold = true;
  }

  _release() {
    this.hold = false;
    this._hide();
  }

  render() {
    return html`
      <div
        id="box"
        class="${classMap({
          in: this.show || this.hold,
        })}"
        @mouseover=${() => this._hold()}
        @mouseout=${() => this._release()}
        @click=${() => this._release()}
      >
        <div class="primary">
          <img class="robot" src="/images/slide-notification-robot.gif" alt="robot" />
          <div>${this.content?.primaryText}</div>
        </div>
        ${this.content?.progressBar?.total && this.content?.progressBar?.partNumber
          ? html`
              <d-progress-bar
                .total=${this.content.progressBar.total}
                .partNumber=${this.content.progressBar.partNumber}
              ></d-progress-bar>
            `
          : nothing}
        ${this.content?.secondaryText
          ? html` <div class="secondary" @mouseover=${() => this._hold()}>${this.content?.secondaryText}</div> `
          : nothing}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-slide-notification': DSlideNotification;
  }
}
