/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkScheduleDay } from './WorkScheduleDay';
import { WorkScheduleDayFromJSON, WorkScheduleDayFromJSONTyped, WorkScheduleDayToJSON } from './WorkScheduleDay';

/**
 *
 * @export
 * @interface WorkScheduleWeek
 */
export interface WorkScheduleWeek {
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  monday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  tuesday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  wednesday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  thursday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  friday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  saturday: WorkScheduleDay;
  /**
   *
   * @type {WorkScheduleDay}
   * @memberof WorkScheduleWeek
   */
  sunday: WorkScheduleDay;
}

/**
 * Check if a given object implements the WorkScheduleWeek interface.
 */
export function instanceOfWorkScheduleWeek(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'monday' in value;
  isInstance = isInstance && 'tuesday' in value;
  isInstance = isInstance && 'wednesday' in value;
  isInstance = isInstance && 'thursday' in value;
  isInstance = isInstance && 'friday' in value;
  isInstance = isInstance && 'saturday' in value;
  isInstance = isInstance && 'sunday' in value;

  return isInstance;
}

export function WorkScheduleWeekFromJSON(json: any): WorkScheduleWeek {
  return WorkScheduleWeekFromJSONTyped(json, false);
}

export function WorkScheduleWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkScheduleWeek {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    monday: WorkScheduleDayFromJSON(json['monday']),
    tuesday: WorkScheduleDayFromJSON(json['tuesday']),
    wednesday: WorkScheduleDayFromJSON(json['wednesday']),
    thursday: WorkScheduleDayFromJSON(json['thursday']),
    friday: WorkScheduleDayFromJSON(json['friday']),
    saturday: WorkScheduleDayFromJSON(json['saturday']),
    sunday: WorkScheduleDayFromJSON(json['sunday']),
  };
}

export function WorkScheduleWeekToJSON(value?: WorkScheduleWeek | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    monday: WorkScheduleDayToJSON(value.monday),
    tuesday: WorkScheduleDayToJSON(value.tuesday),
    wednesday: WorkScheduleDayToJSON(value.wednesday),
    thursday: WorkScheduleDayToJSON(value.thursday),
    friday: WorkScheduleDayToJSON(value.friday),
    saturday: WorkScheduleDayToJSON(value.saturday),
    sunday: WorkScheduleDayToJSON(value.sunday),
  };
}
