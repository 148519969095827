/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  ConnectAssetCommand,
  DisconnectAssetCommand,
  FunctionTaskUpdateMessage,
  FunctionUpdateMessage,
  FunctionViewModel,
  RevisionViewModel,
  TaskViewModel,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  ConnectAssetCommandFromJSON,
  ConnectAssetCommandToJSON,
  DisconnectAssetCommandFromJSON,
  DisconnectAssetCommandToJSON,
  FunctionTaskUpdateMessageFromJSON,
  FunctionTaskUpdateMessageToJSON,
  FunctionUpdateMessageFromJSON,
  FunctionUpdateMessageToJSON,
  FunctionViewModelFromJSON,
  FunctionViewModelToJSON,
  RevisionViewModelFromJSON,
  RevisionViewModelToJSON,
  TaskViewModelFromJSON,
  TaskViewModelToJSON,
} from '../models';

export interface CreateOrUpdateFunctionRequest {
  functionId: string;
  organizationId: string;
  functionUpdateMessage: FunctionUpdateMessage;
}

export interface CreateOrUpdateFunctionTaskRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
  functionTaskUpdateMessage: FunctionTaskUpdateMessage;
}

export interface DeleteAttachmentForFunctionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteAttachmentForFunctionTaskRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  functionId: string;
  organizationId: string;
}

export interface DeleteFunctionRequest {
  functionId: string;
  organizationId: string;
}

export interface DeleteFunctionTaskRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
}

export interface FetchAttachmentForFunctionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentForFunctionTaskRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  functionId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForFunctionRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForFunctionTaskRequest {
  attachmentUuid: string;
  entityId: string;
  functionId: string;
  organizationId: string;
}

export interface FetchFunctionRequest {
  functionId: string;
  organizationId: string;
}

export interface FetchFunctionTaskRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
}

export interface ListAttachmentsForFunctionRequest {
  entityId: string;
  organizationId: string;
}

export interface ListAttachmentsForFunctionTaskRequest {
  entityId: string;
  functionId: string;
  organizationId: string;
}

export interface ListFunctionTasksRequest {
  functionId: string;
  organizationId: string;
}

export interface ListFunctionsRequest {
  organizationId: string;
}

export interface ListRevisionsForFunctionRequest {
  functionId: string;
  organizationId: string;
}

export interface ListRevisionsForFunctionTaskRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
}

export interface MarkTaskDoneRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
}

export interface PostFunctionTaskConnectAssetRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
  connectAssetCommand: ConnectAssetCommand;
}

export interface PostFunctionTaskDisconnectAssetRequest {
  taskId: string;
  functionId: string;
  organizationId: string;
  disconnectAssetCommand: DisconnectAssetCommand;
}

export interface PostponeTasksRequest {
  organizationId: string;
}

export interface RenameAttachmentForFunctionRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface RenameAttachmentForFunctionTaskRequest {
  attachmentUuid: string;
  entityId: string;
  functionId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UploadAttachmentForFunctionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

export interface UploadAttachmentForFunctionTaskRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  functionId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class FunctionsApi extends runtime.BaseAPI {
  /**
   * Update or create function with the specified function id, the relate employees, rotations and pages for the function will also be updated or created,  and the search index for the function will also be updated or created.
   * Creates or updates a function.
   */
  async createOrUpdateFunctionRaw(
    requestParameters: CreateOrUpdateFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling createOrUpdateFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateFunction.',
      );
    }

    if (requestParameters.functionUpdateMessage === null || requestParameters.functionUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'functionUpdateMessage',
        'Required parameter requestParameters.functionUpdateMessage was null or undefined when calling createOrUpdateFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}`
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: FunctionUpdateMessageToJSON(requestParameters.functionUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create function with the specified function id, the relate employees, rotations and pages for the function will also be updated or created,  and the search index for the function will also be updated or created.
   * Creates or updates a function.
   */
  async createOrUpdateFunction(
    requestParameters: CreateOrUpdateFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateFunctionRaw(requestParameters, initOverrides);
  }

  /**
   * Update or create task with the specified task id, the relate schedules and pages for the task will also be updated or created,  and the search index for the task will also be updated or created.
   * Creates or updates a task for a function.
   */
  async createOrUpdateFunctionTaskRaw(
    requestParameters: CreateOrUpdateFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling createOrUpdateFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling createOrUpdateFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateFunctionTask.',
      );
    }

    if (
      requestParameters.functionTaskUpdateMessage === null ||
      requestParameters.functionTaskUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'functionTaskUpdateMessage',
        'Required parameter requestParameters.functionTaskUpdateMessage was null or undefined when calling createOrUpdateFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: FunctionTaskUpdateMessageToJSON(requestParameters.functionTaskUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create task with the specified task id, the relate schedules and pages for the task will also be updated or created,  and the search index for the task will also be updated or created.
   * Creates or updates a task for a function.
   */
  async createOrUpdateFunctionTask(
    requestParameters: CreateOrUpdateFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateFunctionTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a function.
   */
  async deleteAttachmentForFunctionRaw(
    requestParameters: DeleteAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForFunction.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a function.
   */
  async deleteAttachmentForFunction(
    requestParameters: DeleteAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForFunctionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a task.
   */
  async deleteAttachmentForFunctionTaskRaw(
    requestParameters: DeleteAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling deleteAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a task.
   */
  async deleteAttachmentForFunctionTask(
    requestParameters: DeleteAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForFunctionTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Delete function with the specified function id, the search index of the function will also be deleted.
   * Deletes a function.
   */
  async deleteFunctionRaw(
    requestParameters: DeleteFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling deleteFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}`
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete function with the specified function id, the search index of the function will also be deleted.
   * Deletes a function.
   */
  async deleteFunction(
    requestParameters: DeleteFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteFunctionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete task with the specified task id, the search index of the task will also be deleted.
   * Deletes a task.
   */
  async deleteFunctionTaskRaw(
    requestParameters: DeleteFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling deleteFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling deleteFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete task with the specified task id, the search index of the task will also be deleted.
   * Deletes a task.
   */
  async deleteFunctionTask(
    requestParameters: DeleteFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteFunctionTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForFunctionRaw(
    requestParameters: FetchAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForFunction.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForFunction(
    requestParameters: FetchAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForFunctionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {function id}.
   */
  async fetchAttachmentForFunctionTaskRaw(
    requestParameters: FetchAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling fetchAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {function id}.
   */
  async fetchAttachmentForFunctionTask(
    requestParameters: FetchAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForFunctionTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a function.
   */
  async fetchAttachmentLinkForFunctionRaw(
    requestParameters: FetchAttachmentLinkForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForFunction.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a function.
   */
  async fetchAttachmentLinkForFunction(
    requestParameters: FetchAttachmentLinkForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForFunctionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a task.
   */
  async fetchAttachmentLinkForFunctionTaskRaw(
    requestParameters: FetchAttachmentLinkForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForFunctionTask.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling fetchAttachmentLinkForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a task.
   */
  async fetchAttachmentLinkForFunctionTask(
    requestParameters: FetchAttachmentLinkForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForFunctionTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the function with the specified function id, employees, rotations, pages and tasks also have been included in the function.
   * Gets a function.
   */
  async fetchFunctionRaw(
    requestParameters: FetchFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FunctionViewModel>> {
    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling fetchFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}`
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FunctionViewModelFromJSON(jsonValue));
  }

  /**
   * Get the function with the specified function id, employees, rotations, pages and tasks also have been included in the function.
   * Gets a function.
   */
  async fetchFunction(
    requestParameters: FetchFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FunctionViewModel> {
    const response = await this.fetchFunctionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the task with the specified task id.
   * Gets a task for a function.
   */
  async fetchFunctionTaskRaw(
    requestParameters: FetchFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TaskViewModel>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling fetchFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling fetchFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TaskViewModelFromJSON(jsonValue));
  }

  /**
   * Get the task with the specified task id.
   * Gets a task for a function.
   */
  async fetchFunctionTask(
    requestParameters: FetchFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TaskViewModel> {
    const response = await this.fetchFunctionTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a function.
   */
  async listAttachmentsForFunctionRaw(
    requestParameters: ListAttachmentsForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a function.
   */
  async listAttachmentsForFunction(
    requestParameters: ListAttachmentsForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForFunctionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a task.
   */
  async listAttachmentsForFunctionTaskRaw(
    requestParameters: ListAttachmentsForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling listAttachmentsForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a task.
   */
  async listAttachmentsForFunctionTask(
    requestParameters: ListAttachmentsForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForFunctionTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the tasks within the specified organization and function.
   * Lists tasks for a function.
   */
  async listFunctionTasksRaw(
    requestParameters: ListFunctionTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TaskViewModel>>> {
    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling listFunctionTasks.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listFunctionTasks.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks`
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskViewModelFromJSON));
  }

  /**
   * Get all the tasks within the specified organization and function.
   * Lists tasks for a function.
   */
  async listFunctionTasks(
    requestParameters: ListFunctionTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TaskViewModel>> {
    const response = await this.listFunctionTasksRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the functions within the specified organization, employees, rotations, pages and tasks also have been included in the function.
   * Lists functions.
   */
  async listFunctionsRaw(
    requestParameters: ListFunctionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<FunctionViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listFunctions.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FunctionViewModelFromJSON));
  }

  /**
   * Get all the functions within the specified organization, employees, rotations, pages and tasks also have been included in the function.
   * Lists functions.
   */
  async listFunctions(
    requestParameters: ListFunctionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<FunctionViewModel>> {
    const response = await this.listFunctionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists versioning for a function.
   */
  async listRevisionsForFunctionRaw(
    requestParameters: ListRevisionsForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RevisionViewModel>>> {
    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling listRevisionsForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listRevisionsForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/revisions`
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionViewModelFromJSON));
  }

  /**
   * Lists versioning for a function.
   */
  async listRevisionsForFunction(
    requestParameters: ListRevisionsForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RevisionViewModel>> {
    const response = await this.listRevisionsForFunctionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists versioning for a task.
   */
  async listRevisionsForFunctionTaskRaw(
    requestParameters: ListRevisionsForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RevisionViewModel>>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling listRevisionsForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling listRevisionsForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listRevisionsForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}/revisions`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionViewModelFromJSON));
  }

  /**
   * Lists versioning for a task.
   */
  async listRevisionsForFunctionTask(
    requestParameters: ListRevisionsForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RevisionViewModel>> {
    const response = await this.listRevisionsForFunctionTaskRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * The task is converted into a normal repeated task.
   * Marks a startup task as done.
   */
  async markTaskDoneRaw(
    requestParameters: MarkTaskDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling markTaskDone.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling markTaskDone.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling markTaskDone.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}/done`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * The task is converted into a normal repeated task.
   * Marks a startup task as done.
   */
  async markTaskDone(
    requestParameters: MarkTaskDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markTaskDoneRaw(requestParameters, initOverrides);
  }

  /**
   * Connects an asset to a task.
   */
  async postFunctionTaskConnectAssetRaw(
    requestParameters: PostFunctionTaskConnectAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling postFunctionTaskConnectAsset.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling postFunctionTaskConnectAsset.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling postFunctionTaskConnectAsset.',
      );
    }

    if (requestParameters.connectAssetCommand === null || requestParameters.connectAssetCommand === undefined) {
      throw new runtime.RequiredError(
        'connectAssetCommand',
        'Required parameter requestParameters.connectAssetCommand was null or undefined when calling postFunctionTaskConnectAsset.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}/:connect-asset`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConnectAssetCommandToJSON(requestParameters.connectAssetCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Connects an asset to a task.
   */
  async postFunctionTaskConnectAsset(
    requestParameters: PostFunctionTaskConnectAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.postFunctionTaskConnectAssetRaw(requestParameters, initOverrides);
  }

  /**
   * Connects an asset to a task.
   */
  async postFunctionTaskDisconnectAssetRaw(
    requestParameters: PostFunctionTaskDisconnectAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
      throw new runtime.RequiredError(
        'taskId',
        'Required parameter requestParameters.taskId was null or undefined when calling postFunctionTaskDisconnectAsset.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling postFunctionTaskDisconnectAsset.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling postFunctionTaskDisconnectAsset.',
      );
    }

    if (requestParameters.disconnectAssetCommand === null || requestParameters.disconnectAssetCommand === undefined) {
      throw new runtime.RequiredError(
        'disconnectAssetCommand',
        'Required parameter requestParameters.disconnectAssetCommand was null or undefined when calling postFunctionTaskDisconnectAsset.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{taskId}/:disconnect-asset`
          .replace(`{${'taskId'}}`, encodeURIComponent(String(requestParameters.taskId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DisconnectAssetCommandToJSON(requestParameters.disconnectAssetCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Connects an asset to a task.
   */
  async postFunctionTaskDisconnectAsset(
    requestParameters: PostFunctionTaskDisconnectAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.postFunctionTaskDisconnectAssetRaw(requestParameters, initOverrides);
  }

  /**
   * The tasks are rescheduled from the following week.
   * Postpones unfinished startup tasks for the organization.
   */
  async postponeTasksRaw(
    requestParameters: PostponeTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling postponeTasks.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/postpone-tasks`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * The tasks are rescheduled from the following week.
   * Postpones unfinished startup tasks for the organization.
   */
  async postponeTasks(
    requestParameters: PostponeTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.postponeTasksRaw(requestParameters, initOverrides);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a function.
   */
  async renameAttachmentForFunctionRaw(
    requestParameters: RenameAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForFunction.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForFunction.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a function.
   */
  async renameAttachmentForFunction(
    requestParameters: RenameAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForFunctionRaw(requestParameters, initOverrides);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a task.
   */
  async renameAttachmentForFunctionTaskRaw(
    requestParameters: RenameAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling renameAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForFunctionTask.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a task.
   */
  async renameAttachmentForFunctionTask(
    requestParameters: RenameAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForFunctionTaskRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a function.
   */
  async uploadAttachmentForFunctionRaw(
    requestParameters: UploadAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForFunction.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForFunction.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForFunction.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a function.
   */
  async uploadAttachmentForFunction(
    requestParameters: UploadAttachmentForFunctionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForFunctionRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a task.
   */
  async uploadAttachmentForFunctionTaskRaw(
    requestParameters: UploadAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.functionId === null || requestParameters.functionId === undefined) {
      throw new runtime.RequiredError(
        'functionId',
        'Required parameter requestParameters.functionId was null or undefined when calling uploadAttachmentForFunctionTask.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForFunctionTask.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/functions/{functionId}/tasks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'functionId'}}`, encodeURIComponent(String(requestParameters.functionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a task.
   */
  async uploadAttachmentForFunctionTask(
    requestParameters: UploadAttachmentForFunctionTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForFunctionTaskRaw(requestParameters, initOverrides);
  }
}
