/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface ComputerViewModel
 */
export interface ComputerViewModel {
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  journalType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  journalSupplier?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  journalSize?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  messagingType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  messagingSupplier?: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  printerPositionSecure: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  localPrinter?: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  antivirus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  laptop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  storesPersonalInformation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  storesJournal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  journalRegistered?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  journalHasElectronicMessaging?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  messageMonitoring?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  autoLookupPatients?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  autoLookupAddress?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  autoLookupAddressNhn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  autoUpdateAddressNhn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  networkUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  unitType?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  createdDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  serviceProvider?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  connectionType?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerViewModel
   */
  contracts?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  serviceProviderContract?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerViewModel
   */
  antiVirusRequirements?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ComputerViewModel
   */
  mobileMedicalDataRequirements?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  systemUpdateOperatorType?: ComputerViewModelSystemUpdateOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  systemUpdateOperator?: string;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  antiVirusOperatorType?: ComputerViewModelAntiVirusOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ComputerViewModel
   */
  antiVirusOperator?: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  elevated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  locked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  restrictedPhysicalAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputerViewModel
   */
  displayPositionSecure?: boolean;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof ComputerViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const ComputerViewModelSystemUpdateOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type ComputerViewModelSystemUpdateOperatorTypeEnum =
  (typeof ComputerViewModelSystemUpdateOperatorTypeEnum)[keyof typeof ComputerViewModelSystemUpdateOperatorTypeEnum];

/**
 * @export
 */
export const ComputerViewModelAntiVirusOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type ComputerViewModelAntiVirusOperatorTypeEnum =
  (typeof ComputerViewModelAntiVirusOperatorTypeEnum)[keyof typeof ComputerViewModelAntiVirusOperatorTypeEnum];

/**
 * Check if a given object implements the ComputerViewModel interface.
 */
export function instanceOfComputerViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'printerPositionSecure' in value;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function ComputerViewModelFromJSON(json: any): ComputerViewModel {
  return ComputerViewModelFromJSONTyped(json, false);
}

export function ComputerViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputerViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    name: !exists(json, 'name') ? undefined : json['name'],
    location: !exists(json, 'location') ? undefined : json['location'],
    type: !exists(json, 'type') ? undefined : json['type'],
    journalType: !exists(json, 'journalType') ? undefined : json['journalType'],
    journalSupplier: !exists(json, 'journalSupplier') ? undefined : json['journalSupplier'],
    journalSize: !exists(json, 'journalSize') ? undefined : json['journalSize'],
    messagingType: !exists(json, 'messagingType') ? undefined : json['messagingType'],
    messagingSupplier: !exists(json, 'messagingSupplier') ? undefined : json['messagingSupplier'],
    printerPositionSecure: json['printerPositionSecure'],
    localPrinter: !exists(json, 'localPrinter') ? undefined : json['localPrinter'],
    antivirus: !exists(json, 'antivirus') ? undefined : json['antivirus'],
    laptop: !exists(json, 'laptop') ? undefined : json['laptop'],
    storesPersonalInformation: !exists(json, 'storesPersonalInformation')
      ? undefined
      : json['storesPersonalInformation'],
    storesJournal: !exists(json, 'storesJournal') ? undefined : json['storesJournal'],
    journalRegistered: !exists(json, 'journalRegistered') ? undefined : json['journalRegistered'],
    journalHasElectronicMessaging: !exists(json, 'journalHasElectronicMessaging')
      ? undefined
      : json['journalHasElectronicMessaging'],
    messageMonitoring: !exists(json, 'messageMonitoring') ? undefined : json['messageMonitoring'],
    autoLookupPatients: !exists(json, 'autoLookupPatients') ? undefined : json['autoLookupPatients'],
    autoLookupAddress: !exists(json, 'autoLookupAddress') ? undefined : json['autoLookupAddress'],
    autoLookupAddressNhn: !exists(json, 'autoLookupAddressNhn') ? undefined : json['autoLookupAddressNhn'],
    autoUpdateAddressNhn: !exists(json, 'autoUpdateAddressNhn') ? undefined : json['autoUpdateAddressNhn'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    uuid: json['uuid'],
    networkUuid: !exists(json, 'networkUuid') ? undefined : json['networkUuid'],
    unitType: !exists(json, 'unitType') ? undefined : json['unitType'],
    createdDateTime: !exists(json, 'createdDateTime') ? undefined : json['createdDateTime'],
    serviceProvider: !exists(json, 'serviceProvider') ? undefined : json['serviceProvider'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    contracts: !exists(json, 'contracts') ? undefined : json['contracts'],
    serviceProviderContract: !exists(json, 'serviceProviderContract') ? undefined : json['serviceProviderContract'],
    antiVirusRequirements: !exists(json, 'antiVirusRequirements') ? undefined : json['antiVirusRequirements'],
    mobileMedicalDataRequirements: !exists(json, 'mobileMedicalDataRequirements')
      ? undefined
      : json['mobileMedicalDataRequirements'],
    systemUpdateOperatorType: !exists(json, 'systemUpdateOperatorType') ? undefined : json['systemUpdateOperatorType'],
    systemUpdateOperator: !exists(json, 'systemUpdateOperator') ? undefined : json['systemUpdateOperator'],
    antiVirusOperatorType: !exists(json, 'antiVirusOperatorType') ? undefined : json['antiVirusOperatorType'],
    antiVirusOperator: !exists(json, 'antiVirusOperator') ? undefined : json['antiVirusOperator'],
    elevated: !exists(json, 'elevated') ? undefined : json['elevated'],
    locked: !exists(json, 'locked') ? undefined : json['locked'],
    restrictedPhysicalAccess: !exists(json, 'restrictedPhysicalAccess') ? undefined : json['restrictedPhysicalAccess'],
    displayPositionSecure: !exists(json, 'displayPositionSecure') ? undefined : json['displayPositionSecure'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function ComputerViewModelToJSON(value?: ComputerViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notes: value.notes,
    name: value.name,
    location: value.location,
    type: value.type,
    journalType: value.journalType,
    journalSupplier: value.journalSupplier,
    journalSize: value.journalSize,
    messagingType: value.messagingType,
    messagingSupplier: value.messagingSupplier,
    printerPositionSecure: value.printerPositionSecure,
    localPrinter: value.localPrinter,
    antivirus: value.antivirus,
    laptop: value.laptop,
    storesPersonalInformation: value.storesPersonalInformation,
    storesJournal: value.storesJournal,
    journalRegistered: value.journalRegistered,
    journalHasElectronicMessaging: value.journalHasElectronicMessaging,
    messageMonitoring: value.messageMonitoring,
    autoLookupPatients: value.autoLookupPatients,
    autoLookupAddress: value.autoLookupAddress,
    autoLookupAddressNhn: value.autoLookupAddressNhn,
    autoUpdateAddressNhn: value.autoUpdateAddressNhn,
    deleted: value.deleted,
    uuid: value.uuid,
    networkUuid: value.networkUuid,
    unitType: value.unitType,
    createdDateTime: value.createdDateTime,
    serviceProvider: value.serviceProvider,
    connectionType: value.connectionType,
    contracts: value.contracts,
    serviceProviderContract: value.serviceProviderContract,
    antiVirusRequirements: value.antiVirusRequirements,
    mobileMedicalDataRequirements: value.mobileMedicalDataRequirements,
    systemUpdateOperatorType: value.systemUpdateOperatorType,
    systemUpdateOperator: value.systemUpdateOperator,
    antiVirusOperatorType: value.antiVirusOperatorType,
    antiVirusOperator: value.antiVirusOperator,
    elevated: value.elevated,
    locked: value.locked,
    restrictedPhysicalAccess: value.restrictedPhysicalAccess,
    displayPositionSecure: value.displayPositionSecure,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
