/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NetworkUpdateMessage
 */
export interface NetworkUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof NetworkUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  nhnDescription?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  otherDescription?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  connectionType?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  supplierUuid?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  networkType?: NetworkUpdateMessageNetworkTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  firewall?: NetworkUpdateMessageFirewallEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  firewallName?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  systemUpdateOperatorType?: NetworkUpdateMessageSystemUpdateOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  systemUpdateOperator?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  antiVirusOperatorType?: NetworkUpdateMessageAntiVirusOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkUpdateMessage
   */
  antiVirusOperator?: string;
}

/**
 * @export
 */
export const NetworkUpdateMessageNetworkTypeEnum = {
  Cable: 'CABLE',
  Wifi: 'WIFI',
  Both: 'BOTH',
} as const;
export type NetworkUpdateMessageNetworkTypeEnum =
  (typeof NetworkUpdateMessageNetworkTypeEnum)[keyof typeof NetworkUpdateMessageNetworkTypeEnum];

/**
 * @export
 */
export const NetworkUpdateMessageFirewallEnum = {
  None: 'NONE',
  Nhn: 'NHN',
  NhNotherIsp: 'NHNotherISP',
  Other: 'OTHER',
} as const;
export type NetworkUpdateMessageFirewallEnum =
  (typeof NetworkUpdateMessageFirewallEnum)[keyof typeof NetworkUpdateMessageFirewallEnum];

/**
 * @export
 */
export const NetworkUpdateMessageSystemUpdateOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type NetworkUpdateMessageSystemUpdateOperatorTypeEnum =
  (typeof NetworkUpdateMessageSystemUpdateOperatorTypeEnum)[keyof typeof NetworkUpdateMessageSystemUpdateOperatorTypeEnum];

/**
 * @export
 */
export const NetworkUpdateMessageAntiVirusOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type NetworkUpdateMessageAntiVirusOperatorTypeEnum =
  (typeof NetworkUpdateMessageAntiVirusOperatorTypeEnum)[keyof typeof NetworkUpdateMessageAntiVirusOperatorTypeEnum];

/**
 * Check if a given object implements the NetworkUpdateMessage interface.
 */
export function instanceOfNetworkUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function NetworkUpdateMessageFromJSON(json: any): NetworkUpdateMessage {
  return NetworkUpdateMessageFromJSONTyped(json, false);
}

export function NetworkUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    name: !exists(json, 'name') ? undefined : json['name'],
    nhnDescription: !exists(json, 'nhnDescription') ? undefined : json['nhnDescription'],
    otherDescription: !exists(json, 'otherDescription') ? undefined : json['otherDescription'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    networkType: !exists(json, 'networkType') ? undefined : json['networkType'],
    firewall: !exists(json, 'firewall') ? undefined : json['firewall'],
    firewallName: !exists(json, 'firewallName') ? undefined : json['firewallName'],
    systemUpdateOperatorType: !exists(json, 'systemUpdateOperatorType') ? undefined : json['systemUpdateOperatorType'],
    systemUpdateOperator: !exists(json, 'systemUpdateOperator') ? undefined : json['systemUpdateOperator'],
    antiVirusOperatorType: !exists(json, 'antiVirusOperatorType') ? undefined : json['antiVirusOperatorType'],
    antiVirusOperator: !exists(json, 'antiVirusOperator') ? undefined : json['antiVirusOperator'],
  };
}

export function NetworkUpdateMessageToJSON(value?: NetworkUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    nhnDescription: value.nhnDescription,
    otherDescription: value.otherDescription,
    connectionType: value.connectionType,
    supplierUuid: value.supplierUuid,
    networkType: value.networkType,
    firewall: value.firewall,
    firewallName: value.firewallName,
    systemUpdateOperatorType: value.systemUpdateOperatorType,
    systemUpdateOperator: value.systemUpdateOperator,
    antiVirusOperatorType: value.antiVirusOperatorType,
    antiVirusOperator: value.antiVirusOperator,
  };
}
