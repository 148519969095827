/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AccountEmployeeViewModel,
  AccountPlanUpdateMessage,
  AccountStatusUpdateMessage,
  AccountUpdateMessage,
  AccountViewModel,
  AdminSearchOrganizationCommand,
  CreateModulesAccountCommand,
  CreateModulesAccountResponse,
  CreateOrganizationInput,
  CreateOrganizationResponse,
  CreateUserCommand,
  CreateUserResponse,
  CriiptoTokenLoginMessage,
  CriiptoTokenLoginResponse,
  ElmaServiceItem,
  EmailAuthorizeCommand,
  EmailAuthorizeResult,
  EmailVerifyCommand,
  EmailVerifyResult,
  FeedbackMessage,
  HelseIDTokenLoginMessage,
  HelseIDTokenLoginResponse,
  InvoiceMessage,
  LinkAuthResult,
  LinkAuthUpdateMessage,
  MessageViewModel,
  PasswordTokenLoginMessage,
  PasswordTokenLoginResponse,
  PropertiesUpdateMessage,
  RefreshTokenMessage,
  RefreshTokenResponse,
  UnlinkAuthMessage,
  UpdateTemplateMessage,
  UserCreateMessage,
} from '../models';
import {
  AccountEmployeeViewModelFromJSON,
  AccountEmployeeViewModelToJSON,
  AccountPlanUpdateMessageFromJSON,
  AccountPlanUpdateMessageToJSON,
  AccountStatusUpdateMessageFromJSON,
  AccountStatusUpdateMessageToJSON,
  AccountUpdateMessageFromJSON,
  AccountUpdateMessageToJSON,
  AccountViewModelFromJSON,
  AccountViewModelToJSON,
  AdminSearchOrganizationCommandFromJSON,
  AdminSearchOrganizationCommandToJSON,
  CreateModulesAccountCommandFromJSON,
  CreateModulesAccountCommandToJSON,
  CreateModulesAccountResponseFromJSON,
  CreateModulesAccountResponseToJSON,
  CreateOrganizationInputFromJSON,
  CreateOrganizationInputToJSON,
  CreateOrganizationResponseFromJSON,
  CreateOrganizationResponseToJSON,
  CreateUserCommandFromJSON,
  CreateUserCommandToJSON,
  CreateUserResponseFromJSON,
  CreateUserResponseToJSON,
  CriiptoTokenLoginMessageFromJSON,
  CriiptoTokenLoginMessageToJSON,
  CriiptoTokenLoginResponseFromJSON,
  CriiptoTokenLoginResponseToJSON,
  ElmaServiceItemFromJSON,
  ElmaServiceItemToJSON,
  EmailAuthorizeCommandFromJSON,
  EmailAuthorizeCommandToJSON,
  EmailAuthorizeResultFromJSON,
  EmailAuthorizeResultToJSON,
  EmailVerifyCommandFromJSON,
  EmailVerifyCommandToJSON,
  EmailVerifyResultFromJSON,
  EmailVerifyResultToJSON,
  FeedbackMessageFromJSON,
  FeedbackMessageToJSON,
  HelseIDTokenLoginMessageFromJSON,
  HelseIDTokenLoginMessageToJSON,
  HelseIDTokenLoginResponseFromJSON,
  HelseIDTokenLoginResponseToJSON,
  InvoiceMessageFromJSON,
  InvoiceMessageToJSON,
  LinkAuthResultFromJSON,
  LinkAuthResultToJSON,
  LinkAuthUpdateMessageFromJSON,
  LinkAuthUpdateMessageToJSON,
  MessageViewModelFromJSON,
  MessageViewModelToJSON,
  PasswordTokenLoginMessageFromJSON,
  PasswordTokenLoginMessageToJSON,
  PasswordTokenLoginResponseFromJSON,
  PasswordTokenLoginResponseToJSON,
  PropertiesUpdateMessageFromJSON,
  PropertiesUpdateMessageToJSON,
  RefreshTokenMessageFromJSON,
  RefreshTokenMessageToJSON,
  RefreshTokenResponseFromJSON,
  RefreshTokenResponseToJSON,
  UnlinkAuthMessageFromJSON,
  UnlinkAuthMessageToJSON,
  UpdateTemplateMessageFromJSON,
  UpdateTemplateMessageToJSON,
  UserCreateMessageFromJSON,
  UserCreateMessageToJSON,
} from '../models';

export interface AdminSearchOrganizationsRequest {
  adminSearchOrganizationCommand: AdminSearchOrganizationCommand;
}

export interface CreateAccountInvoiceRequest {
  accountId: string;
  invoiceMessage: InvoiceMessage;
}

export interface CreateModulesAccountRequest {
  createModulesAccountCommand: CreateModulesAccountCommand;
}

export interface CreateOrganizationRequest {
  createOrganizationInput: CreateOrganizationInput;
}

export interface CreateUserRequest {
  createUserCommand: CreateUserCommand;
}

export interface CriiptoTokenLoginRequest {
  criiptoTokenLoginMessage: CriiptoTokenLoginMessage;
}

export interface DeleteAccountRequest {
  accountEmail: string;
  username?: string;
}

export interface EmailAuthorizeRequest {
  emailAuthorizeCommand: EmailAuthorizeCommand;
}

export interface EmailVerifyRequest {
  emailVerifyCommand: EmailVerifyCommand;
}

export interface FetchUserStatusRequest {
  accountEmail: string;
}

export interface HelseIDTokenLoginRequest {
  helseIDTokenLoginMessage: HelseIDTokenLoginMessage;
}

export interface ListAccountOrganizationsForIssuesAppRequest {
  accountEmail: string;
}

export interface ListOrganizationsRequest {
  accountEmail: string;
}

export interface PasswordTokenLoginRequest {
  passwordTokenLoginMessage: PasswordTokenLoginMessage;
}

export interface PostUserRequest {
  userCreateMessage: UserCreateMessage;
}

export interface RefreshTokenRequest {
  refreshTokenMessage: RefreshTokenMessage;
}

export interface RequestPasswordRequest {
  userEmail: string;
}

export interface SearchElmaRegistryRequest {
  query?: string;
  limit?: number;
}

export interface SearchOrganizationsRequest {
  q?: string;
}

export interface SendFeedbackRequest {
  feedbackMessage: FeedbackMessage;
}

export interface StartLinkAuthenticationRequest {
  linkAuthUpdateMessage: LinkAuthUpdateMessage;
}

export interface UnlinkAuthenticationRequest {
  unlinkAuthMessage: UnlinkAuthMessage;
}

export interface UpdateAccountPlanRequest {
  accountId: string;
  accountPlanUpdateMessage: AccountPlanUpdateMessage;
}

export interface UpdateAccountStatusRequest {
  accountId: string;
  accountStatusUpdateMessage: AccountStatusUpdateMessage;
}

export interface UpdateAdminPropertiesForORganizationRequest {
  organizationId: string;
  requestBody: { [key: string]: object };
}

export interface UpdatePasswordRequest {
  accountEmail: string;
  accountUpdateMessage: AccountUpdateMessage;
}

export interface UpdateTemplateContentRequest {
  updateTemplateMessage: UpdateTemplateMessage;
}

/**
 *
 */
export class AccountsApi extends runtime.BaseAPI {
  /**
   * Fetch details of matching accounts.
   */
  async adminSearchOrganizationsRaw(
    requestParameters: AdminSearchOrganizationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AccountEmployeeViewModel>>> {
    if (
      requestParameters.adminSearchOrganizationCommand === null ||
      requestParameters.adminSearchOrganizationCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'adminSearchOrganizationCommand',
        'Required parameter requestParameters.adminSearchOrganizationCommand was null or undefined when calling adminSearchOrganizations.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:search`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AdminSearchOrganizationCommandToJSON(requestParameters.adminSearchOrganizationCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountEmployeeViewModelFromJSON));
  }

  /**
   * Fetch details of matching accounts.
   */
  async adminSearchOrganizations(
    requestParameters: AdminSearchOrganizationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AccountEmployeeViewModel>> {
    const response = await this.adminSearchOrganizationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create invoice for organization
   */
  async createAccountInvoiceRaw(
    requestParameters: CreateAccountInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
      throw new runtime.RequiredError(
        'accountId',
        'Required parameter requestParameters.accountId was null or undefined when calling createAccountInvoice.',
      );
    }

    if (requestParameters.invoiceMessage === null || requestParameters.invoiceMessage === undefined) {
      throw new runtime.RequiredError(
        'invoiceMessage',
        'Required parameter requestParameters.invoiceMessage was null or undefined when calling createAccountInvoice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountId}/invoice`.replace(
          `{${'accountId'}}`,
          encodeURIComponent(String(requestParameters.accountId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InvoiceMessageToJSON(requestParameters.invoiceMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create invoice for organization
   */
  async createAccountInvoice(
    requestParameters: CreateAccountInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createAccountInvoiceRaw(requestParameters, initOverrides);
  }

  /**
   * Create account for new user
   */
  async createModulesAccountRaw(
    requestParameters: CreateModulesAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateModulesAccountResponse>> {
    if (
      requestParameters.createModulesAccountCommand === null ||
      requestParameters.createModulesAccountCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'createModulesAccountCommand',
        'Required parameter requestParameters.createModulesAccountCommand was null or undefined when calling createModulesAccount.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:create-modules-account`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateModulesAccountCommandToJSON(requestParameters.createModulesAccountCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CreateModulesAccountResponseFromJSON(jsonValue));
  }

  /**
   * Create account for new user
   */
  async createModulesAccount(
    requestParameters: CreateModulesAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateModulesAccountResponse> {
    const response = await this.createModulesAccountRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create organization and optionally account for new user
   */
  async createOrganizationRaw(
    requestParameters: CreateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateOrganizationResponse>> {
    if (requestParameters.createOrganizationInput === null || requestParameters.createOrganizationInput === undefined) {
      throw new runtime.RequiredError(
        'createOrganizationInput',
        'Required parameter requestParameters.createOrganizationInput was null or undefined when calling createOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:create-organization`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateOrganizationInputToJSON(requestParameters.createOrganizationInput),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrganizationResponseFromJSON(jsonValue));
  }

  /**
   * Create organization and optionally account for new user
   */
  async createOrganization(
    requestParameters: CreateOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateOrganizationResponse> {
    const response = await this.createOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create account for new user
   */
  async createUserRaw(
    requestParameters: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateUserResponse>> {
    if (requestParameters.createUserCommand === null || requestParameters.createUserCommand === undefined) {
      throw new runtime.RequiredError(
        'createUserCommand',
        'Required parameter requestParameters.createUserCommand was null or undefined when calling createUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/accounts/:create-user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateUserCommandToJSON(requestParameters.createUserCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserResponseFromJSON(jsonValue));
  }

  /**
   * Create account for new user
   */
  async createUser(
    requestParameters: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateUserResponse> {
    const response = await this.createUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Login using oauth token from Criipto.
   */
  async criiptoTokenLoginRaw(
    requestParameters: CriiptoTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CriiptoTokenLoginResponse>> {
    if (
      requestParameters.criiptoTokenLoginMessage === null ||
      requestParameters.criiptoTokenLoginMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'criiptoTokenLoginMessage',
        'Required parameter requestParameters.criiptoTokenLoginMessage was null or undefined when calling criiptoTokenLogin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:criipto-token-login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CriiptoTokenLoginMessageToJSON(requestParameters.criiptoTokenLoginMessage),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CriiptoTokenLoginResponseFromJSON(jsonValue));
  }

  /**
   * Login using oauth token from Criipto.
   */
  async criiptoTokenLogin(
    requestParameters: CriiptoTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CriiptoTokenLoginResponse> {
    const response = await this.criiptoTokenLoginRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete the given account organization.
   */
  async deleteAccountRaw(
    requestParameters: DeleteAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountEmail === null || requestParameters.accountEmail === undefined) {
      throw new runtime.RequiredError(
        'accountEmail',
        'Required parameter requestParameters.accountEmail was null or undefined when calling deleteAccount.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.username !== undefined) {
      queryParameters['username'] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountEmail}`.replace(
          `{${'accountEmail'}}`,
          encodeURIComponent(String(requestParameters.accountEmail)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete the given account organization.
   */
  async deleteAccount(
    requestParameters: DeleteAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAccountRaw(requestParameters, initOverrides);
  }

  /**
   * Start email authorization.
   */
  async emailAuthorizeRaw(
    requestParameters: EmailAuthorizeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EmailAuthorizeResult>> {
    if (requestParameters.emailAuthorizeCommand === null || requestParameters.emailAuthorizeCommand === undefined) {
      throw new runtime.RequiredError(
        'emailAuthorizeCommand',
        'Required parameter requestParameters.emailAuthorizeCommand was null or undefined when calling emailAuthorize.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/auth/email/:authorize`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailAuthorizeCommandToJSON(requestParameters.emailAuthorizeCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EmailAuthorizeResultFromJSON(jsonValue));
  }

  /**
   * Start email authorization.
   */
  async emailAuthorize(
    requestParameters: EmailAuthorizeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EmailAuthorizeResult> {
    const response = await this.emailAuthorizeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Verify email authorization.
   */
  async emailVerifyRaw(
    requestParameters: EmailVerifyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EmailVerifyResult>> {
    if (requestParameters.emailVerifyCommand === null || requestParameters.emailVerifyCommand === undefined) {
      throw new runtime.RequiredError(
        'emailVerifyCommand',
        'Required parameter requestParameters.emailVerifyCommand was null or undefined when calling emailVerify.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/auth/email/:verify`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailVerifyCommandToJSON(requestParameters.emailVerifyCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EmailVerifyResultFromJSON(jsonValue));
  }

  /**
   * Verify email authorization.
   */
  async emailVerify(
    requestParameters: EmailVerifyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EmailVerifyResult> {
    const response = await this.emailVerifyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Check if the user already exist or not for specified account email.
   */
  async fetchUserStatusRaw(
    requestParameters: FetchUserStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MessageViewModel>> {
    if (requestParameters.accountEmail === null || requestParameters.accountEmail === undefined) {
      throw new runtime.RequiredError(
        'accountEmail',
        'Required parameter requestParameters.accountEmail was null or undefined when calling fetchUserStatus.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountEmail}/accountStatus`.replace(
          `{${'accountEmail'}}`,
          encodeURIComponent(String(requestParameters.accountEmail)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => MessageViewModelFromJSON(jsonValue));
  }

  /**
   * Check if the user already exist or not for specified account email.
   */
  async fetchUserStatus(
    requestParameters: FetchUserStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MessageViewModel> {
    const response = await this.fetchUserStatusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Login using oauth token from HelseID.
   */
  async helseIDTokenLoginRaw(
    requestParameters: HelseIDTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HelseIDTokenLoginResponse>> {
    if (
      requestParameters.helseIDTokenLoginMessage === null ||
      requestParameters.helseIDTokenLoginMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'helseIDTokenLoginMessage',
        'Required parameter requestParameters.helseIDTokenLoginMessage was null or undefined when calling helseIDTokenLogin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:helseid-token-login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: HelseIDTokenLoginMessageToJSON(requestParameters.helseIDTokenLoginMessage),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => HelseIDTokenLoginResponseFromJSON(jsonValue));
  }

  /**
   * Login using oauth token from HelseID.
   */
  async helseIDTokenLogin(
    requestParameters: HelseIDTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HelseIDTokenLoginResponse> {
    const response = await this.helseIDTokenLoginRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * This is the same as the normal get routine except that we return 412 Preconditions failed instead of 401.
   * Fetch list of accounts, for app use
   */
  async listAccountOrganizationsForIssuesAppRaw(
    requestParameters: ListAccountOrganizationsForIssuesAppRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AccountViewModel>> {
    if (requestParameters.accountEmail === null || requestParameters.accountEmail === undefined) {
      throw new runtime.RequiredError(
        'accountEmail',
        'Required parameter requestParameters.accountEmail was null or undefined when calling listAccountOrganizationsForIssuesApp.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountEmail}/organizations`.replace(
          `{${'accountEmail'}}`,
          encodeURIComponent(String(requestParameters.accountEmail)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AccountViewModelFromJSON(jsonValue));
  }

  /**
   * This is the same as the normal get routine except that we return 412 Preconditions failed instead of 401.
   * Fetch list of accounts, for app use
   */
  async listAccountOrganizationsForIssuesApp(
    requestParameters: ListAccountOrganizationsForIssuesAppRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AccountViewModel> {
    const response = await this.listAccountOrganizationsForIssuesAppRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetches the list of organizations that the current authenticatedUser has access to.
   * Fetch the list of accounts for current user
   */
  async listOrganizationsRaw(
    requestParameters: ListOrganizationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AccountViewModel>> {
    if (requestParameters.accountEmail === null || requestParameters.accountEmail === undefined) {
      throw new runtime.RequiredError(
        'accountEmail',
        'Required parameter requestParameters.accountEmail was null or undefined when calling listOrganizations.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountEmail}`.replace(
          `{${'accountEmail'}}`,
          encodeURIComponent(String(requestParameters.accountEmail)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AccountViewModelFromJSON(jsonValue));
  }

  /**
   * Fetches the list of organizations that the current authenticatedUser has access to.
   * Fetch the list of accounts for current user
   */
  async listOrganizations(
    requestParameters: ListOrganizationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AccountViewModel> {
    const response = await this.listOrganizationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Login using oauth token from Password.
   */
  async passwordTokenLoginRaw(
    requestParameters: PasswordTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PasswordTokenLoginResponse>> {
    if (
      requestParameters.passwordTokenLoginMessage === null ||
      requestParameters.passwordTokenLoginMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'passwordTokenLoginMessage',
        'Required parameter requestParameters.passwordTokenLoginMessage was null or undefined when calling passwordTokenLogin.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:password-token-login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PasswordTokenLoginMessageToJSON(requestParameters.passwordTokenLoginMessage),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PasswordTokenLoginResponseFromJSON(jsonValue));
  }

  /**
   * Login using oauth token from Password.
   */
  async passwordTokenLogin(
    requestParameters: PasswordTokenLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PasswordTokenLoginResponse> {
    const response = await this.passwordTokenLoginRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create account for new user
   */
  async postUserRaw(
    requestParameters: PostUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userCreateMessage === null || requestParameters.userCreateMessage === undefined) {
      throw new runtime.RequiredError(
        'userCreateMessage',
        'Required parameter requestParameters.userCreateMessage was null or undefined when calling postUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/accounts/user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserCreateMessageToJSON(requestParameters.userCreateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Create account for new user
   */
  async postUser(
    requestParameters: PostUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.postUserRaw(requestParameters, initOverrides);
  }

  /**
   * Login using oauth token from Criipto.
   */
  async refreshTokenRaw(
    requestParameters: RefreshTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
    if (requestParameters.refreshTokenMessage === null || requestParameters.refreshTokenMessage === undefined) {
      throw new runtime.RequiredError(
        'refreshTokenMessage',
        'Required parameter requestParameters.refreshTokenMessage was null or undefined when calling refreshToken.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:refresh-token`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RefreshTokenMessageToJSON(requestParameters.refreshTokenMessage),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
  }

  /**
   * Login using oauth token from Criipto.
   */
  async refreshToken(
    requestParameters: RefreshTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RefreshTokenResponse> {
    const response = await this.refreshTokenRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Sends a new password to the users email if it matches a current user.
   */
  async requestPasswordRaw(
    requestParameters: RequestPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MessageViewModel>> {
    if (requestParameters.userEmail === null || requestParameters.userEmail === undefined) {
      throw new runtime.RequiredError(
        'userEmail',
        'Required parameter requestParameters.userEmail was null or undefined when calling requestPassword.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/accounts/{userEmail}/passwordRequest`.replace(
          `{${'userEmail'}}`,
          encodeURIComponent(String(requestParameters.userEmail)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => MessageViewModelFromJSON(jsonValue));
  }

  /**
   * Sends a new password to the users email if it matches a current user.
   */
  async requestPassword(
    requestParameters: RequestPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MessageViewModel> {
    const response = await this.requestPasswordRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch details of matching accounts.
   */
  async searchElmaRegistryRaw(
    requestParameters: SearchElmaRegistryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ElmaServiceItem>>> {
    const queryParameters: any = {};

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/elma`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElmaServiceItemFromJSON));
  }

  /**
   * Fetch details of matching accounts.
   */
  async searchElmaRegistry(
    requestParameters: SearchElmaRegistryRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ElmaServiceItem>> {
    const response = await this.searchElmaRegistryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch details of matching accounts.
   */
  async searchOrganizationsRaw(
    requestParameters: SearchOrganizationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AccountEmployeeViewModel>>> {
    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters['q'] = requestParameters.q;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/search`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountEmployeeViewModelFromJSON));
  }

  /**
   * Fetch details of matching accounts.
   */
  async searchOrganizations(
    requestParameters: SearchOrganizationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AccountEmployeeViewModel>> {
    const response = await this.searchOrganizationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Send user feedback to admin.
   */
  async sendFeedbackRaw(
    requestParameters: SendFeedbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.feedbackMessage === null || requestParameters.feedbackMessage === undefined) {
      throw new runtime.RequiredError(
        'feedbackMessage',
        'Required parameter requestParameters.feedbackMessage was null or undefined when calling sendFeedback.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/feedback`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FeedbackMessageToJSON(requestParameters.feedbackMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send user feedback to admin.
   */
  async sendFeedback(
    requestParameters: SendFeedbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendFeedbackRaw(requestParameters, initOverrides);
  }

  /**
   * Start link auth.
   */
  async startLinkAuthenticationRaw(
    requestParameters: StartLinkAuthenticationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LinkAuthResult>> {
    if (requestParameters.linkAuthUpdateMessage === null || requestParameters.linkAuthUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'linkAuthUpdateMessage',
        'Required parameter requestParameters.linkAuthUpdateMessage was null or undefined when calling startLinkAuthentication.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:link-auth`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LinkAuthUpdateMessageToJSON(requestParameters.linkAuthUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LinkAuthResultFromJSON(jsonValue));
  }

  /**
   * Start link auth.
   */
  async startLinkAuthentication(
    requestParameters: StartLinkAuthenticationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LinkAuthResult> {
    const response = await this.startLinkAuthenticationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Remove auth link.
   */
  async unlinkAuthenticationRaw(
    requestParameters: UnlinkAuthenticationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.unlinkAuthMessage === null || requestParameters.unlinkAuthMessage === undefined) {
      throw new runtime.RequiredError(
        'unlinkAuthMessage',
        'Required parameter requestParameters.unlinkAuthMessage was null or undefined when calling unlinkAuthentication.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/:unlink-auth`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UnlinkAuthMessageToJSON(requestParameters.unlinkAuthMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove auth link.
   */
  async unlinkAuthentication(
    requestParameters: UnlinkAuthenticationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.unlinkAuthenticationRaw(requestParameters, initOverrides);
  }

  /**
   * Updates feature and payment plan.
   */
  async updateAccountPlanRaw(
    requestParameters: UpdateAccountPlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
      throw new runtime.RequiredError(
        'accountId',
        'Required parameter requestParameters.accountId was null or undefined when calling updateAccountPlan.',
      );
    }

    if (
      requestParameters.accountPlanUpdateMessage === null ||
      requestParameters.accountPlanUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'accountPlanUpdateMessage',
        'Required parameter requestParameters.accountPlanUpdateMessage was null or undefined when calling updateAccountPlan.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountId}/:update-account-plan`.replace(
          `{${'accountId'}}`,
          encodeURIComponent(String(requestParameters.accountId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AccountPlanUpdateMessageToJSON(requestParameters.accountPlanUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates feature and payment plan.
   */
  async updateAccountPlan(
    requestParameters: UpdateAccountPlanRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateAccountPlanRaw(requestParameters, initOverrides);
  }

  /**
   * Updates status of an account.
   */
  async updateAccountStatusRaw(
    requestParameters: UpdateAccountStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
      throw new runtime.RequiredError(
        'accountId',
        'Required parameter requestParameters.accountId was null or undefined when calling updateAccountStatus.',
      );
    }

    if (
      requestParameters.accountStatusUpdateMessage === null ||
      requestParameters.accountStatusUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'accountStatusUpdateMessage',
        'Required parameter requestParameters.accountStatusUpdateMessage was null or undefined when calling updateAccountStatus.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountId}/status`.replace(
          `{${'accountId'}}`,
          encodeURIComponent(String(requestParameters.accountId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AccountStatusUpdateMessageToJSON(requestParameters.accountStatusUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates status of an account.
   */
  async updateAccountStatus(
    requestParameters: UpdateAccountStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateAccountStatusRaw(requestParameters, initOverrides);
  }

  /**
   * Updates admin defined properties for an organization.
   */
  async updateAdminPropertiesForORganizationRaw(
    requestParameters: UpdateAdminPropertiesForORganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateAdminPropertiesForORganization.',
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling updateAdminPropertiesForORganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('AdminSecurity', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{organizationId}/properties`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates admin defined properties for an organization.
   */
  async updateAdminPropertiesForORganization(
    requestParameters: UpdateAdminPropertiesForORganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateAdminPropertiesForORganizationRaw(requestParameters, initOverrides);
  }

  /**
   * Updates the account password.
   */
  async updatePasswordRaw(
    requestParameters: UpdatePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.accountEmail === null || requestParameters.accountEmail === undefined) {
      throw new runtime.RequiredError(
        'accountEmail',
        'Required parameter requestParameters.accountEmail was null or undefined when calling updatePassword.',
      );
    }

    if (requestParameters.accountUpdateMessage === null || requestParameters.accountUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'accountUpdateMessage',
        'Required parameter requestParameters.accountUpdateMessage was null or undefined when calling updatePassword.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/{accountEmail}`.replace(
          `{${'accountEmail'}}`,
          encodeURIComponent(String(requestParameters.accountEmail)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AccountUpdateMessageToJSON(requestParameters.accountUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates the account password.
   */
  async updatePassword(
    requestParameters: UpdatePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePasswordRaw(requestParameters, initOverrides);
  }

  /**
   * Update index for all the entities in specified organization.
   */
  async updateSearchIndexRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/index`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update index for all the entities in specified organization.
   */
  async updateSearchIndex(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
    await this.updateSearchIndexRaw(initOverrides);
  }

  /**
   * Update current accounts with new content from template.
   */
  async updateTemplateContentRaw(
    requestParameters: UpdateTemplateContentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.updateTemplateMessage === null || requestParameters.updateTemplateMessage === undefined) {
      throw new runtime.RequiredError(
        'updateTemplateMessage',
        'Required parameter requestParameters.updateTemplateMessage was null or undefined when calling updateTemplateContent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/accounts/update-template`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTemplateMessageToJSON(requestParameters.updateTemplateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update current accounts with new content from template.
   */
  async updateTemplateContent(
    requestParameters: UpdateTemplateContentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTemplateContentRaw(requestParameters, initOverrides);
  }
}
