import {
  CreateAssetInput,
  CreateConstitutionalDocumentInput,
  CreateContactInput,
  CreateContractInput,
  CreateDocumentInput,
  CreateEmployeeInput,
  CreateEntityInput,
  CreateEventOccurrenceInput,
  CreateFunctionInput,
  CreateGuidelineInput,
  CreateMeetingOccurrenceInput,
  CreatePartnerInput,
  CreateReportInput,
  CreateRiskAssessmentInput,
  CreateTaskInput,
} from 'src/layout/parts/d-new-document';
import { ApiClient, AppDispatch, updateItemAction } from 'src/store';
import type {
  AssetsUpdateMessage,
  ConstitutionalDocumentUpdateMessage,
  ContactPersonUpdateMessage,
  ContractUpdateMessage,
  CreateEventOccurrenceCommand,
  CreateMeetingOccurrenceCommand,
  DocumentUpdateMessage,
  EmployeeUpdateMessage,
  FunctionTaskUpdateMessage,
  FunctionUpdateMessage,
  GuidelineUpdateMessage,
  PartnerUpdateMessage,
  ReportUpdateMessage,
  RiskAssessmentUpdateMessage,
} from 'src/store/api';
import { uuid } from 'src/utilities/text';

export async function handleCreateEntity(
  organizationId: number,
  input: CreateEntityInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  switch (input.entityType) {
    case 'assets':
      return await handleCreateAsset(organizationId, input, api, dispatch);
    case 'constitutionalDocuments':
      return await handleCreateConstitutionalDocument(organizationId, input, api, dispatch);
    case 'contacts':
      return await handleCreateContact(organizationId, input, api, dispatch);
    case 'contracts':
      return await handleCreateContract(organizationId, input, api, dispatch);
    case 'documents':
      return await handleCreateDocument(organizationId, input, api, dispatch);
    case 'employees':
      return await handleCreateEmployee(organizationId, input, api, dispatch);
    case 'eventOccurrences':
      return await handleCreateEventOccurrence(organizationId, input, api, dispatch);
    case 'meetingOccurrences':
      return await handleCreateMeetingOccurrence(organizationId, input, api, dispatch);
    case 'functions':
      return await handleCreateFunction(organizationId, input, api, dispatch);
    case 'guidelines':
      return await handleCreateGuideline(organizationId, input, api, dispatch);
    case 'partners':
      return await handleCreatePartner(organizationId, input, api, dispatch);
    case 'reports':
      return await handleCreateReport(organizationId, input, api, dispatch);
    case 'riskAssessments':
      return await handleCreateRiskAssessment(organizationId, input, api, dispatch);
    case 'tasks':
      return await handleCreateTask(organizationId, input, api, dispatch);
  }
}

async function handleCreateAsset(
  organizationId: number,
  input: CreateAssetInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: AssetsUpdateMessage = {
    dsaApproved: false,
    dsaRegistered: false,
    radiation: false,
    radiationType: 'NONE',
    name: '',
    isConfirmedEntity: false,
  };
  await api.assets.createOrUpdateAsset({
    organizationId: '' + organizationId,
    assetId: input.entityUuid,
    assetsUpdateMessage: message,
  });
  const e = await api.assets.fetchAsset({
    organizationId: '' + organizationId,
    assetId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/assets/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateContact(
  organizationId: number,
  input: CreateContactInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: ContactPersonUpdateMessage = {
    accessLevel: 'NONE',
    firstName: '',
    lastName: '',
    notes: '',
    isConfirmedEntity: false,
  };
  await api.partners.createOrUpdatePartnerContact({
    partnerId: input.partnerId,
    organizationId: '' + organizationId,
    contactId: input.entityUuid,
    contactPersonUpdateMessage: message,
  });
  const e = await api.partners.fetchPartnerContact({
    organizationId: '' + organizationId,
    contactId: input.entityUuid,
    partnerId: input.partnerId,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/partners/' + input.partnerId + '/contacts/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateContract(
  organizationId: number,
  input: CreateContractInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: ContractUpdateMessage = {
    classification: 'NONE',
    pages: [input.pageId],
    name: '',
    content: '',
    accessControl: [],
    validFromDate: '',
    validToDate: '',
    employees: input.employeeId === undefined ? [] : [input.employeeId],
    partners: input.partnerId === undefined ? [] : [input.partnerId],
    isConfirmedEntity: false,
  };
  await api.contracts.createOrUpdateContract({
    organizationId: '' + organizationId,
    contractId: input.entityUuid,
    contractUpdateMessage: message,
  });
  const e = await api.contracts.fetchContract({
    organizationId: '' + organizationId,
    contractId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/contracts/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateConstitutionalDocument(
  organizationId: number,
  input: CreateConstitutionalDocumentInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: ConstitutionalDocumentUpdateMessage = {
    classification: 'NONE',
    name: '',
    content: '',
    accessControl: [],
    validFromDate: '',
    validToDate: '',
    isConfirmedEntity: false,
  };
  await api.constitutionalDocuments.createOrUpdateConstitutionalDocument({
    organizationId: '' + organizationId,
    constitutionalDocumentId: input.entityUuid,
    constitutionalDocumentUpdateMessage: message,
  });
  const e = await api.constitutionalDocuments.fetchConstitutionalDocument({
    organizationId: '' + organizationId,
    constitutionalDocumentId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/constitutionalDocuments/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateEmployee(
  organizationId: number,
  input: CreateEmployeeInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: EmployeeUpdateMessage = {
    accessLevel: 'NONE',
    address: '',
    email: '',
    expertise: '',
    firstName: '',
    herNumber: '',
    hprNumber: '',
    lastName: '',
    nextOfKin: '',
    notes: '',
    phone: '',
    profession: '',
    secondaryPhone: '',
    status: 'ACTIVE',
    isConfirmedEntity: false,
  };
  await api.employees.createOrUpdateEmployee({
    organizationId: '' + organizationId,
    employeeId: input.entityUuid,
    employeeUpdateMessage: message,
  });
  const e = await api.employees.fetchEmployee({
    organizationId: '' + organizationId,
    employeeId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/employees/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateEventOccurrence(
  organizationId: number,
  input: CreateEventOccurrenceInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: CreateEventOccurrenceCommand = {
    uuid: input.entityUuid,
    eventUuid: input.taskUuid ?? uuid(),
    date: input.instance,
    taskUuid: input.taskUuid,
    assetUuid: input.assetUuid,
  };
  await api.eventOccurrences.createEventOccurrence({
    organizationId: '' + organizationId,
    createEventOccurrenceCommand: message,
  });
  const o = await api.eventOccurrences.fetchEventOccurrence({
    organizationId: '' + organizationId,
    eventOccurrenceId: input.entityUuid,
  });
  console.log(o);
  const m2 = {
    item: o,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/eventOccurrences/' + input.entityUuid,
  };
  dispatch(updateItemAction(m2));
}

async function handleCreateMeetingOccurrence(
  organizationId: number,
  input: CreateMeetingOccurrenceInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: CreateMeetingOccurrenceCommand = {
    uuid: input.entityUuid,
    date: input.instance,
    meetingUuid: input.meetingUuid,
    employeeUuid: input.employeeUuid,
    templateMeetingOccurrenceUuid: input.templateMeetingOccurrenceUuid,
  };
  await api.meetingOccurrences.createMeetingOccurrence({
    organizationId: '' + organizationId,
    createMeetingOccurrenceCommand: message,
  });
  const o = await api.meetingOccurrences.fetchMeetingOccurrence({
    organizationId: '' + organizationId,
    meetingOccurrenceId: input.entityUuid,
  });
  const m2 = {
    item: o,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/meetingOccurrences/' + input.entityUuid,
  };
  dispatch(updateItemAction(m2));
}

async function handleCreateFunction(
  organizationId: number,
  input: CreateFunctionInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: FunctionUpdateMessage = {
    rotation: 'R-0',
    type: 'SINGLE',
    employees: input.employeeId === undefined ? [] : [input.employeeId],
    pages: [input.pageId],
    name: '',
    responsibility: '',
    isConfirmedEntity: false,
  };
  await api.functions.createOrUpdateFunction({
    organizationId: '' + organizationId,
    functionId: input.entityUuid,
    functionUpdateMessage: message,
  });
  const e = await api.functions.fetchFunction({
    organizationId: '' + organizationId,
    functionId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/functions/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateGuideline(
  organizationId: number,
  input: CreateGuidelineInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: GuidelineUpdateMessage = {
    classification: 'NONE',
    pages: [input.pageId],
    name: '',
    accessControl: [],
    content: '',
    isConfirmedEntity: false,
  };
  await api.guidelines.createOrUpdateGuideline({
    organizationId: '' + organizationId,
    guidelineId: input.entityUuid,
    guidelineUpdateMessage: message,
  });
  const e = await api.guidelines.fetchGuideline({
    organizationId: '' + organizationId,
    guidelineId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/guidelines/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}
async function handleCreatePartner(
  organizationId: number,
  input: CreatePartnerInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: PartnerUpdateMessage = {
    pages: [input.pageId],
    name: '',
    isConfirmedEntity: false,
  };
  await api.partners.createOrUpdatePartner({
    organizationId: '' + organizationId,
    partnerId: input.entityUuid,
    partnerUpdateMessage: message,
  });
  const e = await api.partners.fetchPartner({
    organizationId: '' + organizationId,
    partnerId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/partners/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateReport(
  organizationId: number,
  input: CreateReportInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: ReportUpdateMessage = {
    classification: 'NONE',
    pages: [input.pageId],
    name: '',
    accessControl: [],
    content: '',
    isConfirmedEntity: false,
  };
  await api.reports.createOrUpdateReport({
    organizationId: '' + organizationId,
    reportId: input.entityUuid,
    reportUpdateMessage: message,
  });
  const e = await api.reports.fetchReport({
    organizationId: '' + organizationId,
    reportId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/reports/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateRiskAssessment(
  organizationId: number,
  input: CreateRiskAssessmentInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: RiskAssessmentUpdateMessage = {
    pages: [input.pageId],
    name: input.name ?? '',
    createdFromTemplate: input.templateId,
    relatedSubstance: input.substanceUuid,
    isConfirmedEntity: false,
  };
  await api.riskAssessments.createOrUpdateRiskAssessment({
    organizationId: '' + organizationId,
    riskAssessmentId: input.entityUuid,
    riskAssessmentUpdateMessage: message,
  });
  const e = await api.riskAssessments.fetchRiskAssessment({
    organizationId: '' + organizationId,
    riskAssessmentId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/riskAssessments/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateTask(organizationId: number, input: CreateTaskInput, api: ApiClient, dispatch: AppDispatch) {
  const message: FunctionTaskUpdateMessage = {
    assetSelection: false,
    functionUuid: input.functionId,
    procedures: '',
    situation: '',
    pages: [input.pageId],
    name: '',
    isConfirmedEntity: false,
  };
  await api.functions.createOrUpdateFunctionTask({
    organizationId: '' + organizationId,
    taskId: input.entityUuid,
    functionTaskUpdateMessage: message,
    functionId: input.functionId,
  });
  const e = await api.functions.fetchFunctionTask({
    organizationId: '' + organizationId,
    taskId: input.entityUuid,
    functionId: input.functionId,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/functions/' + input.functionId + '/tasks/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}

async function handleCreateDocument(
  organizationId: number,
  input: CreateDocumentInput,
  api: ApiClient,
  dispatch: AppDispatch,
) {
  const message: DocumentUpdateMessage = {
    classification: 'NONE',
    pages: [input.pageId],
    name: '',
    accessControl: [],
    validFromDate: '',
    validToDate: '',
    isConfirmedEntity: false,
  };
  await api.documents.createOrUpdateDocument({
    organizationId: '' + organizationId,
    documentId: input.entityUuid,
    documentUpdateMessage: message,
  });
  const e = await api.documents.fetchDocument({
    organizationId: '' + organizationId,
    documentId: input.entityUuid,
  });
  const m = {
    item: e,
    hidden: false,
    eventType: 'create',
    path: '/organizations/' + organizationId + '/documents/' + input.entityUuid,
  };
  dispatch(updateItemAction(m));
}
