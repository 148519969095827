/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LeavePeriodDay
 */
export interface LeavePeriodDay {
  /**
   *
   * @type {string}
   * @memberof LeavePeriodDay
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriodDay
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriodDay
   */
  end?: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriodDay
   */
  leaveStatus: LeavePeriodDayLeaveStatusEnum;
}

/**
 * @export
 */
export const LeavePeriodDayLeaveStatusEnum = {
  Work: 'WORK',
  Leave: 'LEAVE',
} as const;
export type LeavePeriodDayLeaveStatusEnum =
  (typeof LeavePeriodDayLeaveStatusEnum)[keyof typeof LeavePeriodDayLeaveStatusEnum];

/**
 * Check if a given object implements the LeavePeriodDay interface.
 */
export function instanceOfLeavePeriodDay(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'leaveStatus' in value;

  return isInstance;
}

export function LeavePeriodDayFromJSON(json: any): LeavePeriodDay {
  return LeavePeriodDayFromJSONTyped(json, false);
}

export function LeavePeriodDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeavePeriodDay {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    start: !exists(json, 'start') ? undefined : json['start'],
    end: !exists(json, 'end') ? undefined : json['end'],
    leaveStatus: json['leaveStatus'],
  };
}

export function LeavePeriodDayToJSON(value?: LeavePeriodDay | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    start: value.start,
    end: value.end,
    leaveStatus: value.leaveStatus,
  };
}
