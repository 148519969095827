import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@capgo/inappbrowser';

export function runningUnderNativePlatform() {
  return Capacitor.isNativePlatform();
}

export async function openInAppBrowser(href: string) {
  await InAppBrowser.addListener('closeEvent', () => {
    console.log('browser was closed.');
  });

  await InAppBrowser.addListener('browserPageLoaded', () => {
    console.log('browser was loaded.');
  });

  await InAppBrowser.open({
    url: href,
    isPresentAfterPageLoad: true,
  });
}
