/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SigningOrderSignatory } from './SigningOrderSignatory';
import {
  SigningOrderSignatoryFromJSON,
  SigningOrderSignatoryFromJSONTyped,
  SigningOrderSignatoryToJSON,
} from './SigningOrderSignatory';

/**
 *
 * @export
 * @interface SigningOrder
 */
export interface SigningOrder {
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  signingOrderStatus: SigningOrderSigningOrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  dueDate: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  closedAt?: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  requesterUuid: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  signatureOrderId: string;
  /**
   *
   * @type {Array<SigningOrderSignatory>}
   * @memberof SigningOrder
   */
  signatories: Array<SigningOrderSignatory>;
}

/**
 * @export
 */
export const SigningOrderSigningOrderStatusEnum = {
  Open: 'OPEN',
  Closed: 'CLOSED',
  Cancelled: 'CANCELLED',
  Expired: 'EXPIRED',
} as const;
export type SigningOrderSigningOrderStatusEnum =
  (typeof SigningOrderSigningOrderStatusEnum)[keyof typeof SigningOrderSigningOrderStatusEnum];

/**
 * Check if a given object implements the SigningOrder interface.
 */
export function instanceOfSigningOrder(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'signingOrderStatus' in value;
  isInstance = isInstance && 'createdAt' in value;
  isInstance = isInstance && 'dueDate' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'requesterUuid' in value;
  isInstance = isInstance && 'signatureOrderId' in value;
  isInstance = isInstance && 'signatories' in value;

  return isInstance;
}

export function SigningOrderFromJSON(json: any): SigningOrder {
  return SigningOrderFromJSONTyped(json, false);
}

export function SigningOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    signingOrderStatus: json['signingOrderStatus'],
    createdAt: json['createdAt'],
    dueDate: json['dueDate'],
    closedAt: !exists(json, 'closedAt') ? undefined : json['closedAt'],
    uuid: json['uuid'],
    requesterUuid: json['requesterUuid'],
    signatureOrderId: json['signatureOrderId'],
    signatories: (json['signatories'] as Array<any>).map(SigningOrderSignatoryFromJSON),
  };
}

export function SigningOrderToJSON(value?: SigningOrder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    signingOrderStatus: value.signingOrderStatus,
    createdAt: value.createdAt,
    dueDate: value.dueDate,
    closedAt: value.closedAt,
    uuid: value.uuid,
    requesterUuid: value.requesterUuid,
    signatureOrderId: value.signatureOrderId,
    signatories: (value.signatories as Array<any>).map(SigningOrderSignatoryToJSON),
  };
}
