/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * For å opprette nytt møte. Ikke alle kombinasjoner av employeeUuid, templateMeetingOccurrenceUuid og meetingUuid er gyldig. Dersom employeeUuid er gitt skal ikke meetingUuid oppgies.
 * @export
 * @interface CreateMeetingOccurrenceCommand
 */
export interface CreateMeetingOccurrenceCommand {
  /**
   * Dato for det nye møtet
   * @type {string}
   * @memberof CreateMeetingOccurrenceCommand
   */
  date: string;
  /**
   * Id for det nye møte. Må ikke finnes fra før.
   * @type {string}
   * @memberof CreateMeetingOccurrenceCommand
   */
  uuid: string;
  /**
   * Id for en møtetype fra malen.
   * @type {string}
   * @memberof CreateMeetingOccurrenceCommand
   */
  meetingUuid?: string;
  /**
   * Id for en annen møtehendelse.
   * @type {string}
   * @memberof CreateMeetingOccurrenceCommand
   */
  templateMeetingOccurrenceUuid?: string;
  /**
   * Id for en ansatt.
   * @type {string}
   * @memberof CreateMeetingOccurrenceCommand
   */
  employeeUuid?: string;
}

/**
 * Check if a given object implements the CreateMeetingOccurrenceCommand interface.
 */
export function instanceOfCreateMeetingOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function CreateMeetingOccurrenceCommandFromJSON(json: any): CreateMeetingOccurrenceCommand {
  return CreateMeetingOccurrenceCommandFromJSONTyped(json, false);
}

export function CreateMeetingOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateMeetingOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    uuid: json['uuid'],
    meetingUuid: !exists(json, 'meetingUuid') ? undefined : json['meetingUuid'],
    templateMeetingOccurrenceUuid: !exists(json, 'templateMeetingOccurrenceUuid')
      ? undefined
      : json['templateMeetingOccurrenceUuid'],
    employeeUuid: !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
  };
}

export function CreateMeetingOccurrenceCommandToJSON(value?: CreateMeetingOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    uuid: value.uuid,
    meetingUuid: value.meetingUuid,
    templateMeetingOccurrenceUuid: value.templateMeetingOccurrenceUuid,
    employeeUuid: value.employeeUuid,
  };
}
