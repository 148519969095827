/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LinkViewModel
 */
export interface LinkViewModel {
  /**
   *
   * @type {string}
   * @memberof LinkViewModel
   */
  linkName: string;
  /**
   *
   * @type {string}
   * @memberof LinkViewModel
   */
  linkHref: string;
  /**
   *
   * @type {number}
   * @memberof LinkViewModel
   */
  pageId: number;
}

/**
 * Check if a given object implements the LinkViewModel interface.
 */
export function instanceOfLinkViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'linkName' in value;
  isInstance = isInstance && 'linkHref' in value;
  isInstance = isInstance && 'pageId' in value;

  return isInstance;
}

export function LinkViewModelFromJSON(json: any): LinkViewModel {
  return LinkViewModelFromJSONTyped(json, false);
}

export function LinkViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    linkName: json['linkName'],
    linkHref: json['linkHref'],
    pageId: json['pageId'],
  };
}

export function LinkViewModelToJSON(value?: LinkViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    linkName: value.linkName,
    linkHref: value.linkHref,
    pageId: value.pageId,
  };
}
