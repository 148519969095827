import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../../abstracts/labeled-element';
import '../elements/d-select-dropdown';
import '../elements/d-edit-text';
import '../elements/d-add-remove-buttons';
import '../../elements/d-action';
import './d-signee-details';
import '../../elements/d-smooth-resize';
import { PersonForSigning } from 'src/layout/parts/request-signatures-dialog';
import { uuid } from 'src/utilities/text';

/**
 *
 * USAGE:
 *    request-signatures-dialog
 *
 * STATUS OK
 */
@customElement('d-edit-organizational-signees')
export class DEditOrganizationalSignees extends LabeledElement {
  static styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
      }
      .wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
      }
      d-smooth-resize:last-child .wrapper {
        padding-bottom: 0;
      }
      .box {
        flex: 1;
        display: block;
        box-sizing: border-box;
        border: 1px solid var(--borderColor);
        border-radius: 12px;
        background-color: hsl(1, 0%, 94%);
        padding: 12px;
      }

      d-add-remove-buttons {
        margin-right: -4px;
      }

      d-signee-details {
        margin: 6px 0 -6px 0;
      }
    `,
  ];
  @property({ type: String })
  placeholder = '';
  @property({ type: Array })
  signees: string[] = [];
  @property({ type: Array })
  availablePeople: PersonForSigning[] = [];
  @property({ type: String })
  addPersonText = '';
  @property({ type: String })
  partnerUuid = '';
  @property({ type: Boolean })
  editingSomewhere = false;

  private get showRemove() {
    return !(this.signees.length === 0);
  }

  signeesForForm(signees) {
    let result = [''];
    if (signees.length > 0) {
      result = signees;
    }
    return result;
  }

  selectSigneeChanged(e, index) {
    e.stopPropagation();
    let signees: string[];
    const value = e.detail.value;
    if (value === 'new') {
      if (this.signees.length === 0) {
        signees = ['new'];
      } else {
        signees = this.signees.map((s, i) => {
          if (i === index) {
            return 'new';
          }
          return s;
        });
      }
    } else if (this.signees.length === 0) {
      signees = [value];
    } else {
      signees = this.signees.map((s, i) => {
        if (i === index) {
          return value;
        }
        return s;
      });
    }
    this.signees = signees;
    this.dispatchValueChanged();
  }

  detailsChanged(e, uuid) {
    const value = e.detail.value;
    e.stopPropagation();
    this.availablePeople = this.availablePeople.map((p) => {
      if (p.uuid === uuid) {
        return value;
      }
      return p;
    });
    this.dispatchValueChanged();
    this.dispatchEvent(
      new CustomEvent('update-signature-person', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: e.detail.value.uuid,
          email: e.detail.value.email,
          personalIdentifier: e.detail.value.personalIdentifier,
        },
      }),
    );
  }

  newPerson(e, index) {
    e.stopPropagation();
    const value = e.detail.value;
    value.uuid = uuid();
    const availablePeople = [...this.availablePeople];
    availablePeople.push(value);
    this.availablePeople = availablePeople;
    this.signees = this.signees.map((s, i) => {
      if (i === index) {
        return value.uuid;
      }
      return s;
    });
    this.dispatchValueChanged();
    this.dispatchEvent(
      new CustomEvent('create-signature-person', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: value.uuid,
          email: e.detail.value.email,
          personalIdentifier: e.detail.value.personalIdentifier,
          firstName: e.detail.value.firstName,
          lastName: e.detail.value.lastName,
          partnerUuid: this.partnerUuid,
        },
      }),
    );
  }

  dispatchValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: {
            signees: this.signees,
            availablePeople: this.availablePeople,
          },
        },
      }),
    );
  }

  person(uuid) {
    return (
      this.availablePeople.find((p) => {
        return p.uuid === uuid;
      }) || {
        uuid: uuid,
        email: undefined,
        personalIdentifier: undefined,
        firstName: '',
        lastName: '',
      }
    );
  }

  onEditModeChanged(e) {
    e.stopPropagation();
    this.editingSomewhere = e.detail.value;
    this.dispatchEvent(
      new CustomEvent('edit-mode-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: e.detail.value,
        },
      }),
    );
  }

  protected renderContent() {
    return html`
      ${this.signeesForForm(this.signees).map((signee, index) => {
        return html` <d-smooth-resize
          ><div class="wrapper">
            <div class="box">
              <d-select-dropdown
                theme-page
                ?disabled="${this.editingSomewhere}"
                .placeholder=${this.placeholder}
                .unselectablePlaceholder=${true}
                .options=${this.options(signee)}
                .value=${signee}
                @value-changed=${(e) => this.selectSigneeChanged(e, index)}
              ></d-select-dropdown>
              ${signee !== ''
                ? html`
                    <d-signee-details
                      id="details-${index}"
                      gray-background
                      .signee=${this.person(signee)}
                      .editingSomewhere=${this.editingSomewhere}
                      @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
                      @value-changed=${(e) => this.detailsChanged(e, signee)}
                      @new-person=${(e) => this.newPerson(e, index)}
                      @delete-new=${() => this.removeSignee(index)}
                    ></d-signee-details>
                  `
                : nothing}
            </div>
            ${this.showAdd(index) || this.showRemove
              ? html`
                  <d-add-remove-buttons
                    ?disabled="${this.editingSomewhere}"
                    .showRemove=${this.showRemove}
                    .showAdd=${this.showAdd(index)}
                    @remove=${() => this.removeSignee(index)}
                    @add=${() => this.addSignee()}
                  ></d-add-remove-buttons>
                `
              : nothing}
          </div></d-smooth-resize
        >`;
      })}
    `;
  }

  private options(signee) {
    const result = this.availablePeople
      .filter((p) => {
        return !this.signees.includes(p.uuid) || p.uuid === signee;
      })
      .map((p) => {
        return { value: p.uuid, text: p.firstName + ' ' + p.lastName };
      });
    if (this.addPersonText) {
      result.push({ value: 'new', text: this.addPersonText });
    }
    return result;
  }

  private showAdd(index) {
    return index === this.signees.length - 1;
  }

  private removeSignee(index) {
    this.signees = this.signees.filter((s, i) => {
      return i !== index;
    });
    this.dispatchValueChanged();
  }

  private addSignee() {
    const signees = [...this.signees];
    signees.push('');
    this.signees = signees;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-organizational-signees': DEditOrganizationalSignees;
  }
}
