/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetViewModel } from './AssetViewModel';
import { AssetViewModelFromJSON, AssetViewModelFromJSONTyped, AssetViewModelToJSON } from './AssetViewModel';
import type { ComputerViewModel } from './ComputerViewModel';
import {
  ComputerViewModelFromJSON,
  ComputerViewModelFromJSONTyped,
  ComputerViewModelToJSON,
} from './ComputerViewModel';
import type { ConstitutionalDocumentViewModel } from './ConstitutionalDocumentViewModel';
import {
  ConstitutionalDocumentViewModelFromJSON,
  ConstitutionalDocumentViewModelFromJSONTyped,
  ConstitutionalDocumentViewModelToJSON,
} from './ConstitutionalDocumentViewModel';
import type { ContractViewModel } from './ContractViewModel';
import {
  ContractViewModelFromJSON,
  ContractViewModelFromJSONTyped,
  ContractViewModelToJSON,
} from './ContractViewModel';
import type { DocumentViewModel } from './DocumentViewModel';
import {
  DocumentViewModelFromJSON,
  DocumentViewModelFromJSONTyped,
  DocumentViewModelToJSON,
} from './DocumentViewModel';
import type { EmployeeViewModel } from './EmployeeViewModel';
import {
  EmployeeViewModelFromJSON,
  EmployeeViewModelFromJSONTyped,
  EmployeeViewModelToJSON,
} from './EmployeeViewModel';
import type { EventOccurrenceViewModel } from './EventOccurrenceViewModel';
import {
  EventOccurrenceViewModelFromJSON,
  EventOccurrenceViewModelFromJSONTyped,
  EventOccurrenceViewModelToJSON,
} from './EventOccurrenceViewModel';
import type { ExternalConnectionViewModel } from './ExternalConnectionViewModel';
import {
  ExternalConnectionViewModelFromJSON,
  ExternalConnectionViewModelFromJSONTyped,
  ExternalConnectionViewModelToJSON,
} from './ExternalConnectionViewModel';
import type { FunctionViewModel } from './FunctionViewModel';
import {
  FunctionViewModelFromJSON,
  FunctionViewModelFromJSONTyped,
  FunctionViewModelToJSON,
} from './FunctionViewModel';
import type { GuidelineViewModel } from './GuidelineViewModel';
import {
  GuidelineViewModelFromJSON,
  GuidelineViewModelFromJSONTyped,
  GuidelineViewModelToJSON,
} from './GuidelineViewModel';
import type { IssueViewModel } from './IssueViewModel';
import { IssueViewModelFromJSON, IssueViewModelFromJSONTyped, IssueViewModelToJSON } from './IssueViewModel';
import type { MeetingOccurrenceViewModel } from './MeetingOccurrenceViewModel';
import {
  MeetingOccurrenceViewModelFromJSON,
  MeetingOccurrenceViewModelFromJSONTyped,
  MeetingOccurrenceViewModelToJSON,
} from './MeetingOccurrenceViewModel';
import type { MeetingViewModel } from './MeetingViewModel';
import { MeetingViewModelFromJSON, MeetingViewModelFromJSONTyped, MeetingViewModelToJSON } from './MeetingViewModel';
import type { NetworkViewModel } from './NetworkViewModel';
import { NetworkViewModelFromJSON, NetworkViewModelFromJSONTyped, NetworkViewModelToJSON } from './NetworkViewModel';
import type { PartnerViewModel } from './PartnerViewModel';
import { PartnerViewModelFromJSON, PartnerViewModelFromJSONTyped, PartnerViewModelToJSON } from './PartnerViewModel';
import type { PersonalDataItemViewModel } from './PersonalDataItemViewModel';
import {
  PersonalDataItemViewModelFromJSON,
  PersonalDataItemViewModelFromJSONTyped,
  PersonalDataItemViewModelToJSON,
} from './PersonalDataItemViewModel';
import type { ReportViewModel } from './ReportViewModel';
import { ReportViewModelFromJSON, ReportViewModelFromJSONTyped, ReportViewModelToJSON } from './ReportViewModel';
import type { RiskAssessmentViewModel } from './RiskAssessmentViewModel';
import {
  RiskAssessmentViewModelFromJSON,
  RiskAssessmentViewModelFromJSONTyped,
  RiskAssessmentViewModelToJSON,
} from './RiskAssessmentViewModel';
import type { SubstanceViewModel } from './SubstanceViewModel';
import {
  SubstanceViewModelFromJSON,
  SubstanceViewModelFromJSONTyped,
  SubstanceViewModelToJSON,
} from './SubstanceViewModel';
import type { TutorialState } from './TutorialState';
import { TutorialStateFromJSON, TutorialStateFromJSONTyped, TutorialStateToJSON } from './TutorialState';
import type { TutorialViewModel } from './TutorialViewModel';
import {
  TutorialViewModelFromJSON,
  TutorialViewModelFromJSONTyped,
  TutorialViewModelToJSON,
} from './TutorialViewModel';

/**
 *
 * @export
 * @interface CompleteOrganizationViewModel
 */
export interface CompleteOrganizationViewModel {
  /**
   *
   * @type {number}
   * @memberof CompleteOrganizationViewModel
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  officeAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  officePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  herNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  businessEntityType?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  organizationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  mailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  mailPostcode?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoicePlan?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  featurePlan?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceReceiver?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceReference?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoicePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceLocality?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  ownerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  settingsHref?: string;
  /**
   *
   * @type {boolean}
   * @memberof CompleteOrganizationViewModel
   */
  singleUser?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CompleteOrganizationViewModel
   */
  activityCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CompleteOrganizationViewModel
   */
  features: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  specialTerms?: CompleteOrganizationViewModelSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  sector: CompleteOrganizationViewModelSectorEnum;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceSendMethod?: CompleteOrganizationViewModelInvoiceSendMethodEnum;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  invoiceOrganizationNumber?: string;
  /**
   *
   * @type {Array<FunctionViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  functions?: Array<FunctionViewModel>;
  /**
   *
   * @type {Array<IssueViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  issues?: Array<IssueViewModel>;
  /**
   *
   * @type {Array<PartnerViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  partners?: Array<PartnerViewModel>;
  /**
   *
   * @type {Array<ConstitutionalDocumentViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  constitutionalDocuments?: Array<ConstitutionalDocumentViewModel>;
  /**
   *
   * @type {Array<ComputerViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  computers?: Array<ComputerViewModel>;
  /**
   *
   * @type {Array<ContractViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  contracts?: Array<ContractViewModel>;
  /**
   *
   * @type {Array<GuidelineViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  guidelines?: Array<GuidelineViewModel>;
  /**
   *
   * @type {Array<SubstanceViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  substances?: Array<SubstanceViewModel>;
  /**
   *
   * @type {Array<NetworkViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  networks?: Array<NetworkViewModel>;
  /**
   *
   * @type {Array<DocumentViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  documents?: Array<DocumentViewModel>;
  /**
   *
   * @type {Array<EmployeeViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  employees?: Array<EmployeeViewModel>;
  /**
   *
   * @type {Array<ExternalConnectionViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  externalConnections?: Array<ExternalConnectionViewModel>;
  /**
   *
   * @type {Array<RiskAssessmentViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  riskAssessments?: Array<RiskAssessmentViewModel>;
  /**
   *
   * @type {Array<ReportViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  reports?: Array<ReportViewModel>;
  /**
   *
   * @type {Array<MeetingViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  meetings?: Array<MeetingViewModel>;
  /**
   *
   * @type {Array<AssetViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  assets?: Array<AssetViewModel>;
  /**
   *
   * @type {Array<PersonalDataItemViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  personalDataItems?: Array<PersonalDataItemViewModel>;
  /**
   *
   * @type {Array<EventOccurrenceViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  eventOccurrences: Array<EventOccurrenceViewModel>;
  /**
   *
   * @type {Array<MeetingOccurrenceViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  meetingOccurrences: Array<MeetingOccurrenceViewModel>;
  /**
   *
   * @type {boolean}
   * @memberof CompleteOrganizationViewModel
   */
  leavePeriodEditRestriction: boolean;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  vacationSummaryNotes: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  vacationSummaryRecipient?: string;
  /**
   *
   * @type {string}
   * @memberof CompleteOrganizationViewModel
   */
  vacationSummaryShared?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CompleteOrganizationViewModel
   */
  vacationSummaryEmployees: Array<string>;
  /**
   *
   * @type {Array<TutorialViewModel>}
   * @memberof CompleteOrganizationViewModel
   */
  tutorials: Array<TutorialViewModel>;
  /**
   *
   * @type {Array<TutorialState>}
   * @memberof CompleteOrganizationViewModel
   */
  tutorialStates: Array<TutorialState>;
  /**
   *
   * @type {Array<string>}
   * @memberof CompleteOrganizationViewModel
   */
  staffingCalendarAccessList: Array<string>;
}

/**
 * @export
 */
export const CompleteOrganizationViewModelSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type CompleteOrganizationViewModelSpecialTermsEnum =
  (typeof CompleteOrganizationViewModelSpecialTermsEnum)[keyof typeof CompleteOrganizationViewModelSpecialTermsEnum];

/**
 * @export
 */
export const CompleteOrganizationViewModelSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type CompleteOrganizationViewModelSectorEnum =
  (typeof CompleteOrganizationViewModelSectorEnum)[keyof typeof CompleteOrganizationViewModelSectorEnum];

/**
 * @export
 */
export const CompleteOrganizationViewModelInvoiceSendMethodEnum = {
  Manual: 'MANUAL',
  Ehf: 'EHF',
} as const;
export type CompleteOrganizationViewModelInvoiceSendMethodEnum =
  (typeof CompleteOrganizationViewModelInvoiceSendMethodEnum)[keyof typeof CompleteOrganizationViewModelInvoiceSendMethodEnum];

/**
 * Check if a given object implements the CompleteOrganizationViewModel interface.
 */
export function instanceOfCompleteOrganizationViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'organizationId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'features' in value;
  isInstance = isInstance && 'sector' in value;
  isInstance = isInstance && 'eventOccurrences' in value;
  isInstance = isInstance && 'meetingOccurrences' in value;
  isInstance = isInstance && 'leavePeriodEditRestriction' in value;
  isInstance = isInstance && 'vacationSummaryNotes' in value;
  isInstance = isInstance && 'vacationSummaryEmployees' in value;
  isInstance = isInstance && 'tutorials' in value;
  isInstance = isInstance && 'tutorialStates' in value;
  isInstance = isInstance && 'staffingCalendarAccessList' in value;

  return isInstance;
}

export function CompleteOrganizationViewModelFromJSON(json: any): CompleteOrganizationViewModel {
  return CompleteOrganizationViewModelFromJSONTyped(json, false);
}

export function CompleteOrganizationViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CompleteOrganizationViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organizationId: json['organizationId'],
    href: !exists(json, 'href') ? undefined : json['href'],
    address: !exists(json, 'address') ? undefined : json['address'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    officeAddress: !exists(json, 'officeAddress') ? undefined : json['officeAddress'],
    officePostcode: !exists(json, 'officePostcode') ? undefined : json['officePostcode'],
    email: !exists(json, 'email') ? undefined : json['email'],
    herNumber: !exists(json, 'herNumber') ? undefined : json['herNumber'],
    name: json['name'],
    businessEntityType: !exists(json, 'businessEntityType') ? undefined : json['businessEntityType'],
    organizationNumber: !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
    mailAddress: !exists(json, 'mailAddress') ? undefined : json['mailAddress'],
    mailPostcode: !exists(json, 'mailPostcode') ? undefined : json['mailPostcode'],
    fax: !exists(json, 'fax') ? undefined : json['fax'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    type: !exists(json, 'type') ? undefined : json['type'],
    url: !exists(json, 'url') ? undefined : json['url'],
    template: !exists(json, 'template') ? undefined : json['template'],
    invoicePlan: !exists(json, 'invoicePlan') ? undefined : json['invoicePlan'],
    featurePlan: !exists(json, 'featurePlan') ? undefined : json['featurePlan'],
    invoiceReceiver: !exists(json, 'invoiceReceiver') ? undefined : json['invoiceReceiver'],
    invoiceReference: !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
    invoicePostcode: !exists(json, 'invoicePostcode') ? undefined : json['invoicePostcode'],
    invoiceLocality: !exists(json, 'invoiceLocality') ? undefined : json['invoiceLocality'],
    reference: !exists(json, 'reference') ? undefined : json['reference'],
    invoiceAddress: !exists(json, 'invoiceAddress') ? undefined : json['invoiceAddress'],
    ownerEmail: !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
    settingsHref: !exists(json, 'settingsHref') ? undefined : json['settingsHref'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
    activityCodes: !exists(json, 'activityCodes') ? undefined : json['activityCodes'],
    features: json['features'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: json['sector'],
    invoiceSendMethod: !exists(json, 'invoiceSendMethod') ? undefined : json['invoiceSendMethod'],
    invoiceOrganizationNumber: !exists(json, 'invoiceOrganizationNumber')
      ? undefined
      : json['invoiceOrganizationNumber'],
    functions: !exists(json, 'functions')
      ? undefined
      : (json['functions'] as Array<any>).map(FunctionViewModelFromJSON),
    issues: !exists(json, 'issues') ? undefined : (json['issues'] as Array<any>).map(IssueViewModelFromJSON),
    partners: !exists(json, 'partners') ? undefined : (json['partners'] as Array<any>).map(PartnerViewModelFromJSON),
    constitutionalDocuments: !exists(json, 'constitutionalDocuments')
      ? undefined
      : (json['constitutionalDocuments'] as Array<any>).map(ConstitutionalDocumentViewModelFromJSON),
    computers: !exists(json, 'computers')
      ? undefined
      : (json['computers'] as Array<any>).map(ComputerViewModelFromJSON),
    contracts: !exists(json, 'contracts')
      ? undefined
      : (json['contracts'] as Array<any>).map(ContractViewModelFromJSON),
    guidelines: !exists(json, 'guidelines')
      ? undefined
      : (json['guidelines'] as Array<any>).map(GuidelineViewModelFromJSON),
    substances: !exists(json, 'substances')
      ? undefined
      : (json['substances'] as Array<any>).map(SubstanceViewModelFromJSON),
    networks: !exists(json, 'networks') ? undefined : (json['networks'] as Array<any>).map(NetworkViewModelFromJSON),
    documents: !exists(json, 'documents')
      ? undefined
      : (json['documents'] as Array<any>).map(DocumentViewModelFromJSON),
    employees: !exists(json, 'employees')
      ? undefined
      : (json['employees'] as Array<any>).map(EmployeeViewModelFromJSON),
    externalConnections: !exists(json, 'externalConnections')
      ? undefined
      : (json['externalConnections'] as Array<any>).map(ExternalConnectionViewModelFromJSON),
    riskAssessments: !exists(json, 'riskAssessments')
      ? undefined
      : (json['riskAssessments'] as Array<any>).map(RiskAssessmentViewModelFromJSON),
    reports: !exists(json, 'reports') ? undefined : (json['reports'] as Array<any>).map(ReportViewModelFromJSON),
    meetings: !exists(json, 'meetings') ? undefined : (json['meetings'] as Array<any>).map(MeetingViewModelFromJSON),
    assets: !exists(json, 'assets') ? undefined : (json['assets'] as Array<any>).map(AssetViewModelFromJSON),
    personalDataItems: !exists(json, 'personalDataItems')
      ? undefined
      : (json['personalDataItems'] as Array<any>).map(PersonalDataItemViewModelFromJSON),
    eventOccurrences: (json['eventOccurrences'] as Array<any>).map(EventOccurrenceViewModelFromJSON),
    meetingOccurrences: (json['meetingOccurrences'] as Array<any>).map(MeetingOccurrenceViewModelFromJSON),
    leavePeriodEditRestriction: json['leavePeriodEditRestriction'],
    vacationSummaryNotes: json['vacationSummaryNotes'],
    vacationSummaryRecipient: !exists(json, 'vacationSummaryRecipient') ? undefined : json['vacationSummaryRecipient'],
    vacationSummaryShared: !exists(json, 'vacationSummaryShared') ? undefined : json['vacationSummaryShared'],
    vacationSummaryEmployees: json['vacationSummaryEmployees'],
    tutorials: (json['tutorials'] as Array<any>).map(TutorialViewModelFromJSON),
    tutorialStates: (json['tutorialStates'] as Array<any>).map(TutorialStateFromJSON),
    staffingCalendarAccessList: json['staffingCalendarAccessList'],
  };
}

export function CompleteOrganizationViewModelToJSON(value?: CompleteOrganizationViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organizationId: value.organizationId,
    href: value.href,
    address: value.address,
    notes: value.notes,
    officeAddress: value.officeAddress,
    officePostcode: value.officePostcode,
    email: value.email,
    herNumber: value.herNumber,
    name: value.name,
    businessEntityType: value.businessEntityType,
    organizationNumber: value.organizationNumber,
    mailAddress: value.mailAddress,
    mailPostcode: value.mailPostcode,
    fax: value.fax,
    phone: value.phone,
    type: value.type,
    url: value.url,
    template: value.template,
    invoicePlan: value.invoicePlan,
    featurePlan: value.featurePlan,
    invoiceReceiver: value.invoiceReceiver,
    invoiceReference: value.invoiceReference,
    invoicePostcode: value.invoicePostcode,
    invoiceLocality: value.invoiceLocality,
    reference: value.reference,
    invoiceAddress: value.invoiceAddress,
    ownerEmail: value.ownerEmail,
    settingsHref: value.settingsHref,
    singleUser: value.singleUser,
    activityCodes: value.activityCodes,
    features: value.features,
    specialTerms: value.specialTerms,
    sector: value.sector,
    invoiceSendMethod: value.invoiceSendMethod,
    invoiceOrganizationNumber: value.invoiceOrganizationNumber,
    functions: value.functions === undefined ? undefined : (value.functions as Array<any>).map(FunctionViewModelToJSON),
    issues: value.issues === undefined ? undefined : (value.issues as Array<any>).map(IssueViewModelToJSON),
    partners: value.partners === undefined ? undefined : (value.partners as Array<any>).map(PartnerViewModelToJSON),
    constitutionalDocuments:
      value.constitutionalDocuments === undefined
        ? undefined
        : (value.constitutionalDocuments as Array<any>).map(ConstitutionalDocumentViewModelToJSON),
    computers: value.computers === undefined ? undefined : (value.computers as Array<any>).map(ComputerViewModelToJSON),
    contracts: value.contracts === undefined ? undefined : (value.contracts as Array<any>).map(ContractViewModelToJSON),
    guidelines:
      value.guidelines === undefined ? undefined : (value.guidelines as Array<any>).map(GuidelineViewModelToJSON),
    substances:
      value.substances === undefined ? undefined : (value.substances as Array<any>).map(SubstanceViewModelToJSON),
    networks: value.networks === undefined ? undefined : (value.networks as Array<any>).map(NetworkViewModelToJSON),
    documents: value.documents === undefined ? undefined : (value.documents as Array<any>).map(DocumentViewModelToJSON),
    employees: value.employees === undefined ? undefined : (value.employees as Array<any>).map(EmployeeViewModelToJSON),
    externalConnections:
      value.externalConnections === undefined
        ? undefined
        : (value.externalConnections as Array<any>).map(ExternalConnectionViewModelToJSON),
    riskAssessments:
      value.riskAssessments === undefined
        ? undefined
        : (value.riskAssessments as Array<any>).map(RiskAssessmentViewModelToJSON),
    reports: value.reports === undefined ? undefined : (value.reports as Array<any>).map(ReportViewModelToJSON),
    meetings: value.meetings === undefined ? undefined : (value.meetings as Array<any>).map(MeetingViewModelToJSON),
    assets: value.assets === undefined ? undefined : (value.assets as Array<any>).map(AssetViewModelToJSON),
    personalDataItems:
      value.personalDataItems === undefined
        ? undefined
        : (value.personalDataItems as Array<any>).map(PersonalDataItemViewModelToJSON),
    eventOccurrences: (value.eventOccurrences as Array<any>).map(EventOccurrenceViewModelToJSON),
    meetingOccurrences: (value.meetingOccurrences as Array<any>).map(MeetingOccurrenceViewModelToJSON),
    leavePeriodEditRestriction: value.leavePeriodEditRestriction,
    vacationSummaryNotes: value.vacationSummaryNotes,
    vacationSummaryRecipient: value.vacationSummaryRecipient,
    vacationSummaryShared: value.vacationSummaryShared,
    vacationSummaryEmployees: value.vacationSummaryEmployees,
    tutorials: (value.tutorials as Array<any>).map(TutorialViewModelToJSON),
    tutorialStates: (value.tutorialStates as Array<any>).map(TutorialStateToJSON),
    staffingCalendarAccessList: value.staffingCalendarAccessList,
  };
}
