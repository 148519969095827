import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import 'src/library/components/d-positioner';
import { LabeledElement } from '../../abstracts/labeled-element.js';
import { classMap } from 'lit/directives/class-map.js';

export interface SelectDropdownRichOption {
  value: string;
  text: string;
  secondaryText: string;
}

@customElement('d-select-dropdown-rich')
export class DSelectDropdownRich extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      .select-wrapper {
        display: block;
        height: var(--inputElementHeight);
        background-image: url(/images/selectbox-image.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 32px 32px;
        padding: 3px 30px 0 10px;
        overflow: hidden;
        cursor: pointer;
      }
      :host([system-content]) .select-wrapper {
        font-family: var(--mainSans), sans-serif;
        font-weight: normal;
      }
      :host([no-arrow]) .select-wrapper {
        background-image: none;
      }
      :host([toolbar]) .select-wrapper {
        box-shadow: none;
        background-color: hsl(0, 0%, 92%);
        border-radius: 4px;
      }
      :host([invalid]) .select-wrapper {
        border: 1px solid var(--alertColor);
        margin-left: -1px;
        margin-right: -1px;
      }
      :host([theme-page]) .select-wrapper {
        background-color: white;
      }
      .placeholder {
        opacity: 0.5;
      }
      .options {
        display: block;
        width: 100%;
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
        background: white;
        box-shadow: 0 4px 8px hsla(0, 0%, 0%, 0.3);
        z-index: 3;
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 6px;
        overflow: auto;
      }
      :host([outskirts]) .options {
        border: none;
      }
      .option {
        padding: 8px 12px;
        font-weight: 200;
        font-size: 15px;
        cursor: pointer;
      }
      .option:not(:first-child) {
        border-top: 1px solid hsl(0, 0%, 80%);
      }
      @media (hover: hover) {
        .option:hover {
          border-color: var(--themeColor);
          background: var(--themeColor);
          color: white;
        }
      }
      .option > div:first-child {
        font-size: 18px;
        font-weight: 500;
      }
    `,
  ];
  @property({ type: String })
  placeholder = '';
  @property({ type: String })
  value = '';
  @property({ type: Boolean })
  disabled = false;
  @property({ type: Array })
  options: SelectDropdownRichOption[] = [];
  @property({ type: Boolean, reflect: true })
  controller = false;
  @property({ type: Boolean, reflect: true })
  toolbar = false;
  @property({ type: Boolean, reflect: true })
  noArrow = false;
  @property({ type: Boolean })
  showOptions = false;
  @property({ type: Object })
  target: HTMLElement | undefined = undefined;
  @query('.select-wrapper')
  selectWrapperElm!: HTMLElement;

  onWrapperClick() {
    if (!this.selectWrapperElm.hasAttribute('disabled')) {
      this.target = this.selectWrapperElm;
      this.showOptions = true;
    }
  }

  onOptionClick(value) {
    this.value = value;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
    this.showOptions = false;
    this.target = undefined;
  }

  private get displayValue(): string {
    if (this.value) {
      const option = this.options.find((o) => {
        return o.value === this.value;
      });
      if (option) {
        return option.text;
      }
    }
    return this.placeholder ?? 'Velg';
  }

  protected renderContent() {
    return html`
      <div class="select-wrapper" ?disabled=${this.disabled} @click=${() => this.onWrapperClick()}>
        <span class="${classMap({ placeholder: !this.value })}">${this.displayValue}</span>
      </div>
      <d-positioner .target=${this.target} @hidden=${() => (this.showOptions = false)}>
        ${this.showOptions
          ? html` <div class="options">
              ${this.options.map((o) => {
                return html`<div class="option" @click=${() => this.onOptionClick(o.value)}>
                  <div>${o.text}</div>
                  <div>${o.secondaryText}</div>
                </div>`;
              })}
            </div>`
          : nothing}
      </d-positioner>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-dropdown-rich': DSelectDropdownRich;
  }
}
