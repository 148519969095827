/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LinkViewModel } from './LinkViewModel';
import { LinkViewModelFromJSON, LinkViewModelFromJSONTyped, LinkViewModelToJSON } from './LinkViewModel';

/**
 *
 * @export
 * @interface ReferenceViewModel
 */
export interface ReferenceViewModel {
  /**
   *
   * @type {string}
   * @memberof ReferenceViewModel
   */
  groupName: string;
  /**
   *
   * @type {Array<LinkViewModel>}
   * @memberof ReferenceViewModel
   */
  pages: Array<LinkViewModel>;
}

/**
 * Check if a given object implements the ReferenceViewModel interface.
 */
export function instanceOfReferenceViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'groupName' in value;
  isInstance = isInstance && 'pages' in value;

  return isInstance;
}

export function ReferenceViewModelFromJSON(json: any): ReferenceViewModel {
  return ReferenceViewModelFromJSONTyped(json, false);
}

export function ReferenceViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferenceViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupName: json['groupName'],
    pages: (json['pages'] as Array<any>).map(LinkViewModelFromJSON),
  };
}

export function ReferenceViewModelToJSON(value?: ReferenceViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groupName: value.groupName,
    pages: (value.pages as Array<any>).map(LinkViewModelToJSON),
  };
}
