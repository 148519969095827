/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FunctionTaskUpdateMessage
 */
export interface FunctionTaskUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof FunctionTaskUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  procedures: string;
  /**
   *
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  situation: string;
  /**
   *
   * @type {boolean}
   * @memberof FunctionTaskUpdateMessage
   */
  assetSelection: boolean;
  /**
   *
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  functionUuid: string;
  /**
   *
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  responsibleFunctionUuid?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof FunctionTaskUpdateMessage
   */
  pages: Array<number>;
  /**
   * Only for new tasks associated with an asset
   * @type {string}
   * @memberof FunctionTaskUpdateMessage
   */
  assetUuidForNewTask?: string;
}

/**
 * Check if a given object implements the FunctionTaskUpdateMessage interface.
 */
export function instanceOfFunctionTaskUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'procedures' in value;
  isInstance = isInstance && 'situation' in value;
  isInstance = isInstance && 'assetSelection' in value;
  isInstance = isInstance && 'functionUuid' in value;
  isInstance = isInstance && 'pages' in value;

  return isInstance;
}

export function FunctionTaskUpdateMessageFromJSON(json: any): FunctionTaskUpdateMessage {
  return FunctionTaskUpdateMessageFromJSONTyped(json, false);
}

export function FunctionTaskUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FunctionTaskUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    name: json['name'],
    procedures: json['procedures'],
    situation: json['situation'],
    assetSelection: json['assetSelection'],
    functionUuid: json['functionUuid'],
    responsibleFunctionUuid: !exists(json, 'responsibleFunctionUuid') ? undefined : json['responsibleFunctionUuid'],
    pages: json['pages'],
    assetUuidForNewTask: !exists(json, 'assetUuidForNewTask') ? undefined : json['assetUuidForNewTask'],
  };
}

export function FunctionTaskUpdateMessageToJSON(value?: FunctionTaskUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    procedures: value.procedures,
    situation: value.situation,
    assetSelection: value.assetSelection,
    functionUuid: value.functionUuid,
    responsibleFunctionUuid: value.responsibleFunctionUuid,
    pages: value.pages,
    assetUuidForNewTask: value.assetUuidForNewTask,
  };
}
