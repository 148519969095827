import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/library/editors/elements/d-select-dropdown-rich';
import 'src/library/editors/elements/d-select-dropdown';
import 'src/library/editors/elements/d-checkbox';
import 'src/library/editors/components/d-edit-select-text';
import 'src/library/editors/elements/d-edit-text';
import './d-signup-form';

import {
  AddonsEnum,
  features,
  organizationSizes,
  Plan,
  PlanFeature,
  planFeatures,
  plans,
  sectorOptions,
  specialTermsOptions,
} from 'src/store/selectors/features';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import planFeaturesPromo from 'src/store/data/plan-features-promo.json';
import { wpStyles } from 'src/library/wp-styles';
import { UserForSignup } from 'src/outskirts/signup/d-signup-form';
import { AccountPlanUpdateMessageInvoicePlanEnum } from 'src/store/api';

@customElement('d-signup-plans')
export class DSignupPlans extends LitElement {
  static readonly styles = [
    wpStyles,
    css`
      :host {
        display: block;
        min-height: 100vh;
        overflow: auto;
        box-sizing: border-box;
        padding-bottom: 20px;
        background: var(--backgroundGray);
      }
      .wrapper {
        min-width: 320px;
        margin: auto;
        position: relative;
        box-sizing: border-box;
        max-width: 1024px;
        padding: 0 20px;
      }
      @media only screen and (min-width: 1024px) {
        .wp.intro img.fullwidth {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
      }
      .selectors {
        margin-top: 30px;
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      table {
        flex: 1;
        margin-bottom: 32px;
        box-sizing: border-box;
        width: 100%;
        border-collapse: collapse;
        font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
        font-size: 17px;
        line-height: 130%;
        color: black;
      }
      tr {
        position: relative;
      }
      th {
        position: sticky;
        top: 50px;
        height: 1px;
        padding-top: 20px;
        background: hsl(0, 0%, 90%);
        font-weight: normal;
        z-index: 1;
        vertical-align: bottom;
      }
      th > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      th:first-child > div {
        height: 100%;
        background: hsl(0, 0%, 90%);
        border-bottom: 1px solid hsl(0, 0%, 80%);
        padding-right: 12px;
        text-align: left;
        hyphens: auto;
      }
      th:first-child > div > div {
        padding: 6px 0;
        font-size: 18px;
      }
      .vat-info {
        margin-top: 20px;
        font-family: 'Chronicle SSm A', 'Chronicle SSm B', sans-serif;
        color: hsl(1, 0%, 40%);
        line-height: 150%;
        font-style: italic;
      }
      th .label {
        font-weight: bold;
        color: hsl(0, 0%, 30%);
      }
      th.plan {
        width: 120px;
      }
      .plan > div {
        padding-bottom: 0;
        background: white;
        height: auto;
        border: 1px solid hsl(0, 0%, 80%);
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        width: calc(100% + 1px);
        position: relative;
        left: -0.5px;
        color: black;
        cursor: pointer;
        align-items: center;
      }
      .plan > div > div {
        text-align: center;
      }
      .name {
        margin-top: 8px;
        margin-bottom: 8px;
        opacity: 0.4;
        font-weight: 500;
      }
      .price {
        margin-right: -8px;
        font-size: 26px;
        font-weight: bold;
        opacity: 0.5;
      }
      .per-month {
        margin-top: 4px;
        margin-left: 8px;
        margin-right: 8px;
        opacity: 0.5;
      }
      .not-price {
        color: black;
        opacity: 0.3;
        text-decoration: line-through;
      }
      table .not-price {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 500;
      }
      .select {
        display: inline-block;
        margin-top: 6px;
        margin-bottom: 12px;
        padding: 8px 14px;
        border-radius: 20px;
        background: hsl(196, 83%, 49%);
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        transition: transform 0.3s;
      }
      tbody tr {
        cursor: pointer;
      }
      td:first-child {
        border-left: none;
        padding-right: 12px;
        background: none;
        text-align: left;
      }
      td {
        border: 1px solid hsl(0, 0%, 80%);
        padding: 6px 0;
        background: white;
        text-align: center;
      }
      tbody tr:first-child td {
        border-top: none;
      }
      @media (hover: hover) {
        tbody tr:hover td {
          color: hsl(196, 83%, 49%);
          text-decoration: underline;
        }
      }
      td.checked {
        background-image: url(/images/check-mini-blue.svg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 36px;
      }
      @media (hover: hover) {
        .plan:hover > div > div.select {
          transform: scale(1.2);
        }
      }
      @media (hover: hover) {
        a:hover {
          color: black;
        }
      }
      @media only screen and (min-width: 769px) {
        h1 {
          font-size: 46px;
        }
        p {
          font-size: 18px;
        }
      }
      @media only screen and (min-width: 1023px) {
        .header-image {
          width: 100%;
          margin: 0;
        }
      }
      @media only screen and (max-width: 768px) {
        .narrow {
          display: block;
        }
        .wide {
          display: none;
        }
        th .label {
          font-size: 14px;
        }
        .option > div:first-child {
          font-size: 16px;
        }
        table {
          font-size: 14px;
        }
        th:first-child > div {
          padding-right: 6px;
        }
        th:first-child > div > div {
          font-size: 15px;
        }
        .plan > div {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        .name {
          margin-bottom: 4px;
        }
        .price {
          margin-right: 0;
          font-size: 17px;
        }
        table .not-price {
          margin-bottom: 2px;
          font-size: 13px;
        }
        #signupFinal p {
          font-size: 14px;
        }
        th,
        th.plans,
        td {
          width: 20px;
        }
        th:first-child,
        td:first-child {
          width: 300px;
          word-break: break-word;
          hyphens: auto;
        }
        td.checked {
          background-size: 30px;
        }
      }
      .overlay {
        position: fixed;
        display: flex;
        align-items: flex-start;
        overflow: auto;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: hsla(197, 82%, 10%, 0.7);
        z-index: 4;
      }
      .overlay.submit-form {
        align-items: center;
      }
      .box {
        position: relative;
        background: white;
        height: auto;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        min-height: 200px;
        border-radius: 20px;
        box-shadow: 6px 6px 30px hsla(0, 0%, 0%, 0.4);
        overflow: auto;
      }
      @media only screen and (min-width: 769px) {
        .box {
          padding: 0 40px 40px 40px;
        }
      }
      .feature .box {
        flex-basis: 1024px;
        min-width: 300px;
        max-width: 1024px;
        margin: 70px 20px;
      }
      .submit-form .box {
        background: var(--backgroundGray);
        width: 700px;
        padding: 30px 30px 20px 30px;
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
      }
      .feature .closer {
        position: fixed;
        display: flex;
        flex-direction: row-reverse;
        width: 1064px;
        max-width: 100vw;
        height: auto;
        margin-top: 76px;
        box-sizing: border-box;
      }
      .submit-form .closer {
        position: absolute;
        top: 0;
        right: 0;
      }
      .closer > div {
        width: 30px;
        height: 30px;
        margin-right: 26px;
        border-radius: 50%;
        background: white url(/images/x-thin-black.svg) 50% 50% no-repeat;
        background-size: 28px 28px;
        cursor: pointer;
        z-index: 3;
        opacity: 0.5;
      }
      .submit-form .closer > div {
        margin-right: 6px;
        margin-top: 6px;
      }
      .submit-form header {
        display: flex;
        flex-wrap: wrap;
        font-size: 24px;
        color: hsl(0, 0%, 30%);
      }
      .submit-form .plan {
        font-weight: bold;
      }
      .submit-form p {
        margin: 8px 0;
        font-size: 16px;
        line-height: 140%;
      }
      .wide {
        display: none;
      }
      @media only screen and (min-width: 600px) {
        .narrow {
          display: none;
        }
        .wide {
          display: block;
        }
      }
    `,
  ];
  @property({ type: Object })
  currentUser: UserForSignup | undefined = undefined;
  @property({ type: String })
  plan: AccountPlanUpdateMessageInvoicePlanEnum = 'X';
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  sector = '';
  @property({ type: String })
  specialTerms = 'NONE';
  @property({ type: Array })
  addons: AddonsEnum[] = [];
  @property({ type: Number })
  viewFeature = 0;
  @property({ type: String })
  email = '';
  @property({ type: String })
  submittedEmail = '';
  @property({ type: Boolean })
  agreementConsent = false;
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  organizationName = '';
  @property({ type: String })
  organizationNumber = '';
  @property({ type: Array })
  organizationAddress: string[] = [];
  @property({ type: String })
  organizationAreaCode = '';
  @property({ type: String })
  organizationArea = '';

  private get healtcarePrice(): number {
    return (
      features.find((f) => {
        return f.id === 'HEALTHCARE';
      })?.price ?? 0
    );
  }

  private get planData() {
    return plans.find((plan) => plan.value === this.plan);
  }

  private get sizeText() {
    return organizationSizes.find((size) => size.value === this.employeesCount)?.text ?? '';
  }

  getRegularPlanPrice(plan: Plan | undefined): number {
    if (plan) {
      const prices = plan.sizes.find((size) => {
        return size.value === this.employeesCount;
      });
      if (prices) {
        let price = prices.regular;
        if (this.addons.includes('HEALTHCARE')) {
          price += this.healtcarePrice;
        }
        return price;
      }
    }
    return 0;
  }

  getPlanPrice(plan: Plan | undefined): number {
    if (plan) {
      const prices = plan.sizes.find((size) => {
        return size.value === this.employeesCount;
      });
      if (prices) {
        let price = prices.regular;
        if (this.specialTerms !== 'NONE') {
          price = prices.specialTerms;
        }
        if (this.addons.includes('HEALTHCARE')) {
          price += this.healtcarePrice;
        }
        return price;
      }
    }
    return 0;
  }

  private get planPriceWithVat() {
    if (this.planData) {
      const prices = this.planData.sizes.find((size) => {
        return size.value === this.employeesCount;
      });
      if (prices) {
        let price = prices.regular * 1.25;
        if (this.specialTerms !== 'NONE') {
          price = prices.specialTerms * 1.25;
        }
        if (this.addons.includes('HEALTHCARE')) {
          price += this.healtcarePrice;
        }
        return price.toFixed(2).replace('.', ',');
      }
    }
    return 0;
  }

  private onFeatureClick(id: number) {
    this.viewFeature = id;
  }

  private featureCheckClass(feature: PlanFeature, plan: string): string {
    if (feature.value === 'healthcare' && this.addons.includes('HEALTHCARE')) {
      return 'checked';
    }
    if (feature.plans.includes(plan)) {
      return 'checked';
    }
    return '';
  }

  private getFeaturePromo(id: number): string {
    return (
      planFeaturesPromo.find((promo) => {
        return promo.id === id;
      })?.content ?? ''
    );
  }

  private closeForm() {
    this.plan = 'X';
    history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
  }

  renderFeaturePromo() {
    if (this.viewFeature !== 0) {
      return html` <div class="overlay feature" @click=${() => (this.viewFeature = 0)}>
        <div class="box wp">${unsafeHTML(this.getFeaturePromo(this.viewFeature))}</div>
        <div class="closer" @click=${() => (this.viewFeature = 0)}><div></div></div>
      </div>`;
    }
    return html``;
  }

  renderForm() {
    if (this.plan !== 'X') {
      return html`
        <div class="overlay submit-form">
          <div class="box">
            <header>
              <div class="plan">
                ${this.planData?.text} ${this.addons.includes('HEALTHCARE') ? ' med bedriftshelsetjeneste' : nothing}
              </div>
              &nbsp;
              <div>for <span>${this.sizeText}</span>:&nbsp;</div>
              <div>
                kr.
                ${this.specialTerms !== 'NONE'
                  ? html`<span class="not-price"
                      >&nbsp;<span>${this.getRegularPlanPrice(this.planData)}</span>&nbsp;</span
                    >`
                  : nothing}
                <span>${this.getPlanPrice(this.planData)}</span>,-/mnd.
              </div>
              <p>
                Pris inkl. mva: kr. ${this.planPriceWithVat} per måned.<br />
                Hvis du avbestiller innen 30 dager, betaler du ingenting.
              </p>
            </header>
            <d-signup-form
              theme-page
              system-content
              .currentUser=${this.currentUser}
              .email=${this.email}
              .submittedEmail=${this.submittedEmail}
              .sector=${this.sector}
              .specialTerms=${this.specialTerms}
              .plan=${this.plan}
              .addons=${this.addons}
              .employeesCount=${this.employeesCount}
              @sector-changed=${(e) => (this.sector = e.detail.value)}
              @special-terms-changed=${(e) => (this.specialTerms = e.detail.value)}
            >
              <slot slot="usernameInput" name="usernameInput"></slot>
              <slot slot="passwordInput" name="passwordInput"></slot>
            </d-signup-form>
            <div class="closer" @click=${() => this.closeForm()}>
              <div></div>
            </div>
          </div>
        </div>
      `;
    }
    return html``;
  }

  renderPlansTable() {
    return html`<table class="plans-table">
      <tr>
        <th>
          <div>
            <div class="vat-info">Alle priser er ekskl. mva.</div>
            <div class="label">Innhold og funk­sjoner</div>
          </div>
        </th>
        ${plans.map((plan) => {
          return html`<th class="plan">
            <div @click=${() => (this.plan = plan.value)}>
              <div class="name">${plan.text}</div>
              ${this.specialTerms !== 'NONE'
                ? html` <div class="price not-price">&nbsp;${this.getRegularPlanPrice(plan)},-&nbsp;</div> `
                : nothing}
              <div class="price">${this.getPlanPrice(plan)},-</div>
              <div class="per-month narrow">pr. mnd.</div>
              <div class="per-month wide">per måned</div>
              <div class="select">Velg</div>
            </div>
          </th>`;
        })}
      </tr>
      <tbody>
        ${planFeatures.map((feature) => {
          return html`<tr @click=${() => this.onFeatureClick(feature.post)}>
            <td>${unsafeHTML(feature.name)}</td>
            ${plans.map((plan) => {
              return html`<td class="${this.featureCheckClass(feature, plan.value)}"></td>`;
            })}
          </tr>`;
        })}
      </tbody>
    </table>`;
  }

  renderHealthcareOption() {
    return html`<d-checkbox
      system-content
      theme-page
      wrap-label
      label="Bedriftshelsetjeneste"
      option-label="Inkluder bedriftshelsetjeneste (kr. ${this.healtcarePrice},-/mnd)"
      .value=${this.addons.includes('HEALTHCARE')}
      @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
        if (e.detail.checked) {
          this.addons = ['HEALTHCARE'];
        } else {
          this.addons = [];
        }
      }}
    ></d-checkbox>`;
  }

  render() {
    return html`
      <div class="wrapper">
        <div class="wp intro">
          <img src="/promo-media/desktop-and-mobile.png" class="fullwidth" />
          <h1>Prøv TrinnVis med 30 dagers angrefrist</h1>
          <p>
            TrinnVis har abonnementer tilpasset virksomhetens størrelse og behov. Uansett hvilket abonnement du velger,
            får du de 30 første dagene gratis. Hvis du avbestiller innen denne tiden, betaler du ingenting.
          </p>
          <p>
            For virksomheter med flere enn 30 personer gjelder egne priser.
            <a href="mailto:siren@trinnvis.no">Ta kontakt.</a>
          </p>
        </div>
        <div class="selectors">
          <d-select-dropdown
            system-content
            theme-page
            label="Virksomhetens størrelse"
            .options=${organizationSizes.map((o) => {
              return { value: o.value + '', text: o.text };
            })}
            .value=${this.employeesCount + ''}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.employeesCount = Number(e.detail.value);
            }}
          ></d-select-dropdown>
          <d-select-dropdown-rich
            system-content
            theme-page
            label="Bransje"
            class="fullWidth"
            placeholder="Velg bransje"
            .options=${sectorOptions}
            .value=${this.sector}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.sector = e.detail.value;
            }}
          ></d-select-dropdown-rich>
          ${this.sector === 'HEALTH'
            ? html` <d-select-dropdown
                system-content
                theme-page
                label="Medlemsrabatt"
                .options=${specialTermsOptions}
                .value=${this.specialTerms}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.specialTerms = e.detail.value;
                }}
              ></d-select-dropdown>`
            : nothing}
        </div>
        ${this.renderPlansTable()}
        <div class="wp">
          <h2>Betalingsbetingelser</h2>
          <ul>
            <li>
              De første 30 dagene av abonnementet er gratis. Hvis du sier opp skriftlig innen denne tiden, betaler du
              ingenting.
            </li>
            <li>Abonnement faktureres 12 måneder forskuddsvis</li>
            <li>Oppsigelsestid er 30 dager før neste faktureringsperiode</li>
            <li>Etableringsgebyr: kr. 1600,-</li>
            <li>Bruk av sms-varsling koster kr. 0,80 per melding</li>
            <li>Alle priser er ekskl. mva.</li>
          </ul>
        </div>
      </div>
      ${this.renderFeaturePromo()} ${this.renderForm()}
    `;
  }

  protected willUpdate() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);
    if (params.has('email')) {
      this.email = params.get('email') ?? '';
    }
    if (params.has('submittedEmail')) {
      this.submittedEmail = params.get('submittedEmail') ?? '';
    }
    if (params.has('plan')) {
      this.plan = (params.get('plan') as AccountPlanUpdateMessageInvoicePlanEnum) ?? 'X';
    }
    if (params.has('employeesCount')) {
      this.employeesCount = Number(params.get('employeesCount'));
    }
    if (params.has('sector')) {
      this.sector = params.get('sector') ?? '';
    }
    if (params.has('specialTerms')) {
      this.specialTerms = params.get('specialTerms') ?? 'NONE';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signup-plans': DSignupPlans;
  }
}
