/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PlusTimePeriod
 */
export interface PlusTimePeriod {
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  end: string;
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  endTime?: string;
  /**
   *
   * @type {string}
   * @memberof PlusTimePeriod
   */
  notes: string;
  /**
   *
   * @type {boolean}
   * @memberof PlusTimePeriod
   */
  confirmed: boolean;
}

/**
 * Check if a given object implements the PlusTimePeriod interface.
 */
export function instanceOfPlusTimePeriod(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'start' in value;
  isInstance = isInstance && 'end' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'confirmed' in value;

  return isInstance;
}

export function PlusTimePeriodFromJSON(json: any): PlusTimePeriod {
  return PlusTimePeriodFromJSONTyped(json, false);
}

export function PlusTimePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlusTimePeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    start: json['start'],
    end: json['end'],
    startTime: !exists(json, 'startTime') ? undefined : json['startTime'],
    endTime: !exists(json, 'endTime') ? undefined : json['endTime'],
    notes: json['notes'],
    confirmed: json['confirmed'],
  };
}

export function PlusTimePeriodToJSON(value?: PlusTimePeriod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    start: value.start,
    end: value.end,
    startTime: value.startTime,
    endTime: value.endTime,
    notes: value.notes,
    confirmed: value.confirmed,
  };
}
