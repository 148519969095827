import { css } from 'lit';

export const wpStyles = css`
  .wp {
    box-sizing: border-box;
    font-family: var(--mainSerif);
    color: hsl(1, 0%, 40%);
    line-height: 140%;
  }
  .wp p,
  .wp ul,
  .wp ol {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .wp h1 {
    font-size: 32px;
  }
  .wp h1,
  .wp h2 {
    display: flow-root;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .wp h1,
  .wp h2,
  .wp h3 {
    line-height: 130%;
    margin-bottom: 0.5em;
    font-weight: 500;
  }
  .wp a {
    color: var(--themeColor);
    text-decoration: none;
  }
  .wp figure {
    width: calc(100% + 40px) !important;
    height: auto !important;
    margin: 1.2em -20px 1em;
    position: relative;
    float: none;
    border-top: none;
    overflow: hidden;
  }
  .wp img:not(.small) {
    width: 100vw !important;
    height: auto !important;
    margin: 1.2em -20px 1em;
    position: relative;
    box-sizing: border-box;
    float: none;
    border-top: 1px solid hsl(1, 0%, 80%);
    border-bottom: 1px solid hsl(1, 0%, 80%);
  }
  .wp figure img,
  .wp figure img:not(.small) {
    margin: 0;
    float: none;
    clear: both;
    width: 100%;
  }
  .wp figcaption {
    font-family: var(--mainSans), Verdana, sans-serif;
    font-size: 15px;
    padding-top: 12px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 1em;
    clear: both;
  }
  .wp img.fullwidth,
  .wp figure.fullwidth {
    width: calc(100% + 40px);
    margin: 0 -20px;
    height: auto;
  }
  .wp .fullwidth:first-child {
    margin-top: -1px;
  }
  .wp .wide {
    display: none;
  }
  .wp .attention,
  .wp .quote,
  .wp .app-reference,
  .wp .testimonial {
    position: relative;
    min-height: 54px;
    box-sizing: border-box;
    margin: 20px 0;
    padding: 16px 20px 16px 54px;
    background-color: hsl(196, 83%, 90%);
    font-family: var(--mainSans), Verdana, sans-serif;
    line-height: 140%;
    border-radius: 6px;
  }
  .wp .testimonial {
    padding-left: 20px;
    font-family: var(--mainSerif), serif;
  }
  .wp .testimonial .says {
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 120%;
  }
  .wp .testimonial .says:before {
    content: '”';
    position: relative;
    top: 0.5em;
    margin-right: 0.05em;
    font-size: 4em;
    line-height: 0;
    color: #4facee;
  }
  .wp .testimonial > div:last-child {
    display: flex;
    align-items: center;
  }
  .wp .testimonial p.person {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    font-size: 18px;
    line-height: 120%;
    font-style: italic;
  }
  .wp .testimonial img:not(.fullwidth):not(.partner):not(.small) {
    width: 100px !important;
    height: 100px !important;
    margin: 0 12px 0 -4px;
    border: 5px solid white;
    border-radius: 50%;
  }
  .wp hr {
    clear: both;
    opacity: 0;
  }
  @media only screen and (min-width: 600px) {
    .wp p,
    .wp ul,
    .wp ol {
      hyphens: manual;
    }

    .wp .narrow {
      display: none;
    }

    .wp .wide {
      display: block;
    }
  }
  @media only screen and (min-width: 769px) {
    .wp p,
    .wp ul,
    .wp ol {
      font-size: 18px;
    }
    .wp h1 {
      font-size: 46px;
    }
    .wp figure.fullwidth,
    .wp img.fullwidth {
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px) !important;
    }
    .wp figure:not(.fullwidth) {
      width: 50vw !important;
      margin-left: 20px;
      margin-right: 0;
      max-width: 520px;
      float: right;
      border-left: 1px solid hsl(1, 0%, 80%);
      border-right: 1px solid hsl(1, 0%, 80%);
      border-bottom: 1px solid hsl(1, 0%, 80%);
    }
    .wp img:not(.fullwidth):not(.small) {
      width: 50vw !important;
      max-width: 520px;
      margin-left: 20px;
      margin-right: 0;
      float: right;
    }
    figure img {
      width: 100% !important;
    }
  }
`;
