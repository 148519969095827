import { html, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import type { DNewIssue, NewIssueEditItem } from 'src/content/issues/d-new-issue';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import 'src/content/issues/d-new-issue';
import { DMobilePanel } from 'src/outskirts/account/mobile-app/d-mobile-panel';
import { isEmptyOrInvalidString } from 'src/utilities/text';

/**
 *
 * STATUS OK
 */
@customElement('d-mobile-new-issue')
export class DMobileNewIssue extends DMobilePanel {
  @property({ type: Array })
  recipientOptions: SelectDropdownOption[] = [];
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  currentEmployeeUuid: string = '';
  @property({ type: Object })
  newIssueEditItem: NewIssueEditItem = {
    description: '',
    recipient: '',
    classified: false,
    anonymous: false,
    eventDate: '',
    immediateMeasures: '',
    furtherMeasures: '',
    processing: 'DEFAULT',
  };
  @property({ type: Boolean })
  disabled = true;
  @property({ type: Boolean })
  issueRegistered = false;
  @property({ type: Boolean })
  registrationComplete = false;
  @query('#new-issue')
  private newIssueElm!: DNewIssue;

  onNewIssueEditItemChanged(e) {
    e.stopPropagation();
    this.newIssueEditItem = e.detail.value;
  }

  onIssueRegistered() {
    this.issueRegistered = true;
  }

  onRegistrationComplete() {
    this.registrationComplete = true;
  }

  close() {
    if (this.issueRegistered && !this.registrationComplete) {
      this.registrationComplete = true;
      this.newIssueElm.registrationComplete = true;
      setTimeout(() => {
        super.close();
      }, 3000);
    } else {
      super.close();
    }
  }

  done(e) {
    e.stopPropagation();
    if (!e.target.disabled) {
      if (this.registrationComplete) {
        this.close();
      } else if (this.issueRegistered) {
        this.newIssueElm.saveAdditionalInformation();
        setTimeout(() => {
          this.close();
        }, 3000);
      } else {
        this.newIssueElm.newIssue();
      }
    }
  }

  protected get calculatedTitle() {
    if (this.registrationComplete) {
      return 'Takk!';
    }
    return super.calculatedTitle;
  }

  protected get hideCancel() {
    return this.registrationComplete;
  }

  protected get doneText() {
    if (this.registrationComplete) {
      return 'Lukk';
    }
    return 'Send';
  }

  protected get doneDisabled() {
    return isEmptyOrInvalidString(this.newIssueEditItem.description);
  }

  protected renderContent() {
    return html`
      <d-new-issue
        id="new-issue"
        context="mobile"
        .editItem=${this.newIssueEditItem}
        .recipientOptions=${this.recipientOptions}
        .organizationId=${this.organizationId}
        .currentEmployeeUuid=${this.currentEmployeeUuid}
        @new-issue-edit-item-changed=${(e) => this.onNewIssueEditItemChanged(e)}
        @issue-registered=${() => this.onIssueRegistered()}
        @registration-complete=${() => this.onRegistrationComplete()}
      ></d-new-issue>
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('recipientOptions')) {
      this.newIssueEditItem.recipient = this.recipientOptions[0].value;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-mobile-new-issue': DMobileNewIssue;
  }
}
