/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestSignaturesSignatory } from './RequestSignaturesSignatory';
import {
  RequestSignaturesSignatoryFromJSON,
  RequestSignaturesSignatoryFromJSONTyped,
  RequestSignaturesSignatoryToJSON,
} from './RequestSignaturesSignatory';

/**
 *
 * @export
 * @interface RequestSignaturesCommand
 */
export interface RequestSignaturesCommand {
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  entityType: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  entityUuid: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  dueDate: string;
  /**
   * The persons signing the document. Must be registered in the system. At least one person must sign. Roles are 'employee', 'partner' or 'organization'
   * @type {Array<RequestSignaturesSignatory>}
   * @memberof RequestSignaturesCommand
   */
  signatories: Array<RequestSignaturesSignatory>;
  /**
   * The documents to sign. Valid values are the entity document itself and any attachments. The order of this list determines the order of the parts. Must contain at least one item
   * @type {Array<string>}
   * @memberof RequestSignaturesCommand
   */
  documents: Array<string>;
}

/**
 * Check if a given object implements the RequestSignaturesCommand interface.
 */
export function instanceOfRequestSignaturesCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'entityType' in value;
  isInstance = isInstance && 'entityUuid' in value;
  isInstance = isInstance && 'dueDate' in value;
  isInstance = isInstance && 'signatories' in value;
  isInstance = isInstance && 'documents' in value;

  return isInstance;
}

export function RequestSignaturesCommandFromJSON(json: any): RequestSignaturesCommand {
  return RequestSignaturesCommandFromJSONTyped(json, false);
}

export function RequestSignaturesCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RequestSignaturesCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: !exists(json, 'message') ? undefined : json['message'],
    entityType: json['entityType'],
    entityUuid: json['entityUuid'],
    dueDate: json['dueDate'],
    signatories: (json['signatories'] as Array<any>).map(RequestSignaturesSignatoryFromJSON),
    documents: json['documents'],
  };
}

export function RequestSignaturesCommandToJSON(value?: RequestSignaturesCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    entityType: value.entityType,
    entityUuid: value.entityUuid,
    dueDate: value.dueDate,
    signatories: (value.signatories as Array<any>).map(RequestSignaturesSignatoryToJSON),
    documents: value.documents,
  };
}
