/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationCreateMessage
 */
export interface OrganizationCreateMessage {
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoiceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoicePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoiceLocality?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoiceReference?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  organizationName?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationCreateMessage
   */
  employeesCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationCreateMessage
   */
  singleUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  specialTerms?: OrganizationCreateMessageSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  sector?: OrganizationCreateMessageSectorEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoiceSendMethod?: OrganizationCreateMessageInvoiceSendMethodEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  invoiceOrganizationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  businessEntityType?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateMessage
   */
  referralCode?: string;
}

/**
 * @export
 */
export const OrganizationCreateMessageSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type OrganizationCreateMessageSpecialTermsEnum =
  (typeof OrganizationCreateMessageSpecialTermsEnum)[keyof typeof OrganizationCreateMessageSpecialTermsEnum];

/**
 * @export
 */
export const OrganizationCreateMessageSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationCreateMessageSectorEnum =
  (typeof OrganizationCreateMessageSectorEnum)[keyof typeof OrganizationCreateMessageSectorEnum];

/**
 * @export
 */
export const OrganizationCreateMessageInvoiceSendMethodEnum = {
  Manual: 'MANUAL',
  Ehf: 'EHF',
} as const;
export type OrganizationCreateMessageInvoiceSendMethodEnum =
  (typeof OrganizationCreateMessageInvoiceSendMethodEnum)[keyof typeof OrganizationCreateMessageInvoiceSendMethodEnum];

/**
 * Check if a given object implements the OrganizationCreateMessage interface.
 */
export function instanceOfOrganizationCreateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrganizationCreateMessageFromJSON(json: any): OrganizationCreateMessage {
  return OrganizationCreateMessageFromJSONTyped(json, false);
}

export function OrganizationCreateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationCreateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    invoiceAddress: !exists(json, 'invoiceAddress') ? undefined : json['invoiceAddress'],
    invoicePostcode: !exists(json, 'invoicePostcode') ? undefined : json['invoicePostcode'],
    invoiceLocality: !exists(json, 'invoiceLocality') ? undefined : json['invoiceLocality'],
    invoiceReference: !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    employeesCount: !exists(json, 'employeesCount') ? undefined : json['employeesCount'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
    template: !exists(json, 'template') ? undefined : json['template'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: !exists(json, 'sector') ? undefined : json['sector'],
    invoiceSendMethod: !exists(json, 'invoiceSendMethod') ? undefined : json['invoiceSendMethod'],
    invoiceOrganizationNumber: !exists(json, 'invoiceOrganizationNumber')
      ? undefined
      : json['invoiceOrganizationNumber'],
    businessEntityType: !exists(json, 'businessEntityType') ? undefined : json['businessEntityType'],
    referralCode: !exists(json, 'referralCode') ? undefined : json['referralCode'],
  };
}

export function OrganizationCreateMessageToJSON(value?: OrganizationCreateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    invoiceAddress: value.invoiceAddress,
    invoicePostcode: value.invoicePostcode,
    invoiceLocality: value.invoiceLocality,
    invoiceReference: value.invoiceReference,
    organizationName: value.organizationName,
    employeesCount: value.employeesCount,
    singleUser: value.singleUser,
    template: value.template,
    specialTerms: value.specialTerms,
    sector: value.sector,
    invoiceSendMethod: value.invoiceSendMethod,
    invoiceOrganizationNumber: value.invoiceOrganizationNumber,
    businessEntityType: value.businessEntityType,
    referralCode: value.referralCode,
  };
}
