import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import '../../../../library/fields/d-expansion.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import './d-edit-data-item-data-processor.js';
import './d-edit-data-item-application.js';
import './d-edit-data-item-computer.js';
import {
  type InfosecDataType,
  type InfosecCategory,
  InfosecStorageUnit,
  InfosecApplicationOption,
  getComputerName,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { uuid } from 'src/utilities/text.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';
import {
  NewComputerDialog,
  NewComputerResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-computer-dialog.js';

export interface DataItemExistingPartnerAccountingChange {
  dataType: string;
  dataProcessor: string;
  category: string;

  type: 'partners';
}

export interface DataItemExistingComputerAccountingChange {
  newComputer: false;
  computerUuid: string;
  dataType: string;
  category: string;

  type: 'computers';
}

export interface DataItemNewComputerAccountingChange {
  newComputer: true;
  computerUuid: string;
  newComputerName: string;
  newComputerType: string;
  dataType: string;
  category: string;

  type: 'computers';
}

export interface DataItemExistingApplicationAccountingChange {
  dataType: string;
  category: string;

  application: string;

  type: 'cloudServices';
}

export type DataItemAccountingChange =
  | DataItemExistingApplicationAccountingChange
  | DataItemExistingComputerAccountingChange
  | DataItemExistingPartnerAccountingChange
  | DataItemNewComputerAccountingChange;

export type AccountingType = '' | 'partners' | 'cloudServices' | 'computers';

export interface PartnerAccountingDataItem {
  type: 'partners';
  supplierUuid: string;
}

export interface CloudServiceAccountingDataItem {
  type: 'cloudServices';
  application: string;
}

export interface ComputerAccountingDataItem {
  type: 'computers';
  computerUuid: string;
}

export type AccountingDataItem =
  | PartnerAccountingDataItem
  | CloudServiceAccountingDataItem
  | ComputerAccountingDataItem;

/**
 *
 *
 */
@customElement('d-edit-data-item-accounting')
export class DEditDataItemAccounting extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-select-dropdown,
    d-select-dropdown-or-add {
      margin-bottom: 12px;
    }

    :host([small-label]) d-select-dropdown,
    :host([small-label]) d-select-dropdown-or-add {
      margin-bottom: 8px;
    }

    d-edit-data-item-application,
    d-edit-data-item-data-processor,
    d-edit-data-item-computer {
      margin-top: 8px;
    }
  `;
  @property({ type: Object })
  dataType!: InfosecDataType;
  @property({ type: Object })
  category!: InfosecCategory;
  @property({ type: Object })
  dataItem?: AccountingDataItem;
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;

  @state()
  newType: AccountingType = '';
  @state()
  newApplication = '';
  @state()
  newSupplierUuid = '';
  @state()
  newComputerUuid = '';

  /**
   * The available application options.
   *
   */
  @property({ type: Array })
  availableApplicationOptions: InfosecApplicationOption[] = [];

  private get typeOptions() {
    return [
      {
        value: 'partners',
        text: 'Av ekstern regnskapsfører',
      },
      {
        value: 'cloudServices',
        text: 'Internt, i nettbasert system',
      },
      {
        value: 'computers',
        text: 'Internt, i lokale programmer/dokumenter',
      },
    ];
  }

  private get applicationOptions() {
    const availableApplicationOptions = this.category.applicationOptions ?? [];

    const options: SelectDropdownOption[] = availableApplicationOptions.map((item) => {
      return {
        value: item.value,
        text: item.text,
      };
    });
    if (this.dataItem && this.dataItem.type === 'cloudServices' && this.dataItem.application.startsWith('CUSTOM')) {
      options.push({
        value: this.dataItem.application,
        text: this.dataItem.application.slice(7),
      });
    }
    if (this.newApplication && this.newApplication.startsWith('CUSTOM')) {
      options.push({
        value: this.newApplication,
        text: this.newApplication.slice(7),
      });
    }
    return options;
  }

  _typeChanged(value) {
    this.newType = value;
  }

  render() {
    if (this.dataItem === undefined) {
      return this.renderNewItem();
    } else {
      return this.renderExistingItem(this.dataItem);
    }
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    if (changedProperties.has('dataItem') && changedProperties.get('dataItem') === undefined) {
      this.newType = '';
    }
  }

  private async onComputerValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (e.detail.value === 'NEW') {
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
      });
      if (result.action === 'done') {
        const computerUuid = uuid();
        this.computers = [
          ...this.computers,
          {
            uuid: computerUuid,
            name: result.name,
            unitType: 'computers',
            computerType: result.type,
            application: '',
            partnerUuid: '',
            networkUuid: '',
          },
        ];

        this.dispatchEvent(
          new CustomEvent<DataItemNewComputerAccountingChange>('data-item-changed', {
            bubbles: true,
            composed: true,
            detail: {
              dataType: this.dataType.type,
              category: this.category.category,
              type: 'computers',
              computerUuid: computerUuid,
              newComputer: true,
              newComputerName: result.name,
              newComputerType: result.type,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemExistingComputerAccountingChange>('data-item-changed', {
          bubbles: true,
          composed: true,
          detail: {
            newComputer: false,
            dataType: this.dataType.type,
            category: this.category.category,
            type: 'computers',
            computerUuid: e.detail.value,
          },
        }),
      );
    }
  }

  private async onPartnerValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (e.detail.value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        const partnerUuid = uuid();

        this.dispatchEvent(
          new CustomEvent<{ uuid: string; name: string }>('new-partner', {
            composed: true,
            bubbles: true,
            detail: { uuid: partnerUuid, name: result.name },
          }),
        );

        this.dispatchEvent(
          new CustomEvent<DataItemExistingPartnerAccountingChange>('data-item-changed', {
            bubbles: true,
            composed: true,
            detail: {
              dataType: this.dataType.type,
              category: this.category.category,
              type: 'partners',
              dataProcessor: partnerUuid,
            },
          }),
        );

        this.newType = '';
      }

      return;
    }

    this.dispatchEvent(
      new CustomEvent<DataItemExistingPartnerAccountingChange>('data-item-changed', {
        bubbles: true,
        composed: true,
        detail: {
          dataType: this.dataType.type,
          category: this.category.category,
          type: 'partners',
          dataProcessor: e.detail.value,
        },
      }),
    );
  }

  private async onApplicationValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (e.detail.value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Nytt regnskapssystem' });
      if (result.action === 'done') {
        this.newApplication = 'CUSTOM-' + result.name;
        this.dispatchEvent(
          new CustomEvent<DataItemExistingApplicationAccountingChange>('data-item-changed', {
            bubbles: true,
            composed: true,
            detail: {
              dataType: this.dataType.type,
              category: this.category.category,
              type: 'cloudServices',
              application: 'CUSTOM-' + result.name,
            },
          }),
        );
      } else {
        this.newApplication = '';
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemExistingApplicationAccountingChange>('data-item-changed', {
          bubbles: true,
          composed: true,
          detail: {
            dataType: this.dataType.type,
            category: this.category.category,
            type: 'cloudServices',
            application: e.detail.value,
          },
        }),
      );
    }
  }

  private renderNewItem() {
    return html`
      <d-select-dropdown
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        unselectablePlaceholder
        label="Hvordan føres regnskapet?"
        placeholder="Velg hvordan regnskapet føres"
        .options=${this.typeOptions}
        .value=${this.newType}
        @value-changed=${(e) => this._typeChanged(e.detail.value)}
      ></d-select-dropdown>
      <d-expansion ?opened="${this.newType === 'partners'}">
        <d-select-dropdown-or-add
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Regnskapsfører"
          unselectablePlaceholder
          placeholder="Velg samarbeidspartner"
          firstItemText="Registrer samarbeidspartner"
          additionalItemText="Annen samarbeidspartner"
          .options=${this.partners}
          .value=${this.newSupplierUuid}
          @value-changed=${this.onPartnerValueChanged}
        ></d-select-dropdown-or-add>
      </d-expansion>
      <d-expansion ?opened="${this.newType === 'cloudServices'}">
        <d-select-dropdown-or-add
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Regnskapssystem"
          unselectablePlaceholder
          placeholder="Velg tjeneste"
          firstItemText="Registrer tjeneste"
          additionalItemText="Annen tjeneste"
          .options=${this.applicationOptions}
          .value=${this.newApplication}
          @value-changed=${this.onApplicationValueChanged}
        ></d-select-dropdown-or-add>
      </d-expansion>
      <d-expansion ?opened="${this.newType === 'computers'}">
        <d-select-dropdown-or-add
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Datamaskin"
          unselectablePlaceholder
          placeholder="Velg datamaskin"
          firstItemText="Registrer datamaskin"
          additionalItemText="Annen datamaskin"
          .options=${this.computers.map((computer) => {
            return {
              value: computer.uuid,
              text: computer.name,
            };
          })}
          .value=${this.newApplication}
          @value-changed=${this.onComputerValueChanged}
        ></d-select-dropdown-or-add>
      </d-expansion>
    `;
  }

  private renderExistingItem(dataItem: AccountingDataItem) {
    return html`
      <d-select-dropdown
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        unselectablePlaceholder
        label="Hvordan føres regnskapet?"
        placeholder="Velg hvordan regnskapet føres"
        .options=${this.typeOptions}
        .value=${dataItem.type}
        disabled
      ></d-select-dropdown>
      ${dataItem.type === 'partners'
        ? html` <d-select-dropdown
            label="Regnskapsfører"
            ?light-label=${this.lightLabel}
            ?small-label=${this.smallLabel}
            theme-page
            unselectablePlaceholder
            .options=${this.partners}
            .value=${dataItem.supplierUuid}
            disabled
          ></d-select-dropdown>`
        : nothing}
      ${dataItem.type === 'cloudServices'
        ? html` <d-select-dropdown
            label="Regnskapssystem"
            ?light-label=${this.lightLabel}
            ?small-label=${this.smallLabel}
            theme-page
            unselectablePlaceholder
            .options=${this.applicationOptions}
            .value=${dataItem.application}
            disabled
          ></d-select-dropdown>`
        : nothing}
      ${dataItem.type === 'computers'
        ? html` <d-select-dropdown
            label="Datamaskin"
            ?light-label=${this.lightLabel}
            ?small-label=${this.smallLabel}
            theme-page
            unselectablePlaceholder
            .options=${this.computers.map((c) => ({ value: c.uuid, text: getComputerName(c) }))}
            .value=${dataItem.computerUuid}
            disabled
          ></d-select-dropdown>`
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-accounting': DEditDataItemAccounting;
  }
}
