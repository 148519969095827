/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageFile } from './MessageFile';
import { MessageFileFromJSON, MessageFileFromJSONTyped, MessageFileToJSON } from './MessageFile';

/**
 *
 * @export
 * @interface MessageContent
 */
export interface MessageContent {
  /**
   *
   * @type {string}
   * @memberof MessageContent
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof MessageContent
   */
  text?: string;
  /**
   *
   * @type {Array<MessageFile>}
   * @memberof MessageContent
   */
  files?: Array<MessageFile>;
  /**
   *
   * @type {string}
   * @memberof MessageContent
   */
  html?: string;
}

/**
 * Check if a given object implements the MessageContent interface.
 */
export function instanceOfMessageContent(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function MessageContentFromJSON(json: any): MessageContent {
  return MessageContentFromJSONTyped(json, false);
}

export function MessageContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageContent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: !exists(json, 'role') ? undefined : json['role'],
    text: !exists(json, 'text') ? undefined : json['text'],
    files: !exists(json, 'files') ? undefined : (json['files'] as Array<any>).map(MessageFileFromJSON),
    html: !exists(json, 'html') ? undefined : json['html'],
  };
}

export function MessageContentToJSON(value?: MessageContent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: value.role,
    text: value.text,
    files: value.files === undefined ? undefined : (value.files as Array<any>).map(MessageFileToJSON),
    html: value.html,
  };
}
