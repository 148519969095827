import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-spinner-robot';
import '../../library/elements/d-action.js';
import type { RobotAlertItem, RobotAlertRuleAction } from 'src/store';
import type { FunctionViewModel } from 'src/store/api';
import { GestureType } from 'src/library/fields/d-spinner-robot';

/**
 *
 * Displays the current robot alert including message and actions. When a new alert is set the current
 * one is first slide down before the new one slides up.
 *
 *
 *
 *
 */
@customElement('d-robot-alert')
export class DRobotAlert extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      position: fixed;
      bottom: 0;
      z-index: 60;
    }

    #wrapper {
      flex: none;
      display: flex;
      align-items: flex-end;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      max-width: 100vw;
      transition: all 0s;
    }

    :host([is-app-platform]) #wrapper {
      padding-bottom: 80px;
    }

    @media only screen and (min-width: 845px) {
      #wrapper {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .maxWidth600 #wrapper {
      margin-right: 20px;
      padding-left: 0;
    }

    d-spinner-robot {
      position: relative;
      width: 48px;
      height: 48px;
      margin-right: 30px;
    }

    #callout {
      box-sizing: border-box;
      max-width: 720px;
      padding: 18px 20px;
      border-radius: 10px;
      background: white;
      display: inline-block;
      position: relative;
      filter: drop-shadow(0 2px 10px hsla(1, 0%, 0%, 0.4));
    }

    #callout:before {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 20px solid white;
      border-bottom: 7px solid transparent;
      display: inline-block;
      position: absolute;
      bottom: 16px;
      left: -20px;
    }

    .message {
      line-height: 130%;
      margin-bottom: 6px;
      max-height: 500px;
      overflow: auto;
    }

    .actions {
      text-align: right;
      margin-top: 8px;
    }

    .action.mini {
      display: inline-block;
      padding-left: 12px;
    }
  `;
  /**
   * The current robot alert to show. Set as undefined to hide the robot
   */
  @property({ type: Object })
  currentRobotAlert?: RobotAlertItem;
  @property({ type: Boolean, attribute: 'is-app-platform', reflect: true })
  isAppPlatform = false;
  @state()
  internalUp = false;
  @state()
  internalRobotAlert?: RobotAlertItem;
  @property({ type: String })
  usernameAsPropertySuffix = '';
  @property({ type: Array })
  ignoredRobotAlerts: any[] = [];
  @property({ type: Number })
  organizationId: number | null = 0;
  @property({ type: Object })
  functionsByTemplateId: Map<number, FunctionViewModel> = new Map<number, FunctionViewModel>();
  private gesture: GestureType[] = ['peekRight', 'surprised'];

  onIgnoreRobot() {
    this.dispatchEvent(new CustomEvent('ignore-robot', { composed: true, bubbles: true, detail: {} }));
  }

  onIgnoreThisRobotAlert() {
    if (this.currentRobotAlert) {
      this.dispatchEvent(
        new CustomEvent('ignore-this-robot-alert', {
          composed: true,
          bubbles: true,
          detail: { id: this.currentRobotAlert.id },
        }),
      );
    }
  }

  _currentRobotAlertChanged(newAlert: RobotAlertItem | undefined) {
    if (this.internalRobotAlert === undefined || newAlert === undefined) {
      this.internalRobotAlert = newAlert;
      this.internalUp = newAlert !== undefined;
    } else if (newAlert.id !== this.internalRobotAlert.id) {
      this.internalUp = false;
      setTimeout(() => {
        this.internalRobotAlert = newAlert;
        this.internalUp = true;
      }, 1000);
    } else {
      this.internalRobotAlert = newAlert;
    }
  }

  _actionClick(e: CustomEvent, action: RobotAlertRuleAction) {
    if (action.href === '') {
      e.preventDefault();
    }
    if ('action' in action && action.action.type === 'ASSIGN_FUNCTION') {
      const f = this.functionsByTemplateId.get(action.action.payload.templateId);
      if (f !== undefined) {
        this.dispatchEvent(
          new CustomEvent<{ functionUuid: string; employeeUuid: string }>('assign-function', {
            composed: true,
            bubbles: true,
            detail: { functionUuid: f.uuid, employeeUuid: action.action.payload.employeeId },
          }),
        );
      }
    }
  }

  render() {
    return this.internalRobotAlert === undefined
      ? nothing
      : html`
          <d-expansion ?opened=${this.internalUp}>
            <div id="wrapper">
              <d-spinner-robot size="48" .gesture=${this.gesture}></d-spinner-robot>
              <div id="calloutWrapper">
                <div id="callout">
                  <div class="message">${this.internalRobotAlert.message}</div>
                  <div class="actions">
                    ${this.internalRobotAlert.actions.map(
                      (action) =>
                        html`<d-action
                          mini
                          href="${action.href}"
                          @click=${(e: CustomEvent) => this._actionClick(e, action)}
                          >${action.title}</d-action
                        >`,
                    )}
                    <d-action mini @click=${this.onIgnoreRobot}>Ikke nå</d-action>
                    <d-action mini @click=${this.onIgnoreThisRobotAlert}>Ikke vis dette igjen</d-action>
                  </div>
                </div>
              </div>
            </div>
          </d-expansion>
        `;
  }

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('currentRobotAlert')) {
      this._currentRobotAlertChanged(this.currentRobotAlert);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-robot-alert': DRobotAlert;
  }
}
