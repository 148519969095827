/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendMeetingOccurrenceReportCommand
 */
export interface SendMeetingOccurrenceReportCommand {
  /**
   *
   * @type {string}
   * @memberof SendMeetingOccurrenceReportCommand
   */
  uuid: string;
  /**
   * En valgfri melding som inkluderes i e-posten.
   * @type {string}
   * @memberof SendMeetingOccurrenceReportCommand
   */
  message: string;
  /**
   * Send ved referatet i selve e-posten.
   * @type {boolean}
   * @memberof SendMeetingOccurrenceReportCommand
   */
  includeReport: boolean;
}

/**
 * Check if a given object implements the SendMeetingOccurrenceReportCommand interface.
 */
export function instanceOfSendMeetingOccurrenceReportCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'message' in value;
  isInstance = isInstance && 'includeReport' in value;

  return isInstance;
}

export function SendMeetingOccurrenceReportCommandFromJSON(json: any): SendMeetingOccurrenceReportCommand {
  return SendMeetingOccurrenceReportCommandFromJSONTyped(json, false);
}

export function SendMeetingOccurrenceReportCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendMeetingOccurrenceReportCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    message: json['message'],
    includeReport: json['includeReport'],
  };
}

export function SendMeetingOccurrenceReportCommandToJSON(value?: SendMeetingOccurrenceReportCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    message: value.message,
    includeReport: value.includeReport,
  };
}
