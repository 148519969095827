import { css, html, nothing, PropertyValues } from 'lit';

import 'src/library/elements/d-section';
import 'src/library/components/d-sortable';
import 'src/library/editors/elements/d-checkbox';
import 'src/library/editors/components/d-edit-organizational-signees';
import 'src/library/editors/components/d-edit-signees';
import 'src/library/fields/d-view-info';
import 'src/library/elements/d-label';
import 'src/library/editors/elements/d-select-date';
import 'src/library/editors/elements/d-edit-textarea';

import { customElement, property, query } from 'lit/decorators.js';
import type { DPopup } from 'src/library/components/d-popup';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import * as dabihStore from 'src/store';
import { LocalDate } from 'src/utilities/local-date';

export interface PersonForSigning {
  uuid: string;
  personalIdentifier: string | undefined;
  email: string | undefined;
  firstName: string;
  lastName: string;
  type?: 'organization' | 'partner' | 'employee';
  partnerUuid?: string;
}

export interface PartnerForSigning {
  uuid: string;
  name: string;
  contacts: PersonForSigning[];
}

export interface SigneesForPartners {
  uuid: string;
  name: string;
  signees: string[];
}

export interface ContentItem {
  type: string;
  value: string;
}

export interface RequestSignaturesInput {
  organizationName: string;
  hasSigningModule: boolean;
  currentUserIsOwner: boolean;
  activeEmployeesCount: number;
  entityTypeName: string;
  entityName: string;
  hasTextContent: boolean;
  attachments: SelectTagOption[];
  availableSigneesForOrganization: PersonForSigning[];
  contractPartyPartners: PartnerForSigning[];
  contractPartyEmployees: PersonForSigning[];
  contractPartyContacts: PersonForSigning[];
  showOrganization: boolean;
}

export interface RequestSignaturesSendResult {
  action: 'send';
  content: ContentItem[];
  signeesForOrganization: string[];
  signeesForPartners: { partnerUuid: string; uuid: string }[];
  signeesAsEmployees: string[];
  message: string;
  dueDate: LocalDate;
}

export type RequestSignaturesResult = RequestSignaturesSendResult | DialogCancelResult;

/**
 *
 * USAGE:
 *
 * STATUS OK
 */
@customElement('request-signatures-dialog')
export class RequestSignaturesDialog extends BaseDialog<RequestSignaturesInput, RequestSignaturesResult> {
  static styles = [
    ...BaseDialog.styles,
    css`
      .promo-image {
        margin: -20px -20px -12px -20px;
        width: calc(100% + 40px);
      }

      .promo {
        font-family: var(--mainSerif);
        font-size: 16px;
        line-height: 150%;
      }

      .promo a {
        color: var(--themeColor);
        outline: none;
      }

      .promo a:hover {
        color: black;
      }

      .promo p {
        margin: 0.5em 0;
      }

      .promo h1 {
        font-weight: 500;
        font-size: 28px;
      }

      .promo p.center {
        margin: 1em 0;
        text-align: center;
      }

      .promo .button {
        display: inline-block;
        border-radius: 6px;
        padding: 10px 14px;
        background: var(--themeColor);
        color: white;
        font-family: var(--mainSans);
        text-decoration: none;
        cursor: pointer;
      }

      .promo .button:hover {
        background: var(--themeColorDarkerTwo);
      }

      .split-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
      }

      .split-wrap > * {
        flex-grow: 1;
      }

      .split-wrap > *:first-child {
        text-align: left;
      }

      .split-wrap > *:last-child {
        display: flex;
        flex-direction: row-reverse;
      }
    `,
  ];
  @property({ type: Boolean })
  hasSigningModule = false;
  @property({ type: Boolean })
  showOrganization = false;
  @property({ type: Number })
  activeEmployeesCount = 0;
  @property({ type: Boolean })
  currentUserIsOwner = false;
  @property({ type: String })
  entityTypeName = '';
  @property({ type: String })
  entityName = '';
  @property({ type: String })
  deadline = '';
  @property({ type: String })
  organizationName = '';
  // selectable for signing
  @property({ type: Array })
  availableSigneesForOrganization: PersonForSigning[] = [];
  @property({ type: Array })
  contractPartyPartners: PartnerForSigning[] = [];
  @property({ type: Array })
  contractPartyEmployees: PersonForSigning[] = [];
  @property({ type: Array })
  contractPartyContacts: PersonForSigning[] = [];
  // selected for signing
  @property({ type: Array })
  signeesForOrganization: string[] = [];
  @property({ type: Array })
  signeesForPartners: SigneesForPartners[] = [];
  @property({ type: Array })
  signeesAsEmployees: string[] = [];
  @property({ type: Array })
  signeesAsContacts: string[] = [];
  @property({ type: Boolean })
  hasTextContent = false;
  @property({ type: Boolean })
  includeTextContent = false;
  @property({ type: Array })
  attachments: SelectTagOption[] = [];
  @property({ type: Array })
  selectedAttachments: string[] = [];
  @property({ type: Array })
  contentSortOrder: ContentItem[] = [];
  @property({ type: Array })
  paths: { href: string }[] = [];
  @property({ type: String })
  message = '';
  @property({ type: Boolean })
  editingSomewhere = false;
  @query('d-popup')
  popup!: DPopup;
  width = 600;
  title = 'Signeringsforespørsel';

  private price = 20;
  private info =
    '<p>Send en forespørsel for å få dette dokumentet signert med BankID. ' +
    'Hvis du ikke har fødselsnummeret til en mottaker er det ditt ansvar at dokumentet blir signert av rett person.</p>';

  get contentItems() {
    const result: { type: string; uuid: string; name: string; checked: boolean }[] = [];
    if (this.hasTextContent) {
      result.push({
        type: 'textContent',
        uuid: '',
        name: 'Avtaletekst',
        checked: this.includeTextContent,
      });
    }
    this.attachments.forEach((a) => {
      result.push({
        type: 'attachment',
        uuid: a.value,
        name: a.text,
        checked: this.selectedAttachments.includes(a.value),
      });
    });
    return result;
  }

  protected get calculatedHeaderActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.sendDisabled },
    ];
  }

  private get promoText() {
    return `
<h1>Elektronisk signering i TrinnVis</h1>
<p>I TrinnVis kan avtaler signeres elektronisk med BankID.</p>
<p>Elektronisk signatur er like gyldig som signatur på papir. Du trenger bare epostadressen til de som skal signere, resten tar systemet seg av. </p>
<p><a href="#">Les mer i brukerstøtte</a></p>
<p>Signeringsmodulen koster kr. ${this.price * this.activeEmployeesCount},- per måned<br>
(kr ${this.price},- ganger antall aktive personer i virksomheten)</p>`;
  }

  private get noValidRecipientsSelected() {
    const validEmployeeRecipients = this.availableSigneesForOrganization.filter((employee) => {
      return (
        (this.signeesForOrganization.includes(employee.uuid) || this.signeesAsEmployees.includes(employee.uuid)) &&
        dabihStore.isEmailValid(employee.email || '')
      );
    });
    const contactsWithValidEmail: string[] = [];
    this.contractPartyPartners.forEach((partner) => {
      partner.contacts.forEach((contact) => {
        if (dabihStore.isEmailValid(contact.email || '')) {
          contactsWithValidEmail.push(contact.uuid);
        }
      });
    });
    const validContactRecipients: string[] = [];
    this.signeesForPartners.forEach((partner) => {
      partner.signees.forEach((signee) => {
        if (contactsWithValidEmail.includes(signee)) {
          validContactRecipients.push(signee);
        }
      });
    });
    return validEmployeeRecipients.length + validContactRecipients.length === 0;
  }

  private get noContentSelected() {
    return !this.includeTextContent && this.selectedAttachments.length === 0;
  }

  private get sendDisabled() {
    return this.noContentSelected || this.noValidRecipientsSelected || this.editingSomewhere;
  }

  private get sortedContent() {
    return this.contentSortOrder.filter((c) => {
      return (c.type === 'textContent' && this.includeTextContent) || this.selectedAttachments.includes(c.value);
    });
  }

  private get partnerSigneesForFetch() {
    const result: { partnerUuid: string; uuid: string }[] = [];
    this.signeesForPartners.forEach((partner) => {
      partner.signees.forEach((signee) => {
        result.push({
          partnerUuid: partner.uuid,
          uuid: signee,
        });
      });
    });
    this.signeesAsContacts.forEach((value) => {
      result.push({
        partnerUuid: '',
        uuid: value,
      });
    });
    return result;
  }

  onContentOrderChange(e) {
    e.stopPropagation();
    if (e.detail.newIndex !== e.detail.oldIndex) {
      this.contentSortOrder = this.arrayMove([...this.contentSortOrder], e.detail.oldIndex, e.detail.newIndex);
    }
  }

  selectedContentChanged(e, item) {
    e.stopPropagation();
    const checked = e.detail.checked;
    if (item.type === 'textContent') {
      this.includeTextContent = checked;
    }
    if (item.type === 'attachment' && item.uuid) {
      if (!checked && this.selectedAttachments.includes(item.uuid)) {
        this.selectedAttachments = this.selectedAttachments.filter((a) => {
          return a !== item.uuid;
        });
      }
      if (checked && !this.selectedAttachments.includes(item.uuid)) {
        const selectedAttachments = [...this.selectedAttachments];
        selectedAttachments.push(item.uuid);
        this.selectedAttachments = selectedAttachments;
      }
    }
  }

  signeesForOrganizationChanged(e) {
    e.stopPropagation();
    this.availableSigneesForOrganization = e.detail.value.availablePeople;
    this.signeesForOrganization = e.detail.value.signees;
  }

  signeesForPartersChanged(e, partnerUuid) {
    e.stopPropagation();
    const availablePeople = e.detail.value.availablePeople;
    const signees = e.detail.value.signees;
    this.contractPartyPartners = this.contractPartyPartners.map((partner) => {
      if (partner.uuid === partnerUuid) {
        partner.contacts = availablePeople;
      }
      return partner;
    });
    const signeesForPartners: { uuid: string; name: string; signees: string[] }[] = [];
    let exists = false;
    this.signeesForPartners.forEach((partner) => {
      if (partner.uuid === partnerUuid) {
        exists = true;
        partner.signees = signees;
      }
      if (signees.length) {
        signeesForPartners.push(partner);
      }
    });
    if (!exists && signees.length) {
      signeesForPartners.push({
        uuid: partnerUuid,
        name:
          this.contractPartyPartners.find((partner) => {
            return partner.uuid === partnerUuid;
          })?.name || '',
        signees: signees,
      });
    }
    this.signeesForPartners = signeesForPartners;
  }

  signeesAsEmployeesChanged(e) {
    e.stopPropagation();
    this.contractPartyEmployees = e.detail.value.availablePeople;
    this.signeesAsEmployees = e.detail.value.signees;
  }
  signeesAsContactsChanged(e) {
    e.stopPropagation();
    this.contractPartyContacts = e.detail.value.availablePeople;
    this.signeesAsContacts = e.detail.value.signees;
  }

  signeesForPartner(partnerUuid): string[] {
    const partner = this.signeesForPartners.find((p) => {
      return p.uuid === partnerUuid;
    });
    if (partner) {
      return partner.signees;
    }
    return [];
  }

  onEditModeChanged(e) {
    e.stopPropagation();
    this.editingSomewhere = e.detail.value;
  }

  renderSelectContent() {
    if (this.attachments.length > 1 || (this.attachments.length > 0 && this.hasTextContent)) {
      return html`
        <d-section label="Inkludert innhold"></d-section>
        <d-sortable
          .items=${this.contentItems}
          @order-changed=${(e) => {
            this.onContentOrderChange(e);
          }}
        >
          ${this.contentItems.map((item, index) => {
            return html` <d-checkbox
              slot="${index}"
              wrap-label
              .optionLabel=${item.name}
              .checked=${item.checked}
              @checked-changed=${(e) => this.selectedContentChanged(e, item)}
            ></d-checkbox>`;
          })}
        </d-sortable>
      `;
    } else {
      return nothing;
    }
  }

  renderSelectRecipientsForOrganization() {
    if (this.showOrganization) {
      return html`
        <d-section>
          <d-edit-organizational-signees
            light-label
            label="For ${this.organizationName}"
            placeholder="Velg person"
            .signees=${this.signeesForOrganization}
            .availablePeople=${this.availableSigneesForOrganization}
            .editingSomewhere=${this.editingSomewhere}
            @value-changed=${(e) => this.signeesForOrganizationChanged(e)}
            @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
          ></d-edit-organizational-signees>
        </d-section>
      `;
    } else {
      return nothing;
    }
  }

  renderSelectRecipientsForPartners() {
    return html`
      ${this.contractPartyPartners.map(
        (partner) => html`
          <d-section>
            <d-edit-organizational-signees
              light-label
              label="For ${partner.name}"
              placeholder="Velg kontaktperson"
              .signees=${this.signeesForPartner(partner.uuid)}
              .availablePeople=${partner.contacts}
              .addPersonText=${'Opprett ny kontaktperson'}
              .partnerUuid=${partner.uuid}
              .editingSomewhere=${this.editingSomewhere}
              @value-changed=${(e) => this.signeesForPartersChanged(e, partner.uuid)}
              @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
            ></d-edit-organizational-signees>
          </d-section>
        `,
      )}
    `;
  }

  renderSelectEmployeeSignees() {
    if (this.contractPartyEmployees.length) {
      return html`
        <d-section>
          <d-edit-signees
            light-label
            label="Personale"
            .signees=${this.signeesAsEmployees}
            .availablePeople=${this.contractPartyEmployees}
            .editingSomewhere=${this.editingSomewhere}
            @value-changed=${(e) => this.signeesAsEmployeesChanged(e)}
            @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
          ></d-edit-signees>
        </d-section>
      `;
    }
    return nothing;
  }
  renderSelectContactSignees() {
    if (this.contractPartyContacts.length) {
      return html`
        <d-section>
          <d-edit-signees
            light-label
            label="Eksterne kontaktpersoner"
            .signees=${this.signeesAsContacts}
            .availablePeople=${this.contractPartyContacts}
            .editingSomewhere=${this.editingSomewhere}
            @value-changed=${(e) => this.signeesAsContactsChanged(e)}
            @edit-mode-changed=${(e) => this.onEditModeChanged(e)}
          ></d-edit-signees>
        </d-section>
      `;
    }
    return nothing;
  }

  renderSelectRecipients() {
    return html`
      <d-section label="Mottakere"></d-section>
      <div>
        ${this.renderSelectRecipientsForOrganization()} ${this.renderSelectRecipientsForPartners()}
        ${this.renderSelectEmployeeSignees()} ${this.renderSelectContactSignees()}
      </div>
    `;
  }

  renderBody() {
    if (this.hasSigningModule) {
      return html`
        <d-section>
          <d-view-info .content=${this.info}></d-view-info>
        </d-section>
        ${this.renderSelectContent()} ${this.renderSelectRecipients()}
        <d-section>
          <div class="split-wrap">
            <d-label big label="Melding til mottakere"></d-label>
            <div>
              <d-select-date
                inline-label
                light-label
                label="Frist for signering"
                .value=${this.deadline}
              ></d-select-date>
            </div>
          </div>
        </d-section>
        <d-section topless>
          <d-edit-textarea
            max-rows="50"
            placeholder="Melding/kommentar"
            value="${this.message}"
            @value-changed=${(e) => {
              this.message = e.detail.value;
            }}
          ></d-edit-textarea>
        </d-section>
      `;
    }
    return html`
      <div class="promo">
        <div>${unsafeHTML(this.promoText)}</div>
        ${this.currentUserIsOwner
          ? html`<p>Modulen kan tas i bruk straks du har bekreftet bestillingen ved å trykke på knappen nedenfor.</p>
              <p class="center">
                <span
                  class="button"
                  @click=${() => {
                    this.hasSigningModule = true;
                    this.cancelOnOutsideClick = false;
                  }}
                  >Ta i bruk signeringsmodulen</span
                >
              </p>
              <p class="center">
                <a
                  href="#"
                  @click=${(e) => {
                    e.preventDefault();
                    this.close();
                  }}
                  >Nei takk</a
                >
              </p> `
          : html`<p>Det er bare administrator for TrinnVis-kontoen som kan oppgradere abonnementet.</p> `}
      </div>
    `;
  }

  renderHeader() {
    if (this.hasSigningModule) {
      return super.renderHeader();
    }
    return html`<img src="/images/elektronisk-signering.png" class="promo-image" />`;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.deadline = LocalDate.now().plusDays(14).toString();
    this.includeTextContent = this.hasTextContent;
    this.selectedAttachments = this.attachments.map((a) => {
      return a.value;
    });
    const contentSortOrder: ContentItem[] = [];
    if (this.includeTextContent) {
      contentSortOrder.push({ type: 'textContent', value: '' });
    }
    this.attachments.forEach((a) => {
      contentSortOrder.push({ type: 'attachment', value: a.value });
    });
    this.contentSortOrder = contentSortOrder;
    this.signeesForPartners = this.contractPartyPartners
      .filter((partner) => {
        return partner.contacts.length;
      })
      .map((partner) => {
        return {
          uuid: partner.uuid,
          name: partner.name,
          signees: [partner.contacts[0].uuid],
        };
      });
    this.signeesAsEmployees = this.contractPartyEmployees
      .filter((e) => {
        return e.email && dabihStore.isEmailValid(e.email);
      })
      .map((e) => {
        return e.uuid;
      });
    this.signeesAsContacts = this.contractPartyContacts
      .filter((e) => {
        return e.email && dabihStore.isEmailValid(e.email);
      })
      .map((e) => {
        return e.uuid;
      });
  }

  protected fetchResult(detail: string | undefined): RequestSignaturesResult {
    if (detail === 'cancel') {
      return {
        action: 'cancel',
      };
    } else
      return {
        action: 'send',
        content: this.sortedContent,
        signeesForOrganization: this.signeesForOrganization,
        signeesForPartners: this.partnerSigneesForFetch,
        signeesAsEmployees: this.signeesAsEmployees,
        message: this.message,
        dueDate: LocalDate.fromString(this.deadline),
      };
  }

  protected initializeDialog(input: RequestSignaturesInput) {
    this.organizationName = input.organizationName;
    this.hasSigningModule = input.hasSigningModule;
    this.currentUserIsOwner = input.currentUserIsOwner;
    this.activeEmployeesCount = input.activeEmployeesCount;
    this.cancelOnOutsideClick = !input.hasSigningModule;
    this.entityTypeName = input.entityTypeName;
    this.entityName = input.entityName;
    this.hasTextContent = input.hasTextContent;
    this.attachments = input.attachments;
    this.availableSigneesForOrganization = input.availableSigneesForOrganization;
    this.contractPartyPartners = input.contractPartyPartners;
    this.contractPartyEmployees = input.contractPartyEmployees;
    this.contractPartyContacts = input.contractPartyContacts;
    this.showOrganization = input.showOrganization;
  }

  private arrayMove(arr: ContentItem[], fromIndex: number, toIndex: number): ContentItem[] {
    const item: ContentItem = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, item);
    return arr;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'request-signatures-dialog': RequestSignaturesDialog;
  }
}
