import { css, html, nothing, PropertyValues, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators.js';
import _, { isEqual, sortBy } from 'lodash';
import { levelContentStyles } from 'src/library/level-content-styles';
import { LocalDate } from 'src/utilities/local-date';
import { LocalDateTime } from 'src/utilities/local-date-time';
import { isEmptyHtmlContent, joinWithAnd } from 'src/utilities/text';
import type { FormViewItem, FormViewItemSizeType } from '../library/components/d-form-view';
import { FormViewBuilder } from '../library/components/d-form-view';
import { ActionInput } from '../library/elements/d-action';
import '../library/elements/d-wrap.js';
import type { AttachmentItem } from '../library/lists/d-list-section-attachment';
import '../library/lists/d-list-section-item';
import { sorted } from '../store/utilities';

import '../library/components/d-form-view';
import '../library/components/d-field-section';
import '../library/elements/d-section';
import '../library/editors/components/d-edit-classification';
import '../library/editors/elements/d-select-tag';
import '../library/lists/d-list-section';
import './d-entity-diff';
import '../library/promo/d-promo-section';
import '../library/components/d-help-section';
import '../library/lists/d-list-section-attachment';
import '../library/fields/d-spinner';
import './d-entity-header';
import './d-update-section';

import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { DocForLinking } from 'src/models/internal-linking.js';
import { ResponsiveLevel } from 'src/library/elements/responsive-level';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { OrganizationViewModelSpecialTermsEnum } from 'src/store/api';
import { features, FeatureStates, promo, Promo } from 'src/store/selectors/features';
import { SectionField } from 'src/library/components/d-field-section';

export interface Revision {
  dateTime: string;
  changedBy: string;
  status: string;
  name: string;
  content: string;
  differences: { name: string; content: string };
}

export interface CommonDataEntityView {
  today: LocalDate;
  currentUserHasWriteAccess: boolean;
  specialTerms: OrganizationViewModelSpecialTermsEnum | undefined;
  featureStates: FeatureStates;
  sector: string;
  employeesCount: number;
  currentUserIsEmployee: boolean;
  accessControlOptions: SelectDropdownOption[];
}

export interface AbstractEntityView<T> extends CommonDataEntityView {
  type: string;
  uuid: string;
  isConfirmedEntity: boolean;
  name: string;
  attachments: AttachmentItem[];
  deleted: boolean;
  deletable: boolean;
  parentHref: string;
  href: string;
  pdfHref: string;
  helpContent: string;
  currentUserHasAccess: boolean;
  hasDraft?: boolean;
  fetchDraft: () => Promise<T>;
  docsForLinking: DocForLinking[];
  availablePages?: SelectDropdownOption[];
}

export interface AbstractEntityViewWithRevisions<T> extends AbstractEntityView<T> {
  revisions: Revision[];
}

/**
 * Enhances the form view builder with business specific methods.
 */
export class ContentViewBuilder extends FormViewBuilder {
  addClassification(
    classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE',
    accessControl: string[],
    accessControlOptions: { value: string; text: string }[],
  ) {
    if (classification !== 'NONE') {
      let s = accessControlOptions
        .filter(function (x) {
          return (accessControl ?? []).indexOf(x.value) >= 0;
        })
        .map(function (x) {
          return x.text;
        });
      s = sortBy(s, (e) => e.toLowerCase());
      const text = joinWithAnd(s);

      this.addText('generalFields_access', text, 'l', true);
    }
  }

  addExpandableHtmlContent(field: string, content: string, sublabel = '') {
    this.addExpandableHtml(field, content, sublabel);
  }

  addHtmlContent(field: string, content: string, sublabel = '', preventChecklists = true) {
    this.addHtml(field, content, sublabel, preventChecklists);
  }

  addHtmlContentIfNotEmpty(field: string, content: string, preventChecklists = true) {
    if (content != '') {
      this.addHtmlContent(field, content, '', preventChecklists);
    }
  }

  addDate(field: string, value: string, size: 's' | 'm' | 'l' = 'm') {
    const text = value ? LocalDate.fromString(value).toStringForDisplay() : '';
    this.addText(field, text, size, true);
  }

  addTextLookup(field: string, value: string, size: FormViewItemSizeType, lookup: Map<string, string>) {
    this.addTextIfNotEmpty(field, lookup.get(value), size);
  }
}

export function contentViewBuilder(): ContentViewBuilder {
  return new ContentViewBuilder();
}

export function toLookup(entries: { [p: string]: string }): Map<string, string> {
  return new Map<string, string>(Object.entries(entries));
}

/**
 *
 * USAGE:
 *    d-meeting-event-view
 *    d-task-view
 *
 *
 */
export abstract class EntityContent<
  T extends AbstractEntityView<U> | AbstractEntityViewWithRevisions<U>,
  U,
> extends ResponsiveLevel {
  static readonly styles = [
    levelContentStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 0;
        transition: padding-bottom 0.3s;
      }

      .meta-section {
        border: 1px solid var(--borderColor);
        border-bottom: none;
        background: hsl(0, 0%, 96%);
        padding: 14px 18px;
      }

      .related-task d-action {
        margin: -6px -4px;
      }

      .related-task + d-view-occurrences {
        margin-top: 6px;
      }

      .event-time {
        margin-top: 24px;
        margin-bottom: 16px;
        font-family: var(--mainSans);
        font-size: 25.6px;
        font-weight: 400;
      }

      :host([covered]) .content {
        overflow: auto;
        direction: rtl;
      }

      d-section > d-action:first-child {
        margin-left: -6px;
      }

      .button {
        display: inline-block;
        padding: 8px 12px;
        background-color: var(--themeColor);
        color: white;
        font-family: var(--mainSans), sans-serif;
        border-radius: 6px;
        cursor: pointer;
      }

      .button:hover {
        background-color: var(--themeColorDarkerOne);
      }

      d-list-section:not(.related-assets) {
        margin-bottom: 6px;
      }

      .delayedFadeIn {
        animation-name: fadeIn;
        animation-duration: 400ms;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-delay: 600ms;
        animation-fill-mode: forwards;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `,
  ];
  /**
   * The href of the current item displayed. Used as context for relative links
   */
  @property({ type: String })
  href = '';
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Number })
  level = 0;
  @property({ type: String })
  parentId = '';
  @property({ type: String })
  parentEntityType = '';
  @property({ type: String })
  afterDeleteHref = '';
  @property({ type: Number })
  pageId = 0;
  @property({ type: String })
  editHref = '';
  @property({ type: String })
  icon = '';
  @property({ type: String })
  labelForTaskEvent = '';
  @property({ type: Boolean })
  updateSectionOpened = false;
  @property({ type: String })
  helpContentForEvent = '';
  @property({ type: String })
  helpContent = '';
  @property({ type: String })
  today = '';
  @property({ type: String })
  helpContentComputed = '';
  @property({ type: Boolean })
  hasHelp = false;
  @property({ type: Boolean })
  helpOpened = false;
  @property({ type: Number })
  templateId = 0;
  @property({ type: String })
  revisionsUrl = '';
  @property({ type: String })
  mode = 'normal';
  @property({ type: String })
  deleteWarning = 'Slettede elementer kan du finne igjen i Papirkurven.\nEr du sikker på at du vil slette elementet?';
  @property({ type: Boolean })
  noView = false;
  @property({ type: Boolean })
  itemLocked = false;
  @property({ type: Boolean })
  duplicatable = false;
  @property({ type: Boolean })
  eventDone = false;
  @property({ type: Array })
  activityCodes = [];
  @property({ type: String })
  token = '';
  @property({ type: Object })
  revision?: Revision;
  @property({ type: Object })
  task = {};
  @property({ type: String })
  key = '';
  @property({ type: String })
  currentEntityType?: string = '';
  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: Boolean })
  isDoneDisabled = false;
  @property({ type: String })
  parentHref = '';
  @property({ type: Object })
  entityView!: T;
  @property({ type: Boolean })
  covered = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Number })
  setScrolltop = 0;
  @property({ type: Boolean, attribute: 'prevent-header-shrink' })
  preventHeaderShrink = false;
  @state()
  protected editItem?: U;
  protected previousEditItem?: {
    editItem: U;
    entityUuid: string;
  };

  @state()
  private savingDraft = false;
  private editItemLocation = '';
  private interval?: NodeJS.Timer;
  private debouncedDoBackup = _.debounce(() => this.onBackup(), 500, { maxWait: 5000 });

  get helpOnly() {
    return false;
  }

  protected get currentItemLabel() {
    return this.entityView.name ?? '';
  }

  protected get currentItemSecondaryLabel() {
    return '';
  }

  protected get currentItemSublabel() {
    return '';
  }

  protected get preventDelete(): boolean {
    return false;
  }

  protected get viewItems(): FormViewItem[] {
    return [];
  }

  protected get internalMode() {
    if (this.mode === 'saving') return 'saving';
    return this.noView ? 'edit' : this.mode;
  }

  protected get hideEditHeader() {
    return false;
  }

  protected get editName() {
    if (this.editItem) {
      const editItem = this.editItem as { name?: string; firstName?: string; lastName?: string };
      if (editItem.firstName || editItem.lastName) {
        return editItem.firstName + ' ' + editItem.lastName;
      }
      if (editItem.name) {
        return editItem.name;
      }
    }
    return '';
  }

  private get hasEditAccess() {
    return this.entityView.currentUserHasWriteAccess && !this.entityView.deleted;
  }

  private get promoIdForAssets(): string | undefined {
    if (this.entityView.featureStates.assets || this.entityView.featureStates.complete) {
      return undefined;
    }
    return 'assets';
  }

  private get promoIdForSubstances(): string | undefined {
    if (this.entityView.featureStates.substances || this.entityView.featureStates.complete) {
      return undefined;
    }
    return 'substances';
  }

  private get promoIdForMeetings(): string | undefined {
    if (this.entityView.featureStates.meetings || this.entityView.featureStates.complete) {
      return undefined;
    }
    return 'meetings';
  }

  private get promoIdForIssues(): string | undefined {
    if (this.entityView.featureStates.issues || this.entityView.featureStates.complete) {
      return undefined;
    }
    return 'issues';
  }

  private get promoIdForEmployees(): string | undefined {
    if (
      this.entityView.featureStates.meetings ||
      this.entityView.featureStates.issues ||
      this.entityView.featureStates.core
    ) {
      return undefined;
    }
    return 'issues';
  }

  private get promoId(): string | undefined {
    if (this.entityView.type === 'assets') {
      return this.promoIdForAssets;
    }
    if (this.entityView.type === 'substances') {
      return this.promoIdForSubstances;
    }
    if (this.entityView.type === 'meetingOccurrences' || this.entityView.type === 'meetings') {
      return this.promoIdForMeetings;
    }
    if (this.entityView.type === 'issues') {
      return this.promoIdForIssues;
    }
    if (this.entityView.type === 'employees') {
      return this.promoIdForEmployees;
    }
    if (
      this.entityView.type === 'partners' ||
      this.entityView.type === 'contacts' ||
      this.entityView.type === 'reports'
    ) {
      if (this.entityView.featureStates.meetings || this.entityView.featureStates.core) {
        return undefined;
      }
      return 'complete';
    }
    if (!this.entityView.featureStates.core) {
      return 'complete';
    }
    return undefined;
  }

  private get promoData(): Promo | undefined {
    if (this.promoId) {
      return promo.find((p) => {
        return p.id === this.promoId;
      });
    }
    return undefined;
  }

  _modeChange() {
    this.helpOpened = false;
  }

  _normalMode(mode) {
    return mode === 'normal';
  }

  _hideSuggestedRevision(mode) {
    return mode !== 'normal';
  }

  _helpSectionOpened(helpOnly, helpOpened, mode, templateId) {
    return mode === 'normal' && (helpOnly || helpOpened || templateId === 7896);
  }

  async onEditModeOn() {
    this.editItem = undefined;
    await this.doInitializeEditItem();
    const e = this.editItem;
    if (e !== undefined) {
      this.previousEditItem = {
        editItem: e,
        entityUuid: this.entityView.uuid,
      };
    }
    this.dispatchEvent(new CustomEvent('edit', { bubbles: true, composed: true }));
    this.mode = 'edit';
  }

  onEditModeOff() {
    if (!this.entityView.isConfirmedEntity || this.noView) {
      this.dispatchEvent(new CustomEvent('cancel-new', { bubbles: true, composed: true }));
    }
    this.mode = 'normal';
    this.dispatchEvent(new CustomEvent('cancel-edit', { bubbles: true, composed: true }));
    this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
    this.editItem = undefined;
  }

  _helpContent(helpContent, helpContentForTaskEvent) {
    if (helpContentForTaskEvent) {
      return helpContentForTaskEvent;
    }
    if (helpContent) {
      return helpContent;
    }
  }

  async _saveAsNew() {
    if (!this.isDoneDisabled) {
      if (this.editItem === undefined) {
        await this.doInitializeEditItem();
      }
      this.dispatchEvent(
        new CustomEvent('save-as-new', {
          bubbles: true,
          composed: true,
          detail: {
            editItem: { ...this.editItem, name: this.entityView.name + ' (kopi)' },
          },
        }),
      );
    }
  }

  async onDone() {
    console.log('onDone()');
    if (!this.isDoneDisabled && this.editItem !== undefined) {
      const editItemToSave = await this.saveEditItem(this.editItem);

      if (editItemToSave !== undefined) {
        this.mode = 'saving';
        console.log('saving');

        this.dispatchEvent(
          new CustomEvent('done', {
            bubbles: true,
            composed: true,
            detail: {
              editItem: editItemToSave,
              afterSave: async () => {
                console.log('DONE2');
                this.editItem = undefined;
                if (this.noView) {
                  this.dispatchEvent(
                    new CustomEvent<{ href: string }>('navigate', {
                      bubbles: true,
                      composed: true,
                      detail: { href: this.entityView.parentHref },
                    }),
                  );
                } else {
                  this.mode = 'normal';
                  this.editItem = undefined;
                  this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
                }
              },
            },
          }),
        );
        this.editItem = undefined;
        console.log('DONE');
      }
    }
  }

  _resetRevisionsMode() {
    if (this.mode === 'diff') {
      this.mode = 'normal';
    }
  }

  _revisionsAreSame(a, b) {
    return a.name === b.name && a.content === b.content;
  }

  _findSuggested(revisionsInfo) {
    const rev = revisionsInfo.filter(function (i) {
      return i.status === 'SUGGESTED';
    });

    if (rev.length > 0) {
      return rev[0];
    }
  }

  _findCurrent(revisionsInfo) {
    const rev = revisionsInfo.filter(function (i) {
      return i.status === 'CURRENT';
    });

    if (rev.length > 0) {
      return rev[0];
    }
  }

  _sortRevisions(a, b) {
    if (!(a.dateTime && b.dateTime)) {
      return 0;
    }
    if (!b.dateTime) {
      return -1;
    }
    if (!a.dateTime) {
      return 1;
    }
    return b.dateTime.localeCompare(a.dateTime);
  }

  _formatRevisionLabel(date, changedBy, status, singleUserVersion) {
    const formattedDate = LocalDateTime.fromString(date).toStringForDisplay();
    let formattedStatus;
    let changedByText = changedBy;
    if (singleUserVersion && changedBy !== 'TrinnVis-teamet') {
      changedByText = 'Jeg';
    }
    switch (status) {
      case 'CURRENT':
        formattedStatus = ' – Gjeldende versjon';
        break;
      case 'ARCHIVED':
        formattedStatus = '';
        break;
      case 'SUGGESTED':
        formattedStatus = ' – Foreslått oppdatering';
        break;
      case 'DELETED':
        formattedStatus = ' – Foreslått sletting';
        break;
      default:
        formattedStatus = '';
    }

    return formattedDate + ' ' + changedByText + formattedStatus;
  }

  _revisionTemplateDeleted(status: string) {
    return status === 'SUGGESTED';
  }

  _revisionTemplateUpdated(status) {
    return status === 'SUGGESTED';
  }

  _toDiffMode(e) {
    this.revision = e.detail.clickData;
    this.mode = 'diff';
  }

  async onViewSuggestion() {
    if ('revisions' in this.entityView && !this.entityView.deleted) {
      const r = this.entityView.revisions;
      const s = r.find((x) => x.status === 'SUGGESTED');
      if (s !== undefined) {
        this.revision = s;
        this.mode = 'diff';
      }
    }
  }

  _isCurrent(status: string) {
    return status === 'CURRENT';
  }

  _restore() {
    this.dispatchEvent(
      new CustomEvent<{ entityType: string; entityId: string }>('restore', {
        bubbles: true,
        composed: true,
        detail: {
          entityType: this.entityView.type,
          entityId: this.entityView.uuid,
        },
      }),
    );
  }

  _keep() {
    this._resetRevisionsMode();
  }

  onDelete() {
    if (!this.entityView.deletable) {
      return;
    }
    // TODO: nye dokumenter bør slettes helt, ikke i papirkurven
    if (this.deleteWarning && this.entityView.isConfirmedEntity) {
      if (confirm(this.deleteWarning)) {
        this.dispatchEvent(
          new CustomEvent('entity-delete', {
            bubbles: true,
            composed: true,
            detail: { url: this.afterDeleteHref },
          }),
        );
        this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
      }
    } else {
      this.dispatchEvent(
        new CustomEvent('entity-delete', { bubbles: true, composed: true, detail: { url: this.afterDeleteHref } }),
      );
      this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
    }
  }

  renderViewMode() {
    if (this.viewItems.length) {
      return html` <d-form-view .viewItems=${this.viewItems}></d-form-view>`;
    }
    return html``;
  }

  abstract initializeEditItem(): Promise<void>;

  renderEditItem(_item: U): TemplateResult<1> {
    return html``;
  }

  protected onFieldSectionChanged(e) {
    this.editItem = e.detail.value;
  }

  protected onFieldAction(e) {
    this[e.detail.value]();
  }

  renderFieldSection(entityType: string, fields: SectionField[], item: any) {
    return html`
      <d-field-section
        .entityType=${entityType}
        .fields=${fields}
        .item=${item}
        @item-changed=${(e) => this.onFieldSectionChanged(e)}
        @field-action=${(e) => this.onFieldAction(e)}
      ></d-field-section>
    `;
  }

  protected renderEditClassification(item: any): TemplateResult<1> {
    if (this.entityView.currentUserIsEmployee) {
      return html`
        <d-section>
          <d-edit-classification
            .accessControl=${item.accessControl}
            .accessControlOptions=${this.entityView.accessControlOptions}
            .classification=${item.classification}
            @value-changed=${(e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>) => {
              this.editItem = {
                ...item,
                accessControl: e.detail.accessControl,
                classification: e.detail.classification,
              };
            }}
          ></d-edit-classification>
        </d-section>
      `;
    }
    return html``;
  }

  protected renderEditSelectPages(item: any): TemplateResult<1> {
    if (this.entityView.featureStates.core && this.entityView.availablePages && this.entityView.availablePages.length) {
      return html`
        <d-section>
          <d-select-tag
            label="Vis på temaside"
            not-deselectable
            lock="281"
            .options=${this.entityView.availablePages}
            .value=${item.pages}
            @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
              this.editItem = { ...item, pages: e.detail.value };
            }}
          ></d-select-tag>
        </d-section>
      `;
    }
    return html``;
  }

  renderEditMode() {
    if (this.editItem === undefined) {
      this.doInitializeEditItem().then(() => console.log('Initialized edit item'));
      return html``;
    }
    return html`${this.renderPreEditMode()} ${this.renderEditItem(this.editItem)}
    ${this.renderEditSelectPages(this.editItem)} `;
  }

  renderPreContent() {
    return html``;
  }

  renderPreEditMode() {
    return html``;
  }

  renderAfterListsContent() {
    return html``;
  }

  renderLists() {
    return html``;
  }

  _revisionItems(r) {
    return sorted(r, this._sortRevisions).map((item) => {
      let updateStatus = 'none';
      if (this._revisionTemplateDeleted(item.status)) {
        updateStatus = 'templateDeleted';
      }
      if (this._revisionTemplateUpdated(item.status)) {
        updateStatus = 'templateUpdated';
      }
      const accessible = !this._isCurrent(item.status);
      return {
        label: this._formatRevisionLabel(item.dateTime, item.changedBy, item.status, this.singleUserVersion),
        updateStatus: updateStatus,
        accessible: accessible,
        clickData: item,
      };
    });
  }

  renderRevisionItems(revisions: Revision[]) {
    return html` <d-list-section
      field="generalFields_revisions"
      icon="versions"
      .items=${this._revisionItems(revisions)}
      @item-clicked=${(e) => this._toDiffMode(e)}
    >
    </d-list-section>`;
  }

  onUploadFile(e: CustomEvent<{ file: File; callback: () => void }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ file: File; callback: () => void; type: string; uuid: string }>('upload-file', {
        bubbles: true,
        composed: true,
        detail: {
          ...e.detail,
          type: this.entityView.type,
          uuid: this.entityView.uuid,
        },
      }),
    );
  }

  _setContentStickyTop(height: number) {
    if (this.covered) {
      this.contentStickyTop = 0;
    } else {
      /**
       * Space for status bar when application runs as native app
       */
      const safeAreaInsetTop = Number(
        getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top').replace('px', ''),
      );
      this.contentStickyTop = height + safeAreaInsetTop;
    }
  }

  handleContentScroll(e) {
    this.dispatchEvent(
      new CustomEvent('scrolltop-changed', {
        bubbles: true,
        composed: true,
        detail: e.target.scrollTop,
      }),
    );
  }

  renderMode() {
    switch (this.internalMode) {
      case 'edit':
        return this.renderEditMode();
      case 'normal':
        return this.renderNormalMode();
      case 'diff':
        return this.revision !== undefined
          ? html` <d-entity-diff
              .singleUserVersion=${this.singleUserVersion}
              .revision=${this.revision}
              .templateDeleted=${this.revision.status === 'DELETED'}
              .docsForLinking=${this.entityView.docsForLinking ?? []}
              .contentStickyTop=${this.contentStickyTop}
              @keep=${() => this._keep()}
              @restore-revision=${this.onRestoreRevision}
            ></d-entity-diff>`
          : nothing;
    }
  }

  renderContent() {
    return html`
      <div class="content" id="content">
        ${this.renderHelpSection()} ${this.renderUpdateSection()} ${this.renderStartTaskSection()} ${this.renderMode()}
      </div>
    `;
  }

  render() {
    if (this.promoData) {
      return html`
        ${this.renderHeader()}
        <div class="content" id="content" style="padding-top: 30px">
          <d-promo-section
            .employeesCount=${this.entityView.employeesCount}
            .specialTerms=${this.entityView.specialTerms}
            .promoData=${this.promoData}
            .features=${features}
          ></d-promo-section>
        </div>
      `;
    }
    return html`
      ${this.renderHeader()} ${this.internalMode === 'saving' ? this.renderSaving() : this.renderContent()}
    `;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  onClearDraft() {
    this.dispatchEvent(
      new CustomEvent<{
        entityType: string;
        entityUuid: string;
      }>('clear-draft', {
        composed: true,
        bubbles: true,
        detail: {
          entityType: this.entityView.type,
          entityUuid: this.entityView.uuid,
        },
      }),
    );
    this.onEditModeOff();
  }

  onCloseNewDraft() {
    this.onEditModeOff();
    this.dispatchEvent(
      new CustomEvent<{ href: string }>('navigate', {
        bubbles: true,
        composed: true,
        detail: { href: this.entityView.parentHref },
      }),
    );
  }

  _hideDelete(mode: string, preventDelete, revision) {
    if (mode === 'normal') {
      return preventDelete;
    } else if (mode === 'diff' && revision.status === 'SUGGESTED') {
      return true;
    } else if (mode === 'diff' && revision.status === 'DELETED') {
      return false;
    }
    return true;
  }

  _hideEdit() {
    return (
      !this.hasEditAccess ||
      this.internalMode !== 'normal' ||
      this.itemLocked ||
      this.eventDone ||
      this.entityView.hasDraft
    );
  }

  _hideSaveAsNew() {
    return this.mode !== 'normal' || !this.entityView.isConfirmedEntity || !this.duplicatable;
  }

  _hideShare() {
    return this.singleUserVersion || this.mode === 'edit' || this.mode === 'diff';
  }

  _hidePdf() {
    return this.mode !== 'normal' || this.helpOnly;
  }

  doneDisabled(): boolean {
    return false;
  }

  draftHeaderActions() {
    return [
      {
        name: 'Rediger',
        action: 'edit',
      },
    ];
  }

  editHeaderActions() {
    const actions: ActionInput[] = [];
    if (this.internalMode === 'edit') {
      if (this.entityView.hasDraft) {
        actions.push({
          name: 'Slett utkast',
          action: !this.entityView.isConfirmedEntity ? 'delete' : 'clearDraft',
        });
        if (!this.entityView.isConfirmedEntity) {
          actions.push({
            name: 'Lukk utkast',
            action: 'closeNewDraft',
          });
        } else {
          actions.push({
            name: 'Lukk utkast',
            action: 'cancel',
          });
        }
      } else if (!this.entityView.isConfirmedEntity) {
        actions.push({
          name: 'Avbryt',
          action: 'delete',
        });
      } else {
        actions.push({
          name: 'Avbryt',
          action: 'cancel',
        });
      }
      actions.push({
        name: 'Ferdig',
        action: 'done',
        disabled: this.doneDisabled(),
      });
    }
    return actions;
  }

  trashHeaderActions() {
    return [
      {
        name: 'Gjenopprett',
        action: 'restore',
      },
    ];
  }

  mainHeaderActions() {
    const actions: ActionInput[] = [];
    if (this.internalMode !== 'saving' && this.internalMode !== 'edit' && !this.entityView.deleted) {
      if (!this._hideShare()) {
        actions.push({
          name: 'Del',
          action: 'share',
        });
      }
      if (!this._hideEdit()) {
        actions.push({
          name: 'Rediger',
          action: 'edit',
        });
      }
    }
    if (this.internalMode !== 'saving' && this.internalMode !== 'edit') {
      actions.push({
        name: 'Lukk',
        href: this.parentHref || '#',
      });
    }
    return actions;
  }

  mainHeaderMoreActions() {
    const actions: ActionInput[] = [];
    if (this.internalMode !== 'saving' && this.internalMode !== 'edit' && !this.entityView.deleted) {
      if (!this._hidePdf()) {
        actions.push({
          name: 'Lag en pdf av dokumentet',
          action: 'pdf',
          href: this.entityView.pdfHref,
          target: '_blank',
        });
      }
      if (!this._hideSaveAsNew()) {
        actions.push({
          name: 'Lag kopi av dokumentet',
          action: 'saveAsNew',
        });
      }
      if (
        !this._hideDelete(this.internalMode, this.preventDelete, this.revision) &&
        this.entityView.currentUserHasWriteAccess
      ) {
        actions.push({
          name: 'Slett dokumentet',
          action: 'delete',
          disabled: !this.entityView.deletable,
          classes: 'delete',
        });
      }
    }
    return actions;
  }

  renderHelpSection() {
    return html`
      <d-help-section
        content="${this.entityView.helpContent}"
        help-only="${this.helpOnly}"
        ?opened="${this._helpSectionOpened(this.helpOnly, this.helpOpened, this.mode, this.templateId)}"
        update-section-opened="${this.updateSectionOpened}"
      ></d-help-section>
    `;
  }

  protected async saveEditItem(item: U): Promise<U | undefined> {
    return item;
  }

  protected renderRevisions(): TemplateResult<1> | typeof nothing {
    return 'revisions' in this.entityView && !this.entityView.deleted
      ? this.renderRevisionItems(this.entityView.revisions)
      : nothing;
  }

  protected onShareContent() {
    this.dispatchEvent(new CustomEvent('share-content', { bubbles: true, composed: true, detail: {} }));
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('editItem')) {
      this.debouncedDoBackup();
    }
    if (_changedProperties.has('mode') && this.mode === 'done') {
      this.onDone();
    }
    if (_changedProperties.has('setScrolltop')) {
      const content = this.shadowRoot?.getElementById('content');
      if (content) {
        content.scrollTo({
          top: this.setScrolltop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  protected renderAttachments() {
    return html` <d-list-section-attachment
      .entityType=${this.entityView.type}
      .items=${this.entityView.attachments}
      .writeAccess=${this.entityView.currentUserHasWriteAccess}
      @upload-file=${this.onUploadFile}
      .contentStickyTop=${this.contentStickyTop}
    ></d-list-section-attachment>`;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    if (_changedProperties.has('editItem')) {
      this.debouncedDoBackup();
    }
    const header = this.shadowRoot?.getElementById('header');
    if (header) {
      this.intersectionController.observe(header);
    }
    const content = this.shadowRoot?.getElementById('content');
    if (content) {
      content.addEventListener('scroll', this.handleContentScroll);
    }
  }

  protected getIcon() {
    return this.entityView.type;
  }

  protected abstract asUpdateSectionItem(): UpdateSectionItem | undefined;

  protected async willUpdate() {
    await this.checkForEditMode();
  }

  protected handleAction(_action: string) {}

  protected async checkForEditMode() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);
    if (params.has('edit') && this.entityView.href === location.pathname) {
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
      await this.onEditModeOn();
    }
  }

  private async doInitializeEditItem(): Promise<void> {
    if (this.entityView.hasDraft) {
      this.editItem = await this.entityView.fetchDraft();
    } else {
      await this.initializeEditItem();
    }
  }

  private renderSaving() {
    return html` <div class="content delayedFadeIn" id="content" style="margin-top: 250px; opacity: 0;">
      <d-spinner label="Lagrer ..."></d-spinner>
    </div>`;
  }

  private async onBackup() {
    return new Promise<void>((resolve) => {
      if (
        this.internalMode === 'edit' &&
        this.editItem &&
        !isEqual(
          {
            editItem: this.editItem,
            entityUuid: this.entityView.uuid,
          },
          this.previousEditItem,
        )
      ) {
        this.previousEditItem = { editItem: this.editItem, entityUuid: this.entityView.uuid };
        this.savingDraft = true;
        setTimeout(() => {
          this.savingDraft = false;
        }, 1000);
        this.dispatchEvent(
          new CustomEvent<{
            entityType: string;
            entityUuid: string;
            draft: Record<string, unknown>;
            done: () => void;
          }>('save-draft', {
            composed: true,
            bubbles: true,
            detail: {
              entityType: this.entityView.type,
              entityUuid: this.entityView.uuid,
              draft: this.editItem,
              done: () => {
                console.log('SAVE DRAFT DONE');
                resolve();
              },
            },
          }),
        );
      } else {
        resolve();
      }
    });
  }

  private onRestoreRevision(e: CustomEvent<{ name: string; content: string; status: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ name: string; content: string; status: string; type: string; uuid: string }>(
        'restore-revision',
        {
          bubbles: true,
          composed: true,
          detail: {
            ...e.detail,
            type: this.entityView.type,
            uuid: this.entityView.uuid,
          },
        },
      ),
    );
    this._resetRevisionsMode();
  }

  private onUpdateDeclined(event: CustomEvent) {
    event.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('update-declined', {
        bubbles: true,
        composed: true,
        detail: {
          entityType: this.entityView.type,
          entityId: this.entityView.uuid,
        },
      }),
    );
  }

  private renderNormalMode() {
    if (!this.entityView.currentUserHasAccess) {
      return html``;
    }

    if (this.entityView.deleted) {
      return this.renderViewMode();
    }

    return html`${this.renderPreContent()} ${this.renderViewMode()} ${this.renderLists()}
    ${this.renderAfterListsContent()} ${this.renderAttachments()} ${this.renderRevisions()}`;
  }

  private async handleHeaderAction(e) {
    e.preventDefault();
    await this.debouncedDoBackup.flush();
    switch (e.detail) {
      case 'edit':
        return this.onEditModeOn();
      case 'clearDraft':
        return this.onClearDraft();
      case 'closeNewDraft':
        return this.onCloseNewDraft();
      case 'cancel':
        return this.onEditModeOff();
      case 'delete':
        return this.onDelete();
      case 'saveAsNew':
        return this._saveAsNew();
      case 'done':
        return this.onDone();
      case 'share':
        return this.onShareContent();
      case 'restore':
        return this._restore();
      default:
        return this.handleAction(e.detail);
    }
  }

  private renderHeaderElm(id) {
    return html` <d-entity-header
      ?top="${id === 'small-header'}"
      icon="${this.getIcon()}"
      id="${id}"
      .label=${this.currentItemLabel}
      .secondaryLabel=${this.currentItemSecondaryLabel}
      .sublabel=${this.currentItemSublabel}
      ?covered="${this.covered}"
      href="${this.entityView.href}"
      .mode=${this.mode}
      .internalMode=${this.internalMode}
      .hasDraft=${this.entityView.hasDraft ?? false}
      .newItem=${!this.entityView.isConfirmedEntity}
      .currentUserHasWriteAccess=${this.entityView.currentUserHasWriteAccess}
      .deleted=${this.entityView.deleted}
      .draftHeaderActions=${this.draftHeaderActions()}
      .editHeaderActions=${this.editHeaderActions()}
      .trashHeaderActions=${this.trashHeaderActions()}
      .mainHeaderActions=${this.mainHeaderActions()}
      .mainHeaderMoreActions=${this.mainHeaderMoreActions()}
      .savingDraft=${this.savingDraft}
      .editName=${this.editName}
      @action=${async (e) => this.handleHeaderAction(e)}
      @height-changed=${(e) => this._setContentStickyTop(e.detail)}
    >
    </d-entity-header>`;
  }

  private renderHeader() {
    return html`
      ${this.renderHeaderElm('small-header')} ${this.renderHeaderElm('large-header')} ${this.renderHeaderElm('header')}
    `;
  }

  private renderUpdateSection() {
    const item = this.asUpdateSectionItem();
    return 'revisions' in this.entityView &&
      !this.entityView.deleted &&
      this._normalMode(this.mode) &&
      item !== undefined
      ? html` <d-update-section
          .helpSectionPresent=${!isEmptyHtmlContent(this.entityView.helpContent)}
          .item=${item}
          .today=${this.entityView.today}
          @view-suggestion=${this.onViewSuggestion}
          @delete=${() => this.onDelete()}
          @decline=${(e) => this.onUpdateDeclined(e)}
        >
        </d-update-section>`
      : nothing;
  }

  private renderStartTaskSection() {
    return !this.itemLocked
      ? nothing
      : html`
          <d-section>
            <d-view-info
              content="Rediger er for øyeblikket ikke tilgjengelig i forbindelse med oppretting. Beklager ulempen."
            ></d-view-info>
          </d-section>
        `;
  }
}
