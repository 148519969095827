import { ContractView, ContractViewEditItem } from 'src/content/contracts/d-contract-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { getOrganization, getOrganizationId } from 'src/store/selectors/organization.js';
import { currentUserIsOwner, employeesNotDeleted } from 'src/store/selectors/organization-employees.js';
import { contactsNotDeleted, partnersNotDeleted } from 'src/store/selectors/organization-partners.js';
import { toContractViewModel } from 'src/store/selectors/organization-documents.js';
import { currentUserUuid, writeAccess } from 'src/store/selectors/user.js';
import { key } from 'src/store/selectors/selectors';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';
import { SelectTagOption } from 'src/library/editors/elements/d-select-tag';
import { sortedByName } from 'src/store/utilities';
import { getStore, signingAttachment } from 'src/store';
import { getFeatureStates } from 'src/store/selectors/features';

export function toSelectOptionsPartners(list): SelectTagOption[] {
  return list.map((l) => {
    return { value: l.uuid, text: l.name };
  });
}

export function toSelectOptionsEmployees(list): SelectTagOption[] {
  return list.map((l) => {
    return { value: l.uuid, text: l.firstName + ' ' + l.lastName };
  });
}

export async function buildContractView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<ContractView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const partnersForContracts = partnersNotDeleted(state)
    .filter((p) => p.isConfirmedEntity)
    .map((p) => ({
      uuid: p.uuid,
      name: p.name,
      contacts: contactsNotDeleted(state)
        .filter((c) => c.partnerUuid === p.uuid)
        .map((c) => ({
          uuid: c.uuid,
          email: c.email,
          personalIdentifier: c.personalIdentifier,
          firstName: c.firstName,
          lastName: c.lastName,
          partnerUuid: c.partnerUuid,
        })),
    }));

  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.contractsById[uuid];
  assertIsDefined(item);

  const entity = toContractViewModel(item, organization);

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),
    contacts: [],
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/contracts/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/contracts/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'contracts',
    name: entity.name,
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      api,
      'contracts',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems(api, 'contracts', entity.uuid, entity.attachments),
    validFromDate: entity.validFromDate ?? '',
    validToDate: entity.validToDate ?? '',
    contractPartyNames: entity.contractPartyNames ?? '',
    checklist: undefined, // TODO entity.checklist === null ? undefined : entity.checklist,
    availablePages: availablePages,
    relationsLocked: entity.relationsLocked ?? false,
    activeEmployeesCount: employeesNotDeleted(state).filter((e) => {
      return e.status === 'ACTIVE';
    }).length,
    hasSigningModule: getFeatureStates(state).signing,
    currentUserIsOwner: currentUserIsOwner(state),
    availablePartners: sortedByName(partnersForContracts),
    availableEmployees: sortedByName(employeesNotDeleted(state))
      .filter((e) => e.status !== 'TERMINATED' && e.isConfirmedEntity)
      .map((p) => ({
        uuid: p.uuid,
        email: p.email,
        personalIdentifier: p.personalIdentifier,
        firstName: p.firstName,
        lastName: p.lastName,
      })),
    availableContacts: [],
    employees: entity.employees ?? [],
    partners: entity.partners ?? [],
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: true,
    signingOrders:
      entity.signingOrders?.map((s) => {
        return {
          ...s,
          asDocumentItem: async () => {
            const organizationId = getOrganizationId(getStore().getState());
            const a2 = await signingAttachment(api, 'contracts', entity.uuid, organizationId, s.uuid);
            return {
              name: a2.name || 'uten navn',
              url: a2.urlForInline || '',
              urlForDownload: a2.url || '',
              entityType: 'contracts',
              attachmentId: s.uuid,
              uuid: entity.uuid,
            };
          },
        };
      }) || [],
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'contracts', uuid, organization.organizationId);
      return command.draft as ContractViewEditItem;
    },
    organizationName: organization.name,
  };
}
