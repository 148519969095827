import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-signup-plans';
import './d-signup-form';
import { Feature, features } from 'src/store/selectors/features';
import { UserForSignup } from 'src/outskirts/signup/d-signup-form';

/**
 *
 */
@customElement('d-signup-module')
export class DSignupModule extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      height: 100vh;
      overflow: auto;
      box-sizing: border-box;
      background: var(--outskirts-background-color);
    }

    .wrapper {
      max-width: 500px;
      margin: 0 auto;
      padding: 60px 20px;
    }

    .logo {
      width: 146px;
      height: 37px;
      background: url(/images/trinnvis-logo.svg) -4px 0px no-repeat;
      background-size: contain;
      padding-left: 146px;
    }

    .feature-name {
      font-size: 26px;
      font-weight: 200;
      margin-right: 0.3em;
      padding-top: 4px;
      color: white;
    }

    .feature-info {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 12px;
      color: white;
    }

    .feature-price {
      font-size: 28px;
      font-weight: 500;
    }

    .feature-price-suffix {
      font-size: 20px;
      font-weight: 200;
    }
  `;
  @property({ type: String })
  module = 'meetings';
  @property({ type: Object })
  currentUser: UserForSignup | undefined = undefined;

  private get requestedFeatureData(): Feature | undefined {
    const feature = features.find((f) => {
      return f.id.toLowerCase() === this.module;
    });
    if (feature) {
      return feature;
    }
    console.error('FEATURE NOT DEFINED');
    return undefined;
  }

  renderHeader() {
    if (this.requestedFeatureData) {
      return html`
        <div class="logo"><div class="feature-name">${this.requestedFeatureData.displayName}</div></div>
        <div class="feature-info">
          <div class="feature-price">${this.requestedFeatureData.price},-</div>
          <div class="feature-price-suffix">
            ${this.requestedFeatureData.organizationPrice ? '/mnd/ekskl.mva' : '/mnd/bruker/ekskl.mva'}
          </div>
        </div>
      `;
    }
    return nothing;
  }

  render() {
    return html`
      <div class="wrapper">
        ${this.renderHeader()}
        <d-signup-form
          outskirts
          system-content
          .module=${this.module}
          .currentUser=${this.currentUser}
          .requestedFeatureData=${this.requestedFeatureData}
        >
          <slot slot="usernameInput" name="usernameInput"></slot>
          <slot slot="passwordInput" name="passwordInput"></slot>
        </d-signup-form>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signup-module': DSignupModule;
  }
}
