/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AskModel
 */
export interface AskModel {
  /**
   *
   * @type {string}
   * @memberof AskModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AskModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AskModel
   */
  description: string;
}

/**
 * Check if a given object implements the AskModel interface.
 */
export function instanceOfAskModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'description' in value;

  return isInstance;
}

export function AskModelFromJSON(json: any): AskModel {
  return AskModelFromJSONTyped(json, false);
}

export function AskModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AskModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    id: json['id'],
    description: json['description'],
  };
}

export function AskModelToJSON(value?: AskModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    description: value.description,
  };
}
