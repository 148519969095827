import { GuidelineView, GuidelineViewEditItem } from 'src/content/guidelines/d-guideline-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH } from 'src/store/api';
import { currentUserUuid, getOrganization, key, toGuidelineViewModel, writeAccess } from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';

export async function buildGuidelineView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<GuidelineView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.guidelinesById[uuid];
  assertIsDefined(item);

  const entity = toGuidelineViewModel(item);

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/guidelines/' + uuid,
    parentHref: currentParent,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/guidelines/' + uuid + '.pdf?key=' + key(state),

    deleted: entity.deleted === true,
    type: 'guidelines',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      api,
      'guidelines',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems(api, 'guidelines', entity.uuid, entity.attachments),
    availablePages: availablePages,
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'guidelines', uuid, organization.organizationId);
      return command.draft as GuidelineViewEditItem;
    },
  };
}
