import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../../library/editors/elements/d-select-dropdown.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import '../../../../library/elements/d-wrap.js';
import '../../../../library/fields/d-expansion.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import type {
  InfosecExternalConnection,
  InfosecExternalConnectionEmployee,
  InfosecExternalConnectionEmpty,
  InfosecExternalConnectionPartner,
  InfosecNetwork,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { ResponsiveTable } from 'src/library/abstracts/responsive-table.js';
import { uuid } from 'src/utilities/text.js';
import { ExternalConnectionUpdateMessageConnectionTypeEnum } from 'src/store/api';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';

export type NetworkExternalConnectionChange =
  | InfosecExternalConnectionEmployee
  | (InfosecExternalConnectionPartner & { newPartnerName?: string });

/**
 * List all external connections for a given network. The external connections are either employees working from home
 * or partners connecting remotely.
 *
 * Existing connections can only be deleted, not changed.
 *
 * @fires map-element-deleted - The external connection is deleted
 * @fires new-partner - A new partner is used for a new external connection
 * @fires external-connection-change - A new external connection is added
 * @fires items-changed - The external connections are changed. This is fired in addition to either external-connection-change or map-element-deleted
 *
 */
@customElement('d-edit-network-external-connections')
export class DEditNetworkExternalConnections extends LitElement {
  static readonly styles = [
    ...ResponsiveTable.styles,
    css`
      :host {
        display: block;
      }
      .second {
        margin-top: 12px;
      }
      d-wrap + d-wrap {
        margin-top: 12px;
        border-top: 1px solid var(--borderColorOnGray);
        padding-top: 12px;
      }
      .wrapper {
        flex: 1;
        margin-right: 0;
      }
      d-select-dropdown-or-add {
        margin-right: 0;
      }
    `,
  ];

  /**
   * The parent network of the external connections. Used to find the name of the network and to filter the external connections.
   *
   */
  @property({ type: Object })
  network!: InfosecNetwork;
  /**
   * The complete list of external connections. Filtered using the network item and to determine if an employee is
   * already connected to any network.
   */
  @property({ type: Array })
  externalConnections: InfosecExternalConnection[] = [];

  /**
   * Available employees. Employees may only be connected to a single network.
   */
  @property({ type: Array })
  employees: SelectDropdownOption[] = [];

  /**
   * The available partners. Partners may be connected to multiple networks.
   */
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  @state()
  addingItem = false;

  editedItemUuid = '';
  typeOptions = [
    {
      value: 'Hjemmekontor',
      text: 'Hjemmekontor',
    },
    {
      value: 'Tilkoblet samarbeidspartner',
      text: 'Tilkoblet samarbeidspartner',
    },
  ];
  @state()
  newItemType = '';

  /**
   * The external connections to display. If the list is empty a new one is always displayed.
   *
   * @private
   */
  private get networkConnections(): InfosecExternalConnection[] {
    return this.externalConnections.filter((computer) => {
      return computer.networkUuid === this.network.uuid;
    });
  }

  _isConnected(uuid: string): boolean {
    return (
      this.externalConnections.filter((connection) => {
        return connection.type === 'Hjemmekontor' && connection.employeeUuid === uuid;
      }).length > 0
    );
  }

  _employeeOptions(employeeUuid: string): SelectDropdownOption[] {
    return this.employees
      .filter((employee) => {
        return !this._isConnected(employee.value) || employee.value === employeeUuid;
      })
      .map((employee) => {
        return {
          value: employee.value,
          text: employee.text,
        };
      });
  }

  _employeeSelectorChanged(value: string) {
    const newConnection: InfosecExternalConnection = {
      type: 'Hjemmekontor',
      employeeUuid: value,
      uuid: uuid(),
      networkUuid: this.network.uuid,
      connectionType: ExternalConnectionUpdateMessageConnectionTypeEnum.Other,
    };

    this.externalConnections = [...this.externalConnections, newConnection];

    this.dispatchEvent(
      new CustomEvent<InfosecExternalConnectionEmployee>('external-connection-change', {
        composed: true,
        bubbles: true,
        detail: newConnection,
      }),
    );
    this.dispatchEvent(
      new CustomEvent<InfosecExternalConnection[]>('items-changed', {
        bubbles: true,
        composed: true,
        detail: this.externalConnections,
      }),
    );

    this.addingItem = false;
    this.newItemType = '';
  }

  async _partnerSelectorChanged(value: string) {
    if (value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        const newUuid = uuid();
        this.partners = [
          ...this.partners,
          {
            value: newUuid,
            text: result.name,
          },
        ];
        const newConnection: InfosecExternalConnection = {
          type: 'Tilkoblet samarbeidspartner',
          partnerUuid: newUuid,
          uuid: uuid(),
          networkUuid: this.network.uuid,
          connectionType: ExternalConnectionUpdateMessageConnectionTypeEnum.Other,
        };

        this.externalConnections = [...this.externalConnections, newConnection];

        this.dispatchEvent(
          new CustomEvent<{ uuid: string; name: string }>('new-partner', {
            composed: true,
            bubbles: true,
            detail: { uuid: newUuid, name: result.name },
          }),
        );
        this.dispatchEvent(
          new CustomEvent<InfosecExternalConnectionPartner>('external-connection-change', {
            composed: true,
            bubbles: true,
            detail: newConnection,
          }),
        );
        this.dispatchEvent(
          new CustomEvent<InfosecExternalConnection[]>('items-changed', {
            bubbles: true,
            composed: true,
            detail: this.externalConnections,
          }),
        );

        this.addingItem = false;
        this.newItemType = '';
      }
    } else {
      const newConnection: InfosecExternalConnection = {
        type: 'Tilkoblet samarbeidspartner',
        partnerUuid: value,
        uuid: uuid(),
        networkUuid: this.network.uuid,
        connectionType: ExternalConnectionUpdateMessageConnectionTypeEnum.Other,
      };

      this.externalConnections = [...this.externalConnections, newConnection];

      this.dispatchEvent(
        new CustomEvent<InfosecExternalConnectionPartner>('external-connection-change', {
          composed: true,
          bubbles: true,
          detail: newConnection,
        }),
      );
      this.dispatchEvent(
        new CustomEvent<InfosecExternalConnection[]>('items-changed', {
          bubbles: true,
          composed: true,
          detail: this.externalConnections,
        }),
      );

      this.addingItem = false;
      this.newItemType = '';
    }
  }

  _removeItem(uuid: string) {
    this.externalConnections = this.externalConnections.filter((connection) => {
      return connection.uuid !== uuid;
    });
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; unitType: 'externalConnection' }>('map-element-deleted', {
        bubbles: true,
        composed: true,
        detail: {
          unitType: 'externalConnection',
          uuid: uuid,
        },
      }),
    );
    this.dispatchEvent(
      new CustomEvent<InfosecExternalConnection[]>('items-changed', {
        bubbles: true,
        composed: true,
        detail: this.externalConnections,
      }),
    );
  }

  _addItem() {
    this.addingItem = true;
    this.newItemType = '';
  }

  render() {
    const networkConnections = this.networkConnections;
    return html`
      ${networkConnections.map((item, index, array) => {
        return this.renderExistingConnection(item, index, index === array.length - 1);
      })}
      ${this.addingItem || networkConnections.length === 0 ? this.renderNewConnection() : nothing}
    `;
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    if (changedProperties.has('networkConnections') && this.networkConnections.length === 0) {
      console.log('No network connections');
      this.newItemType = '';
    }
  }

  /**
   * Existing connections are always rendered disabled and can be deleted.
   * @param item
   * @param index
   * @private
   */
  private renderExistingConnection(
    item: InfosecExternalConnectionEmployee | InfosecExternalConnectionPartner | InfosecExternalConnectionEmpty,
    index: number,
    add: boolean,
  ) {
    return html`
      <d-wrap nowrap>
        <div class="wrapper">
          <d-select-dropdown
            theme-page
            placeholder="Velg type"
            unselectablePlaceholder
            disabled
            .options=${this.typeOptions}
            .value=${item.type}
          ></d-select-dropdown>
          ${item.type === 'Hjemmekontor'
            ? html`
                <d-select-dropdown
                  class="second"
                  theme-page
                  placeholder="Velg person"
                  disabled
                  .options=${this._employeeOptions(item.employeeUuid)}
                  .value=${item.employeeUuid}
                ></d-select-dropdown>
              `
            : nothing}
          ${item.type === 'Tilkoblet samarbeidspartner'
            ? html`
                <d-select-dropdown-or-add
                  class="second"
                  theme-page
                  placeholder="Velg samarbeidspartner"
                  firstItemText="Ny samarbeidspartner"
                  additionalItemText="Annen samarbeidspartner"
                  disabled
                  .options=${this.partners}
                  .value=${item.partnerUuid}
                ></d-select-dropdown-or-add>
              `
            : nothing}
        </div>
        <d-add-remove-buttons
          ?showRemove=${true}
          ?showAdd=${add}
          @remove=${() => this._removeItem(item.uuid)}
          @add=${() => this._addItem()}
        ></d-add-remove-buttons>
      </d-wrap>
    `;
  }

  private renderNewConnection() {
    return html`
      <d-wrap nowrap>
        <div class="wrapper">
          <d-select-dropdown
            theme-page
            placeholder="Velg type"
            unselectablePlaceholder
            .options=${this.typeOptions}
            .value=${this.newItemType}
            @value-changed=${(e) => (this.newItemType = e.detail.value)}
          ></d-select-dropdown>
          ${this.newItemType === 'Hjemmekontor'
            ? html`
                <d-select-dropdown
                  class="second"
                  theme-page
                  placeholder="Velg person"
                  .options=${this._employeeOptions('')}
                  .value=${''}
                  @value-changed=${(e) => this._employeeSelectorChanged(e.detail.value)}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.newItemType === 'Tilkoblet samarbeidspartner'
            ? html`
                <d-select-dropdown-or-add
                  class="second"
                  theme-page
                  placeholder="Velg samarbeidspartner"
                  firstItemText="Ny samarbeidspartner"
                  additionalItemText="Annen samarbeidspartner"
                  .options=${this.partners}
                  .value=${''}
                  @value-changed=${(e) => this._partnerSelectorChanged(e.detail.value)}
                ></d-select-dropdown-or-add>
              `
            : nothing}
        </div>
        <d-add-remove-buttons ?showRemove=${false} ?showAdd=${false}></d-add-remove-buttons>
      </d-wrap>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-network-external-connections': DEditNetworkExternalConnections;
  }
}
