/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MessageFile
 */
export interface MessageFile {
  /**
   *
   * @type {string}
   * @memberof MessageFile
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MessageFile
   */
  src?: string;
  /**
   *
   * @type {string}
   * @memberof MessageFile
   */
  type?: string;
}

/**
 * Check if a given object implements the MessageFile interface.
 */
export function instanceOfMessageFile(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function MessageFileFromJSON(json: any): MessageFile {
  return MessageFileFromJSONTyped(json, false);
}

export function MessageFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageFile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    src: !exists(json, 'src') ? undefined : json['src'],
    type: !exists(json, 'type') ? undefined : json['type'],
  };
}

export function MessageFileToJSON(value?: MessageFile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    src: value.src,
    type: value.type,
  };
}
