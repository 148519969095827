import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { EmployeeForStaffingCalendar } from 'src/store';
import { isInvalidPeriod } from 'src/store';
import * as dabihStore from 'src/store';
import { getPeriodDayData, getScheduleDayData, leavePeriodTypes, timeInMinutes } from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';
import type { LeavePeriodDay } from 'src/store/api';
import { LeavePeriodDayLeaveStatusEnum } from 'src/store/api';
import { minutesToTime } from 'src/utilities/text.js';
import '../library/editors/components/d-select-time.js';
import '../library/editors/elements/d-select-dropdown.js';
import '../library/editors/elements/d-edit-textarea.js';
import '../library/editors/elements/d-select-date.js';
import '../library/elements/d-label.js';
import '../library/elements/d-section.js';
import '../library/fields/d-expansion.js';
import '../library/fields/d-view-info.js';
import '../pages/d-graded-leave.js';
import '../pages/d-list-leave-periods.js';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface PeriodEditItem {
  type: string;
  confirmed: boolean;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  notes: string;
  periodId?: string;
  grade: number;
  days: LeavePeriodDay[];
}

/**
 *
 * FIX must sync with master. Calculations have changed
 * Hente ny funksjonalitet, vise info og alerts for fraværstyper
 *
 * USAGE:
 *    d-timekeeping-list-section
 *    d-staffing-calendar-table
 *    d-staffing-employee-day-popup
 *
 */
@customElement('d-edit-periods')
export class DEditPeriods extends LitElement {
  static readonly styles = [
    css`
      .confirmed select {
        width: 120px;
      }

      .validHours {
        margin-right: 6px;
        margin-bottom: 6px;
        font-weight: bold;
      }
      .invalidHours {
        font-weight: 200;
      }
    `,
  ];
  @property({ type: Object })
  editItem: PeriodEditItem = {
    type: 'vacation',
    confirmed: false,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    notes: '',
    periodId: '',
    grade: 100,
    days: [],
  };
  @property({ type: String })
  application: 'timekeeping' | '' = '';
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: String })
  periodStart = '';
  @property({ type: Array })
  days: LeavePeriodDay[] = [];
  @property({ type: String })
  defaultPeriodType = 'vacation';
  @property({ type: String })
  periodList = 'leavePeriods';
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  private holidays = dabihStore.holidaysForStaffingCalendar();
  private statusOptions = [
    { value: 'false', text: 'Ubekreftet' },
    { value: 'true', text: 'Bekreftet' },
  ];

  get start() {
    return this.editItem.startDate;
  }

  get startHours() {
    return this.editItem.startTime.split(':')[0];
  }

  get startMinutes() {
    return this.editItem.startTime.split(':')[1] || '00';
  }

  get end() {
    return this.editItem.endDate;
  }

  get endHours() {
    return this.editItem.endTime.split(':')[0];
  }

  get endMinutes() {
    return this.editItem.endTime.split(':')[1] || '00';
  }

  private get isSickLeave() {
    return this.editItem.type === 'sickLeave';
  }

  private get gradeOptions() {
    const result: SelectDropdownOption[] = [];
    for (let i = 4; i < 101; i++) {
      result.push({ value: i + '', text: i + '' });
    }
    return result;
  }

  private get alerts() {
    const alerts: string[] = [];
    const type = this.employeePeriod?.type ?? '';
    if (this.employee.uuid && this.editItem.startDate) {
      const leavePeriod = this.employeePeriod;
      if (this.employee) {
        const sickSelfPrevDay =
          this.employee.leavePeriods.filter((item) => {
            return (
              item.type === 'sickSelf' &&
              item.confirmed &&
              this._periodInRange(
                item.start,
                item.end,
                LocalDate.fromString(this.editItem.startDate).minusDays(1).toString(),
                this.editItem.startDate,
              )
            );
          }).length > 0;
        if (!leavePeriod && type === 'sickSelf' && sickSelfPrevDay) {
          alerts.push('staffing_sickSelfPrevDay');
        }
      }
    }
    if (type === 'sickSelf' || type === 'sickLeave') {
      alerts.push('staffing_workRelatedHealthIssueAlert');
    }
    return alerts;
  }

  private get oneYearBeforeStart() {
    return LocalDate.fromString(this.editItem.startDate).plusYears(-1).plusDays(1).toString();
  }

  private get employeePeriod() {
    if (this.employee) {
      const employeePeriod = this.employee[this.periodList];
      return employeePeriod?.filter((item) => item.start === this.periodStart)[0];
    }
    return undefined;
  }

  private get info() {
    const info: string[] = [];
    if (this.editItem.type === 'vacation') {
      info.push('staffing_vacationRules');
    }
    if (this.editItem.type === 'sickSelf') {
      info.push('staffing_sickSelfRules');
    }
    if (this.editItem.type === 'sickChildren') {
      info.push('staffing_sickChildrenRules');
    }
    return info;
  }

  /**
   * Virtual list of days so that it can change if dates or grade is changed before the user starts editing the days.
   *
   * @private
   */
  get calculatedDays(): LeavePeriodDay[] {
    if (this.editItem.grade === 100) {
      return [];
    } else {
      const startDate = LocalDate.fromString(this.start);
      const endDate = LocalDate.fromString(this.end);
      return LocalDate.dates(startDate, endDate.plusDays(1)).map((day): LeavePeriodDay => {
        const leavePeriodDay = this.days.find((x) => x.date === day.toString());
        if (leavePeriodDay !== undefined) {
          return leavePeriodDay;
        } else {
          const s = getScheduleDayData(day, this.employee);
          const holiday = this.holidays[day.toString()] ?? '';
          if (!(s.holidays && holiday) && s.workHours) {
            const scheduledMinutes = dabihStore.timeInMinutes(s.end) - dabihStore.timeInMinutes(s.start);
            const endInMinutes =
              dabihStore.timeInMinutes(s.start) + (scheduledMinutes * (100 - this.editItem.grade)) / 100;
            const editEnd = minutesToTime(endInMinutes);
            return {
              end: editEnd,
              leaveStatus: LeavePeriodDayLeaveStatusEnum.Work,
              start: s.start,
              date: day.toString(),
            };
          } else {
            return {
              end: '00:00',
              leaveStatus: LeavePeriodDayLeaveStatusEnum.Leave,
              start: '00:00',
              date: day.toString(),
            };
          }
        }
      });
    }
  }

  _isEmpty(arr) {
    return arr.length === 0;
  }

  _contains(alerts, alert) {
    return alerts.indexOf(alert) > -1;
  }

  _periodInRange(periodStart, periodEnd, rangeStart, rangeEnd) {
    if (periodStart && periodEnd && rangeStart && rangeEnd) {
      const periodStartDate = LocalDate.fromString(periodStart);
      const periodEndDate = LocalDate.fromString(periodEnd);
      const rangeStartDate = LocalDate.fromString(rangeStart);
      const rangeEndDate = LocalDate.fromString(rangeEnd);
      return (
        (periodStartDate.isSameOrBefore(rangeEndDate) && periodStartDate.isSameOrAfter(rangeStartDate)) ||
        (periodEndDate.isSameOrAfter(rangeStartDate) && periodStartDate.isSameOrBefore(rangeEndDate))
      );
    }
    return false;
  }

  private get periodHoursText() {
    const startDate = LocalDate.fromString(this.editItem.startDate);
    const endDate = LocalDate.fromString(this.editItem.endDate);
    const dates = LocalDate.dates(startDate, endDate.plusDays(1));
    let minutesTotal = 0;
    let validMinutesTotal = 0;
    let validHoursTypeTerm = '';
    const period = {
      type: this.editItem.type,
      start: this.editItem.startDate,
      end: this.editItem.endDate,
      startTime: this.editItem.startTime === 'NONE' ? '00:00' : this.editItem.startTime,
      endTime: this.editItem.endTime === 'NONE' ? '24:00' : this.editItem.endTime,
      notes: this.editItem.notes,
      confirmed: this.editItem.confirmed,
    };
    dates.forEach((date) => {
      const periodDayData = getPeriodDayData(this.employee, period, this.editItem.type, date, undefined);
      minutesTotal += periodDayData.minutes;
      validMinutesTotal += periodDayData.validMinutes;
      if (this.editItem.type === 'timeOff') {
        validHoursTypeTerm = 'avspaseringstime';
      }
      if (this.editItem.type === 'plusTime') {
        validHoursTypeTerm = 'plusstime';
      }
    });
    const hours = Math.round((minutesTotal / 60 + Number.EPSILON) * 100) / 100;
    const validHours = Math.round((validMinutesTotal / 60 + Number.EPSILON) * 100) / 100;
    const invalidHours = hours - validHours;
    if (validHours !== 1) {
      validHoursTypeTerm += 'r';
    }
    let invalidHoursText = '';
    if (invalidHours) {
      if (this.editItem.type === 'timeOff') {
        invalidHoursText = 'Avspasering ut over vanlig arbeidstid telles ikke';
      }
      if (this.editItem.type === 'plusTime') {
        invalidHoursText = 'Plusstid innenfor vanlig arbeidstid telles ikke';
      }
    }
    return {
      validHoursText: validHours + ' ' + validHoursTypeTerm,
      invalidHoursText,
    };
  }

  get periodIsInvalid() {
    return isInvalidPeriod(
      this.editItem.startDate,
      this.editItem.startTime,
      this.editItem.endDate,
      this.editItem.endTime,
    );
  }

  private renderAlerts() {
    return html`<d-expansion ?opened=${!this._isEmpty(this.alerts)}>
      <d-expansion ?opened=${this._contains(this.alerts, 'staffing_sickSelfPrevDay')}>
        <d-section>
          <d-view-info field="staffing_sickSelfPrevDay" alert alert-list></d-view-info>
        </d-section>
      </d-expansion>
      <d-expansion ?opened=${this._contains(this.alerts, 'staffing_workRelatedHealthIssueAlert')}>
        <d-section>
          <d-view-info field="staffing_workRelatedHealthIssueAlert" alert alert-list></d-view-info>
        </d-section>
      </d-expansion>
    </d-expansion>`;
  }

  private renderTimekeepingPeriodsInfo() {
    if ((this.editItem.type === 'timeOff' || this.editItem.type === 'plusTime') && !this.periodIsInvalid) {
      return html`
        <d-section>
          <div class="validHours">${this.periodHoursText.validHoursText}</div>
          ${this.periodHoursText.invalidHoursText
            ? html` <div class="invalidHours">${this.periodHoursText.invalidHoursText}</div> `
            : nothing}
        </d-section>
      `;
    } else {
      return nothing;
    }
  }

  protected render() {
    return html`
      <d-section>
        <d-wrap>
          <d-wrap>
            ${this.application === 'timekeeping'
              ? nothing
              : html`<d-select-dropdown
                  .options=${leavePeriodTypes}
                  .value=${this.editItem.type}
                  @value-changed=${this.onTypeChanged}
                ></d-select-dropdown>`}
            <d-select-dropdown
              label="status"
              inline-label
              light-label
              .options=${this.statusOptions}
              .value=${this.editItem.confirmed ? 'true' : 'false'}
              ?disabled=${!this.currentUserHasAccess && this.leavePeriodEditRestriction}
              @value-changed=${this.onConfirmedChanged}
            ></d-select-dropdown>
          </d-wrap>
          <d-wrap>
            <d-wrap>
              <d-select-date
                label="fra"
                inline-label
                light-label
                label-min-width="24"
                ?invalid="${this.periodIsInvalid}"
                .value=${this.editItem.startDate}
                @value-changed=${this.onStartDateChanged}
              ></d-select-date>
              <d-select-time
                whole-day-option
                ?invalid="${this.periodIsInvalid}"
                max="23:50"
                .value=${this.editItem.startTime}
                @value-changed=${this.onStartTimeChanged}
              ></d-select-time>
            </d-wrap>
            <d-wrap>
              <d-select-date
                label="til"
                inline-label
                light-label
                label-min-width="24"
                ?invalid="${this.periodIsInvalid}"
                .value=${this.editItem.endDate}
                @value-changed=${this.onEndDateChanged}
              ></d-select-date>
              <d-select-time
                whole-day-option
                ?invalid="${this.periodIsInvalid}"
                min="00:05"
                .value=${this.editItem.endTime}
                @value-changed=${this.onEndTimeChanged}
              ></d-select-time>
            </d-wrap>
          </d-wrap>
          ${this.isSickLeave
            ? html`
                <d-wrap>
                  <d-select-dropdown
                    .options=${this.gradeOptions}
                    label="Sykmeldingsprosent"
                    inline-label
                    light-label
                    .value=${this.editItem.grade + ''}
                    @value-changed=${this.onGradeChanged}
                  ></d-select-dropdown>
                </d-wrap>
              `
            : nothing}
        </d-wrap>
      </d-section>
      ${this.periodIsInvalid
        ? html`
            <d-section>
              <d-view-info .content=${`<ul><li>Starttid må være før sluttid</li></ul>`} alert alert-list></d-view-info>
            </d-section>
          `
        : nothing}
      ${this.editItem.grade !== 100 && !this.periodIsInvalid
        ? html` <d-graded-leave
            .employee=${this.employee}
            .grade=${this.editItem.grade}
            .start=${this.editItem.startDate}
            .end=${this.editItem.endDate}
            .days=${this.calculatedDays}
            @days-changed=${(e) => {
              this.onDaysChanged(e);
            }}
          ></d-graded-leave>`
        : nothing}
      ${this.renderTimekeepingPeriodsInfo()}

      <d-section>
        <d-edit-textarea
          label="Notater"
          .value=${this.editItem.notes}
          @value-changed=${this.onNotesChanged}
        ></d-edit-textarea>
      </d-section>

      ${this.renderAlerts()}
      ${this.editItem.type === 'vacation'
        ? html`
            <d-section
              .label=${'Ferie registrert på ' +
              this.employee.name +
              ' i ' +
              LocalDate.fromString(this.editItem.startDate).year()}
            >
              <d-list-leave-periods
                cropped
                .employee=${this.employee}
                .start=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-01-01'}
                .end=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-12-31'}
                sumType="workdays"
                type="vacation"
              >
              </d-list-leave-periods>
            </d-section>
          `
        : nothing}
      ${this.editItem.type === 'sickLeave'
        ? html` <d-section
            .label=${'Sykmeldinger registrert på ' +
            this.employee.name +
            ' i ' +
            LocalDate.fromString(this.editItem.startDate).year()}
          >
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .start=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-01-01'}
              .end=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-12-31'}
              sumType="cropped"
              type="sickLeave"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${this.editItem.type === 'sickSelf'
        ? html` <d-section .label=${'Egenmeldinger registrert på ' + this.employee.name + ' siste tolv måneder'}>
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .end=${this.editItem.startDate}
              .start=${this.oneYearBeforeStart}
              sumType="cropped"
              secondarySumType="full"
              type="sickSelf"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${this.editItem.type === 'sickChildren'
        ? html` <d-section
            .label=${'Omsorgsdager registrert på ' +
            this.employee.name +
            ' i ' +
            LocalDate.fromString(this.editItem.startDate).year()}
          >
            <d-list-leave-periods
              cropped
              .employee=${this.employee}
              .start=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-01-01'}
              .end=${'' + LocalDate.fromString(this.editItem.startDate).year() + '-12-31'}
              sumType="full"
              type="sickChildren"
            >
            </d-list-leave-periods>
          </d-section>`
        : nothing}
      ${html`<d-expansion .opened=${this.info.length > 0}>
        <d-expansion ?opened=${this._contains(this.info, 'staffing_vacationRules')}>
          <d-section>
            <d-view-info info-list field="staffing_vacationRules"></d-view-info>
          </d-section>
        </d-expansion>
        <d-expansion .opened=${this._contains(this.info, 'staffing_sickSelfRules')}>
          <d-section>
            <d-view-info field="staffing_sickSelfRules"></d-view-info>
          </d-section>
        </d-expansion>
        <d-expansion .opened=${this._contains(this.info, 'staffing_sickChildrenRules')}>
          <d-section>
            <d-view-info field="staffing_sickChildrenRules"></d-view-info>
          </d-section>
        </d-expansion>
      </d-expansion>`}
    `;
  }

  fireChanged() {
    this.dispatchEvent(
      new CustomEvent<PeriodEditItem>('edit-item-changed', {
        bubbles: true,
        composed: true,
        detail: this.editItem,
      }),
    );
  }
  private onTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, type: e.detail.value };
    this.fireChanged();
  }

  private onConfirmedChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, confirmed: e.detail.value === 'true' };
    this.fireChanged();
  }

  private onStartDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, startDate: e.detail.value };
    this.fireChanged();
  }

  private onEndDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, endDate: e.detail.value };
    this.fireChanged();
  }

  private onStartTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (this.editItem.startTime === 'NONE') {
      let endTimeInMinutes = timeInMinutes(e.detail.value) + 60;
      if (endTimeInMinutes > 1435) {
        endTimeInMinutes = 1435;
      }
      this.editItem = { ...this.editItem, endTime: minutesToTime(endTimeInMinutes) };
    }
    this.editItem = { ...this.editItem, startTime: e.detail.value };
    if (this.editItem.startTime === 'NONE' && this.editItem.endTime !== 'NONE') {
      this.editItem = { ...this.editItem, endTime: 'NONE' };
    } else if (this.editItem.startTime !== 'NONE' && this.editItem.endTime === 'NONE') {
      this.editItem = { ...this.editItem, endTime: this.editItem.startTime };
    }
    this.fireChanged();
  }

  private onEndTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (this.editItem.endTime === 'NONE') {
      let startTimeInMinutes = timeInMinutes(e.detail.value) - 60;
      if (startTimeInMinutes < 0) {
        startTimeInMinutes = 0;
      }
      this.editItem = { ...this.editItem, startTime: minutesToTime(startTimeInMinutes) };
    }
    this.editItem = { ...this.editItem, endTime: e.detail.value };
    if (this.editItem.endTime === 'NONE' && this.editItem.startTime !== 'NONE') {
      this.editItem = { ...this.editItem, startTime: 'NONE' };
    } else if (this.editItem.endTime !== 'NONE' && this.editItem.startTime === 'NONE') {
      this.editItem = { ...this.editItem, startTime: this.editItem.endTime };
    }
    this.fireChanged();
  }

  private onGradeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    let grade = Number(e.detail.value);
    if (grade < 5) {
      grade = 5;
    } else if (grade > 100) {
      grade = 100;
    }
    this.editItem = { ...this.editItem, grade };
    this.fireChanged();
  }

  private onDaysChanged(e: CustomEvent<{ days: LeavePeriodDay[] }>) {
    e.preventDefault();
    this.days = e.detail.days;
    this.dispatchEvent(
      new CustomEvent<PeriodEditItem>('days-changed', {
        bubbles: true,
        composed: true,
        detail: this.editItem,
      }),
    );
  }
  private onNotesChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.editItem = { ...this.editItem, notes: e.detail.value };
    this.fireChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-periods': DEditPeriods;
  }
}
