/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DataItemAccessUpdateMessage
 */
export interface DataItemAccessUpdateMessage {
  /**
   *
   * @type {Array<string>}
   * @memberof DataItemAccessUpdateMessage
   */
  accessingEmployees: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DataItemAccessUpdateMessage
   */
  accessingPartners: Array<string>;
}

/**
 * Check if a given object implements the DataItemAccessUpdateMessage interface.
 */
export function instanceOfDataItemAccessUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'accessingEmployees' in value;
  isInstance = isInstance && 'accessingPartners' in value;

  return isInstance;
}

export function DataItemAccessUpdateMessageFromJSON(json: any): DataItemAccessUpdateMessage {
  return DataItemAccessUpdateMessageFromJSONTyped(json, false);
}

export function DataItemAccessUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataItemAccessUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessingEmployees: json['accessingEmployees'],
    accessingPartners: json['accessingPartners'],
  };
}

export function DataItemAccessUpdateMessageToJSON(value?: DataItemAccessUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessingEmployees: value.accessingEmployees,
    accessingPartners: value.accessingPartners,
  };
}
