/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReportUpdateMessage
 */
export interface ReportUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof ReportUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  reportDate?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  employee?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  partnerContactUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  meetingUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  meetingEventUuid?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ReportUpdateMessage
   */
  pages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ReportUpdateMessage
   */
  classification?: ReportUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ReportUpdateMessage
   */
  accessControl?: Array<string>;
}

/**
 * @export
 */
export const ReportUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type ReportUpdateMessageClassificationEnum =
  (typeof ReportUpdateMessageClassificationEnum)[keyof typeof ReportUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the ReportUpdateMessage interface.
 */
export function instanceOfReportUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function ReportUpdateMessageFromJSON(json: any): ReportUpdateMessage {
  return ReportUpdateMessageFromJSONTyped(json, false);
}

export function ReportUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    name: !exists(json, 'name') ? undefined : json['name'],
    content: !exists(json, 'content') ? undefined : json['content'],
    reportDate: !exists(json, 'reportDate') ? undefined : json['reportDate'],
    employee: !exists(json, 'employee') ? undefined : json['employee'],
    partnerContactUuid: !exists(json, 'partnerContactUuid') ? undefined : json['partnerContactUuid'],
    meetingUuid: !exists(json, 'meetingUuid') ? undefined : json['meetingUuid'],
    meetingEventUuid: !exists(json, 'meetingEventUuid') ? undefined : json['meetingEventUuid'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
  };
}

export function ReportUpdateMessageToJSON(value?: ReportUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    content: value.content,
    reportDate: value.reportDate === undefined ? undefined : value.reportDate,
    employee: value.employee,
    partnerContactUuid: value.partnerContactUuid,
    meetingUuid: value.meetingUuid,
    meetingEventUuid: value.meetingEventUuid,
    pages: value.pages,
    classification: value.classification,
    accessControl: value.accessControl,
  };
}
