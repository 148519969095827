/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConstitutionalDocumentUpdateMessage
 */
export interface ConstitutionalDocumentUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  templateId?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  validFromDate?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  validToDate?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  employees?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  partners?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  classification?: ConstitutionalDocumentUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentUpdateMessage
   */
  accessControl?: Array<string>;
}

/**
 * @export
 */
export const ConstitutionalDocumentUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type ConstitutionalDocumentUpdateMessageClassificationEnum =
  (typeof ConstitutionalDocumentUpdateMessageClassificationEnum)[keyof typeof ConstitutionalDocumentUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the ConstitutionalDocumentUpdateMessage interface.
 */
export function instanceOfConstitutionalDocumentUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function ConstitutionalDocumentUpdateMessageFromJSON(json: any): ConstitutionalDocumentUpdateMessage {
  return ConstitutionalDocumentUpdateMessageFromJSONTyped(json, false);
}

export function ConstitutionalDocumentUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConstitutionalDocumentUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    template: !exists(json, 'template') ? undefined : json['template'],
    name: !exists(json, 'name') ? undefined : json['name'],
    content: !exists(json, 'content') ? undefined : json['content'],
    validFromDate: !exists(json, 'validFromDate') ? undefined : json['validFromDate'],
    validToDate: !exists(json, 'validToDate') ? undefined : json['validToDate'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    partners: !exists(json, 'partners') ? undefined : json['partners'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
  };
}

export function ConstitutionalDocumentUpdateMessageToJSON(value?: ConstitutionalDocumentUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    templateId: value.templateId,
    template: value.template,
    name: value.name,
    content: value.content,
    validFromDate: value.validFromDate === undefined ? undefined : value.validFromDate,
    validToDate: value.validToDate === undefined ? undefined : value.validToDate,
    employees: value.employees,
    partners: value.partners,
    classification: value.classification,
    accessControl: value.accessControl,
  };
}
