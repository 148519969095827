import type { AssetView, AssetViewEditItem } from 'src/content/assets/d-asset-view.js';
import { fetchDraft, toAttachmentItems } from 'src/models/factory-utilities.js';
import { BASE_PATH } from 'src/store/api';

import type { State } from 'src/store/types.js';
import { employeesNotDeleted } from 'src/store/selectors/organization-employees.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import { variantAssetsView, variantSimplifiedAssets } from 'src/store/selectors/variants.js';
import { tasksForAssets } from 'src/store/selectors/organization-functions.js';
import { toAssetViewModel } from 'src/store/selectors/organization-assets.js';
import { key } from 'src/store/selectors/selectors';
import { ApiClient, assetsWithTasks, EventDataForAsset } from 'src/store';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
export function buildAssetView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): AssetView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const viewOptions = variantAssetsView(state);

  const item = organization.assetsById[uuid];
  assertIsDefined(item);

  const entity = toAssetViewModel(item, organization);
  const href = currentParent + '/assets/' + uuid;

  const withEvents = assetsWithTasks(state).find((a) => a.uuid === uuid);
  assertIsDefined(withEvents);

  const assetEvents: EventDataForAsset[] = withEvents.events;

  return {
    ...commonData,
    docsForLinking: [],
    currentUserHasAccess: true,
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    deleted: entity.deleted === true,
    helpContent: '',
    pdfHref: BASE_PATH + '/organizations/' + organization.organizationId + '/assets/' + uuid + '.pdf?key=' + key(state),
    parentHref: currentParent,
    href: href,
    type: 'assets',
    name: entity.name ?? '',
    attachments: toAttachmentItems(api, 'assets', entity.uuid, entity.attachments),
    dsaApproved: entity.dsaApproved,
    dsaRegistered: entity.dsaRegistered,
    documented: entity.documented === true,
    documentationVerified: entity.documentationVerified === true,
    assetVerified: entity.assetVerified === true,
    maintenanceRequired: entity.maintenanceRequired === true,
    requiresElectricity: entity.requiresElectricity === true,
    radiation: entity.radiation,
    requiredEventInstanceMissing: entity.requiredEventInstanceMissing,
    hasPersonalDataItem: entity.hasPersonalDataItem,
    maintenance: entity.maintenance ?? '',
    competency: entity.competency ?? '',
    complianceDeclaration: entity.complianceDeclaration ?? '',
    accessory: entity.accessory ?? '',
    discardedYear: entity.discardedYear ?? '',
    acquiredYear: entity.acquiredYear ?? '',
    category: entity.category ?? '',
    location: entity.location ?? '',
    manufacturer: entity.manufacturer ?? '',
    model: entity.model ?? '',
    supplier: entity.supplier ?? '',
    number: entity.number ?? '',
    radiationType: entity.radiationType ?? '',
    ownerDisplay: entity.ownerDisplay ?? '',
    owner: entity.owner ?? '',
    userManual: entity.userManual ?? '',
    assetEvents,
    relatedTasks: [],
    availableRelatedTasks: tasksForAssets(state)
      .filter((t) => !t.assets.includes(uuid))
      .map((t) => ({ uuid: t.uuid, label: t.name })),
    availableAssetCategories: viewOptions.categories.map((c) => ({ value: c, text: c })),
    availableAssetEmployees: employeesNotDeleted(state)
      .filter((e) => e.isConfirmedEntity)
      .map((e) => ({ value: e.uuid, text: e.name })),
    editAssetsSimplified: variantSimplifiedAssets(state),
    organizationName: organization.name,
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'assets', uuid, organization.organizationId);
      return command.draft as AssetViewEditItem;
    },
  };
}
