/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SendChangedTaskRecipient } from './SendChangedTaskRecipient';
import {
  SendChangedTaskRecipientFromJSON,
  SendChangedTaskRecipientFromJSONTyped,
  SendChangedTaskRecipientToJSON,
} from './SendChangedTaskRecipient';

/**
 *
 * @export
 * @interface SendChangedTaskCommand
 */
export interface SendChangedTaskCommand {
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskCommand
   */
  taskUuid?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskCommand
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskCommand
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskCommand
   */
  intro?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskCommand
   */
  procedure?: string;
  /**
   *
   * @type {Array<SendChangedTaskRecipient>}
   * @memberof SendChangedTaskCommand
   */
  recipients?: Array<SendChangedTaskRecipient>;
}

/**
 * Check if a given object implements the SendChangedTaskCommand interface.
 */
export function instanceOfSendChangedTaskCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SendChangedTaskCommandFromJSON(json: any): SendChangedTaskCommand {
  return SendChangedTaskCommandFromJSONTyped(json, false);
}

export function SendChangedTaskCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendChangedTaskCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    taskUuid: !exists(json, 'taskUuid') ? undefined : json['taskUuid'],
    name: !exists(json, 'name') ? undefined : json['name'],
    message: !exists(json, 'message') ? undefined : json['message'],
    intro: !exists(json, 'intro') ? undefined : json['intro'],
    procedure: !exists(json, 'procedure') ? undefined : json['procedure'],
    recipients: !exists(json, 'recipients')
      ? undefined
      : (json['recipients'] as Array<any>).map(SendChangedTaskRecipientFromJSON),
  };
}

export function SendChangedTaskCommandToJSON(value?: SendChangedTaskCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    taskUuid: value.taskUuid,
    name: value.name,
    message: value.message,
    intro: value.intro,
    procedure: value.procedure,
    recipients:
      value.recipients === undefined ? undefined : (value.recipients as Array<any>).map(SendChangedTaskRecipientToJSON),
  };
}
