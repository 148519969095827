/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateSpecialTermsCommand
 */
export interface UpdateSpecialTermsCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateSpecialTermsCommand
   */
  specialTerms: UpdateSpecialTermsCommandSpecialTermsEnum;
}

/**
 * @export
 */
export const UpdateSpecialTermsCommandSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type UpdateSpecialTermsCommandSpecialTermsEnum =
  (typeof UpdateSpecialTermsCommandSpecialTermsEnum)[keyof typeof UpdateSpecialTermsCommandSpecialTermsEnum];

/**
 * Check if a given object implements the UpdateSpecialTermsCommand interface.
 */
export function instanceOfUpdateSpecialTermsCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'specialTerms' in value;

  return isInstance;
}

export function UpdateSpecialTermsCommandFromJSON(json: any): UpdateSpecialTermsCommand {
  return UpdateSpecialTermsCommandFromJSONTyped(json, false);
}

export function UpdateSpecialTermsCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateSpecialTermsCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    specialTerms: json['specialTerms'],
  };
}

export function UpdateSpecialTermsCommandToJSON(value?: UpdateSpecialTermsCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    specialTerms: value.specialTerms,
  };
}
