import { ApiClient, createPartner, getOrganization, getStore } from 'src/store';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import type {
  ComputerViewModel,
  CreateOrUpdateComputerRequest,
  CreateOrUpdateExternalConnectionRequest,
  CreateOrUpdateNetworkRequest,
  CreateOrUpdatePersonalDataItemRequest,
  DeleteAssetRequest,
  DeleteComputerRequest,
  DeleteExternalConnectionRequest,
  DeleteNetworkRequest,
  DeletePartnerRequest,
  DeletePersonalDataItemRequest,
  NetworkUpdateMessageNetworkTypeEnum,
  PersonalDataItemViewModel,
  UpdateAccessForPersonalDataItemRequest,
  UpdateBackupForPersonalDataItemRequest,
  UpdateUpdateNetworkComputerRequest,
} from 'src/store/api';
import {
  ComputerUpdateMessageAntiVirusOperatorTypeEnum,
  ComputerUpdateMessageSystemUpdateOperatorTypeEnum,
  ExternalConnectionUpdateMessageConnectionTypeEnum,
  NetworkUpdateMessageAntiVirusOperatorTypeEnum,
  NetworkUpdateMessageFirewallEnum,
  NetworkUpdateMessageSystemUpdateOperatorTypeEnum,
  PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
} from 'src/store/api';
import type { MapElementChangedDetail } from 'src/d-app-core.js';
import type { CloudServiceEditItem } from 'src/pages/computers-page/d-computer-unit-cloud-service.js';
import type { RemoteServerEditItem } from 'src/pages/computers-page/d-computer-unit-remote-server.js';
import type { PrinterEditItem } from 'src/pages/computers-page/d-computer-unit-printer.js';
import type { LocalEditItem } from 'src/pages/computers-page/d-computer-unit-local.js';
import type { ExternalConnectionEditItem } from 'src/pages/computers-page/d-external-connection-view.js';
import type { NetworkConnectionEditItem } from 'src/pages/computers-page/d-network-connection-view.js';
import type { NetworkFirewallEditItem } from 'src/pages/computers-page/d-network-firewall-view.js';
import type { NetworkEditItem } from 'src/pages/computers-page/d-network-item-view.js';
import type { DataEditItem } from 'src/pages/computers-page/d-data-item-view.js';
import type {
  AddDataItemApplication,
  AddDataItemCloudServiceApplication,
  AddDataItemComputerApplication,
  AddDataItemRemoteServerApplication,
  DataItemApplicationChange,
  DataItemApplicationComputerChange,
  DataItemApplicationSupplierChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import { dataItemChanged } from 'src/d-app--data-item-changed.js';
import type { BackupChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-backup.js';
import type { NetworkExternalConnectionChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-network-external-connections.js';
import { uuid } from 'src/utilities/text.js';
import type { AccessChange } from 'src/pages/computers-page/infosec-procedure/d-infosec-access.js';
import type { OrganizationState } from 'src/store/types.js';

function countAttachedPersonalItems(
  computerUuid: string,
  personalDataItemsById: { [p: string]: PersonalDataItemViewModel },
): number {
  return Object.values(personalDataItemsById).filter((p) => p.storageUnit === computerUuid).length;
}
function countAttachedComputers(partnerUuid: string, computersById: { [p: string]: ComputerViewModel }): number {
  return Object.values(computersById).filter((p) => p.serviceProvider === partnerUuid).length;
}

async function removeDataItemStorageUnit(
  api: ApiClient,
  o: OrganizationState,
  element: PersonalDataItemViewModel,
  now: Date,
  storageUnit: string,
) {
  const computer = o.computersById[storageUnit];
  if (computer) {
    if (computer.createdDateTime) {
      const d = new Date(computer.createdDateTime);
      const seconds = (now.getTime() - d.getTime()) / 1000;
      if (seconds < 30 && countAttachedPersonalItems(computer.uuid, o.personalDataItemsById) < 2) {
        const p: DeleteComputerRequest = {
          organizationId: o.organizationId.toString(),
          computerId: computer.uuid,
        };
        await api.computers.deleteComputer(p);
      }
    }
    if (computer.serviceProvider) {
      const partner = o.partnersById[computer.serviceProvider];
      if (partner && partner.contentLastModified && partner.contentLastModifiedWasCreate) {
        const d = new Date(partner.contentLastModified);
        const seconds = (now.getTime() - d.getTime()) / 1000;
        const attachedComputers = countAttachedComputers(partner.uuid, o.computersById);
        if (seconds < 30 && attachedComputers < 2) {
          const p: DeletePartnerRequest = {
            organizationId: o.organizationId.toString(),
            partnerId: partner.uuid,
          };
          await api.partners.deletePartner(p);
        }
      }
    }
  }
}

async function removeDataItemAssets(
  api: ApiClient,
  element: PersonalDataItemViewModel,
  o: OrganizationState,
  now: Date,
) {
  for (const relatedAsset of element.relatedAssets ?? []) {
    const a = o.assetsById[relatedAsset];
    if (a && a.createdDateTime) {
      const d = new Date(a.createdDateTime);

      const seconds = (now.getTime() - d.getTime()) / 1000;
      if (seconds < 30) {
        const p: DeleteAssetRequest = {
          organizationId: o.organizationId.toString(),
          assetId: a.uuid,
        };
        await api.assets.deleteAsset(p);
      }
    }
  }
}

async function removeDataItemAndSystemCreatedEntities(
  api: ApiClient,

  o: OrganizationState,
  e: CustomEvent<{ uuid: string; dataType: string; category: string }>,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    const now = new Date();
    if (element.type === 'patientData.assetData') {
      await removeDataItemAssets(api, element, o, now);
    }
    if (element.storageUnit) {
      await removeDataItemStorageUnit(api, o, element, now, element.storageUnit);
    }

    const p: DeletePersonalDataItemRequest = {
      organizationId: o.organizationId.toString(),
      personalDataItemsId: element.uuid,
    };
    await api.computers.deletePersonalDataItem(p);
  }
}

/**
 * Når brukeren sletter et dataItem skal også alle relaterte elementer som er opprettet for mindre enn 30 sekunder siden slettes.
 * Relaterte elementer kan være
 *
 * utstyrsenheter som identifiseres i feltet assets
 * lagringsenhet som identifiseres i feltet storageUnit (slettes bare hvis de ikke er referert i andre dataItems)
 * samarbeidspartnere som identifiseres i feltet partners i den relaterte lagringsenheten
 *
 * @param e
 */
export async function dataItemsRemovedHandler(
  api: ApiClient,
  e: CustomEvent<{ uuid: string; dataType: string; category: string }>,
) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    await removeDataItemAndSystemCreatedEntities(api, o, e);
  }
}

export async function computerNetworkChangeHandler(
  api: ApiClient,
  e: CustomEvent<ComputerNetworkChange>,
  organizationId: string,
) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    if (e.detail.newNetwork) {
      const createNetworkRequest: CreateOrUpdateNetworkRequest = {
        organizationId: organizationId,
        networkId: e.detail.networkUuid,
        networkUpdateMessage: {
          name: e.detail.newNetworkName,
          connectionType: e.detail.newNetworkConnectionType,
          networkType: e.detail.newNetworkType as NetworkUpdateMessageNetworkTypeEnum,
          isConfirmedEntity: true,
        },
      };
      await api.computers.createOrUpdateNetwork(createNetworkRequest);
    }

    if (e.detail.newComputer) {
      const createComputerRequest: CreateOrUpdateComputerRequest = {
        organizationId: organizationId,
        computerId: e.detail.computerUuid,
        computerUpdateMessage: {
          location: '',
          name: e.detail.newComputerName,
          type: e.detail.newComputerType,
          networkUuid: e.detail.networkUuid,
          unitType: 'computer',
          serviceProvider: '',
          connectionType: '',
          serviceProviderContract: '',
          systemUpdateOperatorType: 'UNDEFINED',
          systemUpdateOperator: undefined,
          antiVirusOperatorType: 'UNDEFINED',
          antiVirusOperator: undefined,
          antiVirusRequirements: [],
          locked: false,
          restrictedPhysicalAccess: false,
          displayPositionSecure: false,
          elevated: false,
          mobileMedicalDataRequirements: [],
          notes: undefined,
          isConfirmedEntity: true,
        },
      };
      await api.computers.createOrUpdateComputer(createComputerRequest);
    }

    const p: UpdateUpdateNetworkComputerRequest = {
      organizationId: o.organizationId.toString(),
      entityId: e.detail.computerUuid,
      updateNetworkComputerMessage: {
        networkId: e.detail.networkUuid,
      },
    };

    await api.computers.updateUpdateNetworkComputer(p);
  }
}

export async function mapElementChangedHandler(
  api: ApiClient,

  e: CustomEvent<MapElementChangedDetail>,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  switch (e.detail.unitType) {
    case 'cloudService':
      await cloudServiceChangedHandler(api, e.detail.uuid, e.detail.element, organizationId);
      break;
    case 'remoteServer':
      await remoteServerChangedHandler(api, e.detail.uuid, e.detail.element, organizationId);
      break;
    case 'printer':
      await printerChangedHandler(api, e.detail.uuid, e.detail.element, organizationId);
      break;
    case 'computer':
      await computerChangedHandler(api, e.detail.uuid, e.detail.element, organizationId);
      break;
    case 'externalConnection':
      await externalConnectionChangedHandler(api, e.detail.uuid, e.detail.element, organizationId);
      break;
    case 'connection':
      await connectionChangedHandler(api, e.detail.uuid, e.detail.element);
      break;
    case 'firewall':
      await firewallChangedHandler(api, e.detail.uuid, e.detail.element);
      break;
    case 'network':
      await networkChangedHandler(api, e.detail.uuid, e.detail.element);
      break;
    case 'personalDataItem':
      await personalDataItemChangedHandler(
        api,
        e.detail.uuid,
        e.detail.element,
        e.detail.extra,
        showNotification,
        organizationId,
      );
      break;
  }
}

async function cloudServiceChangedHandler(
  api: ApiClient,
  uuid: string,
  editItem: CloudServiceEditItem,
  organizationId: string,
) {
  const p: CreateOrUpdateComputerRequest = {
    organizationId: organizationId,
    computerId: uuid,
    computerUpdateMessage: {
      location: '',
      name: editItem.serviceName,
      type: 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'cloudService',
      serviceProvider: editItem.supplierUuid,
      connectionType: editItem.connectionType,
      serviceProviderContract: editItem.contractUuid,
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    },
  };

  await api.computers.createOrUpdateComputer(p);
}

async function remoteServerChangedHandler(
  api: ApiClient,
  uuid: string,
  editItem: RemoteServerEditItem,
  organizationId: string,
) {
  const p: CreateOrUpdateComputerRequest = {
    organizationId: organizationId,
    computerId: uuid,
    computerUpdateMessage: {
      location: '',
      name: '',
      type: 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'remoteServer',
      serviceProvider: editItem.supplierUuid,
      connectionType: editItem.connectionType,
      serviceProviderContract: editItem.contractUuid,
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    },
  };

  await api.computers.createOrUpdateComputer(p);
}

async function printerChangedHandler(api: ApiClient, uuid: string, element: PrinterEditItem, organizationId: string) {
  const p: CreateOrUpdateComputerRequest = {
    organizationId: organizationId,
    computerId: uuid,
    computerUpdateMessage: {
      location: element.location,
      name: element.name,
      type: 'UNDEFINED',
      networkUuid: element.networkUuid,
      unitType: 'printer',
      serviceProvider: '',
      connectionType: '',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: element.notes,
      printerPositionSecure: element.printerPositionSecure,
      isConfirmedEntity: true,
    },
  };
  await api.computers.createOrUpdateComputer(p);
}

async function computerChangedHandler(api: ApiClient, uuid: string, element: LocalEditItem, organizationId: string) {
  const p: CreateOrUpdateComputerRequest = {
    organizationId: organizationId,
    computerId: uuid,
    computerUpdateMessage: {
      location: element.location,
      name: element.name,
      type: element.type,
      networkUuid: element.networkUuid,
      unitType: 'computer',
      serviceProvider: undefined,
      connectionType: 'NONE',
      systemUpdateOperatorType: element.systemUpdateOperatorType as ComputerUpdateMessageSystemUpdateOperatorTypeEnum,
      systemUpdateOperator: element.systemUpdateOperator,
      antiVirusOperatorType: element.antiVirusOperatorType as ComputerUpdateMessageAntiVirusOperatorTypeEnum,
      antiVirusOperator: element.antiVirusOperator,
      antiVirusRequirements: element.antiVirusRequirements,
      locked: element.locked,
      restrictedPhysicalAccess: element.restrictedPhysicalAccess,
      displayPositionSecure: element.displayPositionSecure,
      elevated: element.elevated,
      mobileMedicalDataRequirements: element.mobileMedicalDataRequirements,
      notes: element.notes,
      isConfirmedEntity: true,
    },
  };

  await api.computers.createOrUpdateComputer(p);
}

async function externalConnectionChangedHandler(
  api: ApiClient,
  uuid: string,
  element: ExternalConnectionEditItem,
  organizationId: string,
) {
  const p: CreateOrUpdateExternalConnectionRequest = {
    organizationId: organizationId,
    externalConnectionId: uuid,
    externalConnectionUpdateMessage: {
      networkUuid: element.networkUuid,
      name: '',
      description: element.description,
      connectionType: element.connectionType as ExternalConnectionUpdateMessageConnectionTypeEnum,
      supplierUuid: undefined,
      employeeUuid: element.employeeUuid,
      partnerUuid: element.partnerUuid,
      type: element.type,
      otherRiskAssessments: element.otherRiskAssessments,
      riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
      riskAssessments: element.riskAssessments,
      isConfirmedEntity: true,
    },
  };

  await api.computers.createOrUpdateExternalConnection(p);
}

async function connectionChangedHandler(api: ApiClient, uuid: string, element: NetworkConnectionEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const n = o.networksById[uuid];

    const p: CreateOrUpdateNetworkRequest = {
      organizationId: o.organizationId.toString(),
      networkId: uuid,
      networkUpdateMessage: {
        name: n.name,
        connectionType: element.connectionType,
        supplierUuid: element.supplierUuid,
        firewall: n.firewall,
        firewallName: n.firewallName,
        systemUpdateOperatorType: n.systemUpdateOperatorType,
        systemUpdateOperator: n.systemUpdateOperator,
        antiVirusOperatorType: n.antiVirusOperatorType,
        antiVirusOperator: n.antiVirusOperator,
        networkType: n.networkType,
        isConfirmedEntity: true,
      },
    };

    await api.computers.createOrUpdateNetwork(p);
  }
}

async function firewallChangedHandler(api: ApiClient, uuid: string, element: NetworkFirewallEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const n = o.networksById[uuid];
    const p: CreateOrUpdateNetworkRequest = {
      organizationId: o.organizationId.toString(),
      networkId: uuid,
      networkUpdateMessage: {
        name: n.name,
        connectionType: n.connectionType,
        supplierUuid: n.supplierUuid,
        firewall: element.firewallType as NetworkUpdateMessageFirewallEnum,
        firewallName: element.firewallName,
        systemUpdateOperatorType: n.systemUpdateOperatorType,
        systemUpdateOperator: n.systemUpdateOperator,
        antiVirusOperatorType: n.antiVirusOperatorType,
        antiVirusOperator: n.antiVirusOperator,
        networkType: n.networkType,
        isConfirmedEntity: true,
      },
    };
    await api.computers.createOrUpdateNetwork(p);
  }
}

async function networkChangedHandler(api: ApiClient, uuid: string, element: NetworkEditItem) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const p: CreateOrUpdateNetworkRequest = {
      organizationId: o.organizationId.toString(),
      networkId: uuid,
      networkUpdateMessage: {
        name: element.name,
        connectionType: element.connectionType,
        supplierUuid: element.supplierUuid,
        firewall: element.firewallType as NetworkUpdateMessageFirewallEnum,
        firewallName: element.firewallName,
        systemUpdateOperatorType: element.systemUpdateOperatorType as NetworkUpdateMessageSystemUpdateOperatorTypeEnum,
        systemUpdateOperator: element.systemUpdateOperator,
        antiVirusOperatorType: element.antiVirusOperatorType as NetworkUpdateMessageAntiVirusOperatorTypeEnum,
        antiVirusOperator: element.antiVirusOperator,
        networkType: element.type as NetworkUpdateMessageNetworkTypeEnum,
        isConfirmedEntity: true,
      },
    };

    await api.computers.createOrUpdateNetwork(p);
  }
}

async function personalDataItemChangedHandler(
  api: ApiClient,

  uuid: string,
  element: DataEditItem,
  extra: AddDataItemApplication | undefined,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  let fields = {};
  if (extra !== undefined) {
    await createNewApplication(api, uuid, extra, element.dataType, element.category, showNotification, organizationId);
    const state = getStore().getState();
    const o = getOrganization(state);
    if (o !== undefined) {
      const newItem = o.personalDataItemsById[uuid];

      fields = {
        storageUnit: newItem.storageUnit,
      };
    }
  }

  const p: CreateOrUpdatePersonalDataItemRequest = {
    organizationId: organizationId,
    personalDataItemsId: uuid,
    personalDataItemUpdateMessage: {
      establishedDate: '',
      name: element.name,
      type: element.dataType + '.' + element.category,
      storageMedium: '',
      storageUnit: element.storageUnit,
      storageLocation: '',
      backupOperatorType: PersonalDataItemUpdateMessageBackupOperatorTypeEnum.Undefined,
      backupOperator: undefined,
      application: element.application,
      journalSupplier: undefined,
      journalSize: undefined,
      sharedMedicalRecords: element.sharedMedicalRecords,
      sharedMedicalRecordsPartners: element.sharedMedicalRecordsPartners,
      sharedMedicalRecordsContracts:
        element.sharedMedicalRecordsContracts !== '' ? [element.sharedMedicalRecordsContracts] : [],
      treatments: undefined,
      purpose: element.purpose,
      legalBasis: element.legalBasis,
      consentContracts: element.consentContracts,
      journalHasElectronicMessaging: element.journalHasElectronicMessaging,
      messagingType: element.messagingType,
      messagingTypeOther: element.messagingTypeOther,
      messagingSupplier: element.messagingSupplier,
      messageMonitoring: element.messageMonitoring,
      autoLookupPatients: element.autoLookupPatients,
      autoLookupAddress: element.autoLookupAddress,
      autoLookupAddressNhn: element.autoLookupAddressNhn,
      autoUpdateAddressNhn: element.autoUpdateAddressNhn,
      riskAssessments: undefined,
      otherRiskAssessments: '',
      riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
      patientDialogueRequirements: element.patientDialogueRequirements,
      notes: element.notes,
      accessingEmployees: element.accessingEmployees,
      accessingPartners: element.accessingPartners,
      backupFrequency: element.backupFrequency,
      backupResponsible: element.backupResponsible,
      noBackupReason: element.noBackupReason,
      personalData: element.personalData,
      relatedAssets: element.relatedAssets,
      ...fields,
      isConfirmedEntity: true,
    },
  };

  await api.computers.createOrUpdatePersonalDataItem(p);
}

async function createNewApplication(
  api: ApiClient,

  uuid: string,
  extra: AddDataItemComputerApplication | AddDataItemCloudServiceApplication | AddDataItemRemoteServerApplication,
  dataType: string,
  category: string,
  showNotification: (s: string) => void,
  organizationId: string,
) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    switch (extra.storageUnitType) {
      case 'computers': {
        const c: DataItemApplicationComputerChange = extra.newComputer
          ? {
              newComputer: extra.newComputer,
              newComputerName: extra.newComputerName ?? '',
              newComputerType: extra.newComputerType ?? '',
            }
          : { newComputer: extra.newComputer, computerUuid: extra.computerUuid };
        await dataItemChanged(
          api,
          o,
          new CustomEvent<DataItemApplicationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: uuid,
              application: extra.application,

              storageUnitType: 'computers',

              ...c,
              dataType: dataType,
              category: category,
            },
          }),
          state,
          showNotification,
          organizationId,
        );
        break;
      }
      case 'cloudServices':
      case 'remoteServers': {
        const s: DataItemApplicationSupplierChange = extra.newSupplier
          ? { newSupplier: true, newSupplierName: extra.newSupplierName, supplierUuid: extra.supplierUuid }
          : { newSupplier: false, supplierUuid: extra.supplierUuid };
        await dataItemChanged(
          api,
          o,
          new CustomEvent<DataItemApplicationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: uuid,
              application: extra.application,

              storageUnitType: extra.storageUnitType,

              dataType: dataType,
              category: category,
              ...s,
            },
          }),
          state,
          showNotification,
          organizationId,
        );
      }
    }
  }
}

export async function backupChangeHandler(api: ApiClient, e: CustomEvent<BackupChange>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const p: UpdateBackupForPersonalDataItemRequest = {
      organizationId: o.organizationId.toString(),
      entityId: e.detail.uuid,
      dataItemBackupUpdateMessage: {
        backupFrequency: e.detail.backupFrequency,
        backupResponsible: e.detail.backupResponsible,
        noBackupReason: e.detail.noBackupReason,
      },
    };
    await api.computers.updateBackupForPersonalDataItem(p);
  }
}

export async function externalConnectChangeHandler(
  api: ApiClient,
  e: CustomEvent<NetworkExternalConnectionChange>,
  organizationId: string,
) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    const p: CreateOrUpdateExternalConnectionRequest = {
      organizationId: organizationId,
      externalConnectionId: e.detail.uuid ?? uuid(),
      externalConnectionUpdateMessage: {
        networkUuid: e.detail.networkUuid,
        name: '',
        description: '',
        connectionType: e.detail.connectionType as ExternalConnectionUpdateMessageConnectionTypeEnum,
        supplierUuid: e.detail.type === 'Tilkoblet samarbeidspartner' ? e.detail.partnerUuid : undefined,
        employeeUuid: e.detail.type === 'Hjemmekontor' ? e.detail.employeeUuid : undefined,
        partnerUuid: e.detail.type === 'Tilkoblet samarbeidspartner' ? e.detail.partnerUuid : undefined,
        type: e.detail.type,
        riskAssessments: [],
        otherRiskAssessments: '',
        riskAssessmentConcludesOk: false,
        isConfirmedEntity: true,
      },
    };

    await api.computers.createOrUpdateExternalConnection(p);
  }
}

export async function accessChangeHandler(api: ApiClient, e: CustomEvent<AccessChange>, organizationId: string) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    if (e.detail.newPartner !== undefined) {
      await createPartner(api, e.detail.newPartner.value, e.detail.newPartner.text, organizationId);
    }

    const p: UpdateAccessForPersonalDataItemRequest = {
      organizationId: o.organizationId.toString(),
      entityId: e.detail.uuid,
      dataItemAccessUpdateMessage: {
        accessingPartners: e.detail.accessingPartners,
        accessingEmployees: e.detail.accessingEmployees,
      },
    };
    await api.computers.updateAccessForPersonalDataItem(p);
  }
}

export async function mapElementDeletedHandler(api: ApiClient, e: CustomEvent<{ uuid: string; unitType: string }>) {
  const state = getStore().getState();
  const o = getOrganization(state);
  if (o !== undefined) {
    switch (e.detail.unitType) {
      case 'printer':
      case 'remoteServer':
      case 'cloudService':
      case 'computer':
        {
          const p: DeleteComputerRequest = {
            organizationId: o.organizationId.toString(),
            computerId: e.detail.uuid,
          };
          await api.computers.deleteComputer(p);
        }
        break;
      case 'externalConnection':
        {
          const p: DeleteExternalConnectionRequest = {
            organizationId: o.organizationId.toString(),
            externalConnectionId: e.detail.uuid,
          };
          await api.computers.deleteExternalConnection(p);
        }
        break;
      case 'connection':
      case 'firewall':
      case 'network':
        {
          const p: DeleteNetworkRequest = {
            organizationId: o.organizationId.toString(),
            networkId: e.detail.uuid,
          };
          await api.computers.deleteNetwork(p);
        }
        break;
      case 'personalDataItem':
        {
          const p: DeletePersonalDataItemRequest = {
            organizationId: o.organizationId.toString(),
            personalDataItemsId: e.detail.uuid,
          };
          await api.computers.deletePersonalDataItem(p);
        }
        break;
    }
  }
}
