import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { AccountUpdateMessageReceiveRemindersEnum, AccountUpdateMessageSummariesEnum } from 'src/store/api';
import { displayAlert } from 'src/utilities/display-alert.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-select-radio.js';
import '../../library/editors/elements/d-checkbox';
import '../../library/elements/d-section.js';
import '../../library/presentational/d-sections.js';
import { headerViewStyles } from 'src/layout/parts/header-view-styles.js';
import { features } from 'src/store/selectors/features';
import type { FeatureStates } from 'src/store/selectors/features';
import type { UiSettings } from 'src/store/selectors/ui';

export interface ProfileUser {
  username: string;
  token: string;
  criiptoAuthDescription: string;
  authDescription: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-organization-profile')
export class DOrganizationProfile extends LitElement {
  static alertOptions = [
    {
      value: 'ALL',
      text: 'Etableringsoppgaver og regelmessige oppgaver',
    },
    { value: 'SCHEDULED', text: 'Bare regelmessige oppgaver' },
    { value: 'NONE', text: 'Ingen epostvarsler' },
  ];
  static receiveRemindersOptions = [
    { value: 'NONE', text: 'Ingen påminnelser' },
    { value: 'SMS', text: 'Tekstmelding' },
  ];
  static readonly styles = [
    headerViewStyles,
    css`
      :host {
        display: block;
      }

      div.flexWrapper {
        display: block;
        flex-wrap: wrap;
        align-items: stretch;
        border-bottom: 1px solid var(--borderColor);
        overflow: hidden;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 160%;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .action {
        padding: 6px 0;
        font-family: var(--small);
        font-weight: 600;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: white;
        cursor: pointer;
      }

      d-wrap {
        width: 100%;
      }

      d-checkbox {
        margin-bottom: 6px;
      }
    `,
  ];
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Object })
  user!: ProfileUser;
  @property({ type: String })
  newEmail = '';
  @property({ type: String })
  newPassword = '';
  @property({ type: String })
  newPasswordConfirm = '';
  @property({ type: String })
  lastPassword = '';
  @property({ type: Object })
  employee = {};
  @property({ type: String })
  alerts = 'NONE';
  @property({ type: String })
  receiveReminders = 'NONE';
  @property({ type: Array })
  features: string[] = [];
  @property({ type: String })
  ownerEmail = '';
  @property({ type: Object })
  organization: any = {};
  @property({ type: Boolean })
  isEmployee = false;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Boolean })
  storybook = false;
  @property({ type: Object })
  uiSettings: UiSettings = {
    isSimplifiedUi: false,
  };
  static get observers() {
    return [
      '_usernameChanged(user.username)',
      '_employeeAlertsChanged(employee.summaries, employee)',
      '_employeeReceiveRemindersChanged(employee.receiveReminders, employee)',
    ];
  }

  private get appVersion() {
    if (this.storybook) {
      return new Date('2000-01-01').toLocaleString();
    }
    return new Date(__APP_VERSION__).toLocaleString();
  }

  renderWeeklyAlert() {
    if (this.featureStates.core) {
      return html`
        <div>
          <h2>Ukentlig epostvarsel</h2>
          <p>
            Hver uke sender TrinnVis deg en oversikt over dine oppgaver – hvis du har oppgaver den uken.
            Etableringsoppgaver er automatiske oppgaver som hjelper deg å ta TrinnVis i bruk. Disse oppgavene blir til
            vanlige, regelmessige oppgaver når de er utført første gang.
          </p>
          <d-select-radio
            vertical
            outskirts
            .options=${DOrganizationProfile.alertOptions}
            .value=${this.alerts}
            @value-changed=${this.onAlertsChanged}
          >
          </d-select-radio>
        </div>
      `;
    }
    return nothing;
  }

  renderEmployeeOptions() {
    if (this.isEmployee) {
      return html`
        ${this.renderWeeklyAlert()}
        <div>
          <h2>Påminnelser på SMS</h2>
          <p>
            For oppgaver eller møter med klokkeslett kan det stilles inn påminnelser på SMS. For å motta påminnelser må
            du ha registrert mobilnummer under personale.
          </p>
          <d-select-radio
            vertical
            outskirts
            .options=${DOrganizationProfile.receiveRemindersOptions}
            .value=${this.receiveReminders}
            @value-changed=${this.onReceiveRemindersChanged}
          >
          </d-select-radio>
        </div>
      `;
    }
    return nothing;
  }

  render() {
    return html`
      <d-sections>
        ${this.ownerEmail.endsWith('@trinnvis.no') || this.ownerEmail.endsWith('@mailinator.com')
          ? html` <div>
                <h2>Moduler (beta)</h2>
                ${features
                  .filter((f) => f.module)
                  .map((f) => {
                    return html` <d-checkbox
                      outskirts
                      wrap-label
                      .optionLabel=${f.displayName}
                      .checked=${this.features.includes(f.id)}
                      @checked-changed=${() => {
                        this.toggleFeature(f.id);
                      }}
                    ></d-checkbox>`;
                  })}
              </div>
              <div>
                <h2>Pakker (beta)</h2>
                ${features
                  .filter((f) => f.bundle)
                  .map((f) => {
                    return html` <d-checkbox
                      outskirts
                      wrap-label
                      .optionLabel=${f.displayName + ' (' + f.description + ')'}
                      .checked=${this.features.includes(f.id)}
                      @checked-changed=${() => {
                        this.toggleFeature(f.id);
                      }}
                    ></d-checkbox>`;
                  })}
              </div>`
          : nothing}
        ${this.renderEmployeeOptions()}
        <div>
          <h2>Brukernavn og passord</h2>
          <d-wrap>
            <d-edit-text
              outskirts
              style="flex-grow: 1"
              label="Brukernavn (epostadresse)"
              .value=${this.newEmail}
              @value-changed=${this.onNewEmailChanged}
            ></d-edit-text>
          </d-wrap>
          <d-wrap>
            <d-edit-text
              outskirts
              style="flex-grow: 1"
              autocomplete="new-password"
              class="flexBasis300"
              label="Nytt passord"
              type="password"
              .value=${this.newPassword}
              @value-changed=${this.onNewPasswordChanged}
            ></d-edit-text>
            <d-edit-text
              outskirts
              style="flex-grow: 1"
              autocomplete="new-password"
              class="flexBasis300"
              label="Gjenta nytt passord"
              type="password"
              .value=${this.newPasswordConfirm}
              @value-changed=${this.onNewPasswordConfirmChanged}
            ></d-edit-text>
          </d-wrap>
          <d-wrap split>
            <div></div>
            <div class="action" @click=${this.onUpdateProfile}>Oppdater</div>
          </d-wrap>
        </div>

        <div>
          <h2>Tilkobling til BankID</h2>
          <d-wrap split>
            <p>
              ${this._hasCriiptoAuthDescription(this.user)
                ? 'Kontoen er tilkoblet BankID: ' + this.user.criiptoAuthDescription
                : 'Kontoen er ikke tilkoblet BankID'}
            </p>
            ${this._hasCriiptoAuthDescription(this.user)
              ? html` <div class="action" @click=${() => this._disconnectFromAuthProviderCriipto()}>Koble fra</div>`
              : html` <div class="action" @click=${() => this._connectToAuthProviderCriipto()}>Koble til</div>`}
          </d-wrap>
        </div>

        <div>
          <h2>Tilkobling til HelseID</h2>
          <d-wrap split>
            <p>
              ${this._hasAuthDescription(this.user)
                ? 'Kontoen er tilkoblet HelseID: ' + this.user.authDescription
                : 'Kontoen er ikke tilkoblet HelseID'}
            </p>

            ${this._hasAuthDescription(this.user)
              ? html` <div class="action" @click=${() => this._disconnectFromAuthProvider()}>Koble fra</div>`
              : html` <div class="action" @click=${() => this._connectToAuthProvider()}>Koble til</div>`}
          </d-wrap>
        </div>

        <div>
          <h2>Versjon</h2>
          <d-wrap split>
            <p>${this.appVersion}</p>
            <div class="action" @click=${() => this.forceServiceWorkerUpdate()}>Oppdater versjon</div>
          </d-wrap>
        </div>
      </d-sections>
    `;
  }

  private async forceServiceWorkerUpdate() {
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.update();
      }
      window.location.reload();
    }
  }

  private onUpdateProfile() {
    const newPasswordLength = this.newPassword.length;
    if (newPasswordLength > 0 && newPasswordLength < 4) {
      displayAlert('Passordet m\xE5 best\xE5 av minst fire tegn');
    } else if (this.newPassword !== this.newPasswordConfirm) {
      displayAlert('Du har ikke gjentatt passordet riktig');
    } else {
      this.dispatchEvent(
        new CustomEvent<{
          email: string;
          password?: string;
          alerts: string;
          receiveReminders: string;
        }>('update-user', {
          composed: true,
          bubbles: true,
          detail: {
            email: this.newEmail,
            password: this.newPassword === '' ? undefined : this.newPassword,
            alerts: this.alerts as AccountUpdateMessageSummariesEnum,
            receiveReminders: this.receiveReminders as AccountUpdateMessageReceiveRemindersEnum,
          },
        }),
      );
      this.dispatchEvent(new CustomEvent('close-settings', { bubbles: true, composed: true }));
      this.newPassword = '';
      this.newPasswordConfirm = '';
    }
  }

  private onAlertsChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.alerts = e.detail.value;
  }

  private onReceiveRemindersChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.receiveReminders = e.detail.value;
  }

  private onNewEmailChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.newEmail = e.detail.value;
  }

  private onNewPasswordChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.newPassword = e.detail.value;
  }

  private onNewPasswordConfirmChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.newPasswordConfirm = e.detail.value;
  }

  private _connectToAuthProvider() {
    this.dispatchEvent(
      new CustomEvent<{ provider: 'oidc' | 'criipto' }>('link-auth', {
        composed: true,
        bubbles: true,
        detail: { provider: 'oidc' },
      }),
    );
  }

  private _disconnectFromAuthProvider() {
    this.dispatchEvent(
      new CustomEvent<{ provider: 'oidc' | 'criipto' }>('unlink-auth', {
        composed: true,
        bubbles: true,
        detail: { provider: 'oidc' },
      }),
    );
  }

  private _connectToAuthProviderCriipto() {
    this.dispatchEvent(
      new CustomEvent<{ provider: 'oidc' | 'criipto' }>('link-auth', {
        composed: true,
        bubbles: true,
        detail: { provider: 'criipto' },
      }),
    );
  }

  private _disconnectFromAuthProviderCriipto() {
    this.dispatchEvent(
      new CustomEvent<{ provider: 'oidc' | 'criipto' }>('unlink-auth', {
        composed: true,
        bubbles: true,
        detail: { provider: 'criipto' },
      }),
    );
  }

  private _hasCriiptoAuthDescription(user: { criiptoAuthDescription: string }) {
    return user.criiptoAuthDescription !== '';
  }

  private _hasAuthDescription(user: { authDescription: string }) {
    return user.authDescription !== '';
  }

  private toggleFeature(feature: string) {
    this.dispatchEvent(
      new CustomEvent<{ feature: string }>('toggle-feature', {
        composed: true,
        bubbles: true,
        detail: { feature: feature },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-profile': DOrganizationProfile;
  }
}
