/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountEmployeeViewModel
 */
export interface AccountEmployeeViewModel {
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  accessLevel: AccountEmployeeViewModelAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  associationType?: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  profession?: string;
  /**
   *
   * @type {string}
   * @memberof AccountEmployeeViewModel
   */
  status?: AccountEmployeeViewModelStatusEnum;
}

/**
 * @export
 */
export const AccountEmployeeViewModelAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type AccountEmployeeViewModelAccessLevelEnum =
  (typeof AccountEmployeeViewModelAccessLevelEnum)[keyof typeof AccountEmployeeViewModelAccessLevelEnum];

/**
 * @export
 */
export const AccountEmployeeViewModelStatusEnum = {
  Active: 'ACTIVE',
  Leave: 'LEAVE',
  Terminated: 'TERMINATED',
} as const;
export type AccountEmployeeViewModelStatusEnum =
  (typeof AccountEmployeeViewModelStatusEnum)[keyof typeof AccountEmployeeViewModelStatusEnum];

/**
 * Check if a given object implements the AccountEmployeeViewModel interface.
 */
export function instanceOfAccountEmployeeViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'accessLevel' in value;

  return isInstance;
}

export function AccountEmployeeViewModelFromJSON(json: any): AccountEmployeeViewModel {
  return AccountEmployeeViewModelFromJSONTyped(json, false);
}

export function AccountEmployeeViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountEmployeeViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    accessLevel: json['accessLevel'],
    associationType: !exists(json, 'associationType') ? undefined : json['associationType'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    status: !exists(json, 'status') ? undefined : json['status'],
  };
}

export function AccountEmployeeViewModelToJSON(value?: AccountEmployeeViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    firstName: value.firstName,
    lastName: value.lastName,
    accessLevel: value.accessLevel,
    associationType: value.associationType,
    email: value.email,
    phone: value.phone,
    profession: value.profession,
    status: value.status,
  };
}
