/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CriiptoTokenLoginResponse
 */
export interface CriiptoTokenLoginResponse {
  /**
   *
   * @type {string}
   * @memberof CriiptoTokenLoginResponse
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof CriiptoTokenLoginResponse
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof CriiptoTokenLoginResponse
   */
  username: string;
}

/**
 * Check if a given object implements the CriiptoTokenLoginResponse interface.
 */
export function instanceOfCriiptoTokenLoginResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'accessToken' in value;
  isInstance = isInstance && 'refreshToken' in value;
  isInstance = isInstance && 'username' in value;

  return isInstance;
}

export function CriiptoTokenLoginResponseFromJSON(json: any): CriiptoTokenLoginResponse {
  return CriiptoTokenLoginResponseFromJSONTyped(json, false);
}

export function CriiptoTokenLoginResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CriiptoTokenLoginResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json['accessToken'],
    refreshToken: json['refreshToken'],
    username: json['username'],
  };
}

export function CriiptoTokenLoginResponseToJSON(value?: CriiptoTokenLoginResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessToken: value.accessToken,
    refreshToken: value.refreshToken,
    username: value.username,
  };
}
