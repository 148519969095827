import type { State } from '../../store/types.js';

import {
  activityCodesAvailable,
  BASE_PATH,
  currentEmployeeUuid,
  employeesNotDeleted,
  groupBy,
  isIssueProcessed,
  issuesNotDeleted,
  key,
  toIssueViewModel,
} from 'src/store';
import { LocalDate } from '../../utilities/local-date.js';
import { IssuesPageViewModel } from 'src/pages/issues-page/d-issues-page-content';
import type { ItemGroup } from 'src/pages/organization-page/organization-page-view-model';
import _ from 'lodash';
import { commonFieldsByCode } from 'src/store/fields';
import { AbstractPageView } from 'src/pages/abstract-page-view';
import { getIssueLabel } from 'src/store/selectors/issues';

function formatDate(value: string | undefined) {
  if (value) {
    return LocalDate.fromString(value).toStringForDisplay();
  }
  return 'Uten dato';
}

function getGroupedIssues(state: State, hrefPrefix: string) {
  const groupNames = {
    ferdigbehandlet: 'Ferdigbehandlede',
    aktiv: 'Aktive',
  };
  const issues = issuesNotDeleted(state)
    .map((i) => toIssueViewModel(i))
    .map((i) => {
      return {
        ...i,
        reportedDateFormatted: formatDate(i.reportedDate),
        status: isIssueProcessed(i) ? 'ferdigbehandlet' : 'aktiv',
      };
    });
  const groupedIssues = groupBy(issues, (e) => e.status ?? '');
  const result: ItemGroup[] = _.sortBy(groupedIssues, [(g) => g.group !== 'aktiv', (g) => g.group]).map((g) => {
    return {
      label: groupNames[g.group],
      tooltip: commonFieldsByCode()['issues_closed'].tooltip,
      filtered: g.group === 'ferdigbehandlet',
      items: _.sortBy(g.items, (item) => item.reportedDate).map((item) => {
        const classification = item.classification ?? 'NONE';
        const accessControl = item.accessControl ?? [];
        const accessible =
          item.classification === 'NONE' ||
          (item.accessControl && item.accessControl.includes(currentEmployeeUuid(state) ?? ''));
        return {
          uuid: item.uuid,
          label: getIssueLabel(item, accessible ?? false, employeesNotDeleted(state)),
          dateDisplay: item.reportedDateFormatted,
          accessControl: accessControl,
          classification: classification,
          locked: classification !== 'NONE',
          accessible: accessible ?? false,
          href: hrefPrefix + 'issues/' + item.uuid,
          relatedItems: [],
          hasDraft: item.hasDraft,
        };
      }),
    };
  });
  return result;
}

export function issuesPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): IssuesPageViewModel {
  const o = state.organization;
  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }
  return {
    ...viewModel,
    type: 'issues-page',
    icon: 'issues',
    href: hrefPrefix,
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    issues: getGroupedIssues(state, hrefPrefix),
  };
}
