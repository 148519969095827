import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/fields/d-view-html';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/editors/components/d-select-add';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section.js';
import '../../library/components/d-signatures-section';
import '../../library/components/d-view-attachments';
import { customElement } from 'lit/decorators.js';
import { html, nothing, TemplateResult } from 'lit';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { Checklist } from 'src/library/fields/d-checklist.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { PartnerForSigning, PersonForSigning } from 'src/layout/parts/request-signatures-dialog';
import { toSelectOptionsEmployees, toSelectOptionsPartners } from 'src/models/content/build-contract-view';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SignableEntity, SigningOrderWithAsDocument } from 'src/library/components/d-signatures-section';

export interface ContractViewEditItem {
  pages: string[];
  partners: string[];
  employees: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
  insertTemplateContentAction: string;
}

export interface ContractView
  extends AbstractEntityViewWithRevisions<ContractViewEditItem>,
    UpdateSectionItem,
    SignableEntity {
  partners: string[];
  employees: string[];
  relationsLocked: boolean;
  type: 'contracts';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
  validFromDate: string;
  validToDate: string;
  contractPartyNames: string;
  checklist?: Checklist;
  availablePages: SelectTagOption[];
  availableEmployees: PersonForSigning[];
  activeEmployeesCount: number;
  currentUserIsOwner: boolean;
  availablePartners: PartnerForSigning[];
  pages: string[];
  signingOrders: SigningOrderWithAsDocument[];
  organizationName: string;
  hasSigningModule: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-contract-view')
export class DContractView extends EntityContent<ContractView, ContractViewEditItem> {
  private showTemplateContentOnEdit = false;

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addDate('contracts_validFromDate', this.entityView.validFromDate);
    builder.addDate('contracts_validToDate', this.entityView.validToDate);
    builder.addText('contracts_contractPartyNames', this.entityView.contractPartyNames, 'm', true);
    if (this.entityView.checklist !== undefined) {
      builder.addChecklist('generalFields_checklist', this.entityView.checklist);
    }
    if (!this.entityView.hasSigningModule) {
      builder.addHtmlContent('contracts_content', this.entityView.content);
    }
    return builder.build();
  }

  private get unsavedTemplateContent() {
    return this.entityView.content !== '' && this.entityView.contentLastModifiedBy === 'TrinnVis-teamet';
  }

  private get hasValidContent() {
    return (
      this.entityView.content !== '' &&
      this.entityView.content !== '<p></p>' &&
      this.entityView.contentLastModifiedBy !== 'TrinnVis-teamet'
    );
  }

  private get signingDisabled() {
    return (
      (!this.hasValidContent && this.entityView.attachments.length === 0) ||
      (this.entityView.employees.length === 0 && this.entityView.partners.length === 0)
    );
  }

  renderTextContent() {
    if (this.unsavedTemplateContent) {
      return html`
        <d-section>
          <div style="margin: -6px 0">
            <d-wrap split>
              <d-view-text inline-label label="Avtaletekst"></d-view-text>
              <d-action @click=${() => this.editModeWithTemplateContent()}>Se forslag</d-action>
            </d-wrap>
          </div>
        </d-section>
      `;
    }
    if (
      (this.entityView.content !== '' && this.entityView.content !== '<p></p>') ||
      this.entityView.attachments.length === 0
    ) {
      return html` <d-section>
        <d-view-html field="contracts_content" .value=${this.entityView.content}></d-view-html>
      </d-section>`;
    }
    return nothing;
  }

  renderLists(): TemplateResult<1> {
    return html`
      ${this.renderSigningSection()} ${this.entityView.hasSigningModule ? this.renderContractContent() : nothing}
      ${super.renderLists()}
    `;
  }

  renderAttachments() {
    if (this.entityView.hasSigningModule) {
      return html``;
    } else {
      return super.renderAttachments();
    }
  }

  async initializeEditItem() {
    let content = this.entityView.content;
    let insertTemplateContentAction = '';
    if (this.unsavedTemplateContent && !this.showTemplateContentOnEdit) {
      content = '';
      insertTemplateContentAction = 'Sett inn forslag';
    }
    this.editItem = {
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      insertTemplateContentAction: insertTemplateContentAction,
      content: content,
      employees: this.entityView.employees,
      partners: this.entityView.partners,
      validFromDate: this.entityView.validFromDate,
      validToDate: this.entityView.validToDate,
      name: this.entityView.name,
      pages: this.entityView.pages,
    };
    this.showTemplateContentOnEdit = false;
  }

  renderAttachmentsList() {
    return html`
      <d-section>
        <div>
          <d-wrap split>
            <d-view-text inline-label label="Avtaledokumenter"></d-view-text>
            <d-action @click=${() => this.onUploadFile}>Last opp</d-action>
          </d-wrap>
          ${this.entityView.attachments.length
            ? html` <d-list-section .items=${this.entityView.attachments}></d-list-section> `
            : nothing}
        </div>
      </d-section>
    `;
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: ContractViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)}
      <d-section>
        <d-edit-text
          field="contracts_name"
          .autofocus=${!this.entityView.isConfirmedEntity}
          .selectOnFocus=${!this.entityView.isConfirmedEntity}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-section>
        <d-select-date
          field="contracts_validFromDate"
          .value=${item.validFromDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validFromDate: e.detail.value };
          }}
        >
        </d-select-date>

        <d-select-date
          field="contracts_validToDate"
          .value=${item.validToDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validToDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>

      ${this.entityView.relationsLocked
        ? html` <d-section>
            <d-view-text class="flex" field="contracts_contractPartyNames" .value=${this.entityView.contractPartyNames}>
            </d-view-text>
          </d-section>`
        : html` <d-section>
              <d-select-add
                allow-remove-all
                field="contracts_employees"
                .options=${toSelectOptionsEmployees(this.entityView.availableEmployees)}
                .placeholder=${'Velg person'}
                .selectedItems=${item.employees}
                @selected-items-changed=${(
                  e: CustomEvent<{ options: SelectDropdownOption[]; selectedItems: string[] }>,
                ) => {
                  this.editItem = { ...item, employees: e.detail.selectedItems };
                }}
              ></d-select-add>
            </d-section>
            <d-section>
              <d-select-add
                allow-remove-all
                field="contracts_partners"
                .options=${toSelectOptionsPartners(this.entityView.availablePartners)}
                .placeholder=${'Velg samarbeidspartner'}
                .selectedItems=${item.partners}
                @selected-items-changed=${(
                  e: CustomEvent<{ options: SelectDropdownOption[]; selectedItems: string[] }>,
                ) => {
                  this.editItem = { ...item, partners: e.detail.selectedItems };
                }}
              ></d-select-add>
            </d-section>`}

      <d-edit-html
        field="contracts_content"
        .value=${item.content}
        .labelAction=${item.insertTemplateContentAction}
        @label-action=${() => {
          this.editItem = {
            ...item,
            content: this.entityView.content,
            insertTemplateContentAction: '',
          };
        }}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      ></d-edit-html>

      ${this.entityView.hasSigningModule ? this.renderAttachmentsList() : nothing}
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private renderContractContent() {
    return html`${this.renderTextContent()}
      <d-view-attachments
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        label="Avtaledokumenter"
        .items=${this.entityView.attachments}
        @upload-file=${this.onUploadFile}
      ></d-view-attachments> `;
  }

  private renderSigningSection() {
    return html`<d-signatures-section
      .entityView=${this.entityView}
      .signingDisabled=${this.signingDisabled}
    ></d-signatures-section>`;
  }

  private editModeWithTemplateContent() {
    this.showTemplateContentOnEdit = true;
    // this._editModeOn();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-contract-view': DContractView;
  }
}
