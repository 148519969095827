/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContractChecklistGroup
 */
export interface ContractChecklistGroup {
  /**
   *
   * @type {string}
   * @memberof ContractChecklistGroup
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ContractChecklistGroup
   */
  items?: Array<string>;
}

/**
 * Check if a given object implements the ContractChecklistGroup interface.
 */
export function instanceOfContractChecklistGroup(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContractChecklistGroupFromJSON(json: any): ContractChecklistGroup {
  return ContractChecklistGroupFromJSONTyped(json, false);
}

export function ContractChecklistGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractChecklistGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    items: !exists(json, 'items') ? undefined : json['items'],
  };
}

export function ContractChecklistGroupToJSON(value?: ContractChecklistGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    items: value.items,
  };
}
