/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface NetworkViewModel
 */
export interface NetworkViewModel {
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  nhnDescription?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  otherDescription?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  connectionType?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  supplierUuid?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  networkType?: NetworkViewModelNetworkTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  firewall?: NetworkViewModelFirewallEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  firewallName?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  systemUpdateOperatorType?: NetworkViewModelSystemUpdateOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  systemUpdateOperator?: string;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  antiVirusOperatorType?: NetworkViewModelAntiVirusOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NetworkViewModel
   */
  antiVirusOperator?: string;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof NetworkViewModel
   */
  attachments?: Array<AttachmentViewModel>;
  /**
   *
   * @type {boolean}
   * @memberof NetworkViewModel
   */
  deleted?: boolean;
}

/**
 * @export
 */
export const NetworkViewModelNetworkTypeEnum = {
  Cable: 'CABLE',
  Wifi: 'WIFI',
  Both: 'BOTH',
} as const;
export type NetworkViewModelNetworkTypeEnum =
  (typeof NetworkViewModelNetworkTypeEnum)[keyof typeof NetworkViewModelNetworkTypeEnum];

/**
 * @export
 */
export const NetworkViewModelFirewallEnum = {
  None: 'NONE',
  Nhn: 'NHN',
  NhNotherIsp: 'NHNotherISP',
  Other: 'OTHER',
} as const;
export type NetworkViewModelFirewallEnum =
  (typeof NetworkViewModelFirewallEnum)[keyof typeof NetworkViewModelFirewallEnum];

/**
 * @export
 */
export const NetworkViewModelSystemUpdateOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type NetworkViewModelSystemUpdateOperatorTypeEnum =
  (typeof NetworkViewModelSystemUpdateOperatorTypeEnum)[keyof typeof NetworkViewModelSystemUpdateOperatorTypeEnum];

/**
 * @export
 */
export const NetworkViewModelAntiVirusOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type NetworkViewModelAntiVirusOperatorTypeEnum =
  (typeof NetworkViewModelAntiVirusOperatorTypeEnum)[keyof typeof NetworkViewModelAntiVirusOperatorTypeEnum];

/**
 * Check if a given object implements the NetworkViewModel interface.
 */
export function instanceOfNetworkViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function NetworkViewModelFromJSON(json: any): NetworkViewModel {
  return NetworkViewModelFromJSONTyped(json, false);
}

export function NetworkViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: !exists(json, 'name') ? undefined : json['name'],
    nhnDescription: !exists(json, 'nhnDescription') ? undefined : json['nhnDescription'],
    otherDescription: !exists(json, 'otherDescription') ? undefined : json['otherDescription'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    networkType: !exists(json, 'networkType') ? undefined : json['networkType'],
    firewall: !exists(json, 'firewall') ? undefined : json['firewall'],
    firewallName: !exists(json, 'firewallName') ? undefined : json['firewallName'],
    systemUpdateOperatorType: !exists(json, 'systemUpdateOperatorType') ? undefined : json['systemUpdateOperatorType'],
    systemUpdateOperator: !exists(json, 'systemUpdateOperator') ? undefined : json['systemUpdateOperator'],
    antiVirusOperatorType: !exists(json, 'antiVirusOperatorType') ? undefined : json['antiVirusOperatorType'],
    antiVirusOperator: !exists(json, 'antiVirusOperator') ? undefined : json['antiVirusOperator'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
  };
}

export function NetworkViewModelToJSON(value?: NetworkViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    nhnDescription: value.nhnDescription,
    otherDescription: value.otherDescription,
    connectionType: value.connectionType,
    supplierUuid: value.supplierUuid,
    networkType: value.networkType,
    firewall: value.firewall,
    firewallName: value.firewallName,
    systemUpdateOperatorType: value.systemUpdateOperatorType,
    systemUpdateOperator: value.systemUpdateOperator,
    antiVirusOperatorType: value.antiVirusOperatorType,
    antiVirusOperator: value.antiVirusOperator,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
    deleted: value.deleted,
  };
}
