/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountStateViewModel } from './AccountStateViewModel';
import {
  AccountStateViewModelFromJSON,
  AccountStateViewModelFromJSONTyped,
  AccountStateViewModelToJSON,
} from './AccountStateViewModel';

/**
 *
 * @export
 * @interface OrganizationReference
 */
export interface OrganizationReference {
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  ownerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  ownerName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  expires?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  pending?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  fullName: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  termsAccepted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  closed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  readonly?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  accessLevel?: OrganizationReferenceAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  userType?: OrganizationReferenceUserTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  requiresSecureLogin?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationReference
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationReference
   */
  singleUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  template?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationReference
   */
  features: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OrganizationReference
   */
  sector: OrganizationReferenceSectorEnum;
  /**
   *
   * @type {AccountStateViewModel}
   * @memberof OrganizationReference
   */
  state?: AccountStateViewModel;
}

/**
 * @export
 */
export const OrganizationReferenceAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type OrganizationReferenceAccessLevelEnum =
  (typeof OrganizationReferenceAccessLevelEnum)[keyof typeof OrganizationReferenceAccessLevelEnum];

/**
 * @export
 */
export const OrganizationReferenceUserTypeEnum = {
  Employee: 'EMPLOYEE',
  Contact: 'CONTACT',
} as const;
export type OrganizationReferenceUserTypeEnum =
  (typeof OrganizationReferenceUserTypeEnum)[keyof typeof OrganizationReferenceUserTypeEnum];

/**
 * @export
 */
export const OrganizationReferenceSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationReferenceSectorEnum =
  (typeof OrganizationReferenceSectorEnum)[keyof typeof OrganizationReferenceSectorEnum];

/**
 * Check if a given object implements the OrganizationReference interface.
 */
export function instanceOfOrganizationReference(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'fullName' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'features' in value;
  isInstance = isInstance && 'sector' in value;

  return isInstance;
}

export function OrganizationReferenceFromJSON(json: any): OrganizationReference {
  return OrganizationReferenceFromJSONTyped(json, false);
}

export function OrganizationReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationReference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    ownerEmail: !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
    ownerName: !exists(json, 'ownerName') ? undefined : json['ownerName'],
    expires: !exists(json, 'expires') ? undefined : json['expires'],
    pending: !exists(json, 'pending') ? undefined : json['pending'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    fullName: json['fullName'],
    termsAccepted: !exists(json, 'termsAccepted') ? undefined : json['termsAccepted'],
    closed: !exists(json, 'closed') ? undefined : json['closed'],
    readonly: !exists(json, 'readonly') ? undefined : json['readonly'],
    accessLevel: !exists(json, 'accessLevel') ? undefined : json['accessLevel'],
    userType: !exists(json, 'userType') ? undefined : json['userType'],
    requiresSecureLogin: !exists(json, 'requiresSecureLogin') ? undefined : json['requiresSecureLogin'],
    href: !exists(json, 'href') ? undefined : json['href'],
    message: !exists(json, 'message') ? undefined : json['message'],
    id: json['id'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
    template: !exists(json, 'template') ? undefined : json['template'],
    features: json['features'],
    sector: json['sector'],
    state: !exists(json, 'state') ? undefined : AccountStateViewModelFromJSON(json['state']),
  };
}

export function OrganizationReferenceToJSON(value?: OrganizationReference | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    ownerEmail: value.ownerEmail,
    ownerName: value.ownerName,
    expires: value.expires === undefined ? undefined : value.expires,
    pending: value.pending,
    firstName: value.firstName,
    lastName: value.lastName,
    fullName: value.fullName,
    termsAccepted: value.termsAccepted,
    closed: value.closed,
    readonly: value.readonly,
    accessLevel: value.accessLevel,
    userType: value.userType,
    requiresSecureLogin: value.requiresSecureLogin,
    href: value.href,
    message: value.message,
    id: value.id,
    singleUser: value.singleUser,
    template: value.template,
    features: value.features,
    sector: value.sector,
    state: AccountStateViewModelToJSON(value.state),
  };
}
