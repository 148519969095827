/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateMeetingOccurrenceReportCommand
 */
export interface CreateMeetingOccurrenceReportCommand {
  /**
   * The meeting occurrence uuid.
   * @type {string}
   * @memberof CreateMeetingOccurrenceReportCommand
   */
  uuid: string;
  /**
   * The new report uuid.
   * @type {string}
   * @memberof CreateMeetingOccurrenceReportCommand
   */
  reportUuid: string;
}

/**
 * Check if a given object implements the CreateMeetingOccurrenceReportCommand interface.
 */
export function instanceOfCreateMeetingOccurrenceReportCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'reportUuid' in value;

  return isInstance;
}

export function CreateMeetingOccurrenceReportCommandFromJSON(json: any): CreateMeetingOccurrenceReportCommand {
  return CreateMeetingOccurrenceReportCommandFromJSONTyped(json, false);
}

export function CreateMeetingOccurrenceReportCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateMeetingOccurrenceReportCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    reportUuid: json['reportUuid'],
  };
}

export function CreateMeetingOccurrenceReportCommandToJSON(value?: CreateMeetingOccurrenceReportCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    reportUuid: value.reportUuid,
  };
}
