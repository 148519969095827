/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PageGroupItemViewModel
 */
export interface PageGroupItemViewModel {
  /**
   *
   * @type {number}
   * @memberof PageGroupItemViewModel
   */
  pageId: number;
  /**
   *
   * @type {string}
   * @memberof PageGroupItemViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PageGroupItemViewModel
   */
  helpContent: string;
}

/**
 * Check if a given object implements the PageGroupItemViewModel interface.
 */
export function instanceOfPageGroupItemViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pageId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'helpContent' in value;

  return isInstance;
}

export function PageGroupItemViewModelFromJSON(json: any): PageGroupItemViewModel {
  return PageGroupItemViewModelFromJSONTyped(json, false);
}

export function PageGroupItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageGroupItemViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pageId: json['pageId'],
    name: json['name'],
    helpContent: json['helpContent'],
  };
}

export function PageGroupItemViewModelToJSON(value?: PageGroupItemViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pageId: value.pageId,
    name: value.name,
    helpContent: value.helpContent,
  };
}
