/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendMeetingOccurrenceNoticeCommand
 */
export interface SendMeetingOccurrenceNoticeCommand {
  /**
   *
   * @type {string}
   * @memberof SendMeetingOccurrenceNoticeCommand
   */
  uuid: string;
  /**
   * En valgfri melding som inkluderes i e-posten.
   * @type {string}
   * @memberof SendMeetingOccurrenceNoticeCommand
   */
  message: string;
  /**
   * Send ved agendaen i selve e-posten.
   * @type {boolean}
   * @memberof SendMeetingOccurrenceNoticeCommand
   */
  includeAgenda: boolean;
}

/**
 * Check if a given object implements the SendMeetingOccurrenceNoticeCommand interface.
 */
export function instanceOfSendMeetingOccurrenceNoticeCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'message' in value;
  isInstance = isInstance && 'includeAgenda' in value;

  return isInstance;
}

export function SendMeetingOccurrenceNoticeCommandFromJSON(json: any): SendMeetingOccurrenceNoticeCommand {
  return SendMeetingOccurrenceNoticeCommandFromJSONTyped(json, false);
}

export function SendMeetingOccurrenceNoticeCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendMeetingOccurrenceNoticeCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    message: json['message'],
    includeAgenda: json['includeAgenda'],
  };
}

export function SendMeetingOccurrenceNoticeCommandToJSON(value?: SendMeetingOccurrenceNoticeCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    message: value.message,
    includeAgenda: value.includeAgenda,
  };
}
