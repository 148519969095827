/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationUpdateMessage
 */
export interface OrganizationUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  officeAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  officePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  herNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  businessEntityType?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  organizationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  mailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  mailPostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationUpdateMessage
   */
  activityCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdateMessage
   */
  sector?: OrganizationUpdateMessageSectorEnum;
}

/**
 * @export
 */
export const OrganizationUpdateMessageSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationUpdateMessageSectorEnum =
  (typeof OrganizationUpdateMessageSectorEnum)[keyof typeof OrganizationUpdateMessageSectorEnum];

/**
 * Check if a given object implements the OrganizationUpdateMessage interface.
 */
export function instanceOfOrganizationUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrganizationUpdateMessageFromJSON(json: any): OrganizationUpdateMessage {
  return OrganizationUpdateMessageFromJSONTyped(json, false);
}

export function OrganizationUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    address: !exists(json, 'address') ? undefined : json['address'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    officeAddress: !exists(json, 'officeAddress') ? undefined : json['officeAddress'],
    officePostcode: !exists(json, 'officePostcode') ? undefined : json['officePostcode'],
    email: !exists(json, 'email') ? undefined : json['email'],
    herNumber: !exists(json, 'herNumber') ? undefined : json['herNumber'],
    name: !exists(json, 'name') ? undefined : json['name'],
    businessEntityType: !exists(json, 'businessEntityType') ? undefined : json['businessEntityType'],
    organizationNumber: !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
    mailAddress: !exists(json, 'mailAddress') ? undefined : json['mailAddress'],
    mailPostcode: !exists(json, 'mailPostcode') ? undefined : json['mailPostcode'],
    fax: !exists(json, 'fax') ? undefined : json['fax'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    type: !exists(json, 'type') ? undefined : json['type'],
    url: !exists(json, 'url') ? undefined : json['url'],
    activityCodes: !exists(json, 'activityCodes') ? undefined : json['activityCodes'],
    sector: !exists(json, 'sector') ? undefined : json['sector'],
  };
}

export function OrganizationUpdateMessageToJSON(value?: OrganizationUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address: value.address,
    notes: value.notes,
    officeAddress: value.officeAddress,
    officePostcode: value.officePostcode,
    email: value.email,
    herNumber: value.herNumber,
    name: value.name,
    businessEntityType: value.businessEntityType,
    organizationNumber: value.organizationNumber,
    mailAddress: value.mailAddress,
    mailPostcode: value.mailPostcode,
    fax: value.fax,
    phone: value.phone,
    type: value.type,
    url: value.url,
    activityCodes: value.activityCodes,
    sector: value.sector,
  };
}
