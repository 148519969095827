/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PartnerUpdateMessage
 */
export interface PartnerUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof PartnerUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  industry?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerUpdateMessage
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof PartnerUpdateMessage
   */
  remoteAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerUpdateMessage
   */
  physicalAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PartnerUpdateMessage
   */
  pageAccess?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof PartnerUpdateMessage
   */
  pages?: Array<number>;
}

/**
 * Check if a given object implements the PartnerUpdateMessage interface.
 */
export function instanceOfPartnerUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function PartnerUpdateMessageFromJSON(json: any): PartnerUpdateMessage {
  return PartnerUpdateMessageFromJSONTyped(json, false);
}

export function PartnerUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    email: !exists(json, 'email') ? undefined : json['email'],
    url: !exists(json, 'url') ? undefined : json['url'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    industry: !exists(json, 'industry') ? undefined : json['industry'],
    name: !exists(json, 'name') ? undefined : json['name'],
    address: !exists(json, 'address') ? undefined : json['address'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    remoteAccess: !exists(json, 'remoteAccess') ? undefined : json['remoteAccess'],
    physicalAccess: !exists(json, 'physicalAccess') ? undefined : json['physicalAccess'],
    pageAccess: !exists(json, 'pageAccess') ? undefined : json['pageAccess'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
  };
}

export function PartnerUpdateMessageToJSON(value?: PartnerUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    email: value.email,
    url: value.url,
    notes: value.notes,
    industry: value.industry,
    name: value.name,
    address: value.address,
    phone: value.phone,
    remoteAccess: value.remoteAccess,
    physicalAccess: value.physicalAccess,
    pageAccess: value.pageAccess,
    pages: value.pages,
  };
}
