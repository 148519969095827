/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface PersonalDataItemViewModel
 */
export interface PersonalDataItemViewModel {
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  establishedDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  personalData: boolean;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  storageMedium?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  dataProcessor?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  createdDateTime: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  storageUnit?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  storageLocation?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  backupOperatorType?: PersonalDataItemViewModelBackupOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  backupOperator?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  journalType?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  journalSupplier?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  journalSize?: string;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  sharedMedicalRecords?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  sharedMedicalRecordsPartners?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  sharedMedicalRecordsContracts?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  treatments: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  purpose?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  legalBasis?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  consentContracts?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  journalHasElectronicMessaging?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  autoLookupPatients?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  autoLookupAddress?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  autoLookupAddressNhn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  autoUpdateAddressNhn?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  messageMonitoring?: boolean;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  messagingType?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  messagingTypeOther?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  application?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  messagingSupplier?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  patientDialogueRequirements?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  relatedAssets?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  riskAssessments?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  otherRiskAssessments?: string;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  riskAssessmentConcludesOk?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  notes?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  accessingEmployees?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemViewModel
   */
  accessingPartners?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  backupFrequency?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  backupResponsible?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemViewModel
   */
  noBackupReason?: string;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof PersonalDataItemViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const PersonalDataItemViewModelBackupOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type PersonalDataItemViewModelBackupOperatorTypeEnum =
  (typeof PersonalDataItemViewModelBackupOperatorTypeEnum)[keyof typeof PersonalDataItemViewModelBackupOperatorTypeEnum];

/**
 * Check if a given object implements the PersonalDataItemViewModel interface.
 */
export function instanceOfPersonalDataItemViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'personalData' in value;
  isInstance = isInstance && 'createdDateTime' in value;
  isInstance = isInstance && 'treatments' in value;

  return isInstance;
}

export function PersonalDataItemViewModelFromJSON(json: any): PersonalDataItemViewModel {
  return PersonalDataItemViewModelFromJSONTyped(json, false);
}

export function PersonalDataItemViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PersonalDataItemViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: !exists(json, 'name') ? undefined : json['name'],
    establishedDate: !exists(json, 'establishedDate') ? undefined : json['establishedDate'],
    type: !exists(json, 'type') ? undefined : json['type'],
    personalData: json['personalData'],
    storageMedium: !exists(json, 'storageMedium') ? undefined : json['storageMedium'],
    dataProcessor: !exists(json, 'dataProcessor') ? undefined : json['dataProcessor'],
    createdDateTime: json['createdDateTime'],
    storageUnit: !exists(json, 'storageUnit') ? undefined : json['storageUnit'],
    storageLocation: !exists(json, 'storageLocation') ? undefined : json['storageLocation'],
    backupOperatorType: !exists(json, 'backupOperatorType') ? undefined : json['backupOperatorType'],
    backupOperator: !exists(json, 'backupOperator') ? undefined : json['backupOperator'],
    journalType: !exists(json, 'journalType') ? undefined : json['journalType'],
    journalSupplier: !exists(json, 'journalSupplier') ? undefined : json['journalSupplier'],
    journalSize: !exists(json, 'journalSize') ? undefined : json['journalSize'],
    sharedMedicalRecords: !exists(json, 'sharedMedicalRecords') ? undefined : json['sharedMedicalRecords'],
    sharedMedicalRecordsPartners: !exists(json, 'sharedMedicalRecordsPartners')
      ? undefined
      : json['sharedMedicalRecordsPartners'],
    sharedMedicalRecordsContracts: !exists(json, 'sharedMedicalRecordsContracts')
      ? undefined
      : json['sharedMedicalRecordsContracts'],
    treatments: json['treatments'],
    purpose: !exists(json, 'purpose') ? undefined : json['purpose'],
    legalBasis: !exists(json, 'legalBasis') ? undefined : json['legalBasis'],
    consentContracts: !exists(json, 'consentContracts') ? undefined : json['consentContracts'],
    journalHasElectronicMessaging: !exists(json, 'journalHasElectronicMessaging')
      ? undefined
      : json['journalHasElectronicMessaging'],
    autoLookupPatients: !exists(json, 'autoLookupPatients') ? undefined : json['autoLookupPatients'],
    autoLookupAddress: !exists(json, 'autoLookupAddress') ? undefined : json['autoLookupAddress'],
    autoLookupAddressNhn: !exists(json, 'autoLookupAddressNhn') ? undefined : json['autoLookupAddressNhn'],
    autoUpdateAddressNhn: !exists(json, 'autoUpdateAddressNhn') ? undefined : json['autoUpdateAddressNhn'],
    messageMonitoring: !exists(json, 'messageMonitoring') ? undefined : json['messageMonitoring'],
    messagingType: !exists(json, 'messagingType') ? undefined : json['messagingType'],
    messagingTypeOther: !exists(json, 'messagingTypeOther') ? undefined : json['messagingTypeOther'],
    application: !exists(json, 'application') ? undefined : json['application'],
    messagingSupplier: !exists(json, 'messagingSupplier') ? undefined : json['messagingSupplier'],
    patientDialogueRequirements: !exists(json, 'patientDialogueRequirements')
      ? undefined
      : json['patientDialogueRequirements'],
    relatedAssets: !exists(json, 'relatedAssets') ? undefined : json['relatedAssets'],
    riskAssessments: !exists(json, 'riskAssessments') ? undefined : json['riskAssessments'],
    otherRiskAssessments: !exists(json, 'otherRiskAssessments') ? undefined : json['otherRiskAssessments'],
    riskAssessmentConcludesOk: !exists(json, 'riskAssessmentConcludesOk')
      ? undefined
      : json['riskAssessmentConcludesOk'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    accessingEmployees: !exists(json, 'accessingEmployees') ? undefined : json['accessingEmployees'],
    accessingPartners: !exists(json, 'accessingPartners') ? undefined : json['accessingPartners'],
    backupFrequency: !exists(json, 'backupFrequency') ? undefined : json['backupFrequency'],
    backupResponsible: !exists(json, 'backupResponsible') ? undefined : json['backupResponsible'],
    noBackupReason: !exists(json, 'noBackupReason') ? undefined : json['noBackupReason'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function PersonalDataItemViewModelToJSON(value?: PersonalDataItemViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    establishedDate: value.establishedDate === undefined ? undefined : value.establishedDate,
    type: value.type,
    personalData: value.personalData,
    storageMedium: value.storageMedium,
    dataProcessor: value.dataProcessor,
    createdDateTime: value.createdDateTime,
    storageUnit: value.storageUnit,
    storageLocation: value.storageLocation,
    backupOperatorType: value.backupOperatorType,
    backupOperator: value.backupOperator,
    journalType: value.journalType,
    journalSupplier: value.journalSupplier,
    journalSize: value.journalSize,
    sharedMedicalRecords: value.sharedMedicalRecords,
    sharedMedicalRecordsPartners: value.sharedMedicalRecordsPartners,
    sharedMedicalRecordsContracts: value.sharedMedicalRecordsContracts,
    treatments: value.treatments,
    purpose: value.purpose,
    legalBasis: value.legalBasis,
    consentContracts: value.consentContracts,
    journalHasElectronicMessaging: value.journalHasElectronicMessaging,
    autoLookupPatients: value.autoLookupPatients,
    autoLookupAddress: value.autoLookupAddress,
    autoLookupAddressNhn: value.autoLookupAddressNhn,
    autoUpdateAddressNhn: value.autoUpdateAddressNhn,
    messageMonitoring: value.messageMonitoring,
    messagingType: value.messagingType,
    messagingTypeOther: value.messagingTypeOther,
    application: value.application,
    messagingSupplier: value.messagingSupplier,
    patientDialogueRequirements: value.patientDialogueRequirements,
    relatedAssets: value.relatedAssets,
    riskAssessments: value.riskAssessments,
    otherRiskAssessments: value.otherRiskAssessments,
    riskAssessmentConcludesOk: value.riskAssessmentConcludesOk,
    deleted: value.deleted,
    notes: value.notes,
    accessingEmployees: value.accessingEmployees,
    accessingPartners: value.accessingPartners,
    backupFrequency: value.backupFrequency,
    backupResponsible: value.backupResponsible,
    noBackupReason: value.noBackupReason,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
