/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RevisionViewModel
 */
export interface RevisionViewModel {
  /**
   *
   * @type {number}
   * @memberof RevisionViewModel
   */
  revisionId: number;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  dateTime: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  changedBy: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  nameDifference: string;
  /**
   *
   * @type {string}
   * @memberof RevisionViewModel
   */
  contentDifference: string;
}

/**
 * Check if a given object implements the RevisionViewModel interface.
 */
export function instanceOfRevisionViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'revisionId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'content' in value;
  isInstance = isInstance && 'dateTime' in value;
  isInstance = isInstance && 'changedBy' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'nameDifference' in value;
  isInstance = isInstance && 'contentDifference' in value;

  return isInstance;
}

export function RevisionViewModelFromJSON(json: any): RevisionViewModel {
  return RevisionViewModelFromJSONTyped(json, false);
}

export function RevisionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    revisionId: json['revisionId'],
    name: json['name'],
    content: json['content'],
    dateTime: json['dateTime'],
    changedBy: json['changedBy'],
    status: json['status'],
    nameDifference: json['nameDifference'],
    contentDifference: json['contentDifference'],
  };
}

export function RevisionViewModelToJSON(value?: RevisionViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    revisionId: value.revisionId,
    name: value.name,
    content: value.content,
    dateTime: value.dateTime,
    changedBy: value.changedBy,
    status: value.status,
    nameDifference: value.nameDifference,
    contentDifference: value.contentDifference,
  };
}
