/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateOrganizationResponse
 */
export interface CreateOrganizationResponse {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationResponse
   */
  target: string;
}

/**
 * Check if a given object implements the CreateOrganizationResponse interface.
 */
export function instanceOfCreateOrganizationResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'target' in value;

  return isInstance;
}

export function CreateOrganizationResponseFromJSON(json: any): CreateOrganizationResponse {
  return CreateOrganizationResponseFromJSONTyped(json, false);
}

export function CreateOrganizationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateOrganizationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    target: json['target'],
  };
}

export function CreateOrganizationResponseToJSON(value?: CreateOrganizationResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    target: value.target,
  };
}
