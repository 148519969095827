/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RelatedAssetViewModel
 */
export interface RelatedAssetViewModel {
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  assetUuid: string;
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  assetStatus?: RelatedAssetViewModelAssetStatusEnum;
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  statusLastUpdatedByEmployeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  statusLastUpdatedByContactUuid?: string;
  /**
   *
   * @type {string}
   * @memberof RelatedAssetViewModel
   */
  statusLastUpdatedAt?: string;
}

/**
 * @export
 */
export const RelatedAssetViewModelAssetStatusEnum = {
  None: 'NONE',
  Ok: 'OK',
  NotOk: 'NOT_OK',
} as const;
export type RelatedAssetViewModelAssetStatusEnum =
  (typeof RelatedAssetViewModelAssetStatusEnum)[keyof typeof RelatedAssetViewModelAssetStatusEnum];

/**
 * Check if a given object implements the RelatedAssetViewModel interface.
 */
export function instanceOfRelatedAssetViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'assetUuid' in value;

  return isInstance;
}

export function RelatedAssetViewModelFromJSON(json: any): RelatedAssetViewModel {
  return RelatedAssetViewModelFromJSONTyped(json, false);
}

export function RelatedAssetViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedAssetViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assetUuid: json['assetUuid'],
    comments: !exists(json, 'comments') ? undefined : json['comments'],
    assetStatus: !exists(json, 'assetStatus') ? undefined : json['assetStatus'],
    statusLastUpdatedByEmployeeUuid: !exists(json, 'statusLastUpdatedByEmployeeUuid')
      ? undefined
      : json['statusLastUpdatedByEmployeeUuid'],
    statusLastUpdatedByContactUuid: !exists(json, 'statusLastUpdatedByContactUuid')
      ? undefined
      : json['statusLastUpdatedByContactUuid'],
    statusLastUpdatedAt: !exists(json, 'statusLastUpdatedAt') ? undefined : json['statusLastUpdatedAt'],
  };
}

export function RelatedAssetViewModelToJSON(value?: RelatedAssetViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assetUuid: value.assetUuid,
    comments: value.comments,
    assetStatus: value.assetStatus,
    statusLastUpdatedByEmployeeUuid: value.statusLastUpdatedByEmployeeUuid,
    statusLastUpdatedByContactUuid: value.statusLastUpdatedByContactUuid,
    statusLastUpdatedAt: value.statusLastUpdatedAt,
  };
}
