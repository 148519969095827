import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Sortable } from 'sortablejs';

/**
 *
 * Drag and drop sort.
 * Requires sortable items as both property items and slotted elements with slot=index.
 * Dispatches event 'order-changed' on changed order with oldIndex and newIndex of moved item.
 *
 * USAGE:
 *    request-signatures-dialog
 *
 * STATUS OK
 */
@customElement('d-sortable')
export class DSortable extends LitElement {
  static styles = css`
    :host {
      display: block;
    }
    #sortable > div {
      margin: 0 -20px;
      padding: 0 20px;
      background: white;
    }
    #sortable > div > * {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid var(--borderColor);
      padding: 12px 0;
    }
    .sortable-chosen {
      opacity: 0.8;
    }
    .sortable-ghost {
      opacity: 0.5;
    }
    .drag-handle {
      flex: none;
      width: 32px;
      height: 32px;
      background: url(/images/sort-black.png) 50% 50% no-repeat;
      background-size: 40px;
      opacity: 0.3;
      cursor: pointer;
    }
    #sortable:not(.dragging) .drag-handle:hover,
    .sortable-drag .drag-handle {
      opacity: 0.8;
    }
  `;
  @property({ type: Array })
  items: any[] = [];
  private sortable: any = undefined;

  render() {
    return html`
      <div id="sortable">
        ${this.items.map((item, index) => {
          return html`<div>
            <div>
              <slot name="${index}"></slot>
              ${this.items.length > 1 ? html` <div class="drag-handle"></div> ` : nothing}
            </div>
          </div>`;
        })}
      </div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    const el = this.shadowRoot?.getElementById('sortable');
    if (el) {
      this.sortable = Sortable.create(el, {
        animation: 300,
        handle: '.drag-handle',
        onStart: () => {
          el.classList.add('dragging');
        },
        onEnd: (e) => {
          el.classList.remove('dragging');
          if (e.newIndex !== e.oldIndex) {
            this.dispatchEvent(
              new CustomEvent<{ oldIndex: number; newIndex: number }>('order-changed', {
                composed: true,
                bubbles: true,
                detail: { oldIndex: e.oldIndex, newIndex: e.newIndex },
              }),
            );
          }
        },
      });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-sortable': DSortable;
  }
}
