/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RestoreRevisionCommand
 */
export interface RestoreRevisionCommand {
  /**
   *
   * @type {string}
   * @memberof RestoreRevisionCommand
   */
  entityType: string;
  /**
   *
   * @type {string}
   * @memberof RestoreRevisionCommand
   */
  entityId: string;
  /**
   *
   * @type {string}
   * @memberof RestoreRevisionCommand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RestoreRevisionCommand
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof RestoreRevisionCommand
   */
  status: string;
}

/**
 * Check if a given object implements the RestoreRevisionCommand interface.
 */
export function instanceOfRestoreRevisionCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'entityType' in value;
  isInstance = isInstance && 'entityId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'content' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function RestoreRevisionCommandFromJSON(json: any): RestoreRevisionCommand {
  return RestoreRevisionCommandFromJSONTyped(json, false);
}

export function RestoreRevisionCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestoreRevisionCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityType: json['entityType'],
    entityId: json['entityId'],
    name: json['name'],
    content: json['content'],
    status: json['status'],
  };
}

export function RestoreRevisionCommandToJSON(value?: RestoreRevisionCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityType: value.entityType,
    entityId: value.entityId,
    name: value.name,
    content: value.content,
    status: value.status,
  };
}
