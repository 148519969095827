/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationReference } from './OrganizationReference';
import {
  OrganizationReferenceFromJSON,
  OrganizationReferenceFromJSONTyped,
  OrganizationReferenceToJSON,
} from './OrganizationReference';

/**
 *
 * @export
 * @interface AccountViewModel
 */
export interface AccountViewModel {
  [key: string]: object | any;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  href?: string;
  /**
   * Fornavn fra den nyeste kontoen. Null dersom brukeren ikke har konto
   * @type {string}
   * @memberof AccountViewModel
   */
  firstName?: string;
  /**
   * Etternavn fra den nyeste kontoen. Null dersom brukeren ikke har konto
   * @type {string}
   * @memberof AccountViewModel
   */
  lastName?: string;
  /**
   * Navn fra den nyeste kontoen. Null dersom brukeren ikke har konto
   * @type {string}
   * @memberof AccountViewModel
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  ablyTokenRequest?: string;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  authDescription?: string;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  vippsAuthDescription?: string;
  /**
   *
   * @type {string}
   * @memberof AccountViewModel
   */
  criiptoAuthDescription?: string;
  /**
   * Angir hvilken type innlogging brukeren har. Dersom det kun er brukernavn og passord er denne '' ellers vil den ha en av verdiene 'admin','oidc','vipps','bankid'
   * @type {string}
   * @memberof AccountViewModel
   */
  provider?: string;
  /**
   *
   * @type {Array<OrganizationReference>}
   * @memberof AccountViewModel
   */
  organizations?: Array<OrganizationReference>;
}

/**
 * Check if a given object implements the AccountViewModel interface.
 */
export function instanceOfAccountViewModel(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AccountViewModelFromJSON(json: any): AccountViewModel {
  return AccountViewModelFromJSONTyped(json, false);
}

export function AccountViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...json,
    key: !exists(json, 'key') ? undefined : json['key'],
    href: !exists(json, 'href') ? undefined : json['href'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    fullName: !exists(json, 'fullName') ? undefined : json['fullName'],
    ablyTokenRequest: !exists(json, 'ablyTokenRequest') ? undefined : json['ablyTokenRequest'],
    authDescription: !exists(json, 'authDescription') ? undefined : json['authDescription'],
    vippsAuthDescription: !exists(json, 'vippsAuthDescription') ? undefined : json['vippsAuthDescription'],
    criiptoAuthDescription: !exists(json, 'criiptoAuthDescription') ? undefined : json['criiptoAuthDescription'],
    provider: !exists(json, 'provider') ? undefined : json['provider'],
    organizations: !exists(json, 'organizations')
      ? undefined
      : (json['organizations'] as Array<any>).map(OrganizationReferenceFromJSON),
  };
}

export function AccountViewModelToJSON(value?: AccountViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...value,
    key: value.key,
    href: value.href,
    firstName: value.firstName,
    lastName: value.lastName,
    fullName: value.fullName,
    ablyTokenRequest: value.ablyTokenRequest,
    authDescription: value.authDescription,
    vippsAuthDescription: value.vippsAuthDescription,
    criiptoAuthDescription: value.criiptoAuthDescription,
    provider: value.provider,
    organizations:
      value.organizations === undefined
        ? undefined
        : (value.organizations as Array<any>).map(OrganizationReferenceToJSON),
  };
}
