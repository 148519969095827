/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { SigningOrder } from './SigningOrder';
import { SigningOrderFromJSON, SigningOrderFromJSONTyped, SigningOrderToJSON } from './SigningOrder';

/**
 *
 * @export
 * @interface ReportViewModel
 */
export interface ReportViewModel {
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  reportDate?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  uuid: string;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  employeeUuid?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  draftName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  partnerContactUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  meetingUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  meetingEventUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  meetingOccurrenceUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof ReportViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof ReportViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReportViewModel
   */
  classification?: ReportViewModelClassificationEnum;
  /**
   *
   * @type {boolean}
   * @memberof ReportViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ReportViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ReportViewModel
   */
  accessControl?: Array<string>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof ReportViewModel
   */
  attachments?: Array<AttachmentViewModel>;
  /**
   *
   * @type {Array<SigningOrder>}
   * @memberof ReportViewModel
   */
  signingOrders?: Array<SigningOrder>;
}

/**
 * @export
 */
export const ReportViewModelClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type ReportViewModelClassificationEnum =
  (typeof ReportViewModelClassificationEnum)[keyof typeof ReportViewModelClassificationEnum];

/**
 * Check if a given object implements the ReportViewModel interface.
 */
export function instanceOfReportViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function ReportViewModelFromJSON(json: any): ReportViewModel {
  return ReportViewModelFromJSONTyped(json, false);
}

export function ReportViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    content: !exists(json, 'content') ? undefined : json['content'],
    reportDate: !exists(json, 'reportDate') ? undefined : json['reportDate'],
    uuid: json['uuid'],
    isConfirmedEntity: json['isConfirmedEntity'],
    employeeUuid: !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    partnerContactUuid: !exists(json, 'partnerContactUuid') ? undefined : json['partnerContactUuid'],
    meetingUuid: !exists(json, 'meetingUuid') ? undefined : json['meetingUuid'],
    meetingEventUuid: !exists(json, 'meetingEventUuid') ? undefined : json['meetingEventUuid'],
    meetingOccurrenceUuid: !exists(json, 'meetingOccurrenceUuid') ? undefined : json['meetingOccurrenceUuid'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
    signingOrders: !exists(json, 'signingOrders')
      ? undefined
      : (json['signingOrders'] as Array<any>).map(SigningOrderFromJSON),
  };
}

export function ReportViewModelToJSON(value?: ReportViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    content: value.content,
    reportDate: value.reportDate === undefined ? undefined : value.reportDate,
    uuid: value.uuid,
    isConfirmedEntity: value.isConfirmedEntity,
    employeeUuid: value.employeeUuid,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    partnerContactUuid: value.partnerContactUuid,
    meetingUuid: value.meetingUuid,
    meetingEventUuid: value.meetingEventUuid,
    meetingOccurrenceUuid: value.meetingOccurrenceUuid,
    helpContent: value.helpContent,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    classification: value.classification,
    deleted: value.deleted,
    pages: value.pages,
    accessControl: value.accessControl,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
    signingOrders:
      value.signingOrders === undefined ? undefined : (value.signingOrders as Array<any>).map(SigningOrderToJSON),
  };
}
