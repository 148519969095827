/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SigningOrderSignatory
 */
export interface SigningOrderSignatory {
  /**
   *
   * @type {string}
   * @memberof SigningOrderSignatory
   */
  signatoryStatus: SigningOrderSignatorySignatoryStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SigningOrderSignatory
   */
  signatoryId: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrderSignatory
   */
  href: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrderSignatory
   */
  closedAt?: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrderSignatory
   */
  uuid: string;
}

/**
 * @export
 */
export const SigningOrderSignatorySignatoryStatusEnum = {
  Open: 'OPEN',
  Signed: 'SIGNED',
  Rejected: 'REJECTED',
} as const;
export type SigningOrderSignatorySignatoryStatusEnum =
  (typeof SigningOrderSignatorySignatoryStatusEnum)[keyof typeof SigningOrderSignatorySignatoryStatusEnum];

/**
 * Check if a given object implements the SigningOrderSignatory interface.
 */
export function instanceOfSigningOrderSignatory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'signatoryStatus' in value;
  isInstance = isInstance && 'signatoryId' in value;
  isInstance = isInstance && 'href' in value;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function SigningOrderSignatoryFromJSON(json: any): SigningOrderSignatory {
  return SigningOrderSignatoryFromJSONTyped(json, false);
}

export function SigningOrderSignatoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningOrderSignatory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    signatoryStatus: json['signatoryStatus'],
    signatoryId: json['signatoryId'],
    href: json['href'],
    closedAt: !exists(json, 'closedAt') ? undefined : json['closedAt'],
    uuid: json['uuid'],
  };
}

export function SigningOrderSignatoryToJSON(value?: SigningOrderSignatory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    signatoryStatus: value.signatoryStatus,
    signatoryId: value.signatoryId,
    href: value.href,
    closedAt: value.closedAt,
    uuid: value.uuid,
  };
}
