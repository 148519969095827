import { css, CSSResult, html, LitElement, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

/**
 *
 * STATUS OK
 */
@customElement('d-mobile-panel')
export class DMobilePanel extends LitElement {
  static readonly styles: CSSResult[] = [
    css`
      :host {
        display: block;
        position: fixed;
        width: 100vw;
        height: calc(100vh - var(--safe-area-inset-top));
        box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.3);
        background: white;
        transition: top 0.3s;
      }
      .viewport {
        display: flex;
        flex-direction: column;
      }
      .viewport * {
        box-sizing: border-box;
      }
      header {
        flex: none;
        padding: 24px 20px 0 20px;
        background-color: white;
        background-size: 50px;
        background-position: 16px 12px;
        background-repeat: no-repeat;
        font-weight: bold;
        font-size: 24px;
      }
      header:after {
        content: '';
        display: block;
        margin-top: 20px;
        border-bottom: 1px solid var(--borderColor);
      }
      .title {
        padding-left: 50px;
      }
      main {
        position: relative;
        flex: 1;
        margin-top: -2px;
        padding: 0 20px;
        overflow: auto;
      }
      main > * {
        position: relative;
        top: -1px;
      }
      main:after {
        display: block;
        content: '';
        height: 26px;
      }
      footer {
        flex: none;
        position: relative;
        background: white;
      }
      .overflow-gradient {
        position: absolute;
        top: -50px;
        width: 100%;
        height: 50px;
        background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1));
      }
      .buttons {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        padding: 12px 20px 50px 20px;
      }
      button {
        margin-left: 12px;
        border: none;
        border-radius: 6px;
        padding: 10px 14px;
        -webkit-appearance: none;
        background-color: var(--themeColor);
        font-family: var(--mainSans);
        font-size: 16px;
        color: white;
      }
      button.cancel {
        background-color: silver;
      }
      button[disabled] {
        opacity: 0.5;
      }
    `,
  ];
  @property({ type: String })
  icon: string = '';
  @property({ type: String })
  title: string = '';
  @property({ type: Number })
  availableHeight = 1000;
  @query('header')
  headerelm!: HTMLElement;
  @query('main')
  mainelm!: HTMLElement;
  @query('footer')
  footerelm!: HTMLElement;
  @property({ type: Number, attribute: 'slide-down-breakpoint' })
  slideDownBreakpoint = 200;
  @property({ type: Number, attribute: 'friction-point' })
  frictionPoint = 80;
  @property({ type: Number, attribute: 'friction-factor' })
  frictionFactor = 0;
  @property({ type: Number, attribute: 'allow-slide-up' })
  allowSlideUp = 0;
  @state()
  private initialTop = 0;
  @state()
  private touchStartPos = 0;
  @state()
  private touchStartTime = 0;
  @state()
  private touchDistance = 0;

  constructor() {
    super();
    this.addEventListener('touchstart', this.onTouchStart);
    this.addEventListener('touchmove', this.onTouchMove);
    this.addEventListener('touchend', this.onTouchEnd);
    this.addEventListener('touchcancel', this.onTouchEnd);
  }

  private get topStyleValue(): number {
    const defaultView = this.ownerDocument.defaultView;
    if (defaultView && defaultView.getComputedStyle) {
      const top = defaultView.getComputedStyle(this, null).getPropertyValue('top');
      return Number(top.replace('px', ''));
    }
    return 0;
  }

  private get frictionedDistance() {
    const frictionDistance = this.slideDownBreakpoint - this.frictionPoint;
    const beyondFrictionPoint = this.touchDistance - this.frictionPoint;
    let frictionDistanceFactor = beyondFrictionPoint / frictionDistance;
    if (frictionDistanceFactor > 1) {
      frictionDistanceFactor = 1;
    }
    const friction = frictionDistanceFactor ** 2;
    return this.frictionPoint + beyondFrictionPoint / (1 + friction * this.frictionFactor);
  }

  private onTouchStart(e: TouchEvent) {
    if (this.mainelm.scrollTop < 1) {
      this.touchStartTime = e.timeStamp;
      this.touchStartPos = e.touches[0].clientY;
      this.initialTop = this.topStyleValue;
    }
  }

  private onTouchMove(e: TouchEvent) {
    const pos = e.touches[0].clientY;
    this.touchDistance = (this.touchStartPos - pos) * -1;
    if (this.touchDistance > this.allowSlideUp * -1) {
      this.style.transition = 'top 0s';
      let slideDistance = this.touchDistance;
      if (this.frictionFactor > 0 && this.touchDistance > this.frictionPoint) {
        slideDistance = this.frictionedDistance;
      }
      this.style.top = this.initialTop + slideDistance + 'px';
    }
  }

  private onTouchEnd(e: TouchEvent) {
    const duration = e.timeStamp - this.touchStartTime;
    const speed = this.touchDistance / duration;
    this.style.transition = 'top 0.3s';
    const currentTop = this.topStyleValue;
    if (currentTop - this.initialTop < this.slideDownBreakpoint && speed < 1) {
      this.style.top = this.initialTop + 'px';
    } else {
      this.touchDistance = 0;
      const height = this.getBoundingClientRect().height;
      this.style.top = height + 'px';
      setTimeout(() => {
        this.style.removeProperty('top');
        this.dispatchEvent(
          new CustomEvent('close', {
            composed: true,
            bubbles: true,
          }),
        );
      }, 300);
    }
  }
  close() {
    this.dispatchEvent(
      new CustomEvent('close', {
        composed: true,
        bubbles: true,
      }),
    );
  }

  done(e: CustomEvent) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('done', {
        composed: true,
        bubbles: true,
      }),
    );
  }

  protected get calculatedTitle() {
    return this.title;
  }

  protected get hideCancel() {
    return false;
  }

  protected get doneText() {
    return 'Ferdig';
  }

  protected get doneDisabled() {
    return false;
  }

  private get headerStyle() {
    if (this.icon) {
      const typeName = this.icon.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
      return 'background-image: url(/images/' + typeName + '-color' + '.svg)';
    }
    return '';
  }

  private get viewportStyle() {
    return { 'max-height': this.availableHeight + 'px' };
  }

  protected renderContent() {
    return html``;
  }

  protected renderButtons() {
    return html`
      ${!this.hideCancel ? html`<button class="cancel" @click=${() => this.close()}>Avbryt</button>` : nothing}
      <button id="done" ?disabled=${this.doneDisabled} @click=${(e) => this.done(e)}>${this.doneText}</button>
    `;
  }

  render() {
    return html`
      <div class="viewport" style=${styleMap(this.viewportStyle)}>
        <header style="${this.headerStyle}">
          <div class="title">${this.calculatedTitle}</div>
        </header>
        <main>${this.renderContent()}</main>
        <footer>
          <div class="overflow-gradient"></div>
          <div class="buttons">${this.renderButtons()}</div>
        </footer>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-mobile-panel': DMobilePanel;
  }
}
