/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TutorialParticipant
 */
export interface TutorialParticipant {
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof TutorialParticipant
   */
  hasAccess: boolean;
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  assigned?: string;
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  due?: string;
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  completed?: string;
  /**
   *
   * @type {string}
   * @memberof TutorialParticipant
   */
  statusComment: string;
}

/**
 * Check if a given object implements the TutorialParticipant interface.
 */
export function instanceOfTutorialParticipant(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'hasAccess' in value;
  isInstance = isInstance && 'statusComment' in value;

  return isInstance;
}

export function TutorialParticipantFromJSON(json: any): TutorialParticipant {
  return TutorialParticipantFromJSONTyped(json, false);
}

export function TutorialParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialParticipant {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    hasAccess: json['hasAccess'],
    assigned: !exists(json, 'assigned') ? undefined : json['assigned'],
    due: !exists(json, 'due') ? undefined : json['due'],
    completed: !exists(json, 'completed') ? undefined : json['completed'],
    statusComment: json['statusComment'],
  };
}

export function TutorialParticipantToJSON(value?: TutorialParticipant | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    hasAccess: value.hasAccess,
    assigned: value.assigned,
    due: value.due,
    completed: value.completed,
    statusComment: value.statusComment,
  };
}
