/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface ConstitutionalDocumentViewModel
 */
export interface ConstitutionalDocumentViewModel {
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof ConstitutionalDocumentViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof ConstitutionalDocumentViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  classification?: ConstitutionalDocumentViewModelClassificationEnum;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  validFromDate?: string;
  /**
   *
   * @type {string}
   * @memberof ConstitutionalDocumentViewModel
   */
  validToDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConstitutionalDocumentViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentViewModel
   */
  employees?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentViewModel
   */
  partners?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConstitutionalDocumentViewModel
   */
  accessControl?: Array<string>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof ConstitutionalDocumentViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const ConstitutionalDocumentViewModelClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type ConstitutionalDocumentViewModelClassificationEnum =
  (typeof ConstitutionalDocumentViewModelClassificationEnum)[keyof typeof ConstitutionalDocumentViewModelClassificationEnum];

/**
 * Check if a given object implements the ConstitutionalDocumentViewModel interface.
 */
export function instanceOfConstitutionalDocumentViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function ConstitutionalDocumentViewModelFromJSON(json: any): ConstitutionalDocumentViewModel {
  return ConstitutionalDocumentViewModelFromJSONTyped(json, false);
}

export function ConstitutionalDocumentViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConstitutionalDocumentViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    template: !exists(json, 'template') ? undefined : json['template'],
    name: !exists(json, 'name') ? undefined : json['name'],
    content: !exists(json, 'content') ? undefined : json['content'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    classification: !exists(json, 'classification') ? undefined : json['classification'],
    validFromDate: !exists(json, 'validFromDate') ? undefined : json['validFromDate'],
    validToDate: !exists(json, 'validToDate') ? undefined : json['validToDate'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    partners: !exists(json, 'partners') ? undefined : json['partners'],
    accessControl: !exists(json, 'accessControl') ? undefined : json['accessControl'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function ConstitutionalDocumentViewModelToJSON(value?: ConstitutionalDocumentViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    template: value.template,
    name: value.name,
    content: value.content,
    helpContent: value.helpContent,
    templateUpdated: value.templateUpdated,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    classification: value.classification,
    validFromDate: value.validFromDate === undefined ? undefined : value.validFromDate,
    validToDate: value.validToDate === undefined ? undefined : value.validToDate,
    deleted: value.deleted,
    employees: value.employees,
    partners: value.partners,
    accessControl: value.accessControl,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
