import { html, css, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityView, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-smooth-resize';
import '../../library/lists/d-list-section-attachment.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { EventDataForAsset } from 'src/store';
import { classMap } from 'lit/directives/class-map.js';
import { shortenPath } from 'src/utilities/path';
import { CreateEntityInput, CreateEventOccurrenceInput } from 'src/layout/parts/d-new-document';
import { LocalDate } from 'src/utilities/local-date';
import { ActionInput } from 'src/library/elements/d-action';

export interface ItemAssetTaskInstance {
  status: '' | 'OK' | 'NOT_OK';
  time?: string;
  doneDate?: string;
  overdue: boolean;
  href: string;
  dateAndTimeDisplay: string;
  statusDatePerson: string;
  statusComments: string;
}

export interface ItemAssetTask {
  instances: ItemAssetTaskInstance[];
  name: string;
  href: string;
  uuid: string;
}

export interface AssetRelatedTaskOption {
  uuid: string;
  label: string;
}

export interface AssetViewEditItem {
  name: string;
  number: string;
  category: string;
  owner: string;
  requiresElectricity: boolean;
  radiation: boolean;
  radiationType: string;
  dsaRegistered: boolean;
  dsaApproved: boolean;
  manufacturer: string;
  model: string;
  location: string;
  userManual: string;
  supplier: string;
  acquiredYear: string;
  discardedYear: string;
  accessory: string;
  competency: string;
  maintenance: string;
  maintenanceRequired: boolean;
  complianceDeclaration: string;
  assetVerified: boolean;
  documentationVerified: boolean;
  documented: boolean;
}

export interface AssetView extends AbstractEntityView<AssetViewEditItem> {
  radiationType: string;
  owner: string;
  dsaApproved: boolean;
  dsaRegistered: boolean;
  documented: boolean;
  documentationVerified: boolean;
  assetVerified: boolean;
  maintenanceRequired: boolean;
  requiredEventInstanceMissing: boolean;
  radiation: boolean;
  requiresElectricity: boolean;
  hasPersonalDataItem: boolean;
  maintenance: string;
  competency: string;
  accessory: string;
  complianceDeclaration: string;
  discardedYear: string;
  acquiredYear: string;
  supplier: string;
  userManual: string;
  ownerDisplay: string;
  category: string;
  location: string;
  model: string;
  manufacturer: string;
  number: string;
  type: 'assets';
  name: string;
  assetEvents: EventDataForAsset[];
  relatedTasks: ItemAssetTask[];
  availableRelatedTasks: AssetRelatedTaskOption[];
  availableAssetCategories: SelectDropdownOption[];
  availableAssetEmployees: SelectDropdownOption[];
  editAssetsSimplified: boolean;
  organizationName: string;
}

/**
 *
 */
@customElement('d-asset-view')
export class DAssetView extends EntityContent<AssetView, AssetViewEditItem> {
  static readonly styles = [
    EntityContent.styles[0],
    css`
      .event-wrapper * {
        line-height: 140%;
      }
      .event-wrapper .event {
        padding: 12px 0 10px 0;
        border-bottom: 1px solid var(--borderColor);
      }
      .event-wrapper:last-child .event {
        border-bottom: none;
      }
      .event-header {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
      }
      .event-wrapper:hover .event-header d-label {
        opacity: 1;
      }
      .event-header > *:last-child {
        flex-grow: 1;
        margin-right: 12px;
        margin-bottom: 2px;
      }
      .event-header .actions {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-right: -8px;
        margin-left: -8px;
      }
      .event-prev-next {
        margin-top: 4px;
      }
      .event-prev-next > div {
        display: flex;
        align-items: baseline;
        padding: 2px 0;
      }
      .event-prev-next > div > * {
        margin-right: 12px;
      }
      .event-prev-next > div > d-label {
        width: 60px;
        margin-right: 6px;
      }
      .prev-next-text {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        background-position: -4px -1px;
        background-repeat: no-repeat;
        background-size: 22px;
        padding-left: 18px;
        color: var(--linkColorGray);
        text-decoration: none;
      }
      a.prev-next-text.ok {
        background-image: url(/images/check-mini-blue.svg);
      }
      a.prev-next-text.not-ok {
        background-image: url(/images/x-fat-orange.svg);
      }
      a.prev-next-text.done {
        background-image: url(/images/check-mini-gray.svg);
      }
      a.prev-next-text .date {
        margin-right: 6px;
      }
      .done-text {
        font-size: 14px;
        font-weight: 200;
      }
      span.prev-next-text {
        font-style: italic;
        font-weight: 200;
      }
      .occurrences a.prev-next-text {
        background-position-y: 3px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      @media only screen and (min-width: 600px) {
        .event-wrapper {
          padding-left: var(--listPaddingLeft);
        }
        .event-wrapper[classified] .meeting-header {
          padding-left: 0;
        }
      }
      @media (hover: hover) {
        .event-header .actions {
          visibility: hidden;
        }
        .event:hover .event-header .actions {
          visibility: visible;
          display: flex;
        }
        .event:hover a.prev-next-text {
          color: var(--themeColorDarkerOne);
        }
        .event:hover a.prev-next-text:hover {
          color: black;
        }
        .event:hover a.prev-next-text[disabled],
        .event:hover a.prev-next-text[disabled]:hover {
          color: black;
          cursor: default;
        }
      }
    `,
  ];
  @property({ type: Array })
  radiationTypes = [
    { value: 'NONE', text: 'Velg type' },
    {
      value: 'category1',
      text: 'Ultralyd / laser klasse 3B og lavere',
    },
    {
      value: 'category2',
      text: 'Tannrøntgen / laser klasse 4',
    },
    { value: 'category3', text: 'Røntgen / MR / CT' },
  ];

  @property({ type: Number })
  showAllOccurrencesIndex = -1;

  protected get currentItemLabel() {
    return this.entityView.name + ' ' + this.entityView.number;
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    const list: { field: string; value: string | undefined }[] = [];

    list.push({ field: 'assets_number', value: this.entityView.number });
    list.push({ field: 'assets_manufacturer', value: this.entityView.manufacturer });
    list.push({ field: 'assets_model', value: this.entityView.model });
    list.push({ field: 'assets_location', value: this.entityView.location });
    list.push({ field: 'assets_category', value: this.entityView.category });

    builder.addTextList(list, 'm');

    const listRight: { field: string; value: string | undefined }[] = [];

    if (this.entityView.ownerDisplay) {
      listRight.push({ field: 'assets_owner', value: this.entityView.ownerDisplay });
    }
    listRight.push({ field: 'assets_userManual', value: this.entityView.userManual });
    listRight.push({ field: 'assets_supplier', value: this.entityView.supplier });
    listRight.push({ field: 'assets_acquiredYear', value: this.entityView.acquiredYear });
    listRight.push({ field: 'assets_discardedYear', value: this.entityView.discardedYear });
    listRight.push({ field: 'assets_complianceDeclaration', value: this.entityView.complianceDeclaration });
    builder.addTextList(listRight, 'm');

    builder.addTextVerticalIfNotEmpty('assets_accessory', this.entityView.accessory, 'm');
    builder.addTextVerticalIfNotEmpty('assets_competency', this.entityView.competency, 'm');
    builder.addTextVerticalIfNotEmpty('assets_maintenance', this.entityView.maintenance, 'm');
    builder.addConditionalBullets(this.radiationBullets());
    const bullets = [
      {
        field: 'assets_assetVerified',
        elseField: 'assets_assetVerifiedNOT',
        value: this.entityView.assetVerified,
        alert: false,
      },
      {
        field: 'assets_documentationVerified',
        elseField: 'assets_documentationVerifiedNOT',
        value: this.entityView.documentationVerified,
        alert: false,
      },
      {
        field: 'assets_documented',
        elseField: 'assets_documentedNOT',
        value: this.entityView.documented,
        alert: true,
      },
      {
        field: 'assets_maintenanceRequired',
        elseField: 'assets_maintenanceRequiredNOT',
        value: this.entityView.maintenanceRequired,
        alert: this.entityView.requiredEventInstanceMissing,
      },
    ];
    if (
      this._registrationRequired(
        this.entityView.requiresElectricity ?? false,
        this.entityView.radiation,
        this.entityView.radiationType,
      )
    ) {
      bullets.push({
        field: 'assets_dsaRegistered',
        elseField: 'assets_dsaRegisteredNOT',
        value: this.entityView.dsaRegistered,
        alert: true,
      });
    }
    if (
      this._approvalRequired(
        this.entityView.requiresElectricity ?? false,
        this.entityView.radiation,
        this.entityView.radiationType,
      )
    ) {
      bullets.push({
        field: 'assets_dsaApproved',
        elseField: 'assets_dsaApprovedNOT',
        value: this.entityView.dsaApproved,
        alert: true,
      });
    }

    builder.addConditionalBullets(bullets);

    return builder.build();
  }

  private get assetEventsHeaderActions(): ActionInput[] {
    return [
      {
        name: 'Legg til',
        action: 'create-event-occurrence',
        slot: 'top-right',
      },
    ];
  }

  hasMoreOccurrences(event: EventDataForAsset): boolean {
    return (
      event.occurrences.filter((e) => {
        return e.eventUuid !== event.prev?.eventUuid && e.eventUuid !== event.next?.eventUuid;
      }).length > 0
    );
  }

  _radiationTypeBulletText() {
    let typeText = '';
    if (this.entityView.radiationType === 'category2' || this.entityView.radiationType === 'category3') {
      typeText =
        ': ' +
        this.radiationTypes.filter((item) => {
          return item.value === this.entityView.radiationType;
        })[0].text;
    }
    return commonFieldsByCode()['assets_radiation'].label + typeText;
  }

  _combinedList(organizationName: string, list: SelectDropdownOption[]): SelectDropdownOption[] {
    return [
      {
        value: '',
        text: organizationName,
      },
      ...list,
    ];
  }

  _registrationRequired(electricity: boolean, radiation: boolean, radiationType: string): boolean {
    return electricity && radiation && radiationType === 'category2';
  }

  _approvalRequired(electricity: boolean, radiation: boolean, radiationType: string): boolean {
    return electricity && radiation && radiationType === 'category3';
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  eventClassMap(event) {
    return {
      ok: event.status === 'OK',
      'not-ok': event.status === 'NOT_OK',
      done: event.status === 'DONE',
    };
  }

  toggleShowAllOccurrences(index) {
    if (this.showAllOccurrencesIndex === index) {
      this.showAllOccurrencesIndex = -1;
    } else {
      this.showAllOccurrencesIndex = index;
    }
  }

  renderAllOccurrences(occurrences) {
    return html`
      ${occurrences.map(
        (event) => html`
          <div class="occurrences">
            <a
              class="prev-next-text ${classMap(this.eventClassMap(event))}"
              href="${shortenPath(this.entityView.href + '/' + event.href)}"
            >
              <span class="date">${event.dateTimeDisplay}</span>
              ${event.statusText ? html` <span class="done-text">${event.statusText}</span> ` : nothing}
            </a>
          </div>
        `,
      )}
    `;
  }

  renderPrevNextOccurrence(event) {
    return html`<div class="event-prev-next">
      <div>
        <d-label semibold label="Forrige"></d-label>
        ${event.prev
          ? html`
              <a
                class="prev-next-text ${classMap(this.eventClassMap(event.prev))}"
                href="${shortenPath(this.entityView.href + '/' + event.prev.href)}"
              >
                <span class="date">${event.prev.dateTimeDisplay}</span>
                ${event.prev.statusText ? html` <span class="done-text">${event.prev.statusText}</span> ` : nothing}
              </a>
            `
          : html` <span class="prev-next-text">Ingen tidligere</span> `}
      </div>
      <div>
        <d-label semibold label="Neste"></d-label>
        ${event.next
          ? html`
              <a class="prev-next-text" href="${shortenPath(this.entityView.href + '/' + event.next.href)}"
                >${event.next.dateTimeDisplay}</a
              >
            `
          : html` <span class="prev-next-text">Ingen kommende</span> `}
      </div>
    </div> `;
  }
  renderLists() {
    return html`<d-list-header
        label="Oppgaver"
        icon="events"
        bordered
        style="top:${this.contentStickyTop - 1}px"
        .actions=${this.assetEventsHeaderActions}
        @action=${(e) => this.onAction(e)}
      ></d-list-header>
      <div>
        ${this.entityView.assetEvents.map(
          (event, index) => html`
            <div class="event-wrapper">
              <div class="event">
                <div class="event-header">
                  <div class="actions">
                    ${
                      this.hasMoreOccurrences(event)
                        ? html`
                            <d-action theme-page mini @click=${() => this.toggleShowAllOccurrences(index)}>
                              ${this.showAllOccurrencesIndex === index ? 'Vis færre' : 'Vis alle'}
                            </d-action>
                          `
                        : nothing
                    }                    
                    ${
                      this.entityView.currentUserHasWriteAccess
                        ? html`
                            <d-action
                              @click=${(e) => this.onCreateEventOccurrenceWithName(e, event.name)}
                              href="#"
                              theme-page
                              mini
                              >Legg til</d-action
                            >
                          `
                        : nothing
                    }
                  </div>
                  <div>
                    <d-label big allow-wrap .label=${event.name}></d-label>
                  </div>
                </div>
                <d-smooth-resize>
                ${
                  this.showAllOccurrencesIndex === index && this.hasMoreOccurrences(event)
                    ? html` ${this.renderAllOccurrences(event.occurrences)}`
                    : html`${this.renderPrevNextOccurrence(event)}`
                }
                </d-smooth-resize>
              </div>
            </div>
            </div>
          `,
        )}
      </div> `;
  }

  async initializeEditItem() {
    this.editItem = {
      accessory: this.entityView.accessory,
      acquiredYear: this.entityView.acquiredYear,
      assetVerified: this.entityView.assetVerified,
      category: this.entityView.category,
      competency: this.entityView.competency,
      complianceDeclaration: this.entityView.complianceDeclaration,
      discardedYear: this.entityView.discardedYear,
      documentationVerified: this.entityView.documentationVerified,
      documented: this.entityView.documented,
      dsaApproved: this.entityView.dsaApproved,
      dsaRegistered: this.entityView.dsaRegistered,
      location: this.entityView.location,
      maintenance: this.entityView.maintenance,
      maintenanceRequired: this.entityView.maintenanceRequired,
      manufacturer: this.entityView.manufacturer,
      model: this.entityView.model,
      name: this.entityView.name,
      number: this.entityView.number,
      owner: this.entityView.owner,
      radiation: this.entityView.radiation,
      radiationType: this.entityView.radiationType,
      requiresElectricity: this.entityView.requiresElectricity,
      supplier: this.entityView.supplier,
      userManual: this.entityView.userManual,
    };
  }

  renderEditItem(item): TemplateResult<1> {
    return html` <d-section>
        <d-edit-text
          class="minWidth300"
          field="assets_name"
          .autofocus=${!this.entityView.isConfirmedEntity}
          .selectOnFocus=${!this.entityView.isConfirmedEntity}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>

        <d-edit-text
          class="minWidth300"
          field="assets_number"
          .value=${item.number}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, number: e.detail.value };
          }}
        >
        </d-edit-text>
        <d-select-dropdown
          class="minWidth300"
          field="assets_category"
          .options=${this.entityView.availableAssetCategories}
          .value=${item.category}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, category: e.detail.value };
          }}
        >
        </d-select-dropdown>

        <d-select-dropdown
          class="minWidth300"
          field="assets_owner"
          .options="${this._combinedList(this.entityView.organizationName, this.entityView.availableAssetEmployees)}"
          prompt=""
          .value=${item.owner}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, owner: e.detail.value };
          }}
        >
        </d-select-dropdown>
      </d-section>

      <d-section>
        ${this.entityView.editAssetsSimplified
          ? nothing
          : html`<d-checkbox
              wrap-label
              ?checked=${item.requiresElectricity}
              option-field="assets_requiresElectricity"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, requiresElectricity: e.detail.checked };
              }}
            >
            </d-checkbox>`}
      </d-section>

      <d-expansion ?opened=${item.requiresElectricity}>
        <d-section>
          <div class="flexbox-split flex-baseline">
            <d-checkbox
              wrap-label
              ?checked=${item.radiation}
              class="minWidth200"
              option-field="assets_radiation"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, radiation: e.detail.checked };
              }}
            >
            </d-checkbox>
            ${item.radiation
              ? html`<d-select-dropdown
                  class="minWidth200"
                  .options=${this.radiationTypes}
                  .value=${item.radiationType}
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    this.editItem = { ...item, radiationType: e.detail.value };
                  }}
                >
                </d-select-dropdown>`
              : nothing}
          </div>
        </d-section>

        <d-expansion
          ?opened=${this._registrationRequired(item.requiresElectricity ?? false, item.radiation, item.radiationType)}
        >
          <d-section>
            <d-checkbox
              wrap-label
              ?checked=${item.dsaRegistered}
              option-field="assets_dsaRegistered"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, dsaRegistered: e.detail.checked };
              }}
            >
            </d-checkbox>
          </d-section>
        </d-expansion>

        <d-expansion
          ?opened=${this._approvalRequired(item.requiresElectricity ?? false, item.radiation, item.radiationType)}
        >
          <d-section>
            <d-checkbox
              wrap-label
              ?checked=${item.dsaApproved}
              option-field="assets_dsaApproved"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                this.editItem = { ...item, dsaApproved: e.detail.checked };
              }}
            >
            </d-checkbox>
          </d-section>
        </d-expansion>
      </d-expansion>

      <d-section>
        <d-edit-text
          class="minWidth200"
          field="assets_manufacturer"
          .value=${item.manufacturer}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, manufacturer: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          field="assets_model"
          .value=${item.model}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, model: e.detail.value };
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="assets_location"
          .value=${item.location}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, location: e.detail.value };
          }}
        ></d-edit-text>

        <d-edit-text
          class="minWidth200"
          field="assets_userManual"
          .value=${item.userManual}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, userManual: e.detail.value };
          }}
        >
        </d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="assets_supplier"
          .value=${item.supplier}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, supplier: e.detail.value };
          }}
        >
        </d-edit-text>

        <d-edit-text
          class="minWidth200"
          field="assets_acquiredYear"
          .value=${item.acquiredYear}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, acquiredYear: e.detail.value };
          }}
        >
        </d-edit-text>

        <d-edit-text
          class="minWidth200"
          field="assets_discardedYear"
          .value=${item.discardedYear}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, discardedYear: e.detail.value };
          }}
        >
        </d-edit-text>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="assets_accessory"
          .value=${item.accessory}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, accessory: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="assets_competency"
          .value=${item.competency}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, competency: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="assets_maintenance"
          .value=${item.maintenance}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, maintenance: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-checkbox
          wrap-label
          ?checked=${item.maintenanceRequired}
          class="flex-3"
          option-field="assets_maintenanceRequired"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            this.editItem = { ...item, maintenanceRequired: e.detail.checked };
          }}
        >
        </d-checkbox>
      </d-section>

      <d-section>
        <d-edit-text
          class="flex"
          field="assets_complianceDeclaration"
          placeholder="Angi hvor samsvarserklæringen finnes"
          .value=${item.complianceDeclaration}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, complianceDeclaration: e.detail.value };
          }}
        >
        </d-edit-text>
      </d-section>

      <d-section label="Mottakskontroll" .contentStickyTop=${this.contentStickyTop}>
        <d-checkbox
          wrap-label
          ?checked=${item.assetVerified}
          option-field="assets_assetVerified"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            this.editItem = { ...item, assetVerified: e.detail.checked };
          }}
        >
        </d-checkbox>
        <d-checkbox
          wrap-label
          ?checked=${item.documentationVerified}
          option-field="assets_documentationVerified"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            this.editItem = { ...item, documentationVerified: e.detail.checked };
          }}
        >
        </d-checkbox>
        <d-checkbox
          wrap-label
          ?checked=${item.documented}
          option-field="assets_documented"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            this.editItem = { ...item, documented: e.detail.checked };
          }}
        >
        </d-checkbox>
      </d-section>`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private onAction(e: CustomEvent<string>) {
    e.stopPropagation();
    if (e.detail === 'create-event-occurrence') {
      this.fireCreateEventOccurrence(undefined);
    }
  }

  private onCreateEventOccurrenceWithName(e: MouseEvent, name: string) {
    e.preventDefault();
    e.stopPropagation();
    this.fireCreateEventOccurrence(name);
  }

  private fireCreateEventOccurrence(name: string | undefined) {
    const id = uuid();
    let nameParam = '';
    if (name) {
      nameParam = '&name=' + encodeURI(name);
    }
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      assetUuid: this.entityView.uuid,
      instance: LocalDate.now().toString(),
      targetUrl: this.entityView.href + '/eventOccurrences/' + id + '?edit' + nameParam,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private radiationBullets() {
    return [
      {
        field: 'assets_hasPersonalDataItem',
        elseField: 'assets_hasPersonalDataItemNOT',
        value: this.entityView.hasPersonalDataItem,
        alert: false,
      },
      {
        field: 'assets_requiresElectricity',
        elseField: '',
        value: this.entityView.requiresElectricity,
        alert: false,
      },
      {
        label: this._radiationTypeBulletText(),
        elseLabel: '',
        value: this.entityView.radiation,
        alert: false,
      },
      {
        field: 'assets_maintenanceRequired',
        elseField: 'assets_maintenanceRequiredNOT',
        value: this.entityView.maintenanceRequired,
        alert: this.entityView.requiredEventInstanceMissing,
      },
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-asset-view': DAssetView;
  }
}
