import { IssueViewModel } from 'src/store/api';
import type { EmployeeViewModelWithName } from 'src/store/types';
import { shorten } from 'src/utilities/text';

export function getIssueLabel(issue: IssueViewModel, accessible: boolean, employees: EmployeeViewModelWithName[]) {
  if (!accessible) {
    if (issue.reportedTo) {
      const employee = employees.find((e) => {
        return e.uuid === issue.reportedTo;
      });
      if (employee) {
        return 'Ingen tilgang (meldt til ' + employee.name + ')';
      }
    }
    return 'Ingen tilgang';
  }
  if (issue.name) {
    return issue.name;
  }
  if (issue.description) {
    return shorten(issue.description, 42);
  }
  return '';
}
