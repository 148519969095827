import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from 'src/store';
import { AccountPlanUpdateMessageInvoicePlanEnum, OrganizationViewModelSpecialTermsEnum } from 'src/store/api';
import { SelectDropdownRichOption } from 'src/library/editors/elements/d-select-dropdown-rich';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface FeatureStates {
  meetings: boolean;
  issues: boolean;
  risk: boolean;
  staffing: boolean;
  assets: boolean;
  substances: boolean;
  data: boolean;
  healthcare: boolean;
  core: boolean;
  mini: boolean;
  complete: boolean;
  signing: boolean;
}

interface PriceVariants {
  none: number;
  member: number;
}

export interface Prices {
  a: PriceVariants;
  b: PriceVariants;
  c: PriceVariants;
  d: PriceVariants;
}

export interface Feature {
  id: string;
  displayName: string;
  description: string;
  bundle: boolean;
  module: boolean;
  price?: number;
  organizationPrice?: boolean;
  prices?: Prices;
}

export interface Promo {
  id: string;
  imageSource: string;
  videoSource?: string;
  title: string;
  text: string;
  modules: string[];
  bundles: string[];
  addons: string[];
  selectedFeature: string;
  pageAfterUpgrade: number;
}

interface PlanSize {
  value: number;
  regular: number;
  specialTerms: number;
}

export interface Plan {
  value: AccountPlanUpdateMessageInvoicePlanEnum;
  text: string;
  sizes: PlanSize[];
}

export interface PlanFeature {
  value: string;
  post: number;
  name: string;
  plans: string[];
}

export const features: Feature[] = [
  {
    id: 'MINI',
    displayName: 'Komplett Mini',
    description: 'All funksjonalitet unntatt Utstyr og Stoffkartotek',
    bundle: true,
    module: false,
    prices: {
      a: { none: 375, member: 260 },
      b: { none: 750, member: 525 },
      c: { none: 1390, member: 970 },
      d: { none: 2250, member: 1575 },
    },
  },
  {
    id: 'BASIC',
    displayName: 'Komplett Basis',
    description: 'All funksjonalitet',
    bundle: true,
    module: false,
    prices: {
      a: { none: 420, member: 295 },
      b: { none: 845, member: 590 },
      c: { none: 1560, member: 1095 },
      d: { none: 2530, member: 1770 },
    },
  },
  {
    id: 'EXTRA',
    displayName: 'Komplett Ekstra',
    description: 'All funksjonalitet pluss full opplæring og kontinuerlig oppfølging',
    bundle: true,
    module: false,
    prices: {
      a: { none: 1070, member: 945 },
      b: { none: 2145, member: 1890 },
      c: { none: 4110, member: 3645 },
      d: { none: 5730, member: 4970 },
    },
  },
  {
    id: 'MEETINGS',
    displayName: 'Møter',
    description: '',
    bundle: false,
    module: true,
    price: 20,
  },
  {
    id: 'ISSUES',
    displayName: 'Avvik',
    description: '',
    bundle: false,
    module: true,
    price: 20,
  },
  {
    id: 'RISK',
    displayName: 'Risiko',
    description: '',
    bundle: false,
    module: true,
    price: 40,
    organizationPrice: true,
  },
  {
    id: 'STAFFING',
    displayName: 'Bemanning',
    description: '',
    bundle: false,
    module: true,
    price: 40,
  },
  {
    id: 'ASSETS',
    displayName: 'Utstyr',
    description: '',
    bundle: false,
    module: true,
    price: 20,
  },
  {
    id: 'SUBSTANCES',
    displayName: 'Stoffkartotek',
    description: '',
    bundle: false,
    module: true,
    price: 20,
  },
  {
    id: 'DATA',
    displayName: 'Datasikkerhet',
    description: '',
    bundle: false,
    module: true,
    price: 60,
  },
  {
    id: 'HEALTHCARE',
    displayName: 'Bedriftshelsetjeneste',
    description: '',
    bundle: false,
    module: true,
    price: 375,
    organizationPrice: true,
  },
  {
    id: 'SIGNING',
    displayName: 'Signering',
    description: '',
    bundle: false,
    module: true,
    price: 0,
    organizationPrice: true,
  },
];

export const promo: Promo[] = [
  {
    id: 'meetings',
    imageSource: '/promo-media/meetings.png',
    title: 'Møter og medarbeidersamtaler',
    text: `<p>Møteverktøyet i TrinnVis har forslag til faste møter som styremøte, personalmøte og 
medarbeidersamtaler. Du kan også lage egne møter og møteserier. 
Planlegging, innkalling, oppfølging og referater er samlet på ett sted.</p>
<p>Møteforslagene har forslag til agenda, som du selv kan tilpasse etter behov. 
Når du lager nye møter, hentes automatisk agendaen fra forrige møte i samme serie.</p>`,
    modules: ['MEETINGS'],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'MEETINGS',
    pageAfterUpgrade: 9772,
  },
  {
    id: 'issues',
    imageSource: '/promo-media/issues.png',
    videoSource: '/promo-media/issues.mp4',
    title: 'Superenkelt avvikssystem',
    text: `<p>Alle virksomheter skal ha et avvikssystem. 
TrinnVis Avvik gjør det superenkelt å melde og behandle avvik. 
Avviksbehandlingen inkluderer en smart sjekkliste med lenker til en rekke eksterne meldeordninger.</p>`,
    modules: ['ISSUES'],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 882901,
  },
  {
    id: 'risk',
    imageSource: '/promo-media/risk.png',
    title: 'Risikovurdering på en forståelig måte',
    text: `<p>Risikovurdering er en systematisk måte å vurdere risikoen for uønskede hendelser, 
og er en viktig del av sikkerhets- og kvalitetsarbeidet i enhver virksomhet.</p>
<p>Poenget med risikovurdering er å unngå farlige, alvorlige eller på andre måter uønskede hendelser ved å 
kartlegge dem i forveien, og sette inn tiltak for å begrense risiko.</p>
<p>TrinnVis Komplett har verktøy som hjelper deg å kalkulere risiko på en systematisk måte. 
Du finner også en rekke forslag til hendelser som du bør vurdere risikoen for, 
og hjelpetekster som forklarer hvordan du går frem.</p>`,
    modules: [],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 712363,
  },
  {
    id: 'staffing',
    imageSource: '/promo-media/staffing.png',
    title: 'Arbeidstid, ferie og fravær',
    text: `<p>Bemanningskalenderen i TrinnVis Komplett gir visuell oversikt over arbeidstid, ferie og fravær. 
Kalenderen har én rad for hver person, hvor arbeidsdager og -tider vises som hvite felter og fridager som grå. 
Fraværsperioder har fargekoder etter fraværstype.</p>
<p>Alle kan selv legge inn fravær, f.eks egenmelding og ferieønsker.</p>
<p>Kalenderen viser også hvor mange som er tilstede fra ulike personalgrupper.</p>`,
    modules: [],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 5962,
  },
  {
    id: 'assets',
    imageSource: '/promo-media/assets.png',
    title: 'Oversikt og kontroll på utstyr',
    text: `<p>I TrinnVis Utstyrsoversikt kan du registrere alt utstyr du trenger å ha kontroll på, 
og laste opp dokumentasjon som samsvarserklæringer og manualer. </p>
<p>Du kan også lage oppgaver for vedlikehold og kontroll knyttet til utstyrsenheter,
og tildele oppgavene til ansvarsområder eller enkeltpersoner.</p>`,
    modules: [],
    bundles: ['BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 110,
  },
  {
    id: 'substances',
    imageSource: '/promo-media/substances.png',
    title: 'Full kontroll på farlige stoffer',
    text: `<p>Alle som oppbevarer eller behandler farlige stoffer skal ha et stoffkartotek med sikkerhetsdatablader. 
Kartoteket skal være lett tilgjengelig for hele personalet. Databladene blir vanligvis levert i pdf-format, 
og i mange virksomheter blir disse dokumentene liggende i en mappe på en datamaskin. </p>
<p>I TrinnVis Komplett kan du laste opp alle sikkerhetsdatablader ved å dra og slippe dem på stoffkartoteket. 
TrinnVis leser databladene du laster opp og viser navn, fareetiketter og bruksområder i en oversiktstabell. 
Du kan selv legge til andre opplysninger, som leverandør, lagringssted og eventuelle notater.</p>`,
    modules: [],
    bundles: ['BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 12368,
  },
  {
    id: 'data',
    imageSource: '/promo-media/data.jpg',
    title: 'Enkle grep for bedre datasikkerhet',
    text: `<p>Løsepengevirus og andre dataangrep mot små virksomheter øker stadig. 
I TrinnVis Komplett finner du opplæringsressurser og verktøy for å beskytte deg mot angrep 
og sørge for at du oppfyller personvernkravene.</p>
<p>TrinnVis Komplett inkluderer Veileder for datasikkerhet, Sikkerhetskurs for personalet og 
verktøy for kartlegging og dokumentasjon av datasystemer, tilganger og sikkerhetskopiering.</p>`,
    modules: [],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: [],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 20,
  },
  {
    id: 'healthcare',
    imageSource: '/promo-media/complete.png',
    title: 'Integrert og personlig bedriftshelsetjeneste',
    text: `<p>Bedriftshelsetjeneste er lovpålagt for de fleste virksomheter. Med TrinnVis Bedriftshelsetjeneste får du en bedriftshelsetjeneste som er fullt integrert i ditt driftssystem. TrinnVis Bedriftshelsetjeneste dekker alle lovkrav og gir personlig oppfølging.</p>`,
    modules: ['HEALTHCARE'],
    bundles: [],
    addons: [],
    selectedFeature: 'HEALTHCARE',
    pageAfterUpgrade: 695944,
  },
  {
    id: 'hse',
    imageSource: '/promo-media/complete.png',
    title: 'Et komplett HMS-system',
    text: `<p>TrinnVis er et komplett HMS-system med ferdige forslag til ansvarsområder, rutiner, 
retningslinjer, avtaler og andre dokumenter. Du kan bruke forslagene som de er, 
eller redigere etter behov. Du kan også legge til egne dokumenter. 
Alle dokumentforslag er utstyrt med lenker til relevant lovverk og andre referanser.</p>
<p>Siden hele personalet har tilgang til systemet, 
fungerer det også som en personalhåndbok der alle rutiner og retningslinjer er lette å finne. </p>
<p>TrinnVis Komplett har også avvikssystem, risikovurdering og en rekke andre verktøy.</p>`,
    modules: [],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: ['HEALTHCARE'],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 63,
  },
  {
    id: 'complete',
    imageSource: '/promo-media/complete.png',
    title: 'Trinnvis Komplett – alt på ett sted',
    text: `<p>TrinnVis Komplett er et driftssystem med innebygget HMS og internkontroll.</p>
<p>I motsetning til de fleste andre driftssystemer kommer TrinnVis med ferdig innhold i form av forslag til 
ansvarsområder, rutiner, retningslinjer og andre nødvendige dokumenter.
TrinnVis er bygget for å tilpasse seg virksomheten, 
slik at det bare inneholder de dokumentene som kreves for din virksomhet.</p>
<p>De fleste TrinnVis-brukere er mest opptatt av å utøve yrket sitt, ikke drive med administrasjon. 
TrinnVis er laget for å gjøre det så enkelt som mulig å drive effektivt, ryddig og lovlig.</p>`,
    modules: [],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    addons: ['HEALTHCARE'],
    selectedFeature: 'BASIC',
    pageAfterUpgrade: 695944,
  },
  {
    id: 'doctypes',
    imageSource: '/promo-media/complete.png',
    title: 'Trinnvis har alle dokumenter du trenger',
    text: `<p>TrinnVis Komplett er et driftssystem med innebygget HMS og internkontroll.
Systemet inneholder alle dokumenttyper du trenger: måldokumenter, rutiner, retningslinjer, avtaler, avviksmeldinger etc.</p>
<p>I motsetning til de fleste andre driftssystemer kommer TrinnVis med ferdig innhold.
TrinnVis er bygget for å tilpasse seg virksomheten, 
slik at det bare inneholder de dokumentene som kreves for din virksomhet.</p>
<p>De fleste TrinnVis-brukere er mest opptatt av å utøve yrket sitt, ikke drive med administrasjon. 
TrinnVis er laget for å gjøre det så enkelt som mulig å drive effektivt, ryddig og lovlig.</p>`,
    modules: [],
    selectedFeature: 'BASIC',
    addons: ['HEALTHCARE'],
    bundles: ['MINI', 'BASIC', 'EXTRA'],
    pageAfterUpgrade: 695944,
  },
];

export const promoCodesForPages = [
  {
    pageId: 318434,
    promo: 'complete',
  },
  {
    pageId: 5962,
    promo: 'staffing',
  },
  {
    pageId: 9772,
    promo: 'meetings',
  },
  {
    pageId: 110,
    promo: 'assets',
  },
  {
    pageId: 12368,
    promo: 'substances',
  },
  {
    pageId: 65,
    promo: 'data',
  },
  {
    pageId: 882901,
    promo: 'issues',
  },
  {
    pageId: 712363,
    promo: 'risk',
  },
];

export const getFeatureStates = createSelector(getOrganization, function (organization): FeatureStates {
  const m = organization?.features ?? [];
  return {
    meetings: m.includes('MEETINGS'),
    staffing: m.includes('STAFFING'),
    assets: m.includes('ASSETS'),
    substances: m.includes('SUBSTANCES'),
    issues: m.includes('ISSUES'),
    risk: m.includes('RISK'),
    data: m.includes('DATA'),
    healthcare: m.includes('HEALTHCARE'),
    core: m.includes('CORE'),
    mini: m.includes('CORE') && m.includes('MEETINGS'),
    complete:
      m.includes('BASIC') ||
      m.includes('EXTRA') ||
      (m.includes('CORE') && m.includes('MEETINGS') && m.includes('ASSETS') && m.includes('SUBSTANCES')),
    signing: m.includes('SIGNING'),
  };
});

export const getSpecialTerms = createSelector(getOrganization, function (organization):
  | OrganizationViewModelSpecialTermsEnum
  | undefined {
  if (organization) {
    return organization.specialTerms as OrganizationViewModelSpecialTermsEnum;
  }
});

export function getPromoCodeForPage(pageId: number): string {
  const promo = promoCodesForPages.find((p) => {
    return p.pageId === pageId;
  });
  if (promo) {
    return promo.promo;
  }
  return '';
}

export const sectorOptions: SelectDropdownRichOption[] = [
  {
    value: 'HEALTH',
    text: 'Helse- og omsorgstjenester',
    secondaryText: 'f.eks lege, tannlege, fysioterapeut, institusjon',
  },
  {
    value: 'CRAFTS_TRANSPORTATION_CLEANING',
    text: 'Håndverk, transport, renhold',
    secondaryText: 'f.eks entreprenør, rørlegger, verksted, taxi, bud',
  },
  {
    value: 'FOOD_ACCOMMODATION',
    text: 'Mat og overnatting',
    secondaryText: 'f.eks kafe, restaurant, catering, gårdsdrift, hotell',
  },
  {
    value: 'PERSONAL_TRADE_SERVICE',
    text: 'Personlig handel og tjenester',
    secondaryText: 'f.eks frisør, galleri, butikk, trening, opplevelser',
  },
  {
    value: 'OFFICE',
    text: 'Kontor',
    secondaryText: 'f.eks konsulent, advokat, salg, IT, finans',
  },
  {
    value: 'OTHER',
    text: 'Annet',
    secondaryText: '',
  },
];

export const organizationSizes = [
  { value: 1, text: '1-3 personer' },
  { value: 4, text: '4-8 personer' },
  { value: 9, text: '9-15 personer' },
  { value: 16, text: '16-30 personer' },
];

export const plans: Plan[] = [
  {
    value: 'A',
    text: 'Mini',
    sizes: [
      { value: 1, regular: 375, specialTerms: 260 },
      { value: 4, regular: 750, specialTerms: 525 },
      { value: 9, regular: 1390, specialTerms: 970 },
      { value: 16, regular: 2250, specialTerms: 1575 },
    ],
  },
  {
    value: 'B',
    text: 'Basis',
    sizes: [
      { value: 1, regular: 420, specialTerms: 295 },
      { value: 4, regular: 845, specialTerms: 590 },
      { value: 9, regular: 1560, specialTerms: 1095 },
      { value: 16, regular: 2530, specialTerms: 1770 },
    ],
  },
  {
    value: 'C',
    text: 'Ekstra',
    sizes: [
      { value: 1, regular: 1070, specialTerms: 945 },
      { value: 4, regular: 2145, specialTerms: 1890 },
      { value: 9, regular: 4110, specialTerms: 3645 },
      { value: 16, regular: 5730, specialTerms: 4970 },
    ],
  },
];

export const AddonsEnum = {
  HEALTHCARE: 'HEALTHCARE',
} as const;
export type AddonsEnum = (typeof AddonsEnum)[keyof typeof AddonsEnum];

export const planFeatures: PlanFeature[] = [
  {
    value: 'templates',
    post: 8118,
    name: 'Ferdige rutiner og retnings&shylinjer',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'hms',
    post: 10545,
    name: 'HMS og personal&shyhånd&shybok',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'privacy',
    post: 8147,
    name: 'Person&shyvern og GDPR',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'issues',
    post: 8317,
    name: 'Verktøy for avvik, risiko&shyvurdering og intern&shykontroll',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'calendar',
    post: 8153,
    name: 'Felles kalender og gjøre&shyliste',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'meetings',
    post: 8284,
    name: 'Verktøy for møter og med&shyarbeider&shysam&shytaler',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'roles',
    post: 8233,
    name: 'Til&shydeling av an&shysvar og opp&shygaver',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'staffing',
    post: 8249,
    name: 'Beman&shynings&shyover&shysikt',
    plans: ['A', 'B', 'C'],
  },
  {
    value: 'assets',
    post: 8273,
    name: 'Utstyrs&shyoversikt',
    plans: ['B', 'C'],
  },
  {
    value: 'substanceRegister',
    post: 10291,
    name: 'Stoff&shykartotek',
    plans: ['B', 'C'],
  },
  {
    value: 'followUp',
    post: 10582,
    name: 'Opp&shylæring og kon&shytinu&shyerlig opp&shyfølging',
    plans: ['C'],
  },
  /*
  {
    value: 'healthcare',
    post: 15429,
    name: 'Bedrifts&shyhelse&shytjeneste',
    plans: [''],
  },
   */
];

export const specialTermsOptions: SelectDropdownOption[] = [
  { value: 'NONE', text: 'Velg organisasjon' },
  { value: 'DNLF', text: 'Den norske legeforening' },
  { value: 'NTF', text: 'Den norske tannlegeforening' },
  { value: 'NPF', text: 'Norsk psykologforening' },
  { value: 'NFF', text: 'Norsk Fysioterapeutforbund' },
  { value: 'NKF', text: 'Norsk kiropraktorforening' },
  { value: 'NMF', text: 'Norsk manuellterapeutforening' },
  { value: 'NOF', text: 'Norsk osteopatforbund' },
  { value: 'NNF', text: 'Norges Naprapatforbund' },
];
