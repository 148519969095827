/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DeleteMeetingOccurrenceCommand
 */
export interface DeleteMeetingOccurrenceCommand {
  /**
   * The meeting occurrence uuid.
   * @type {string}
   * @memberof DeleteMeetingOccurrenceCommand
   */
  uuid: string;
  /**
   * Should future instances also be deleted?
   * @type {string}
   * @memberof DeleteMeetingOccurrenceCommand
   */
  saveType: DeleteMeetingOccurrenceCommandSaveTypeEnum;
}

/**
 * @export
 */
export const DeleteMeetingOccurrenceCommandSaveTypeEnum = {
  This: 'THIS',
  Future: 'FUTURE',
} as const;
export type DeleteMeetingOccurrenceCommandSaveTypeEnum =
  (typeof DeleteMeetingOccurrenceCommandSaveTypeEnum)[keyof typeof DeleteMeetingOccurrenceCommandSaveTypeEnum];

/**
 * Check if a given object implements the DeleteMeetingOccurrenceCommand interface.
 */
export function instanceOfDeleteMeetingOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'saveType' in value;

  return isInstance;
}

export function DeleteMeetingOccurrenceCommandFromJSON(json: any): DeleteMeetingOccurrenceCommand {
  return DeleteMeetingOccurrenceCommandFromJSONTyped(json, false);
}

export function DeleteMeetingOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DeleteMeetingOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    saveType: json['saveType'],
  };
}

export function DeleteMeetingOccurrenceCommandToJSON(value?: DeleteMeetingOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    saveType: value.saveType,
  };
}
