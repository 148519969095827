/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageFile } from './MessageFile';
import { MessageFileFromJSON, MessageFileFromJSONTyped, MessageFileToJSON } from './MessageFile';

/**
 *
 * @export
 * @interface AskResponse
 */
export interface AskResponse {
  /**
   *
   * @type {string}
   * @memberof AskResponse
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof AskResponse
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof AskResponse
   */
  error?: string;
  /**
   *
   * @type {boolean}
   * @memberof AskResponse
   */
  overwrite?: boolean;
  /**
   *
   * @type {Array<MessageFile>}
   * @memberof AskResponse
   */
  files?: Array<MessageFile>;
  /**
   *
   * @type {string}
   * @memberof AskResponse
   */
  html?: string;
}

/**
 * Check if a given object implements the AskResponse interface.
 */
export function instanceOfAskResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AskResponseFromJSON(json: any): AskResponse {
  return AskResponseFromJSONTyped(json, false);
}

export function AskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AskResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: !exists(json, 'role') ? undefined : json['role'],
    text: !exists(json, 'text') ? undefined : json['text'],
    error: !exists(json, 'error') ? undefined : json['error'],
    overwrite: !exists(json, 'overwrite') ? undefined : json['overwrite'],
    files: !exists(json, 'files') ? undefined : (json['files'] as Array<any>).map(MessageFileFromJSON),
    html: !exists(json, 'html') ? undefined : json['html'],
  };
}

export function AskResponseToJSON(value?: AskResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: value.role,
    text: value.text,
    error: value.error,
    overwrite: value.overwrite,
    files: value.files === undefined ? undefined : (value.files as Array<any>).map(MessageFileToJSON),
    html: value.html,
  };
}
