import { optionsFromFields } from './selectors';
import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from './organization.js';
import { fieldsByCode } from './fields-selector-functions.js';
import type {
  ComputerViewModel,
  ContractViewModel,
  ExternalConnectionViewModel,
  NetworkViewModel,
  PersonalDataItemViewModel,
  RiskAssessmentViewModel,
} from '../api';
import {
  computersNotDeleted,
  ComputerViewModelExtended,
  externalConnectionsNotDeleted,
  networksNotDeleted,
  nhn,
  personalDataItemsNotDeleted,
  PersonalDataItemViewModelExtended,
} from './organization-computers.js';
import { employeeNamesById } from './organization-employees.js';
import { partnerNamesById } from './organization-partners.js';
import { flatMap, toList } from './utilities.js';
import thenByModule from 'thenby';
import type { NhnViewModel } from '../types.js';

const { firstBy } = thenByModule;

export function mobileMedicalDataRequirements(): {
  id: string;
  value: string;
}[] {
  return [
    { id: 'storageEncryption', value: 'Lagring er kryptert' },
    {
      id: 'networkEncryption',
      value: 'Kommunikasjon med internt nettverk er kryptert',
    },
    { id: 'strongAuthentication', value: 'Bruker sterk autentisering' },
    {
      id: 'firewallConfiguration',
      value: 'Kan bare kommunisere med predefinert utstyr',
    },
    {
      id: 'accessPointLogged',
      value: 'Tilkoblingspunkt logges kontinuerlig',
    },
    { id: 'antitheftMarking', value: 'Tyverimerket' },
    { id: 'printBlocked', value: 'Sperret for utskrift' },
    {
      id: 'externalStorageBlocked',
      value: 'Sperret for eksterne lagringsenheter',
    },
    {
      id: 'communicationPortsBlocked',
      value: 'Kommunikasjonsporter er sperret',
    },
    { id: 'secureStorage', value: 'Sikker oppbevaring' },
    { id: 'noPrivateUse', value: 'Brukes ikke privat' },
  ];
}

const computerHasMedicalData = function (
  c: ComputerViewModel & { personalDataItems: PersonalDataItemViewModel[] },
): boolean {
  const items = c.personalDataItems.filter((item) => item.type?.startsWith('patientData'));
  return items.length > 0;
};

/**
 * Defines the rules for a computer. A rule as consists of a test that given a
 * computer, determines if this rule is violated and a message function
 * that creates the error message.
 *
 * There is also an id field. this is used when testing the rules.
 *
 * @type {Array}
 */
const rulesForComputers = [
  {
    id: 1,
    test: function (c: ComputerViewModelExtended): boolean {
      return (
        (c.unitType === 'computer' || c.unitType === '') &&
        c.hasMedicalData &&
        c.type === 'mobile' &&
        (c.mobileMedicalDataRequirements ?? []).length !== mobileMedicalDataRequirements().length
      );
    },
    message: function (): string {
      return 'Krav for lagring av helseopplysninger på bærbare enheter er ikke oppfylt';
    },
  },
];

function personalDataItemsInComputers(
  personalDataItems: PersonalDataItemViewModelExtended[],
  computerId: string,
): PersonalDataItemViewModelExtended[] {
  computerId = computerId ?? '';
  return personalDataItems.filter((p) => {
    const s = p.storageUnit ?? '';
    return s === computerId;
  });
}

/**
 * Computers with a given network uuid
 *
 * @param computers
 * @param networkUuid
 * @returns {*}
 */
function computersInNetwork(computers: ComputerViewModelExtended[], networkUuid: string): ComputerViewModelExtended[] {
  return computers.filter((c) => c.networkUuid === networkUuid);
}

/**
 * Finds all computers connected to the input computer. If the input computer
 * is not in a network, then return a list with just it self.
 *
 * @param computer the computer to find connect computers for
 * @param computers all computers, should already be filtered and augumented
 *         by computersForMap
 * @returns {*}
 */
function computersInSameNetwork(
  computer: ComputerViewModelExtended,
  computers: ComputerViewModelExtended[],
): ComputerViewModelExtended[] {
  if (!computer.networkUuid) {
    return [computer];
  } else {
    return computersInNetwork(computers, computer.networkUuid);
  }
}

function isEmpty(content?: string[]): boolean {
  return content === undefined || content.length === 0;
}

function isEmptyText(content?: string): boolean {
  return content === undefined || content === '';
}

function alertsForDataItem(c: PersonalDataItemViewModelExtended): string[] {
  const warnings: string[] = [];
  if (!c.riskAssessmentConcludesOk && c.personalData) {
    warnings.push('Løsningen er ikke riskovurdert med tilfredsstillende resultat');
  }
  if (
    c.storageMedium === 'digital' &&
    c.treatments.indexOf('storage') > -1 &&
    (c.backupOperatorType === 'NONE' || c.backupOperatorType === 'UNDEFINED')
  ) {
    if (
      c.storageUnitComputer &&
      (c.storageUnitComputer.unitType === 'cloudService' || c.storageUnitComputer.unitType === 'remoteServer')
    ) {
      // do nothing
    } else {
      warnings.push('Backup er ikke definert');
    }
  }
  if (c.backupOperatorType === 'external' && (c.backupOperator == null || c.backupOperator.length < 1)) {
    warnings.push('Leverandør av backup er ikke definert');
  }
  if (!c.patientDialogueRequirements) {
    c.patientDialogueRequirements = [];
  }
  if (c.type === 'patientDialogue' && c.patientDialogueRequirements.length < 1) {
    warnings.push('Krav til system for digital pasientdialog ikke oppfylt');
  }
  let missing = '';
  if (!c.type || c.type === 'UNDEFINED') {
    missing += 'Type, ';
  }
  if (c.type === 'medicalRecords' && c.storageMedium === 'digital') {
    if (!c.journalSize) {
      missing += 'Antall pasienter, ';
    }
    if (c.journalHasElectronicMessaging === true) {
      if (!c.messagingType || c.messagingType === 'UNDEFINED') {
        missing += 'Kommunikasjonsmodul, ';
      }

      if (c.messagingSupplier === null || c.messagingSupplier === '') {
        missing += 'Leverandør av kommunikasjonsmodul, ';
      }
    }
  }
  if (c.personalData || c.type?.startsWith('patientData')) {
    if (isEmptyText(c.purpose)) {
      missing += 'Formål, ';
    }
    if (c.type === 'medicalRecords' && isEmptyText(c.legalBasis)) {
      missing += 'Lovhjemmel, ';
    }
    if (c.type != 'medicalRecords' && isEmptyText(c.legalBasis) && isEmpty(c.consentContracts)) {
      missing += 'Lovhjemmel eller dokumentasjon av samtykke, ';
    }
  }
  missing = missing.slice(0, -2);

  if (missing.length > 0) {
    warnings.push('Ikke registrert: ' + missing);
  }
  return warnings;
}

function alertsForComputer(c: ComputerViewModelExtended): string[] {
  const warnings: string[] = [];

  rulesForComputers.forEach((r) => {
    if (r.test(c)) {
      warnings.push(r.message());
    }
  });

  if (c.unitType === 'computer' || c.unitType === '') {
    if (c.hasMedicalData) {
      if (c.type !== 'mobile' && c.elevated === false) {
        warnings.push('Maskinen skal ikke stå på gulvet');
      }
      if (c.type !== 'mobile' && c.type !== 'Server' && c.computerIsInNetworkWithServer) {
        warnings.push('Pasientdata bør lagres på server');
      }
      if (c.type !== 'mobile' && c.locked === false) {
        warnings.push(
          'Maskinen og nettverksutstyr skal oppbevares i ' + 'låst serverrom eller i låst skap utenfor publikumsområde',
        );
      }
      if (c.restrictedPhysicalAccess === false) {
        warnings.push('Adgang til enheten skal bare gis til personell med absolutt behov');
      }
    }
    if (c.computerIsInNetworkWithMedicalData) {
      if (!c.antiVirusRequirements) {
        c.antiVirusRequirements = [];
      }
      if (c.antiVirusRequirements.length < 3) {
        warnings.push('Krav til antivirussystem i nettverk med pasientdata er ikke oppfylt');
      }
      if (c.type === 'Arbeidsstasjon' && c.displayPositionSecure === false) {
        warnings.push('Skjermer skal plasseres slik at uvedkommende ikke får innsyn');
      }
    }
    if (c.computerIsInNetworkWithPersonalData) {
      if (c.systemUpdateOperatorType === 'NONE' || c.systemUpdateOperatorType === 'UNDEFINED') {
        warnings.push('Drift av systemoppdateringer er ikke definert');
      }
      if (
        (c.systemUpdateOperatorType === 'external' || c.systemUpdateOperatorType === 'internalAndExternal') &&
        (c.systemUpdateOperator === undefined || c.systemUpdateOperator === '')
      ) {
        warnings.push('Leverandør av systemoppdateringer er ikke definert');
      }
      if (c.antiVirusOperatorType === 'NONE' || c.antiVirusOperatorType === 'UNDEFINED') {
        warnings.push('Drift av antivirussystem er ikke definert');
      }
      if (
        (c.antiVirusOperatorType === 'external' || c.antiVirusOperatorType === 'internalAndExternal') &&
        (c.antiVirusOperator === undefined || c.antiVirusOperator === '')
      ) {
        warnings.push('Leverandør av antivirussystem er ikke definert');
      }
      if (c.type !== 'mobile' && (c.location === undefined || c.location === '')) {
        warnings.push('Fysisk plassering er ikke definert');
      }
    }
  }
  if ((c.unitType === 'remoteServer' || c.unitType === 'cloudService') && computerHasMedicalData(c)) {
    if (c.connectionType !== 'NHN' && c.connectionType !== 'NHNotherISP') {
      if (c.unitType === 'remoteServer') {
        warnings.push('Fjernservere som behandler helseopplysninger skal være tilkoblet gjennom Norsk helsenett');
      }
      if (c.unitType === 'cloudService') {
        warnings.push('Skytjenester som behandler helseopplysninger skal være tilkoblet gjennom Norsk helsenett');
      }
    }
    if (!c.serviceProviderContract) {
      warnings.push('Mangler databehandleravtale');
    }
  }
  if (c.unitType === 'printer' && c.computerIsInNetworkWithMedicalData) {
    if (!c.location) {
      warnings.push('Plassering er ikke angitt');
    }
    if (!c.printerPositionSecure) {
      warnings.push(
        'Nettverksskrivere på nettverk med pasientdata skal ' +
          'plasseres slik at uvedkommende ikke kan få tilgang til utskrifter',
      );
    }
  }
  return warnings;
}

function alertsForNetwork(
  c: NetworkViewModel & { computers: ComputerViewModelExtended[]; alerts: string[] },
): string[] {
  const warnings: string[] = [];
  let hasPersonalData = false;
  let hasMedicalData = false;
  let computerInheritsSystemUpdateOperatorType = false;
  let computerInheritsAntiVirusOperatorType = false;
  for (const item of c.computers) {
    if (item.systemUpdateOperatorType === 'INHERIT') {
      computerInheritsSystemUpdateOperatorType = true;
    }
    if (item.antiVirusOperatorType === 'INHERIT') {
      computerInheritsAntiVirusOperatorType = true;
    }
    if (item.personalDataItems && item.personalDataItems.length > 0) {
      hasPersonalData = true;
      for (const element of item.personalDataItems) {
        if (element.type === 'medicalRecords' || element.type === 'medicalEquipmentRecords') {
          hasMedicalData = true;
        }
      }
    }
  }
  if (hasMedicalData && c.connectionType !== 'NHN' && c.connectionType !== 'NHNotherISP') {
    warnings.push('Nettverk som lagrer pasientdata skal bare ha internetttilkobling gjennom Norsk helsenett');
  }
  if (hasPersonalData) {
    if (
      (c.systemUpdateOperatorType === 'NONE' || c.systemUpdateOperatorType === 'UNDEFINED') &&
      computerInheritsSystemUpdateOperatorType
    ) {
      warnings.push('Drift av systemoppdateringer er ikke definert');
    }
    if (
      (c.systemUpdateOperatorType === 'external' || c.systemUpdateOperatorType === 'internalAndExternal') &&
      (!c.systemUpdateOperator || c.systemUpdateOperator.length < 1)
    ) {
      warnings.push('Leverandør av systemoppdateringer er ikke definert');
    }
    if (
      (c.antiVirusOperatorType === 'NONE' || c.antiVirusOperatorType === 'UNDEFINED') &&
      computerInheritsAntiVirusOperatorType
    ) {
      warnings.push('Drift av antivirussystem er ikke definert');
    }

    if (
      (c.antiVirusOperatorType === 'external' || c.antiVirusOperatorType === 'internalAndExternal') &&
      (!c.antiVirusOperator || c.antiVirusOperator.length < 1)
    ) {
      warnings.push('Leverandør av antivirusdrift er ikke definert');
    }
  }
  return warnings;
}

function alertsForExternalConnections(
  c: ExternalConnectionViewModel,
  networks: (NetworkViewModel & { computers: ComputerViewModelExtended[]; alerts: string[] })[],
): string[] {
  const warnings: string[] = [];
  let connectedNetworkHasMedicalData = false;
  let typeName;
  for (const item1 of networks) {
    if (item1.uuid === c.networkUuid) {
      for (const item of item1.computers) {
        for (const element of item.personalDataItems) {
          if (element.type === 'medicalRecords' || element.type === 'medicalEquipmentRecords') {
            connectedNetworkHasMedicalData = true;
          }
        }
      }
    }
  }
  if (!c.networkUuid) {
    typeName = 'samarbeidspartneren';
    if (c.type === 'Hjemmekontor') {
      typeName = 'hjemmekontoret';
    }
    warnings.push('Det er ikke registrert hvilket nettverk ' + typeName + ' har tilgang til');
  }
  if (c.connectionType !== 'NHN' && c.connectionType !== 'NHNotherISP' && connectedNetworkHasMedicalData) {
    typeName = 'Samarbeidspartner';
    if (c.type === 'Hjemmekontor') {
      typeName = 'Hjemmekontor';
    }
    warnings.push(
      typeName + ' med tilgang til lokale nettverk ' + 'med helseopplysninger skal være tilkoblet gjennom helsenettet',
    );
  }
  if (
    connectedNetworkHasMedicalData &&
    (!c.riskAssessments || c.riskAssessments.length < 1) &&
    (!c.otherRiskAssessments || c.otherRiskAssessments.length < 1)
  ) {
    // warnings.push('Det er ikke registrert noen risikovurderinger');
  }
  if (!c.riskAssessmentConcludesOk) {
    warnings.push('Tilkoblingen skal risikovurderes');
  }
  if (c.type === 'Hjemmekontor' && isEmptyText(c.employeeUuid)) {
    warnings.push('Mangler person');
  }

  if (c.type === 'Tilkoblet samarbeidspartner' && isEmptyText(c.partnerUuid)) {
    warnings.push('Mangler samarbeidspartner');
  }

  return warnings;
}

const alertsForNhn = function (c: NhnViewModel): string[] {
  const warnings: string[] = [];
  if (c.partner === '') {
    warnings.push('Norsk helsenett er ikke registrert som samarbeidspartner');
  }
  if (c.contract === '') {
    warnings.push('Avtale med Norsk helsenett er ikke registrert');
  }

  return warnings;
};

const personalDataItemTypeInSortOrder = [
  'medicalRecords',
  'medicalEquipmentRecords',
  'patientDialogue',
  'employeeRecords',
  'customerRecords',
  'OTHER',
  'UNDEFINED',
];

export const personalDataItemsForMap = createSelector(
  personalDataItemsNotDeleted,
  getOrganization,
  function (
    items,
    organization,
  ): (PersonalDataItemViewModel & {
    storageUnitComputer: ComputerViewModel | undefined;
  } & {
    alerts: string[];
  })[] {
    if (organization === undefined) return [];
    const itemsWithComputers = items as unknown as PersonalDataItemViewModelExtended[];
    const map = itemsWithComputers
      .map((c) => {
        return {
          ...c,
          storageUnitComputer: c.storageUnit !== undefined ? organization.computersById[c.storageUnit] : undefined,
          personalDataItems: [],
          hasMedicalData: false,
        };
      })
      .map((c) => {
        return {
          ...c,
          alerts: alertsForDataItem(c),
        };
      });
    return map.sort(
      firstBy(function (v: { type: string }) {
        return personalDataItemTypeInSortOrder.indexOf(v.type);
      })
        .thenBy('name', { ignoreCase: true })
        .thenBy('uuid'),
    );
  },
);

const computerUnitTypeInSortOrder = ['cloudService', 'remoteServer', 'computer', 'printer'];
const computerTypeInSortOrder = ['Server', 'Arbeidsstasjon', 'mobile'];

function computerIsInNetworkWithServer(c: { computersInSameNetwork: ComputerViewModelExtended[] }): boolean {
  const items = c.computersInSameNetwork.filter(function (item) {
    return item.type === 'Server';
  });
  return items.length > 0;
}

function computerIsInNetworkWithPersonalData(c: { computersInSameNetwork: ComputerViewModelExtended[] }): boolean {
  const items = c.computersInSameNetwork.filter(function (item) {
    return item.personalDataItems.length > 0;
  });
  return items.length > 0;
}

function computerIsInNetworkWithMedicalData(c: { computersInSameNetwork: ComputerViewModelExtended[] }): boolean {
  const items = c.computersInSameNetwork.filter(function (item) {
    return item.hasMedicalData;
  });
  return items.length > 0;
}

export const computersForMap = createSelector(
  computersNotDeleted,
  personalDataItemsForMap,
  function (computers, personalDataItems) {
    const withPersonalDataItems = computers
      .map((c) => {
        return {
          ...c,
          personalDataItems: personalDataItemsInComputers(personalDataItems, c.uuid),
          alerts: [],
          computerIsInNetworkWithServer: false,
          computerIsInNetworkWithPersonalData: false,
          computerIsInNetworkWithMedicalData: false,
        };
      })
      .map((c) => Object.assign({}, c, { hasMedicalData: computerHasMedicalData(c) }));

    return withPersonalDataItems
      .map((c) => Object.assign({}, c, { alerts: alertsForComputer(c) }))
      .map((c, i, items) => {
        return {
          ...c,
          computersInSameNetwork: computersInSameNetwork(c, items),
        };
      })
      .map((c) => {
        return {
          ...c,
          computerIsInNetworkWithServer: computerIsInNetworkWithServer(c),
          computerIsInNetworkWithPersonalData: computerIsInNetworkWithPersonalData(c),
          computerIsInNetworkWithMedicalData: computerIsInNetworkWithMedicalData(c),
        };
      })
      .sort(
        firstBy(function (v: { unitType: string }) {
          return computerUnitTypeInSortOrder.indexOf(v.unitType);
        })
          .thenBy(function (v: { type: string }) {
            return computerTypeInSortOrder.indexOf(v.type);
          })
          .thenBy(function (v: { personalDataItems: PersonalDataItemViewModel[] }) {
            return v.personalDataItems.length > 0 ? 0 : 1;
          })
          .thenBy('name', { ignoreCase: true })
          .thenBy('uuid'),
      );
  },
);

function networkHasPersonalDataItems(n: { computers: { personalDataItems: PersonalDataItemViewModel[] }[] }): boolean {
  return n.computers.filter((c) => c.personalDataItems.length > 0).length > 0;
}

export const networksForMap = createSelector(networksNotDeleted, computersForMap, function (networks, computers) {
  let map: (NetworkViewModel & { computers: ComputerViewModelExtended[]; alerts: string[] })[] = networks.map((c) =>
    Object.assign({}, c, {
      computers: computersInNetwork(computers, c.uuid),
      alerts: [],
    }),
  );
  map = map.map(function (c) {
    return Object.assign({}, c, { alerts: alertsForNetwork(c) });
  });
  return map.sort(
    firstBy(function (
      v: NetworkViewModel & {
        computers: { personalDataItems: PersonalDataItemViewModel[] }[];
      },
    ) {
      return networkHasPersonalDataItems(v) ? 0 : 1;
    })
      .thenBy('name', { ignoreCase: true })
      .thenBy('uuid'),
  );
});

function calculateConnectedViaNhn(c: ExternalConnectionViewModel, networks: NetworkViewModel[]) {
  const n = networks.find((network) => network.uuid === c.networkUuid);
  if (n === undefined) {
    return false;
  } else {
    return n.firewall === 'NHN' || n.firewall === 'NHNotherISP';
  }
}

export const externalConnectionsForMap = createSelector(
  externalConnectionsNotDeleted,
  networksForMap,
  employeeNamesById,
  partnerNamesById,
  (externalConnections, networks, employeeNamesById, partnerNamesById) => {
    const map: (ExternalConnectionViewModel & { alerts: string[]; connectedViaNhn: boolean })[] =
      externalConnections.map((c) =>
        Object.assign({}, c, {
          alerts: alertsForExternalConnections(c, networks),
          name: nameForExternalConnection(c),
          connectedViaNhn: calculateConnectedViaNhn(c, networks),
        }),
      );

    map.sort(
      firstBy<ExternalConnectionViewModel>('type', -1)
        .thenBy((v: { employeeUuid?: string; partnerUuid?: string }) => nameForExternalConnection(v).toLowerCase())
        .thenBy('uuid'),
    );

    function nameForExternalConnection(v: { employeeUuid?: string; partnerUuid?: string }) {
      if (v.employeeUuid) {
        return employeeNamesById[v.employeeUuid];
      } else if (v.partnerUuid) {
        if (partnerNamesById[v.partnerUuid]) {
          return partnerNamesById[v.partnerUuid];
        } else {
          return '';
        }
      } else {
        return '';
      }
    }

    return map;
  },
);

export const nhnForMap = createSelector(nhn, function (nhn) {
  Object.assign({}, nhn, { alerts: alertsForNhn(nhn) });
  return nhn;
});

export const computersOffNhn = createSelector(computersForMap, function (computers) {
  //        console.log('computersOffNhn', list);
  return computers.filter(function (c) {
    return c.unitType !== 'computer' && c.unitType !== 'printer' && c.unitType !== '' && c.connectionType === 'OTHER';
  });
});

export const externalConnectionsOffNhn = createSelector(externalConnectionsForMap, (externalConnections) =>
  externalConnections.filter((c) => c.connectionType === 'OTHER'),
);

export const computersOnNhn = createSelector(computersForMap, (computers) =>
  computers.filter(function (c) {
    return (
      c.unitType !== 'computer' &&
      c.unitType !== '' &&
      c.unitType !== 'printer' &&
      (c.connectionType === 'NHN' || c.connectionType === 'NHNotherISP')
    );
  }),
);

export const externalConnectionsOnNhn = createSelector(externalConnectionsForMap, (externalConnections) =>
  externalConnections.filter(function (c) {
    return c.connectionType === 'NHN' || c.connectionType === 'NHNotherISP';
  }),
);

export const externalItemsOffNhn = createSelector(
  computersOffNhn,
  externalConnectionsOffNhn,
  (computers, externalConnections) => computers.length > 0 || externalConnections.length > 0,
);

export const externalItemsOnNhn = createSelector(
  computersOnNhn,
  externalConnectionsOnNhn,
  (computers, externalConnections) => computers.length > 0 || externalConnections.length > 0,
);

export const networksLocalOffNhn = createSelector(networksForMap, (networks) => {
  return networks.filter(function (c) {
    return c.connectionType === 'OTHER';
  });
});

export const networksLocalOnNhn = createSelector(networksForMap, (networks) => {
  return networks.filter(function (c) {
    return c.connectionType === 'NHN' || c.connectionType === 'NHNotherISP';
  });
});

export const onlineElementsExist = createSelector(
  networksForMap,
  externalItemsOnNhn,
  externalItemsOffNhn,
  (networks, externalItemsOnNhn, externalItemsOffNhn) => {
    const list1 = networks.filter(function (c) {
      return c.connectionType === 'NHN' || c.connectionType === 'NHNotherISP' || c.connectionType === 'OTHER';
    });

    if (list1.length > 0) {
      //            console.log('onlineElementsExist', true);
      return true;
    }

    if (externalItemsOnNhn) {
      //            console.log('onlineElementsExist', true);
      return true;
    }

    return externalItemsOffNhn;
  },
);

export const noElementsOnNhn = createSelector(
  networksForMap,
  computersForMap,
  externalConnectionsForMap,
  (networks, computers, externalConnections) => {
    for (const item of networks) {
      if (item.connectionType === 'NHN' || item.connectionType === 'NHNotherISP') {
        //                console.log('noElementsOnNhn', false);
        return false;
      }
    }
    for (const item of computers) {
      if (
        item.unitType != 'computer' &&
        item.unitType != '' &&
        (item.connectionType === 'NHN' || item.connectionType === 'NHNotherISP')
      ) {
        //                console.log('noElementsOnNhn', false);
        return false;
      }
    }
    for (const item of externalConnections) {
      if (item.connectionType === 'NHN' || item.connectionType === 'NHNotherISP') {
        //                console.log('noElementsOnNhn', false);
        return false;
      }
    }
    //        console.log('noElementsOnNhn', true);
    return true;
  },
);

export const networksOffline = createSelector(networksForMap, (networks) => {
  return networks.filter(function (c) {
    return c.connectionType === 'NONE';
  });
});

export const standaloneComputers = createSelector(computersForMap, (computers) => {
  return computers.filter(function (c) {
    return c.unitType === 'computer' && !c.networkUuid;
  });
});

export const standalonePrinters = createSelector(computersForMap, (computers) =>
  computers.filter(function (c) {
    return c.unitType === 'printer' && !c.networkUuid;
  }),
);

export const offlinePersonalDataItems = createSelector(personalDataItemsForMap, (personalDataItems) => {
  return personalDataItemsInComputers(personalDataItems, '');
});

export const mapElementsExist = createSelector(
  networksForMap,
  computersForMap,
  personalDataItemsForMap,
  externalConnectionsForMap,
  (networksForMap, computersForMap, personalDataItemsForMap, externalConnectionsForMap) =>
    networksForMap.length > 0 ||
    computersForMap.length > 0 ||
    personalDataItemsForMap.length > 0 ||
    externalConnectionsForMap.length > 0,
);

export const riskAssessmentMenuItemsForComputerMap = createSelector(
  computersForMap,
  personalDataItemsForMap,
  externalConnectionsForMap,
  getOrganization,
  (computersForMap, personalDataItemsForMap, externalConnectionsForMap, o) => {
    const r: { [key: string]: RiskAssessmentViewModel } = {};

    if (o === undefined) return r;
    /*
       computersForMap
         .flatMap(function(e) {
           return e.riskAssessments ?? [];
         })
         .forEach(function(e) {
           r[e] = o.riskAssessmentsById[e];
         });

   */

    flatMap(personalDataItemsForMap, function (e) {
      return e.riskAssessments ?? [];
    }).forEach(function (e) {
      r[e] = o.riskAssessmentsById[e];
    });

    flatMap(externalConnectionsForMap, function (e) {
      return e.riskAssessments !== undefined ? e.riskAssessments : [];
    }).forEach(function (e) {
      r[e] = o.riskAssessmentsById[e];
    });

    return toList(r).map(function (e) {
      return Object.assign({}, e, { pages: [20] });
    });
  },
);

export const contractMenuItemsForComputerMap = createSelector(
  computersForMap,
  personalDataItemsForMap,
  externalConnectionsForMap,
  getOrganization,
  (computersForMap, personalDataItemsForMap, externalConnectionsForMap, o) => {
    const r: { [key: string]: ContractViewModel } = {};

    if (o === undefined) return r;

    flatMap(computersForMap, function (e) {
      if (e.serviceProviderContract) {
        return [e.serviceProviderContract];
      } else {
        return [];
      }
    }).forEach(function (e) {
      r[e] = o.contractsById[e];
    });

    flatMap(personalDataItemsForMap, function (e) {
      return e.consentContracts ?? [];
    }).forEach(function (e) {
      r[e] = o.contractsById[e];
    });

    flatMap(personalDataItemsForMap, function (e) {
      if (e.sharedMedicalRecords) {
        return e.sharedMedicalRecordsContracts ?? [];
      } else {
        return [];
      }
    }).forEach(function (e) {
      r[e] = o.contractsById[e];
    });

    return toList(r).map(function (e) {
      return Object.assign({}, e, { pages: [20] });
    });
  },
);

export const menuItemsForComputerMap = createSelector(
  riskAssessmentMenuItemsForComputerMap,
  contractMenuItemsForComputerMap,
  (riskAssessmentMenuItems, contractMenuItems) => ({
    riskAssessments: riskAssessmentMenuItems,
    contracts: contractMenuItems,
  }),
);

export const patientDialogueRequirementsOptions = createSelector(fieldsByCode, function (fieldsByCode) {
  const entity = 'personalDataItems';
  const fields = ['securityLevel4'];
  return optionsFromFields(fieldsByCode, entity, fields);
});

export const antiVirusRequirementsOptions = createSelector(fieldsByCode, function (fieldsByCode) {
  const entity = 'localUnits';
  const fields = ['alwaysActive', 'autoUpdate', 'autoScan'];
  return optionsFromFields(fieldsByCode, entity, fields);
});

export const mobileMedicalDataRequirementsOptions = createSelector(fieldsByCode, function (fieldsByCode) {
  const entity = 'localUnits';
  const fields = [
    'storageEncryption',
    'networkEncryption',
    'strongAuthentication',
    'firewallConfiguration',
    'accessPointLogged',
    'antitheftMarking',
    'printBlocked',
    'externalStorageBlocked',
    'communicationPortsBlocked',
    'secureStorage',
    'noPrivateUse',
  ];
  return optionsFromFields(fieldsByCode, entity, fields);
});
