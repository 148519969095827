import type {
  AssetEditDoneDetail,
  ConstitutionalDocumentEditDoneDetail,
  ContactEditDoneDetail,
  ContentEditDoneDetail,
  ContractEditDoneDetail,
  DocumentEditDoneDetail,
  EmployeeEditDoneDetail,
  FunctionEditDoneDetail,
  GuidelineEditDoneDetail,
  IssueEditDoneDetail,
  MeetingEditDoneDetail,
  PartnerEditDoneDetail,
  ReportEditDoneDetail,
  RiskAssessmentEditDoneDetail,
  SubstanceEditDoneDetail,
  TaskEditDoneDetail,
} from 'src/content/d-content';
import type {
  AssetsUpdateMessage,
  ConstitutionalDocumentUpdateMessage,
  ConstitutionalDocumentUpdateMessageClassificationEnum,
  ContactPersonUpdateMessage,
  ContactPersonUpdateMessageAccessLevelEnum,
  ContractUpdateMessage,
  ContractUpdateMessageClassificationEnum,
  DocumentUpdateMessage,
  DocumentUpdateMessageClassificationEnum,
  EmployeeUpdateMessage,
  EmployeeUpdateMessageAccessLevelEnum,
  EmployeeUpdateMessageStatusEnum,
  FunctionTaskUpdateMessage,
  FunctionUpdateMessage,
  FunctionUpdateMessageTypeEnum,
  GuidelineUpdateMessage,
  GuidelineUpdateMessageClassificationEnum,
  IssueUpdateMessage,
  MeetingUpdateMessage,
  PartnerUpdateMessage,
  ReportUpdateMessage,
  ReportUpdateMessageClassificationEnum,
  RiskAssessmentUpdateMessage,
  SubstanceUpdateMessage,
} from 'src/store/api';
import { getOrganization } from 'src/store';
import type { State } from 'src/store/types.js';

function documentUpdateMessage(detail: DocumentEditDoneDetail): DocumentUpdateMessage {
  return {
    name: detail.editItem.name,
    content: detail.editItem.content,
    accessControl: detail.editItem.accessControl,
    classification: detail.editItem.classification as DocumentUpdateMessageClassificationEnum,
    validFromDate: detail.editItem.validFromDate,
    validToDate: detail.editItem.validToDate,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function employeeUpdateMessage(detail: EmployeeEditDoneDetail): EmployeeUpdateMessage {
  return {
    address: detail.editItem.address,
    profession: detail.editItem.profession,
    notes: detail.editItem.notes,
    email: detail.editItem.email,
    lastName: detail.editItem.lastName,
    herNumber: detail.editItem.herNumber,
    firstName: detail.editItem.firstName,
    hprNumber: detail.editItem.hprNumber,
    expertise: detail.editItem.expertise,
    associationType: detail.editItem.associationType,
    accessLevel: detail.editItem.accessLevel as EmployeeUpdateMessageAccessLevelEnum,
    phone: detail.editItem.phone,
    secondaryPhone: detail.editItem.secondaryPhone,
    nextOfKin: detail.editItem.nextOfKin,
    speciality: '',
    status: detail.editItem.status as EmployeeUpdateMessageStatusEnum,
    functionAssignments: detail.editItem.functionAssignments,
    gender: detail.editItem.gender,
    isConfirmedEntity: true,
    personalIdentifier: detail.editItem.personalIdentifier,
  };
}

function functionUpdateMessage(detail: FunctionEditDoneDetail): FunctionUpdateMessage {
  return {
    name: detail.editItem.name,
    // responsibility: detail.editItem.responsibility,
    type: detail.editItem.functionType as FunctionUpdateMessageTypeEnum,
    description: detail.editItem.description,
    rotation: detail.editItem.rotationType,
    rotations: detail.editItem.rotations,
    pages: detail.editItem.pages.map((x) => Number(x)),
    employees: detail.editItem.employees,
    isConfirmedEntity: true,
  };
}

function functionTaskUpdateMessage(state: State, detail: TaskEditDoneDetail): FunctionTaskUpdateMessage {
  const organization = getOrganization(state);
  if (organization) {
    return {
      assetSelection: false, // TODO remove property
      name: detail.editItem.name,
      procedures: detail.editItem.procedures,
      situation: detail.editItem.situation,
      functionUuid: detail.editItem.functionUuid,
      responsibleFunctionUuid: detail.editItem.responsibleFunctionUuid,
      pages: detail.editItem.pages.map((x) => Number(x)),
      isConfirmedEntity: true,
    };
  }
  throw new Error('Illegal state (E11), no organization');
}

function issueUpdateMessage(detail: IssueEditDoneDetail): IssueUpdateMessage {
  return {
    name: detail.editItem.name,
    description: detail.editItem.description,
    reportedDate: detail.editItem.reportedDate,
    reportedTo: detail.editItem.reportedTo,
    reportedBy: detail.editItem.reportedBy,
    immediateMeasures: detail.editItem.immediateMeasures,
    furtherMeasures: detail.editItem.furtherMeasures,
    eventDate: detail.editItem.eventDate,
    notes: detail.editItem.notes,
    identifiedMeasures: detail.editItem.identifiedMeasures,
    processedBy: detail.editItem.processedBy,
    evaluatedBy: detail.editItem.evaluatedBy,
    processedDate: detail.editItem.processedDate,
    evaluatedDate: detail.editItem.evaluatedDate,
    evaluated: detail.editItem.evaluated,
    implementedMeasure: detail.editItem.implementedMeasure,
    informationLeakagePossibility: detail.editItem.informationLeakagePossibility,
    personDamagePossibility: detail.editItem.personDamagePossibility,
    relateToEmployees: detail.editItem.relateToEmployees,
    relateToEquipment: detail.editItem.relateToEquipment,
    relateToPatient: detail.editItem.relateToPatient,
    relateToRadiation: detail.editItem.relateToRadiation,
    requiresMeasures: detail.editItem.requiresMeasures,
    measuresSatisfactorily: detail.editItem.measuresSatisfactorily,
    evaluatedOk: detail.editItem.evaluatedOk,
    workRelatedHealthIssueEmployee: detail.editItem.workRelatedHealthIssueEmployee,
    workRelatedHealthIssue: detail.editItem.workRelatedHealthIssue,
    accessControl: detail.editItem.accessControl,
    workRelatedSicknessCausingEnvironment: detail.editItem.workRelatedSicknessCausingEnvironment,
    workRelatedSicknessCausingTaskLocation: detail.editItem.workRelatedSicknessCausingTaskLocation,
    workRelatedSicknessCausingTask: detail.editItem.workRelatedSicknessCausingTask,
    relateToMaritimeHealthCertificate: detail.editItem.relateToMaritimeHealthCertificate,
    workRelatedInjuryIncidentCause: detail.editItem.workRelatedInjuryIncidentCause,
    workRelatedHealthIssueDescription: detail.editItem.workRelatedHealthIssueDescription,
    workRelatedSickness: detail.editItem.workRelatedSickness,
    workRelatedInjuryTask: detail.editItem.workRelatedInjuryTask,
    workRelatedInjury: detail.editItem.workRelatedInjury,
    classification: detail.editItem.classification as GuidelineUpdateMessageClassificationEnum,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function metingUpdateMessage(_detail: MeetingEditDoneDetail): MeetingUpdateMessage {
  throw new Error('Illegal access. Meetings are virtual');
}

function contractUpdateMessage(detail: ContractEditDoneDetail): ContractUpdateMessage {
  return {
    name: detail.editItem.name,
    content: detail.editItem.content,
    employees: detail.editItem.employees,
    partners: detail.editItem.partners,
    validFromDate: detail.editItem.validFromDate,
    validToDate: detail.editItem.validToDate,
    accessControl: detail.editItem.accessControl,
    classification: detail.editItem.classification as ContractUpdateMessageClassificationEnum,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function substanceUpdateMessage(detail: SubstanceEditDoneDetail): SubstanceUpdateMessage {
  return {
    name: detail.editItem.name,
    supplierUuid: detail.editItem.supplierUuid,
    hazardLabels: detail.editItem.hazardLabels,
    applications: detail.editItem.applications,
    location: detail.editItem.location,
    archived: detail.editItem.archived,
    notes: detail.editItem.notes,
    isConfirmedEntity: true,
  };
}

function riskAssessmentUpdateMessage(detail: RiskAssessmentEditDoneDetail): RiskAssessmentUpdateMessage {
  return {
    name: detail.editItem.name,
    description: detail.editItem.description,
    responsibleBy: detail.editItem.responsibleBy,
    probability: detail.editItem.probability,
    consequence: detail.editItem.consequence,
    riskDescription: detail.editItem.riskDescription,
    measures: detail.editItem.measures,
    assessmentDate: detail.editItem.assessmentDate,
    violatesInformationSecurityRequirements: detail.editItem.violatesInformationSecurityRequirements,
    violatesIntegrityRequirement: detail.editItem.violatesIntegrityRequirement,
    violatesConfidentialityRequirement: detail.editItem.violatesConfidentialityRequirement,
    violatesNonRepudiationRequirement: detail.editItem.violatesNonRepudiationRequirement,
    violatesAvailabilityRequirement: detail.editItem.violatesAvailabilityRequirement,
    determinedBy: detail.editItem.determinedBy,
    pages: detail.editItem.pages.map((x) => Number(x)),
    createdFromTemplate: detail.editItem.createdFromTemplate,
    relatedSubstance: detail.editItem.relatedSubstance,
    isConfirmedEntity: true,
  };
}

function reportUpdateMessage(detail: ReportEditDoneDetail): ReportUpdateMessage {
  return {
    name: detail.editItem.name,
    content: detail.editItem.content,
    reportDate: detail.editItem.reportDate,
    employee: detail.editItem.employee,
    accessControl: detail.editItem.accessControl,
    classification: detail.editItem.classification as ReportUpdateMessageClassificationEnum,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function contactUpdateMessage(detail: ContactEditDoneDetail): ContactPersonUpdateMessage {
  return {
    notes: detail.editItem.notes,
    email: detail.editItem.email,
    lastName: detail.editItem.lastName,
    firstName: detail.editItem.firstName,
    telephone: detail.editItem.telephone,
    mobilePhone: detail.editItem.mobilePhone,
    accessLevel: detail.editItem.accessLevel as ContactPersonUpdateMessageAccessLevelEnum,
    accessExpires: detail.editItem.accessExpires,
    isConfirmedEntity: true,
    personalIdentifier: detail.editItem.personalIdentifier,
  };
}

function partnerUpdateMessage(detail: PartnerEditDoneDetail): PartnerUpdateMessage {
  return {
    name: detail.editItem.name,
    email: detail.editItem.email,
    url: detail.editItem.url,
    notes: detail.editItem.notes,
    address: detail.editItem.address,
    industry: detail.editItem.industry,
    phone: detail.editItem.phone,
    remoteAccess: detail.editItem.remoteAccess,
    physicalAccess: detail.editItem.physicalAccess,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function guidelineUpdateMessage(detail: GuidelineEditDoneDetail): GuidelineUpdateMessage {
  return {
    name: detail.editItem.name,
    content: detail.editItem.content,
    accessControl: detail.editItem.accessControl,
    classification: detail.editItem.classification as GuidelineUpdateMessageClassificationEnum,
    pages: detail.editItem.pages.map((x) => Number(x)),
    isConfirmedEntity: true,
  };
}

function constitutionalDocumentUpdateMessage(
  detail: ConstitutionalDocumentEditDoneDetail,
): ConstitutionalDocumentUpdateMessage {
  return {
    name: detail.editItem.name,
    content: detail.editItem.content,
    accessControl: detail.editItem.accessControl,
    classification: detail.editItem.classification as ConstitutionalDocumentUpdateMessageClassificationEnum,
    validFromDate: detail.editItem.validFromDate,
    validToDate: detail.editItem.validToDate,
    employees: detail.editItem.employees,
    partners: detail.editItem.partners,
    isConfirmedEntity: true,
  };
}

function assetUpdateMessage(detail: AssetEditDoneDetail): AssetsUpdateMessage {
  return {
    name: detail.editItem.name,
    number: detail.editItem.number,
    category: detail.editItem.category,
    owner: detail.editItem.owner,
    manufacturer: detail.editItem.manufacturer,
    model: detail.editItem.model,
    location: detail.editItem.location,
    userManual: detail.editItem.userManual,
    supplier: detail.editItem.supplier,
    acquiredYear: detail.editItem.acquiredYear,
    discardedYear: detail.editItem.discardedYear,
    accessory: detail.editItem.accessory,
    competency: detail.editItem.competency,
    maintenance: detail.editItem.maintenance,
    complianceDeclaration: detail.editItem.complianceDeclaration,
    requiresElectricity: detail.editItem.requiresElectricity,
    maintenanceRequired: detail.editItem.maintenanceRequired,
    assetVerified: detail.editItem.assetVerified,
    documentationVerified: detail.editItem.documentationVerified,
    documented: detail.editItem.documented,
    radiation: detail.editItem.radiation,
    radiationType: detail.editItem.radiationType,
    dsaRegistered: detail.editItem.dsaRegistered,
    dsaApproved: detail.editItem.dsaApproved,
    maintenanceInterval: '', // deprecated
    storesSensitiveInformation: false, // deprecated
    isConfirmedEntity: true,
  };
}

/**
 *
 * @param state - state from redux used to initialize data if elements are created in a context
 * @param detail
 */
export function updateMessage(state: State, detail: ContentEditDoneDetail) {
  switch (detail.type) {
    case 'documents':
      return documentUpdateMessage(detail);
    case 'employees':
      return employeeUpdateMessage(detail);
    case 'functions':
      return functionUpdateMessage(detail);

    case 'tasks':
      return functionTaskUpdateMessage(state, detail);

    case 'issues':
      return issueUpdateMessage(detail);

    case 'meetings':
      return metingUpdateMessage(detail);

    case 'contracts':
      return contractUpdateMessage(detail);

    case 'substances':
      return substanceUpdateMessage(detail);

    case 'riskAssessments':
      return riskAssessmentUpdateMessage(detail);

    case 'reports':
      return reportUpdateMessage(detail);

    case 'contacts':
      return contactUpdateMessage(detail);

    case 'partners':
      return partnerUpdateMessage(detail);

    case 'guidelines':
      return guidelineUpdateMessage(detail);

    case 'constitutionalDocuments':
      return constitutionalDocumentUpdateMessage(detail);

    case 'assets':
      return assetUpdateMessage(detail);
  }
}
