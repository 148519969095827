/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  IssueSimpleUpdateMessage,
  IssueUpdateMessage,
  IssueViewModel,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  IssueSimpleUpdateMessageFromJSON,
  IssueSimpleUpdateMessageToJSON,
  IssueUpdateMessageFromJSON,
  IssueUpdateMessageToJSON,
  IssueViewModelFromJSON,
  IssueViewModelToJSON,
} from '../models';

export interface CreateIssueRequest {
  issueId: string;
  organizationId: string;
  issueSimpleUpdateMessage: IssueSimpleUpdateMessage;
}

export interface CreateOrUpdateIssueRequest {
  issueId: string;
  organizationId: string;
  issueUpdateMessage: IssueUpdateMessage;
}

export interface DeleteAttachmentForIssueRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteIssueRequest {
  issueId: string;
  organizationId: string;
}

export interface FetchAttachmentForIssueRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForIssueRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchIssueRequest {
  issueId: string;
  organizationId: string;
}

export interface ListAttachmentsForIssueRequest {
  entityId: string;
  organizationId: string;
}

export interface ListIssuesRequest {
  organizationId: string;
}

export interface RenameAttachmentForIssueRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UploadAttachmentForIssueRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class IssuesApi extends runtime.BaseAPI {
  /**
   * Creates an issue with only basic information.
   */
  async createIssueRaw(
    requestParameters: CreateIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        'issueId',
        'Required parameter requestParameters.issueId was null or undefined when calling createIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createIssue.',
      );
    }

    if (
      requestParameters.issueSimpleUpdateMessage === null ||
      requestParameters.issueSimpleUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'issueSimpleUpdateMessage',
        'Required parameter requestParameters.issueSimpleUpdateMessage was null or undefined when calling createIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{issueId}/simple`
          .replace(`{${'issueId'}}`, encodeURIComponent(String(requestParameters.issueId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: IssueSimpleUpdateMessageToJSON(requestParameters.issueSimpleUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates an issue with only basic information.
   */
  async createIssue(
    requestParameters: CreateIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createIssueRaw(requestParameters, initOverrides);
  }

  /**
   * Update or create issue with the specified issue id.
   * Creates or updates an issue.
   */
  async createOrUpdateIssueRaw(
    requestParameters: CreateOrUpdateIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        'issueId',
        'Required parameter requestParameters.issueId was null or undefined when calling createOrUpdateIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateIssue.',
      );
    }

    if (requestParameters.issueUpdateMessage === null || requestParameters.issueUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'issueUpdateMessage',
        'Required parameter requestParameters.issueUpdateMessage was null or undefined when calling createOrUpdateIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{issueId}`
          .replace(`{${'issueId'}}`, encodeURIComponent(String(requestParameters.issueId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: IssueUpdateMessageToJSON(requestParameters.issueUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create issue with the specified issue id.
   * Creates or updates an issue.
   */
  async createOrUpdateIssue(
    requestParameters: CreateOrUpdateIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateIssueRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an issue.
   */
  async deleteAttachmentForIssueRaw(
    requestParameters: DeleteAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForIssue.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an issue.
   */
  async deleteAttachmentForIssue(
    requestParameters: DeleteAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForIssueRaw(requestParameters, initOverrides);
  }

  /**
   * Delete issue with the specified issue id, the search index of the issue will also be deleted.
   * Deletes an issue.
   */
  async deleteIssueRaw(
    requestParameters: DeleteIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        'issueId',
        'Required parameter requestParameters.issueId was null or undefined when calling deleteIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{issueId}`
          .replace(`{${'issueId'}}`, encodeURIComponent(String(requestParameters.issueId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete issue with the specified issue id, the search index of the issue will also be deleted.
   * Deletes an issue.
   */
  async deleteIssue(
    requestParameters: DeleteIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteIssueRaw(requestParameters, initOverrides);
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForIssueRaw(
    requestParameters: FetchAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForIssue.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForIssue(
    requestParameters: FetchAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForIssueRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an issue.
   */
  async fetchAttachmentLinkForIssueRaw(
    requestParameters: FetchAttachmentLinkForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForIssue.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an issue.
   */
  async fetchAttachmentLinkForIssue(
    requestParameters: FetchAttachmentLinkForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForIssueRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the issue with the specified issue id.
   * Gets an issue.
   */
  async fetchIssueRaw(
    requestParameters: FetchIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<IssueViewModel>> {
    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        'issueId',
        'Required parameter requestParameters.issueId was null or undefined when calling fetchIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{issueId}`
          .replace(`{${'issueId'}}`, encodeURIComponent(String(requestParameters.issueId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => IssueViewModelFromJSON(jsonValue));
  }

  /**
   * Get the issue with the specified issue id.
   * Gets an issue.
   */
  async fetchIssue(
    requestParameters: FetchIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IssueViewModel> {
    const response = await this.fetchIssueRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an issue.
   */
  async listAttachmentsForIssueRaw(
    requestParameters: ListAttachmentsForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an issue.
   */
  async listAttachmentsForIssue(
    requestParameters: ListAttachmentsForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForIssueRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the issues within the specified organization.
   * Lists issues.
   */
  async listIssuesRaw(
    requestParameters: ListIssuesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<IssueViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listIssues.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IssueViewModelFromJSON));
  }

  /**
   * Get all the issues within the specified organization.
   * Lists issues.
   */
  async listIssues(
    requestParameters: ListIssuesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<IssueViewModel>> {
    const response = await this.listIssuesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an issue.
   */
  async renameAttachmentForIssueRaw(
    requestParameters: RenameAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForIssue.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForIssue.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an issue.
   */
  async renameAttachmentForIssue(
    requestParameters: RenameAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForIssueRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an issue.
   */
  async uploadAttachmentForIssueRaw(
    requestParameters: UploadAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForIssue.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForIssue.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForIssue.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/issues/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an issue.
   */
  async uploadAttachmentForIssue(
    requestParameters: UploadAttachmentForIssueRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForIssueRaw(requestParameters, initOverrides);
  }
}
