/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CancelSignaturesCommand
 */
export interface CancelSignaturesCommand {
  /**
   *
   * @type {string}
   * @memberof CancelSignaturesCommand
   */
  signatureOrderId: string;
  /**
   *
   * @type {string}
   * @memberof CancelSignaturesCommand
   */
  entityType: string;
  /**
   *
   * @type {string}
   * @memberof CancelSignaturesCommand
   */
  entityUuid: string;
}

/**
 * Check if a given object implements the CancelSignaturesCommand interface.
 */
export function instanceOfCancelSignaturesCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'signatureOrderId' in value;
  isInstance = isInstance && 'entityType' in value;
  isInstance = isInstance && 'entityUuid' in value;

  return isInstance;
}

export function CancelSignaturesCommandFromJSON(json: any): CancelSignaturesCommand {
  return CancelSignaturesCommandFromJSONTyped(json, false);
}

export function CancelSignaturesCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelSignaturesCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    signatureOrderId: json['signatureOrderId'],
    entityType: json['entityType'],
    entityUuid: json['entityUuid'],
  };
}

export function CancelSignaturesCommandToJSON(value?: CancelSignaturesCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    signatureOrderId: value.signatureOrderId,
    entityType: value.entityType,
    entityUuid: value.entityUuid,
  };
}
