/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateOrganizationInput
 */
export interface CreateOrganizationInput {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  ownerEmail?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateOrganizationInput
   */
  addons?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  ownerFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  ownerLastName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  module?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  plan?: CreateOrganizationInputPlanEnum;
  /**
   *
   * @type {number}
   * @memberof CreateOrganizationInput
   */
  employeesCount?: number;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  specialTerms?: CreateOrganizationInputSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  sector?: CreateOrganizationInputSectorEnum;
}

/**
 * @export
 */
export const CreateOrganizationInputPlanEnum = {
  A: 'A',
  B: 'B',
  C: 'C',
  X: 'X',
} as const;
export type CreateOrganizationInputPlanEnum =
  (typeof CreateOrganizationInputPlanEnum)[keyof typeof CreateOrganizationInputPlanEnum];

/**
 * @export
 */
export const CreateOrganizationInputSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type CreateOrganizationInputSpecialTermsEnum =
  (typeof CreateOrganizationInputSpecialTermsEnum)[keyof typeof CreateOrganizationInputSpecialTermsEnum];

/**
 * @export
 */
export const CreateOrganizationInputSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type CreateOrganizationInputSectorEnum =
  (typeof CreateOrganizationInputSectorEnum)[keyof typeof CreateOrganizationInputSectorEnum];

/**
 * Check if a given object implements the CreateOrganizationInput interface.
 */
export function instanceOfCreateOrganizationInput(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CreateOrganizationInputFromJSON(json: any): CreateOrganizationInput {
  return CreateOrganizationInputFromJSONTyped(json, false);
}

export function CreateOrganizationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrganizationInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ownerEmail: !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
    addons: !exists(json, 'addons') ? undefined : json['addons'],
    ownerFirstName: !exists(json, 'ownerFirstName') ? undefined : json['ownerFirstName'],
    ownerLastName: !exists(json, 'ownerLastName') ? undefined : json['ownerLastName'],
    name: !exists(json, 'name') ? undefined : json['name'],
    number: !exists(json, 'number') ? undefined : json['number'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postcode: !exists(json, 'postcode') ? undefined : json['postcode'],
    locality: !exists(json, 'locality') ? undefined : json['locality'],
    module: !exists(json, 'module') ? undefined : json['module'],
    plan: !exists(json, 'plan') ? undefined : json['plan'],
    employeesCount: !exists(json, 'employeesCount') ? undefined : json['employeesCount'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: !exists(json, 'sector') ? undefined : json['sector'],
  };
}

export function CreateOrganizationInputToJSON(value?: CreateOrganizationInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ownerEmail: value.ownerEmail,
    addons: value.addons,
    ownerFirstName: value.ownerFirstName,
    ownerLastName: value.ownerLastName,
    name: value.name,
    number: value.number,
    address: value.address,
    postcode: value.postcode,
    locality: value.locality,
    module: value.module,
    plan: value.plan,
    employeesCount: value.employeesCount,
    specialTerms: value.specialTerms,
    sector: value.sector,
  };
}
