/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateSignaturePersonCommand
 */
export interface CreateSignaturePersonCommand {
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  partnerUuid: string;
  /**
   *
   * @type {string}
   * @memberof CreateSignaturePersonCommand
   */
  personalIdentifier?: string;
}

/**
 * Check if a given object implements the CreateSignaturePersonCommand interface.
 */
export function instanceOfCreateSignaturePersonCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'partnerUuid' in value;

  return isInstance;
}

export function CreateSignaturePersonCommandFromJSON(json: any): CreateSignaturePersonCommand {
  return CreateSignaturePersonCommandFromJSONTyped(json, false);
}

export function CreateSignaturePersonCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSignaturePersonCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: !exists(json, 'email') ? undefined : json['email'],
    partnerUuid: json['partnerUuid'],
    personalIdentifier: !exists(json, 'personalIdentifier') ? undefined : json['personalIdentifier'],
  };
}

export function CreateSignaturePersonCommandToJSON(value?: CreateSignaturePersonCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    partnerUuid: value.partnerUuid,
    personalIdentifier: value.personalIdentifier,
  };
}
