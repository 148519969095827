import { displayAlert } from 'src/utilities/display-alert.js';
import * as dabihStore from 'src/store';
import { createClient, getStore, loginUserAction } from 'src/store';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import type { LitElement } from 'lit';
import { installRouter } from 'pwa-helpers/router.js';
import { updatePath } from 'src/store/actions';
import { LocalDateTime } from 'src/utilities/local-date-time.js';
import { clearTokens, hasAccessToken } from 'src/store/oauth-browser'; /**
 *
 * The redux store contains a normalized view of the current organization.
 *
 * All entities are at the top level under organization.
 *
 *
 *
 */

/**
 *
 * The redux store contains a normalized view of the current organization.
 *
 * All entities are at the top level under organization.
 *
 *
 *
 */

console.log('Initializing dabih store at ' + LocalDateTime.now().toString());
dabihStore.initActions((error: Error) => Bugsnag.notify(error), displayAlert);

// Create an instance of reduxConnectMixin
// export const reduxConnectMixin = createMixin(getStore());

type GConstructor<T = unknown> = new (...args: any[]) => T;

export function reduxConnectMixin<TBase extends GConstructor<LitElement>>(Base: TBase) {
  const d = getStore().dispatch;
  type TestParams = Parameters<typeof d>;
  type FirstParam = TestParams[0];
  return class reduxElement extends connect(getStore())(Base) {
    dispatchAction(args: FirstParam) {
      return d(args);
    }
  };
}

window.document.addEventListener<any>('navigate', async (e: CustomEvent<{ href: string }>) => {
  window.history.pushState({}, '', e.detail.href);
  await handleNavigation(window.location);
});

export async function handleNavigation(location: Location) {
  window.scrollTo(0, 0);
  console.trace();
  console.log('Navigation ' + location.href);
  if (location.pathname === '/iframe.html') {
    console.log('Ignoring redux navigation for storybook rendering');
    return;
  }

  const path = decodeURIComponent(location.pathname);
  const query = location.search.slice(1);
  const params = new URLSearchParams(query);
  const m: Record<string, string> = {};
  params.forEach((v, k) => {
    m[k] = v;
  });

  const username = localStorage.getItem('dabih-username');
  const accessToken = await hasAccessToken();
  const hasUser = username !== null || accessToken;

  // const user = await currentUser();

  // console.log('cognito', user);

  if (hasUser) {
    console.log('User: ' + username + ' ' + path);
    const usernameFromLocalStorage = localStorage.getItem('dabih-username');
    console.log('Local Storage: ' + usernameFromLocalStorage);
    getStore().dispatch(updatePath(path, m));
    const u = getStore().getState().user;
    if (u === undefined) {
      try {
        const result = await getStore().dispatch(loginUserAction(createClient(), username ?? ''));
        if (!result) {
          console.log('Log out');
          logOut(false);
        }
      } catch (e) {
        console.error(JSON.stringify(e));
      }
    }

    const targetUrl = sessionStorage.getItem('targetUrl');
    if (targetUrl !== null) {
      console.log('Redirecting to targetUrl ' + targetUrl);
      sessionStorage.removeItem('targetUrl');
      setTimeout(async () => {
        window.history.pushState({}, '', targetUrl);
        await handleNavigation(window.location);
      }, 0);
    }
  } else if (path === '/link') {
    getStore().dispatch(updatePath(path, m));
    console.log('Navigate to link');
  } else if (path === '/signup') {
    getStore().dispatch(updatePath(path, m));
    console.log('Navigate to signup');
  } else {
    console.log('No user, navigate to signin');
    window.history.pushState({}, '', '/signin');
    dabihStore.getStore().dispatch(updatePath('/signin', m));
  }

  // window.scrollTo({ top: 0, left: 0 });
}

export function logOut(redirect: boolean) {
  console.log('Log out', redirect);
  Bugsnag.setUser();

  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    Bugsnag.notify(error as NotifiableError);
  }

  setTimeout(() => {
    clearTokens().then(() => {
      window.location.href = '/';
    });
  }, 15);
}

installRouter((location) => handleNavigation(location));

setInterval(() => {
  const issued = localStorage.getItem('dabih-issued');
  if (issued !== null) {
    const issuedAt = new Date(issued);
    const now = new Date();
    const diffMs = now.getTime() - issuedAt.getTime(); // milliseconds between now & issued
    const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffMinutes > 60) {
      displayAlert('Innlogging avsluttet');
      logOut(false);
    }
  }
}, 30000);
