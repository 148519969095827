import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { DEditText } from 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/elements/d-smooth-resize';
import { LabeledElement } from 'src/library/abstracts/labeled-element';

export interface Suggestion {
  name: string;
  type: string;
  dataValue?: string;
}

/**
 *
 */
@customElement('d-edit-select-text')
export class DEditSelectText extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        line-height: var(--lineHeightDefault);
      }
      #options {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-height: 190px;
        margin: 0 -1px;
        border-left: 1px solid var(--borderColorLight);
        border-right: 1px solid var(--borderColorLight);
        border-bottom: 1px solid var(--borderColorLight);
        overflow: auto;
        background: white;
        z-index: 1;
      }
      :host([outskirts]) #options {
        margin-top: 1px;
        border-radius: var(--inputElementBorderRadius);
        border: none;
      }
      .option {
        border-top: 1px solid var(--borderColorLight);
        background-size: 22px;
        background-repeat: no-repeat;
        background-position: 4px 5px;
        padding: 6px 14px 6px 30px;
        font-family: var(--mainSerif);
        color: var(--linkColorGray);
        cursor: pointer;
        text-decoration: none;
      }
      .option:first-child {
        border: none;
      }
      :host([system-content]) .option {
        font-family: var(--mainSans);
      }
      .option[no-type] {
        padding-left: 10px;
      }
      .option:focus-visible,
      .option[selected] {
        outline: none;
        background-color: var(--themeColor);
        color: white;
      }
      :host([outskirts]) .option:focus-visible,
      :host([outskirts]) .option[selected] {
        background-color: var(--themeColorDarkerOne);
      }
      .option.events {
        background-image: url(/images/events-gray.svg);
      }
      .option.tasks {
        background-image: url(/images/tasks-gray.svg);
      }
      .option.meetings {
        background-image: url(/images/meetings-gray.svg);
      }
      @media (hover: hover) {
        .option:hover {
          color: black;
        }
        .option:focus-visible:hover,
        .option[selected]:hover {
          color: white;
        }
      }
    `,
  ];

  @query('#input')
  editText!: DEditText;
  @property({ type: Boolean })
  button = false;
  @property({ type: Boolean })
  autofocus = false;
  @property({ type: Boolean, attribute: 'select-on-focus' })
  selectOnFocus = false;
  @property({ type: Boolean, attribute: 'mark-if-empty' })
  markIfEmpty = false;
  @property({ type: String })
  value = '';
  @property({ type: String })
  dataValue = '';
  @property({ type: Array })
  items: Suggestion[] = [];
  @property({ type: Array })
  options: Suggestion[] = [];
  @property({ type: String })
  selectedItem = '';
  @query('d-edit-text')
  inputElm!: DEditText;
  @query('#options')
  optionsElm!: HTMLElement;
  @property({ type: String })
  placeholder = '';
  onKeyDown(event) {
    if (this.options.length) {
      const focusedElm: HTMLElement = this.shadowRoot?.activeElement as HTMLElement;
      if (focusedElm) {
        if (event.code === 'ArrowDown') {
          this.handleArrowDown(event, focusedElm);
        } else if (event.code === 'ArrowUp') {
          this.handleArrowUp(event, focusedElm);
        } else if (event.code === 'Enter') {
          this.handleEnter(event, focusedElm);
        }
      }
    } else if (event.code === 'Enter') {
      this.dispatchValueSelected();
    }
  }

  focusInput() {
    this.editText.setFocus();
  }

  setValue(e) {
    if (e.target.dataset.value) {
      this.dataValue = e.target.dataset.value;
    }
    this.value = e.target.textContent;
    this.dismissOptions();
    this.dispatchValueSelected();
  }

  dispatchValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  dispatchValueSelected() {
    this.dispatchEvent(
      new CustomEvent('value-selected', {
        bubbles: true,
        composed: true,
        detail: { value: this.value, dataValue: this.dataValue },
      }),
    );
  }

  dismissOptions() {
    this.options = [];
  }

  onValueChanged(e) {
    e.stopPropagation();
    this.value = e.detail.value;
    if (this.value.length > 0 && this.items.length) {
      this.options = this.items.filter((item) => {
        return item.name.toUpperCase().includes(this.value.toUpperCase());
      });
    } else {
      this.options = [];
    }
    this.dispatchValueChanged();
  }

  render() {
    return html`
      <d-edit-text
        id="input"
        .autofocus=${this.autofocus}
        ?outskirts="${this.outskirts}"
        ?system-content="${this.systemContent}"
        select-on-focus
        .button=${this.button}
        ?mark-if-empty="${this.markIfEmpty}"
        .buttonDisabled=${!this.value}
        .label=${this.label}
        .sublabel=${this.sublabel}
        .field=${this.field}
        .placeholder=${this.placeholder}
        .value=${this.value}
        @value-changed=${(e) => this.onValueChanged(e)}
      ></d-edit-text>
      <d-smooth-resize>
        ${this.options.length
          ? html`
              <div id="options">
                ${this.options.map(
                  (item) => html`
                    <a
                      class="option ${item.type}"
                      ?no-type="${!item.type}"
                      data-value="${item.dataValue ?? ''}"
                      tabindex="0"
                      @click=${(e) => this.setValue(e)}
                      >${item.name}</a
                    >
                  `,
                )}
              </div>
            `
          : nothing}
      </d-smooth-resize>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.getRootNode().addEventListener('keydown', (e: Event) => this.onKeyDown(e));
  }

  protected handleEnter(event, focusedElm: HTMLElement) {
    event.preventDefault();
    if (focusedElm.tagName === 'D-EDIT-TEXT' && this.optionsElm.firstElementChild) {
      this.dismissOptions();
    }
    if (focusedElm.classList.contains('option')) {
      this.value = focusedElm.textContent ?? '';
      this.dataValue = focusedElm.dataset.value ?? '';
      this.dismissOptions();
    }
    if (this.value) {
      this.dispatchValueSelected();
    }
  }

  protected handleArrowUp(event, focusedElm: HTMLElement) {
    event.preventDefault();
    if (focusedElm.classList.contains('option')) {
      const nextElm = focusedElm.previousElementSibling as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      } else {
        this.inputElm.setFocus();
      }
    }
  }

  protected handleArrowDown(event, focusedElm: HTMLElement) {
    event.preventDefault();
    if (focusedElm.tagName === 'D-EDIT-TEXT') {
      const nextElm = this.optionsElm.firstElementChild as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      }
    }
    if (focusedElm.classList.contains('option')) {
      const nextElm = focusedElm.nextElementSibling as HTMLAnchorElement;
      if (nextElm) {
        nextElm.focus();
      } else {
        this.inputElm.setFocus();
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-select-text': DEditSelectText;
  }
}
