import { css } from 'lit';

export const signatureStatusStyles = css`
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .icon.idle {
    display: inline-block;
  }

  .icon.idle:after {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin: 1px 2px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.2);
    animation: rotate 1.2s linear infinite;
  }

  .icon.idle.noAnimation:after {
    animation: none;
  }

  .icon.signed {
    background: url(/images/check-blue.svg) 50% -3px no-repeat;
    background-size: 24px 24px;
  }

  .icon.error {
    background: url(/images/x-fat-orange.svg) 50% -2px no-repeat;
    background-size: 24px 24px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
