export { loadedApplicationViewModel, applicationViewModel } from 'src/models/application-view-model.js';
export {
  accessChangeHandler,
  backupChangeHandler,
  mapElementDeletedHandler,
  mapElementChangedHandler,
  computerNetworkChangeHandler,
  externalConnectChangeHandler,
  dataItemsRemovedHandler,
} from 'src/handlers/computers-handlers.js';

export { createToken } from './utilities/create-token.js';

export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    const error = new Error(`Value is not defined`);
    console.log('Not defined', error.stack ?? 'No stack trace available');
    throw error;
  }
}
